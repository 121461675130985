import React from "react";

export const checkExist = (name) => {
  return localStorage.getItem(name);
};

export const updateUserProperty = (item, key, value) => {
  const localItem = localStorage.getItem(item);
  const jsonItem = JSON.parse(localItem);
  jsonItem.user[key] = value;
  localStorage.setItem(item, JSON.stringify(jsonItem));
  return jsonItem;
};

export const updateProperty = (item, key, value) => {
  const localItem = localStorage.getItem(item);
  const jsonItem = JSON.parse(localItem);
  jsonItem[key] = value;
  localStorage.setItem(item, JSON.stringify(jsonItem));
  return jsonItem;
};

export const updateProperties = (item, kvp) => {
  const localItem = localStorage.getItem(item);
  const jsonItem = JSON.parse(localItem);
  kvp.map((property) => {
    return (jsonItem[property.name] = property.value);
  });

  localStorage.setItem(item, JSON.stringify(jsonItem));
  return jsonItem;
};

export const getItem = (item) => {
  const localItem = localStorage.getItem(item);
  const jsonItem = JSON.parse(localItem);
  return jsonItem;
};
