import React from "react";

export default function Scribes() {
  const scribes = [
    {
      link: "https://scribehow.com/embed/Bayonet__opening_a_prospect__xmh7G2YtSIiVR_KQyFbjOw",
      width: 640,
      height: 640,
      title: "How to bla bla",
    },
    {
      link: "https://scribehow.com/embed/Bayonet__unlocking_compromised_employees_and_users__LlevfEKrSvqkHH7YB_lUsA",
      width: 640,
      height: 640,
      title: "How to bla bla",
    },
    {
      link: "https://scribehow.com/embed/Bayonet__External_Attack_Surface__CudfXM7kQb-G2z3KbmZ0bw",
      width: 640,
      height: 640,
      title: "How to bla bla",
    },
    {
      link: "https://scribehow.com/embed/Bayonet__Technology_Discovery__rsxpgkKKQ_y_IQJb36T2Cw",
      width: 640,
      height: 640,
      title: "How to bla bla",
    },
    {
      link: "https://scribehow.com/embed/Bayonet__Finding_prospect_using_filters__IiyKZ40OSh2af4mxKOXWzg",
      width: 640,
      height: 640,
      title: "How to bla bla",
    },
    {
      link: "https://scribehow.com/embed/Bayonet__Search_companies_with_vulnerabilities_based_on_keyword__rlC2Jf7vQ5KbrN2jW1w9uA",
      width: 640,
      height: 640,
      title: "How to bla bla",
    },
  ];
  return (
    <div className="scribes">
      {scribes.map((item, index) => (
        <div key={index} style={{ width: "100%", minHeight: "500px" }}>
          {/* <h2>{item.title}</h2> */}
          <iframe
            style={{
              border: "2px solid black",
              borderRadius: "10px",
              width: "100%",
              height: "100%",
            }}
            src={item.link}
            // width={item.width}
            // height={item.height}
            allowfullscreen
            frameborder="0"
          ></iframe>
        </div>
      ))}
    </div>
  );
}
