import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import logo from "../../images/logo-1.png";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import CountrySelector from "../Partials/CountrySelector";
import { useLocation } from "react-router";
import CheckoutTable from "./CheckoutTable";
import PaymentFields from "./PaymentFields";
import Footer from "../Partials/Footer";
import { createBrowserHistory } from "history";
import { authAddress, blueSnapAddress, bsEnv } from "../../Config";
import { ImCheckmark, ImTwitter } from "react-icons/im";
import Button from "@material-ui/core/Button";
import axios from "axios";
import LoaderIcon from "react-loader-icon";
import Creditcard from "./CreditCard";
import FormControl from "@material-ui/core/FormControl";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { isValidEmail } from "../../hooks/Validation";

// var perf = require("./PaymentFields.html");
const history = createBrowserHistory();

const queryString = require("query-string");

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },

  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
}));

export default function Checkout(props) {
  const classes = useStyles();
  const [price, setPrice] = useState(1449);
  const [countryCode, setCountryCode] = useState("");
  const [fName, setFname] = useState("");
  const [lName, setLname] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [cName, setCname] = useState("");
  const [cWeb, setCweb] = useState("");
  const [tel, setTel] = useState("");
  const [title, setTitle] = useState("");
  const [userState, setUserState] = useState("");
  const [city, setCity] = useState("");
  const [address, setAddress] = useState("");
  const [address2, setAddress2] = useState("");
  const [zip, setZip] = useState("");
  const [tier, setTier] = useState();
  const [period, setPeriod] = useState("annually");
  const [plan, setPlan] = useState();
  const [planName, setPlanName] = useState("");
  const [paid, setPaid] = useState(false);
  const [accept, setAccept] = useState(false);
  const [stage, setStage] = useState("company");
  // Error States:
  const [fNameError, setFnameError] = useState(false);
  const [lNameError, setLnameError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [cNameError, setCnameError] = useState(false);
  const [cWebError, setCwebError] = useState(false);
  const [telError, setTelError] = useState(false);
  const [titleError, setTitleError] = useState(false);
  const [countryError, setCountryError] = useState(false);
  const [cityError, setCityError] = useState(false);
  const [addressError, setAddressError] = useState(false);
  const [switchTo, setSwitch] = useState("");
  const [initialCharge, setInitialCharge] = useState(0);
  const [enterprisePeriod, setEnterprisePeriod] = useState(null);
  const [initialCreditAmount, setInitialCreditAmount] = useState(0);
  const [recurringCreditAmount, setRecurringCreditAmount] = useState(0);
  const [topUp, setTopUp] = useState(false);

  const basic_monthly = ["2741315", "3332639"];
  const basic_annual = ["2741325", "3332681"];
  const advanced_monthly = ["2801843", "4013076"];
  const advanced_annual = ["2801841", "4025330"];
  const all_sb_plans = ["2741315", "2741325", "2801843", "2801841"];
  const all_prod_plans = ["3332639", "3332681", "4013076", "4025330"];
  const defaultMonthly = "4068550";
  // const defaultMonthly = "2801843";

  const [loading, setLoading] = useState(false);
  const [cardLoading, setCardLoading] = useState(false);
  const [qty, setQty] = useState(1);

  const location = useLocation();

  const planString = queryString.parse(location.search).plan;
  const qtyString = queryString.parse(location.search).qty;

  const fixPath = (plan) => {
    history.push(`/checkout/?plan=${plan}`);
    window.location.reload();
  };

  useEffect(() => {
    if (planString) {
      getPlan(planString);
    } else {
      if (bsEnv === "sandbox") {
        getPlan(defaultMonthly);
      } else {
        getPlan(defaultMonthly);
      }
    }
    if (qtyString) {
      setQty(Number(qtyString));
    }
  }, []);

  const getPlan = async (planString) => {
    setLoading(true);
    await axios
      .get(`${blueSnapAddress}/plans/get-plan?plan=${planString}&env=${bsEnv}`)
      .then((res) => {
        setPlanName(res.data.name);
        setInitialCharge(res.data.initialChargeAmount);
        setPrice(res.data.recurringChargeAmount);
        setPeriod(res.data.chargeFrequency.toLowerCase());
        setPlan(res.data.planId);
        setEnterprisePeriod(res.data.chargeFrequency);
        setInitialCreditAmount(res.data.initial_credit_amount);
        setRecurringCreditAmount(res.data.recurring_credit_amount);
      })
      .catch((err) => {
        if (err.response && err.response.data) {
          if (err.response.data === "Invalid Token") {
            props.handleHomeLogOut();
          } else {
            if (bsEnv === "sandbox") {
              fixPath(defaultMonthly);
            } else {
              fixPath(defaultMonthly);
            }
          }
        } else {
          if (bsEnv === "sandbox") {
            fixPath(defaultMonthly);
          } else {
            fixPath(defaultMonthly);
          }
        }
      });
    setLoading(false);
  };

  const onSelect = (code) => setCountryCode(code);

  const onFieldChange = (e, field) => {
    // setTelError(null);
    e.preventDefault();
    const value = e.target.value;
    const states = [
      setFname,
      setLname,
      setEmail,
      setPassword,
      setCname,
      setCweb,
      setTel,
      setTitle,
      setUserState,
      setCity,
      setAddress,
      setAddress2,
      setZip,
    ];
    states.map((s, index) => {
      index + 1 === field && s(value);
    });
  };

  const validateFields = () => {
    setFnameError(fName ? false : true);
    setLnameError(lName ? false : true);
    setEmailError(isValidEmail(email) ? false : true);
    setPasswordError(password ? false : true);
    setCnameError(cName ? false : true);
    setCwebError(cWeb ? false : true);
    setTelError(isValidNumber(tel) ? false : true);
    setTitleError(title ? false : true);
    setCountryError(countryCode ? false : true);
    setCityError(city ? false : true);
    setAddressError(address ? false : true);
  };

  // const isValidEmail = (email) => {
  //   return /^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/.test(email);
  // };

  const isValidNumber = (number) => {
    return /^[0-9]+$/.test(number);
  };

  const success = () => {
    setPaid(true);
  };

  const switchPeriod = (e) => {
    const _period = e.target.value;
    setPeriod(_period);
    if (_period === "monthly") {
      setPrice(149 * qty);
      if (bsEnv === "sandbox") {
        setPlan(advanced_monthly[0]);
      } else {
        setPlan(advanced_monthly[1]);
      }
    } else {
      setPrice(1449 * qty);
      if (bsEnv === "sandbox") {
        setPlan(advanced_annual[0]);
      } else {
        setPlan(advanced_annual[1]);
      }
    }
  };

  return !loading ? (
    <div>
      {!paid ? (
        <div style={{ margin: "2rem auto", textAlign: "center" }}>
          <img src={logo} alt="logo" width="80" height="80" />
          {props.stage === "company" ? (
            <div>
              <br />
              <h3 className="cyberFont yellow">
                {!planName.includes("TOP-UP")
                  ? "Activate your plan"
                  : "Top-Up your credits"}
              </h3>
              {!enterprisePeriod && period != "ondemand" && (
                <p className="darkText">
                  Choose a billing plan that suits your need, and proceed to
                  payment.
                </p>
              )}
              {/* <span
                style={{
                  borderRadius: "10px",
                  padding: "8px 20px",
                  backgroundColor: "#ffffff54",
                  color: "white",
                  fontSize: "18px",
                }}
              >
                No Initial Payment
              </span> */}
              <div
                style={{
                  margin: "auto",
                  backgroundColor: "white",
                  width: "40%",
                  minHeight: period != "ondemand" ? "400px" : "250px",
                  borderRadius: "10px",
                  padding: "50px",
                }}
              >
                {!enterprisePeriod && (
                  <div
                    className="capitalize"
                    style={{
                      display: "inline-flex",
                      width: "100%",
                      justifyContent: "center",
                    }}
                  >
                    <FormControl
                      component="fieldset"
                      className=""
                      style={{ width: "100%" }}
                    >
                      <RadioGroup
                        onChange={switchPeriod}
                        row
                        aria-label="position"
                        name="position"
                        value={period}
                        style={{
                          display: "inline-flex",
                          width: "100%",
                          justifyContent: "center",
                        }}
                        className="planRadioGroup"
                      >
                        <FormControlLabel
                          value="annually"
                          control={
                            <Radio color="primary" className="planRadios" />
                          }
                          label={
                            <div
                              style={{
                                color: "black",
                                display: "inline-flex",
                                width: "100%",
                                justifyContent: "space-between",
                              }}
                            >
                              <span>Annual</span>{" "}
                              <span className="green">Save 20%</span>
                            </div>
                          }
                          labelPlacement="right"
                        />
                        <FormControlLabel
                          value="monthly"
                          control={
                            <Radio color="primary" className="planRadios" />
                          }
                          label={
                            <div
                              style={{
                                color: "black",
                                display: "inline-flex",
                                width: "100%",
                                justifyContent: "space-between",
                              }}
                            >
                              Monthly
                            </div>
                          }
                          labelPlacement="right"
                        />
                      </RadioGroup>
                    </FormControl>
                  </div>
                )}
                {period != "ondemand" && (
                  <div
                    style={{
                      width: "100%",
                      display: "inline-flex",
                      alignItems: "baseline",
                      marginTop: "15px",
                    }}
                  >
                    <div className="seats" style={{ width: "70%" }}>
                      <h6 style={{ margin: "0px" }}>Seats</h6>
                      {!enterprisePeriod ? (
                        <div className="qtyDiv">
                          <button
                            style={{ borderRadius: "50px 0px 0px 50px" }}
                            onClick={() => {
                              if (qty > 1) {
                                setQty(qty - 1);
                              }
                            }}
                          >
                            -
                          </button>
                          <input
                            type="text"
                            value={qty}
                            onChange={(e) => {
                              if (e.target.value <= 5 && e.target.value >= 1) {
                                setQty(e.target.value);
                              }
                            }}
                            id="qtyInput"
                          />
                          <button
                            style={{ borderRadius: "0px 50px 50px 0px" }}
                            onClick={() => {
                              if (qty < 5) {
                                setQty(qty + 1);
                              }
                            }}
                          >
                            +
                          </button>
                        </div>
                      ) : (
                        <div className="qtyDiv">
                          <button
                            style={{ borderRadius: "50px 0px 0px 50px" }}
                            onClick={() => {
                              if (qty > 1) {
                                setQty(qty - 1);
                              }
                            }}
                          >
                            -
                          </button>
                          <input
                            type="text"
                            value={qty}
                            onChange={(e) => {
                              if (e.target.value >= 1) {
                                setQty(e.target.value);
                              }
                            }}
                            id="qtyInput"
                          />
                          <button
                            style={{ borderRadius: "0px 50px 50px 0px" }}
                            onClick={() => {
                              setQty(qty + 1);
                            }}
                          >
                            +
                          </button>
                        </div>
                      )}
                    </div>
                    <div>
                      <h6>
                        ${price * qty} /{" "}
                        {period === "monthly" ? "month" : "year"}
                      </h6>
                    </div>
                  </div>
                )}
                {period != "ondemand" && (
                  <p
                    style={{
                      fontSize: "14px",
                      textAlign: "left",
                      width: "50%",
                      marginTop: "10px",
                    }}
                    className="cyberFont lightText"
                  >
                    The amount of users you will be able to add under your
                    supervision.
                  </p>
                )}
                <div
                  style={{
                    width: "100%",
                    display: "inline-flex",
                    alignItems: "baseline",
                  }}
                >
                  <div className="seats" style={{ width: "70%" }}>
                    <h6 style={{ margin: "0px" }}>Credits</h6>
                  </div>
                  {!enterprisePeriod ? (
                    <div>
                      <h6>40 / month</h6>
                    </div>
                  ) : period != "ondemand" ? (
                    <div>
                      <h6>{recurringCreditAmount} / month</h6>
                    </div>
                  ) : (
                    <div>
                      <h6>{initialCreditAmount}</h6>
                    </div>
                  )}
                </div>

                {period != "ondemand" && (
                  <p
                    style={{
                      fontSize: "14px",
                      textAlign: "left",
                      width: "50%",
                      marginTop: "2px",
                    }}
                    className="cyberFont lightText"
                  >
                    Every 30 days your credits will top up by the mentioned
                    amount.
                  </p>
                )}

                {initialCreditAmount && (
                  <p
                    style={{
                      fontSize: "14px",
                      textAlign: "left",
                      width: "50%",
                      marginTop: "2px",
                    }}
                    className="cyberFont lightText"
                  >
                    Initial Credits: {initialCreditAmount}
                  </p>
                )}

                {/* {period === "annually" && (
                  <div
                    style={{
                      width: "100%",
                      display: "inline-flex",
                      alignItems: "baseline",
                      // backgroundColor: "#fecd4e",
                      // padding: "10px",
                      // borderRadius: "20px",
                    }}
                  >
                    <div className="seats" style={{ width: "70%" }}>
                      <h6 style={{ margin: "0px" }}>Annual plan saving</h6>
                    </div>
                    <div style={{ width: "20%" }}>
                      <h6 className="green" style={{ fontSize: "22px" }}>
                        ${(149 * 12 - price) * qty}
                      </h6>
                    </div>
                  </div>
                )} */}
                <div style={{ textAlign: "left", width: "80%" }}>
                  <ul className="noDots">
                    {initialCharge > 0 ? (
                      <>
                        <li>${initialCharge * qty}.00 initial charge</li>
                      </>
                    ) : (
                      <>
                        <li style={{ fontWeight: "bold", fontSize: "16px" }}>
                          No Initial Payment
                        </li>
                        <li style={{ fontWeight: "bold" }}>
                          ${price * qty}.00, charged {period} after 7 day trial
                          expires.
                        </li>
                      </>
                    )}
                  </ul>
                </div>
                <button
                  className="btn yellow-bg btn-lg btn-block cyberFont"
                  onClick={() => {
                    history.push(`/payment?plan=${plan}&qty=${qty}`);
                    window.location.reload();
                    // setCardLoading(true);
                    // setTimeout(() => {
                    //   setCardLoading(false);
                    // }, 2000);
                  }}
                  style={{ fontWeight: "bold", width: "100%" }}
                >
                  Continue to secure payment
                </button>
              </div>
            </div>
          ) : (
            <Creditcard
              success={success}
              loading={cardLoading}
              // email={props.user.user.email}
              // firstName={props.user.user.first_name}
              // lastName={props.user.user.last_name}
              plan={plan}
              qty={qty}
              user={props.user}
              period={period}
            />
          )}
        </div>
      ) : (
        <div className="thankyou_details" style={{ minHeight: "90vh" }}>
          <img src={logo} alt="logo" width="80" height="80" />
          <h1 className="anton" style={{ marginTop: "10px" }}>
            Thank You For Your Order!
          </h1>
          <ImCheckmark size={50} className="yellow" />
          {/* <div className="alert alert-success mt-3">
              <p className="thankyou-content">
                Thank for your order. <br /> Your account is under review now,
                you will be notified soon about your account activation by
                email.
              </p>
            </div> */}
          <h3 style={{ fontWeight: "bolder" }}>
            You will receive an email with your order information.
          </h3>
          <h3 style={{ fontWeight: "bolder" }}>
            Your account will be activated shortly.
          </h3>
          <div
            style={{
              color: "black",
              margin: "auto",
              width: "30%",
              marginTop: "30px",
            }}
            className="styledBox yellow-bg"
          >
            <h4 style={{ textAlign: "left" }}>Order Details</h4>
            <ul className="order-details-list">
              <li>First Name: {props.user.user.first_name}</li>
              <li>Last Name: {props.user.user.last_name}</li>
              <li>Email: {props.user.user.email}</li>
              {period != "ondemand" ? (
                <li>Seats: {qty}</li>
              ) : (
                <li>Credits: {initialCreditAmount}</li>
              )}
              {/* <li>Total: ${price * qty}</li> */}
            </ul>
          </div>
          <br />
          <a href="/">
            <Button
              type="button"
              variant="contained"
              className={"searchBtn desktopSearchBtn"}
            >
              Back to Bayonet
            </Button>
          </a>
          <br />
          <br />
          <ImTwitter size={20} />
          <a
            className="darkText"
            href="https://twitter.com/rockhudsonrock"
            target="_blank"
          >
            Follow Us on Twitter.
          </a>
        </div>
      )}
      <Footer checkout={true} noSocial={true} />
    </div>
  ) : (
    <div>
      <LoaderIcon
        type="spin"
        className="tabsLoader"
        style={{ position: "relative", top: "20rem" }}
      />
    </div>
  );
}
