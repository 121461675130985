import React, { useEffect, useState } from "react";
import thumbnail from "../../images/thumbnail.jpeg";
import Button from "@material-ui/core/Button";
import Icon from "@material-ui/core/Icon";
import ExportToCsv from "../Partials/ExportToCsv";
import { BsUnlockFill } from "react-icons/bs";
import {
  FaUserTie,
  FaUsers,
  FaFileCsv,
  FaAngleDown,
  FaAngleUp,
} from "react-icons/fa";
import BlurryText from "../Partials/Blur";
import { CSVLink, CSVDownload } from "react-csv";
import axios from "axios";
import { APIFullAddress } from "../../Config";
import LoaderIcon from "react-loader-icon";
import SimpleModal from "../Partials/BasicModal";

export default function SystemDiscovery(props) {
  const [expand, setExpand] = useState(null);
  const [unlockLoader, setUnlockLoader] = useState(null);
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const defaultSrc = (ev) => {
    ev.target.src = thumbnail;
  };

  const unlockUrlsById = async (id) => {
    console.log(props.user);
    if (!props.user.sub && props.user.user.role === "mssp") return handleOpen();
    setUnlockLoader(id);
    const config = {
      headers: {
        "auth-token": props.user.token,
      },
    };

    await axios
      .post(
        `${APIFullAddress}/url-parts/unlock?id=${id}&system=${props.system}`,
        {},
        config
      )
      .then((res) => {
        props.resetUser(res.data.user);
        const reveal = props.data.find((s) => s.id === res.data.id);

        props.activeDomainAssets.urls = res.data.unlockedUrls;
        reveal.domain = res.data.domain;
        reveal.blur = false;
      })
      .catch((err) => console.log(err));
    setUnlockLoader(null);
  };

  const moveSection = (section) => {
    window.location.href = `#${section}`;
  };
  // const expandAndExport = (item) => {
  //   setExpand(item.id);
  //   props.getUrlsByDomain(item);
  //   // clickOnCsv(item.id);
  // };

  const clickOnCsv = (id) => {
    document.getElementById(id).click();
  };

  const censorDomain = (domain, url) => {
    let splittedUrl = url.split(domain);
    let http = splittedUrl[0].split("//")[0];

    return (
      <p>
        <span>{splittedUrl[0]}</span>
        <span className="censored">{domain.split(".")[0]}</span>
        <span>{domain.split(".")[1]}</span>
        <span>{domain.split(".")[2]}</span>
        <span>{splittedUrl[1]}</span>
      </p>
    );
  };

  return (
    <div className="row">
      <SimpleModal
        title="Top-Up Your Credits Balance for Additional Unlocks:"
        open={open}
        handleOpen={handleOpen}
        handleClose={handleClose}
        topUpLoader={props.topUpLoader}
        openTopUpModal={(item) => props.openTopUpModal(item)}
        user={props.user}
      />
      {props.data.map((item, index) => (
        <div
          className="col-md-12 col-sm-12"
          key={index}
          onClick={() => {
            if (!props.urlsLoading) {
              setExpand(item.id);
              props.getUrlsByDomain(item);
              moveSection(item.id);
            }
          }}
        >
          <div
            style={{
              width: "0px",
              height: "0px",
              position: "relative",
              top: "-80px",
            }}
            id={item.id}
          ></div>
          <div className="hoverable">
            <div className="systemCard">
              <div
                className="flex_box items_center cursor"
                style={{
                  backgroundColor: "#222831",
                  color: "black",
                  width: "70%",
                  textAlign: "left",
                  minHeight: "100px",
                  padding: "20px",
                  borderRadius: "20px",
                  margin: "5px",
                  justifyContent: "space-between",
                }}
              >
                <img
                  src={
                    item.blur
                      ? thumbnail
                      : `https://logo.clearbit.com/${item.domain}`
                  }
                  style={{ width: "60px", borderRadius: "50px" }}
                  onError={defaultSrc}
                />
                {props.unmask && (
                  <p className="uppercase left-5 darkText no-margin align-center">
                    Keyword
                    <br />
                    {item.system}
                  </p>
                )}
                <p className="uppercase left-5 darkText no-margin">
                  {item.blur ? (
                    <span>
                      <BlurryText value={item.domain.split(".")[0]} />.
                      {item.domain.split(".")[1]}
                      {item.domain.split(".")[2] &&
                        `.${item.domain.split(".")[2]}`}
                    </span>
                  ) : (
                    <div className="align-center">
                      {props.unmask && (
                        <span>
                          Domain
                          <br />
                        </span>
                      )}
                      <span>{item.domain}</span>
                    </div>
                  )}
                </p>
                {item.blur ? (
                  <Button
                    type="button"
                    variant="contained"
                    className={"searchBtn desktopSearchBtn"}
                    endIcon={
                      unlockLoader === `${item.id}` ? (
                        <LoaderIcon
                          type="spin"
                          style={{
                            width: "16px",
                            height: "16px",
                            marginLeft: "3px",
                            position: "relative",
                            top: "-10px",
                          }}
                        />
                      ) : (
                        <BsUnlockFill
                          style={{ marginTop: "-5px", marginLeft: "5px" }}
                        />
                      )
                    }
                    onClick={(e) => {
                      e.stopPropagation();
                      unlockUrlsById(item.id);
                    }}
                  >
                    UNLOCK URLS
                  </Button>
                ) : props.activeDomainAssets &&
                  expand === item.id &&
                  !props.urlsLoading &&
                  props.activeDomainAssets.urls &&
                  props.activeDomainAssets.urls.length > 0 ? (
                  <CSVLink
                    id={item.id}
                    className="csvDownload"
                    data={props.activeDomainAssets.urls}
                    filename={`${item.domain}.csv`}
                    target="_blank"
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                  >
                    EXPORT ALL
                  </CSVLink>
                ) : (
                  <Button
                    type="button"
                    variant="contained"
                    className={"searchBtn desktopSearchBtn"}
                    endIcon={<Icon>expand</Icon>}
                    onClick={(e) => {
                      setExpand(item.id);
                    }}
                  >
                    SHOW URLS
                  </Button>
                )}
                {!props.unmask && (
                  <p className="uppercase left-5 lightText no-margin yellow">
                    {item.count} URLs{" "}
                    {expand === item.id ? (
                      <FaAngleUp size={25} />
                    ) : (
                      <FaAngleDown size={25} />
                    )}
                  </p>
                )}
              </div>

              {expand === item.id &&
              props.activeDomainAssets &&
              props.activeDomainAssets.urls &&
              props.activeDomainAssets.urls.length > 0 &&
              !props.urlsLoading ? (
                <div
                  className="scrollBox"
                  style={{
                    width: "70%",
                    margin: "auto",
                    backgroundColor: "#fecc4c",
                  }}
                >
                  {props.activeDomainAssets.urls.map((asset, index) => (
                    <div
                      key={index}
                      style={{
                        textAlign: "left",
                        backgroundColor: "#1A1C20",
                        borderRadius: "20px",
                        padding: "10px",
                        fontSize: "16px",
                        display: "inline-flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        width: "100%",
                        margin: "5px 0px",
                      }}
                    >
                      {item.blur ? (
                        <p
                          style={{
                            margin: "0px",
                            width: "70%",
                            wordBreak: "break-word",
                          }}
                        >
                          {censorDomain(item.domain, asset.url)}
                        </p>
                      ) : (
                        <p
                          style={{
                            margin: "0px",
                            width: "70%",
                            wordBreak: "break-word",
                          }}
                        >
                          {asset.url}
                        </p>
                      )}
                      <p style={{ margin: "0px" }}>
                        Occurrence: {asset.occurrence}
                      </p>
                    </div>
                  ))}
                </div>
              ) : (
                props.urlsLoading === item.id && (
                  <LoaderIcon
                    type="spin"
                    style={{ fill: "white", margin: "4rem auto" }}
                  />
                )
              )}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}
