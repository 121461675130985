import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import MoreIcon from "@material-ui/icons/MoreVert";
import logo from "../../images/logo-1.png";
import { Link, useLocation } from "react-router-dom";
import Form from "../Data/Form";
import { HiMenu } from "react-icons/hi";
import Button from "@material-ui/core/Button";
import { useMediaQuery } from "react-responsive";
import { GiConsoleController, GiTwoCoins } from "react-icons/gi";
import SimpleModal from "../Partials/BasicModal";
import FeedbackIcon from "@material-ui/icons/Feedback";
import FeedbackModal from "./FeedbackModal";
import LoaderIcon from "react-loader-icon";
import Modal from "../Account/Modal";
import { authAddress, blueSnapAddress, bsEnv } from "../../Config";
import axios from "axios";
import Overlay from "../Account/Overlay";

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },

  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
}));

export default function AppBarNew(props) {
  const location = useLocation();
  const currentPath = location.pathname.split("/")[1];
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const [path, setPath] = React.useState("");
  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const [open, setOpen] = useState(false);
  const [feedbackOpen, setFeedbackOpen] = React.useState(false);
  const [endTrialOpen, setEndTrialOpen] = useState(false);
  const [loader, setLoader] = useState(null);
  const [selectedPlan, setSelectedPlan] = useState([]);
  const [finish, setFinish] = useState(false);
  const [overlay, setOverlay] = useState("");
  const [role, setRole] = useState("");

  const config = {
    headers: {
      "auth-token": props.user.token,
    },
  };

  const checkRole = async () => {
    await axios
      .post(`${authAddress}/role`, {}, config)
      .then((res) => {
        setRole(res.data);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    checkRole();
  }, []);

  const endTrial = async () => {
    let body = {
      selectedPlan: selectedPlan,
    };
    setFinish(false);
    setOverlay("end-trial");
    await axios
      .post(
        `${blueSnapAddress}/subscription/update?action=end-trial&sub=${props.user.sub.subscriptionId}&env=${bsEnv}`,
        body,
        config
      )
      .then((res) => {
        setOverlay("");
        handleClose();
        setFinish(true);
        props.resetUser(res.data.user);
      })
      .catch((err) => {
        setOverlay("error");
        handleClose();
        console.log(err);
      });
  };

  const handleEndTrialOpen = async () => {
    setLoader("end-trial");
    await axios
      .post(
        `${blueSnapAddress}/plans/get-one?sub=${props.user.sub.subscriptionId}&action=end-trial&env=${bsEnv}`,
        {},
        config
      )
      .then((res) => setSelectedPlan(res.data))
      .catch((err) => {
        if (err.response && err.response.data) {
          if (err.response.data === "Invalid Token") {
            props.handleHomeLogOut();
          } else {
            console.log(err.response.data);
          }
        } else {
          console.log(err);
        }
      });
    setLoader(null);
    setEndTrialOpen(true);
  };

  const handleEndTrialClose = () => {
    setEndTrialOpen(false);
  };

  const openFeedback = () => {
    setFeedbackOpen(true);
  };

  const closeFeedback = () => {
    setFeedbackOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });

  useEffect(() => {
    switch (currentPath) {
      case "/advanced-search":
        setPath("advanced");
        break;
      case "/search-by-domain":
        setPath("domain");
        break;
      case "/search-by-prospect":
        setPath("prospect");
        break;
      case "/search-by-system":
        setPath("system");
        break;
    }
  }, []);

  // const refreshUser = async () => {
  //   const config = {
  //     headers: {
  //       "auth-token": props.user.token,
  //     },
  //   };
  //   await axios
  //     .post(`${authAddress}/refresh-user`, {}, config)
  //     .then((res) => {
  //       setCredits(res.data.user.credits);
  //       props.resetUser(res.data.user);
  //     })
  //     .catch((error) => console.log(error));
  // };

  // useEffect(() => {
  //   console.log("called");
  //   refreshUser();
  // }, [props.data]);

  // useEffect(() => {
  //   const localUser = localStorage.getItem("user");
  //   const jsonUser = JSON.parse(localUser);
  //   console.log(jsonUser.user.credits);
  //   setCredits(jsonUser.user.credits);
  // }, [props]);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const clickOutside = () => {
    anchorEl && setAnchorEl(null);
  };

  const menuId =
    !props.user.sub && props.user.user.role === "mssp"
      ? "primary-search-account-menu"
      : "primary-search-account-menu-2";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      // anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={menuId}
      keepMounted
      // transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      {(role === "admin" ||
        props.user.user.email === "beta@hudsonrock.com") && (
        <Link to="/admin-panel">
          <MenuItem className="cyberFont bold">Admin Panel</MenuItem>
        </Link>
      )}
      {props.user.user &&
        props.user.user.unlocked_content &&
        props.user.user.unlocked_content.length > 0 && (
          <Link
            to="/unlocked-data"
            // to={
            //   !props.user.sub && props.user.user.role === "mssp"
            //     ? "#"
            //     : "/unlocked-data"
            // }
            // onClick={
            //   !props.user.sub && props.user.user.role === "mssp" && handleOpen
            // }
          >
            <MenuItem className="cyberFont bold">Unlocked Data</MenuItem>
          </Link>
        )}
      <Link to="/prospects">
        <MenuItem className="cyberFont bold">Prospects</MenuItem>
      </Link>
      <Link to="/my-account">
        <MenuItem className="cyberFont bold">My account</MenuItem>
      </Link>
      <Link to="/tutorials">
        <MenuItem className="cyberFont bold">Tutorials</MenuItem>
      </Link>
      <span onClick={openFeedback}>
        <MenuItem className="cyberFont bold">Send Feedback</MenuItem>
      </span>
      <Link to="/help">
        <MenuItem className="cyberFont bold">Help</MenuItem>
      </Link>
      {/* <Link to="/contact">
        <MenuItem>Contact</MenuItem>
      </Link> */}
      <Link to="/">
        <MenuItem onClick={props.handleHomeLogOut} className="cyberFont bold">
          Log Out
        </MenuItem>
      </Link>
    </Menu>
  );

  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      // anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={mobileMenuId}
      keepMounted
      // transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <Link to="/search">
        <MenuItem>
          <p className="mobileMenuLink">Search</p>
        </MenuItem>
      </Link>
      <Link to="/help">
        <MenuItem>
          <p className="mobileMenuLink">Help</p>
        </MenuItem>
      </Link>
      {/* <Link to="/contact">
        <MenuItem>
          <p className="mobileMenuLink">Contact</p>
        </MenuItem>
      </Link> */}
    </Menu>
  );

  return (
    <div className={classes.grow}>
      {((!props.user.sub && props.user.user.role === "mssp") ||
        (props.user.sub &&
          props.user.sub.free_trial &&
          props.credits === 0)) && (
        <div
          style={{
            height: "40px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "#4a40c5",
            position: "fixed",
            top: "0px",
            width: "100%",
            zIndex: "99999",
          }}
          className={
            (props.user.sub && props.user.sub.status != "pending") ||
            !props.user.sub
              ? ""
              : ""
          }
          onClick={
            props.user.sub && props.user.sub.free_trial && props.credits === 0
              ? handleEndTrialOpen
              : ((props.user.sub && props.user.sub.status != "pending") ||
                  !props.user.sub) &&
                null
          }
          // onClick={
          //   props.user.sub && props.user.sub.status != "pending" && handleOpen
          // }
        >
          {/* <h5 style={{ textAlign: "center", margin: "0px", color: "white" }}>
            To start your free trial contact us at{" "}
            <a className="yellow" href="mailto:sales@hudsonrock.com">
              sales@hudsonrock.com
            </a>
          </h5> */}
          <h5 style={{ textAlign: "center", margin: "0px", color: "white" }}>
            {props.user.sub && props.user.sub.status === "pending" ? (
              "Account pending approval"
            ) : props.user.sub &&
              props.user.sub.free_trial &&
              props.credits === 0 ? (
              "End your trial and get 40 credits now →"
            ) : (
              <span>
                Contact{" "}
                <a className="yellow" href="mailto:sales@hudsonrock.com">
                  sales@hudsonrock.com
                </a>{" "}
                to start using Bayonet. →
              </span>
            )}
            {loader === "end-trial" && (
              <LoaderIcon
                type="spin"
                className="tabsLoader"
                style={{
                  position: "absolute",
                  bottom: "0px",
                  right: "40%",
                  top: "10px",
                  height: "16px",
                  width: "16px",
                  fontSize: "14px",
                }}
              />
            )}
          </h5>
        </div>
      )}
      {isTabletOrMobile && (
        <div
          style={{
            height: "60px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "#4a40c5",
            position: "fixed",
            top: "0px",
            width: "100%",
            zIndex: "99999",
          }}
          className="cursor"
          onClick={handleOpen}
        >
          <h5 style={{ textAlign: "center", margin: "0px", color: "white" }}>
            Bayonet is supported in desktop and laptop devices only.
          </h5>
        </div>
      )}
      <AppBar position="static" onClick={clickOutside}>
        <Toolbar
          className="Appbar-header"
          style={{
            marginTop:
              ((!props.user.sub && props.user.user.role === "mssp") ||
                (props.user.sub &&
                  props.user.sub.free_trial &&
                  props.credits === 0)) &&
              "40px",
            marginBottom: "-65px",
          }}
        >
          <Link
            className="logoLink"
            to="/"
            onClick={() => {
              location.pathname === "/" && window.location.reload();
            }}
          >
            <img
              src={logo}
              className="Appbar-logo"
              alt="logo"
              width="60"
              height="60"
            />
          </Link>
          <div className={classes.sectionDesktop + " newMenu"}>
            <div className="headerForm">
              <Form
                inputValue={props.inputValue}
                user={props.user}
                email={props.email}
                token={props.token}
                role={props.role}
                domains={props.domains}
                redirect={(e) => props.redirect(e)}
                prospects={props.prospects}
                searchType={props.currentType ? props.currentType : "domain"}
                path={props.path}
                filterCompanies={(industries, sizes, countries, regions) =>
                  props.filterCompanies(industries, sizes, countries, regions)
                }
                handleLogOut={props.handleLogOut}
                openUpgradeModal={props.openUpgradeModal}
                upgradeLoader={props.upgradeLoader}
              />
            </div>
            {/* <div className="headerButtons">
              <IconButton
                aria-label="show more"
                aria-controls={menuId}
                aria-haspopup="true"
                onClick={handleProfileMenuOpen}
                color="inherit"
              >
                <HiMenu className="hamburger" />
              </IconButton>
            </div> */}
            <div id="navBtns" className="desktop">
              {!props.user.sub && props.user.user.role === "mssp"
                ? props.user.user &&
                  props.user.user.unlocked_content &&
                  props.user.user.unlocked_content.length > 0 && (
                    <Link to="#">
                      <Button
                        type="button"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className="squareBtn"
                        onClick={handleOpen}
                      >
                        View Unlocked Data
                      </Button>
                    </Link>
                  )
                : props.user.user.role != "admin" &&
                  props.user.user &&
                  props.user.user.unlocked_content &&
                  props.user.user.unlocked_content.length > 0 && (
                    <Link to="/unlocked-data">
                      <Button
                        type="button"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className="squareBtn"
                      >
                        View Unlocked Data
                      </Button>
                    </Link>
                  )}
              {/* <Link to="/prospects">
                <Button
                  type="button"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className="squareBtn"
                >
                  Prospects
                </Button>
              </Link> */}
              <Link to="#">
                <Button
                  type="button"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className="squareBtn"
                  onClick={() => props.openTopUps()}
                  // className="cyberFont"
                >
                  Credits ({props.credits})
                </Button>
              </Link>
              {/* <IconButton
                aria-haspopup="true"
                onClick={openFeedback}
                color="inherit"
                style={{ backgroundColor: "#5132C0" }}
              >
                <FeedbackIcon />
              </IconButton> */}
              <div className="headerButtons">
                <IconButton
                  aria-label="show more"
                  aria-controls={menuId}
                  aria-haspopup="true"
                  onClick={handleProfileMenuOpen}
                  color="inherit"
                >
                  <HiMenu className="hamburger" />
                </IconButton>
              </div>
            </div>
          </div>

          {isTabletOrMobile && (
            <div className={classes.sectionMobile + " mobileMenu"}>
              <div className="headerForm">
                <Form
                  user={props.user}
                  email={props.email}
                  token={props.token}
                  role={props.role}
                  domains={props.domains}
                  redirect={(e) => props.redirect(e)}
                  prospects={props.prospects}
                  searchType={props.path ? props.path.split("-")[2] : "domain"}
                  path={props.path}
                  handleLogOut={props.handleLogOut}
                  openUpgradeModal={() => props.openUpgradeModal}
                  upgradeLoader={props.upgradeLoader}
                />
              </div>
              <IconButton
                aria-label="show more"
                aria-controls={mobileMenuId}
                aria-haspopup="true"
                onClick={handleMobileMenuOpen}
                color="inherit"
              >
                <MoreIcon />
              </IconButton>
            </div>
          )}
        </Toolbar>
      </AppBar>
      <SimpleModal
        user={props.user}
        open={open}
        handleOpen={handleOpen}
        handleClose={handleClose}
        topUpLoader={props.topUpLoader}
        openTopUpModal={(item) => props.openTopUpModal(item)}
      />
      {renderMobileMenu}
      {renderMenu}
      {feedbackOpen && (
        <FeedbackModal
          user={props.user}
          open={feedbackOpen}
          handleClickOpen={openFeedback}
          handleClose={closeFeedback}
        />
      )}
      <Modal
        selectedPlan={selectedPlan}
        user={props.user}
        sub={props.user.sub}
        open={endTrialOpen}
        handleClickOpen={handleEndTrialOpen}
        handleClose={handleEndTrialClose}
        confirm={true}
        func={() => endTrial()}
        title={"End your trial and get 40 credits now"}
        type={"end-trial"}
      />
      {overlay === "end-trial" && (
        <Overlay
          title="Your subscription is being updated..."
          content="Please do not leave or refresh the page."
          successTitle="Your trial has been successfully ended"
          successContent=""
          finish={finish}
        />
      )}
      {overlay === "error" && (
        <Overlay
          title="Something went wrong while ending your trial."
          content="Please contact us at support@hudsonrock.com"
          successTitle=""
          successContent=""
          finish={finish}
        />
      )}
    </div>
  );
}
