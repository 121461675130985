import React, { useState } from "react";
// import cryptoRandomString from "crypto-random-string";
import CreateApiClient from "../../API";

const api = CreateApiClient();

export default function RegisterUser(props) {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [title, setTitle] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);

  const handleChange = (e) => {
    setSuccess(false);
    setError(false);

    const name = e.target.name;
    const value = e.target.value;
    switch (name) {
      case "first_name":
        setFirstName(value);
        break;
      case "last_name":
        setLastName(value);
        break;
      case "title":
        setTitle(value);
        break;
      case "email":
        setEmail(value);
        break;
      case "password":
        setPassword(value);
        break;
    }
  };

  //   const api_token = cryptoRandomString({
  //     length: 64,
  //     type: "url-safe",
  //   });

  const registerUser = async (e) => {
    e.preventDefault();
    const newUser = {
      first_name: firstName,
      last_name: lastName,
      title: title,
      email: email,
      password: password,
      tel: props.user.tel,
      company_name: props.user.company_name,
      company_website: props.user.company_website,
      role: "mssp",
      //   api_token: api_token,
      subscriptionId: props.sub.subscriptionId,
      plan_id: props.sub.plan_id,
      prospects: [],
      unlocked_content: {
        stealers: [],
        creds: [],
        dbs: [],
      },
    };

    const config = {
      headers: {
        "auth-token": props.token,
      },
    };

    const response = await api.registerUser(`register`, newUser, config);
    if (response.data) {
      setError("");
      setSuccess("User Successfully Added!");
      setTimeout(() => {
        window.location.reload();
        setSuccess("");
      }, 1000);
    } else {
      setSuccess("");
      setError("Something Went Wrong!");
    }
  };

  return (
    <div style={{ padding: "0px 10px" }}>
      <h5>Add New User</h5>
      <form onSubmit={registerUser}>
        <div className="row mt-2">
          <div className="col-md-6">
            {/* <label className="labels">First Name</label> */}
            <input
              type="text"
              className="form-control blackTextInput capitalize"
              placeholder="First Name"
              name="first_name"
              onChange={handleChange}
            />
          </div>
          <div className="col-md-6">
            {/* <label className="labels">Last Name</label> */}
            <input
              type="text"
              className="form-control blackTextInput capitalize"
              placeholder="Last Name"
              name="last_name"
              onChange={handleChange}
            />
          </div>
          <div className="col-md-6">
            {/* <label className="labels">Job Title</label> */}
            <input
              type="text"
              className="form-control blackTextInput capitalize"
              placeholder="Job Title"
              name="title"
              onChange={handleChange}
            />
          </div>
          <div className="col-md-6">
            {/* <label className="labels">Email Address</label> */}
            <input
              type="email"
              className="form-control blackTextInput"
              placeholder="Email"
              name="email"
              onChange={handleChange}
            />
          </div>
          <div className="col-md-6">
            {/* <label className="labels">Password</label> */}
            <input
              type="password"
              className="form-control blackTextInput capitalize"
              placeholder="Password"
              name="password"
              onChange={handleChange}
              value={password}
            />
          </div>
          <div className="text-left col-md-6" style={{ alignSelf: "end" }}>
            <button
              style={{ margin: "0px" }}
              className="btn btn-primary profile-button searchBtn"
              type="submit"
            >
              Add User
            </button>
          </div>
          <p className="error">{error}</p>
          <p className="success green text-left">{success}</p>
        </div>
      </form>
    </div>
  );
}
