import React from "react";
import LoaderIcon from "react-loader-icon";
import Button from "@material-ui/core/Button";
import Icon from "@material-ui/core/Icon";
import FeaturesTable from "./FeaturesTable";
import { publicAddress } from "../../Config";
import { createBrowserHistory } from "history";

const history = createBrowserHistory();

export default function PricingTable(props) {
  const packages = [
    {
      name: "10 Credits",
      price: 5,
      credits: 10,
      backgroundColor: "#222831",
      discount: "",
      id: 4026114,
    },
    {
      name: "50 Credits",
      price: 3,
      credits: 50,
      backgroundColor: "#222831",
      discount: "(40% off)",
      id: 4026084,
    },
    {
      name: "100 Credits",
      price: 2,
      credits: 100,
      backgroundColor: "#04AA6D",
      discount: "(60% off)",
      id: 4026086,
    },
  ];

  const goTo = (link) => {
    history.push(link);
    window.location.reload();
  };
  return (
    <>
      {props.user.sub ? (
        <>
          <h2 style={{ textAlign: "center" }}>{props.title} </h2>
          {packages.map((item) => (
            <div className="columns">
              <ul className="price">
                <li
                  className="header"
                  style={{ backgroundColor: item.backgroundColor }}
                >
                  {item.name}
                </li>
                <li className="grey">
                  ${item.price}.00 / credit {item.discount}{" "}
                </li>
                <li>One Time Payment</li>
                <li>{item.credits} Credits</li>
                <li>${item.credits * item.price} Total</li>
                <li className="grey price-footer">
                  <Button
                    // href={
                    //   props.user.sub.plan_id === 1 ||
                    //   props.user.sub.plan_id === 33501618
                    //     ? `${publicAddress}/checkout?plan=${item.id}`
                    //     : undefined
                    // }
                    href={`${publicAddress}/checkout?plan=${item.id}`}
                    type="button"
                    variant="contained"
                    className={"searchBtn desktopSearchBtn"}
                    endIcon={
                      props.topUpLoader === item.name ? (
                        <LoaderIcon
                          className="removeLoader tableLoader"
                          style={{
                            position: "relative",
                            top: "-10px",
                            right: "0px",
                          }}
                        />
                      ) : (
                        <Icon>send</Icon>
                      )
                    }
                    // onClick={() => {
                    //   if (
                    //     props.user.sub.plan_id != 1 ||
                    //     props.user.sub.plan_id != 33501618
                    //   ) {
                    //     props.openTopUpModal(item);
                    //   }
                    // }}
                  >
                    Purchase
                  </Button>
                </li>
              </ul>
            </div>
          ))}
        </>
      ) : (
        <div
          style={{
            height: "100px",
            display: "flex",
            alignItems: "center",
          }}
        >
          <h2 className="align-center" style={{ margin: "auto" }}>
            Not enough credits <br />
            Contact our Sales Team at:{" "}
            <a href="mailto:sales@hudsonrock.com" className="yellow">
              sales@hudsonrock.com
            </a>
          </h2>
        </div>
      )}
      {/* ) : props.user.sub ? (
        <div
          style={{
            height: "100px",
            display: "flex",
            alignItems: "center",
          }}
        >
          <h2 className="align-center" style={{ margin: "auto" }}>
            Contact our Sales Team at:{" "}
            <a href="mailto:sales@hudsonrock.com" className="yellow">
              sales@hudsonrock.com
            </a>
          </h2>
        </div>
      ) : (
        <FeaturesTable />
      )} */}
    </>
  );
}
