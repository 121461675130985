import React, { useEffect, useState } from "react";
import AppBarNew from "../Partials/AppBar";
import CollapsibleTable from "./Table";
import CreateApiClient from "../../API";
import SimpleModal from "../Partials/BasicModal";
import Footer from "../Partials/Footer";
import LoaderIcon from "react-loader-icon";
import FindContacts from "./FindContacts";
import { GiOrganigram } from "react-icons/gi";
const api = CreateApiClient();

function Prospects(props) {
  const [prospects, setProspects] = useState([]);
  const [addedDomains, setAddedDomains] = useState([]);
  const [addedDomainType, setAddedDomainType] = useState("");
  const [socialType, setScoialType] = useState("");
  const [addedSocial, setAddedSocial] = useState("");
  const [socialInputValue, setSocialInputValue] = useState("");
  const [loader, setLoader] = useState("");
  const [inputValue, setInputValue] = useState("");
  //
  const [domainInputValue, setDomainInputValue] = useState("");
  const [apkInputValue, setApkInputValue] = useState("");
  const [partyInputValue, setPartyInputValue] = useState("");

  const [upgradeLoader, setUpgradeLoader] = useState("");
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [addLoader, setAddLoader] = useState("");
  const [prospectsDomains, setProspectsDomains] = useState([]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const updateUserFields = (field, update) => {
    setProspects(update);
  };

  const config = {
    headers: {
      "auth-token": props.user.token,
    },
  };

  useEffect(() => {
    if (props.user) {
      const time = Date.now().toString().substr(0, 10);
      const nowNum = Number(time);
      if (props.user.expiry < nowNum) {
        props.handleLogOut();
      } else {
        getProspects();
      }
    } else {
      props.handleLogOut();
    }
  }, [props.user]);

  useEffect(() => {
    getFirstData();
  }, []);

  async function getProspects() {
    const response = await api.editUser("get-prospects", {}, config);
    setProspects(response.prospects);
    setProspectsDomains(response.prospects_domains);
  }

  async function getFirstData() {
    setLoading(true);
    const response = await api.editUser("get-prospects", {}, config);
    setProspects(response.prospects);
    setProspectsDomains(response.prospects_domains);
    setLoading(false);
  }

  const onAddDomain = (e, type) => {
    setAddedDomainType(type);
    setAddedDomains([
      ...new Set(
        e.target.value
          .replace(/ /g, "")
          .replace(/www./g, "")
          .split(",")
          .filter((s) => s != "")
      ),
    ]);

    if (type === "domain") {
      setDomainInputValue(e.target.value);
    } else if (type === "apk") {
      setApkInputValue(e.target.value);
    } else if (type === "3rd party") {
      setPartyInputValue(e.target.value);
    } else {
      setDomainInputValue(e.target.value);
    }
  };

  const onAddSocial = (e) => {
    setAddedSocial(e.target.value);
    setSocialInputValue(e.target.value);
  };

  const onChangeDomainType = (e) => {
    setAddedDomainType(e.value.toLowerCase());
  };

  const onChangeSocialType = (e) => {
    setScoialType(e.value.toLowerCase());
  };

  const toggleAlerts = async (prospect) => {
    setUpgradeLoader(prospect._id);
    let action;
    prospect.alerts ? (action = "remove") : (action = "add");
    const response = await api.editUser(
      `update-prospect?action=${action}`,
      { prospect: prospect._id, type: "alerts" },
      config
    );
    await props.resetUser(response);
    setTimeout(() => {
      setUpgradeLoader(null);
    }, 1000);
  };

  const addDomains = async (prospect, type) => {
    setAddLoader(type);
    const response = await api.editUser(
      "update-prospect?action=add",
      { prospect: prospect, domains: addedDomains, type: type },
      config
    );
    if (response && response.prospects) {
      const newProspects = await api.editUser("get-prospects", {}, config);
      setProspects(newProspects.prospects);
      setProspectsDomains(newProspects.prospects_domains);

      props.resetUser(response);
    }
    setDomainInputValue("");
    setApkInputValue("");
    setPartyInputValue("");
    setInputValue("");
    setAddLoader("");
  };

  const addSocial = async (prospect) => {
    const response = await api.editUser(
      "update-prospect?action=add",
      { prospect: prospect, domains: addedSocial, type: socialType },
      config
    );
    if (response) {
      const newProspects = await api.editUser("get-prospects", {}, config);
      setProspects(newProspects.prospects);
      setSocialInputValue("");
    }
  };

  const removeDomain = async (prospect, domain, type) => {
    setLoader(domain);
    const response = await api.editUser(
      "update-prospect?action=remove",
      { prospect: prospect, domains: domain, type: type },
      config
    );
    if (response) {
      const newProspects = await api.editUser("get-prospects", {}, config);
      setProspects(newProspects.prospects);
      setProspectsDomains(newProspects.prospects_domains);
      setLoader("");
    }
  };

  return (
    <div className="App prospectPage">
      <AppBarNew
        resetUser={(user) => props.resetUser(user)}
        handleHomeLogOut={props.handleHomeLogOut}
        className="mobileHeader"
        user={props.user}
        redirect={(e) => props.redirect(e)}
        prospects={prospects}
        handleLogOut={props.handleLogOut}
        filterCompanies={(industries, sizes, countries, regions) =>
          props.filterCompanies(industries, sizes, countries, regions)
        }
        credits={props.user.user.credits}
        openTopUps={() =>
          props.user.sub && props.user.sub.status != "pending" && handleOpen()
        }
      />
      <SimpleModal
        title="Top-Up Your Credits Balance for Additional Unlocks:"
        open={open}
        handleOpen={handleOpen}
        handleClose={handleClose}
        topUpLoader={props.topUpLoader}
        openTopUpModal={(item) => props.openTopUpModal(item)}
        user={props.user}
      />
      {loading ? (
        <div className="load-wrapper" style={{ height: "100vh" }}>
          <LoaderIcon type={"spin"} className="tabsLoader page-loader" />
        </div>
      ) : (
        <div
          style={{ display: "flex", padding: "1rem 10rem" }}
          className="prospectPageDiv"
          id="prospects-page"
        >
          <div
            className="prospectsTable"
            style={{ margin: "10px auto", marginBottom: "100px" }}
          >
            <h2
              style={{
                display: "flex",
                alignItems: "center",
                padding: "1rem",
                backgroundColor: "#fecd4e",
                color: "black",
                borderRadius: "20px 20px 0px 0px",
              }}
            >
              <span>My Prospects</span>
            </h2>
            <br />
            <CollapsibleTable
              openUpgradeModal={(e) => props.openUpgradeModal(e)}
              upgradeLoader={props.upgradeLoader}
              resetUser={(user) => props.resetUser(user)}
              setProspects={(response) => setProspects(response)}
              prospects={prospects}
              onChangeSocialType={onChangeSocialType}
              onAddSocial={onAddSocial}
              socialInputValue={socialInputValue}
              addSocial={addSocial}
              user={props.user}
              updateUserFields={(field, update) =>
                updateUserFields(field, update)
              }
              onAddDomain={onAddDomain}
              onChangeDomainType={onChangeDomainType}
              addDomain={(prospect, type) => addDomains(prospect, type)}
              addedDomains={addedDomains}
              removeDomain={(prospect, domain, type) =>
                removeDomain(prospect, domain, type)
              }
              domainLoader={loader}
              addLoader={addLoader}
              inputValue={inputValue}
              addedDomainType={addedDomainType}
              socialType={socialType}
              handleLogOut={props.handleLogOut}
              toggleAlerts={(prospect) => toggleAlerts(prospect)}
              loader={upgradeLoader}
              domainInputValue={domainInputValue}
              apkInputValue={apkInputValue}
              partyInputValue={partyInputValue}
            />
          </div>
          {/* <FindContacts
            prospects={prospects}
            user={props.user}
            resetUser={(user) => props.resetUser(user)}
            prospectsDomains={prospectsDomains}
          /> */}
        </div>
      )}

      {!loading && <Footer />}
    </div>
  );
}

export default Prospects;
