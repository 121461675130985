import React, { useEffect } from "react";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import Icon from "@material-ui/core/Icon";
import { useNavigate, Link } from "react-router-dom";
import LoaderIcon from "react-loader-icon";

const useStyles = makeStyles((theme) => ({
  button: {
    margin: "0px 4px",
    height: "36px",
    // marginTop: "-3px",
  },
}));

export default function SearchBtn(props) {
  const history = useNavigate();
  const classes = useStyles();
  const basicPlans = [2741315, 2741325, 3332681, 3332639];

  // const search = async (e) => {
  //   e.preventDefault();
  //   const domainsArr = await Promise.all(
  //     props.activeDomains.map((domain) => {
  //       return domain.value;
  //     })
  //   ).catch((err) => console.log(err));
  //   let queryTxt = domainsArr.join("&");
  //   history.push(`/${queryTxt}`);
  // };

  return props.type === "domain" ? (
    <div>
      {props.domain != null &&
      props.domain.includes(".") &&
      !props.domain.includes(":") &&
      !props.domain.includes("/") ? (
        <Link to={`/search-by-domain/${props.domain}`}>
          <Button
            type="submit"
            variant="contained"
            className={classes.button + " searchBtn desktopSearchBtn"}
            endIcon={<Icon>send</Icon>}
          ></Button>
        </Link>
      ) : (
        <Button
          type="button"
          variant="contained"
          className={classes.button + " searchBtn grayBtn desktopSearchBtn"}
          endIcon={<Icon>send</Icon>}
        ></Button>
      )}
    </div>
  ) : props.type === "prospect" ? (
    <div>
      {props.prospect ? (
        <Link to={`/search-by-prospect/${props.prospect}`}>
          <Button
            type="submit"
            variant="contained"
            className={classes.button + " searchBtn desktopSearchBtn cursor"}
            endIcon={<Icon>send</Icon>}
          ></Button>
        </Link>
      ) : (
        <Button
          type="button"
          variant="contained"
          className={classes.button + " searchBtn grayBtn desktopSearchBtn"}
          endIcon={<Icon>send</Icon>}
        ></Button>
      )}
    </div>
  ) : props.type === "system" ? (
    <div>
      {props.system ? (
        <Link to={`/search-by-system/${props.system}`}>
          <Button
            type="submit"
            variant="contained"
            className={classes.button + " searchBtn desktopSearchBtn"}
            endIcon={<Icon>send</Icon>}
          ></Button>
        </Link>
      ) : (
        <Button
          type="button"
          variant="contained"
          className={classes.button + " searchBtn grayBtn desktopSearchBtn"}
          endIcon={<Icon>send</Icon>}
        ></Button>
      )}
    </div>
  ) : (
    props.type === "advanced" && (
      <div>
        {props.user.user.role != "admin" &&
        (!props.user.sub || !basicPlans.includes(props.user.sub.plan_id)) ? (
          props.countriesSelected.length > 0 &&
          props.industriesSelected.length > 0 &&
          props.sizesSelected.length > 0 ? (
            <Link to={`/advanced-search`}>
              <Button
                type="submit"
                variant="contained"
                className={classes.button + " searchBtn desktopSearchBtn"}
                endIcon={<Icon>send</Icon>}
                onClick={props.filterCompanies}
              ></Button>
            </Link>
          ) : (
            <Button
              type="button"
              variant="contained"
              className={classes.button + " searchBtn desktopSearchBtn grayBtn"}
              endIcon={<Icon>send</Icon>}
            ></Button>
          )
        ) : props.user.user.role != "admin" ? (
          <Button
            id="advanceSearchBtn"
            type="button"
            variant="contained"
            className={classes.button + " searchBtn desktopSearchBtn"}
            endIcon={
              props.upgradeLoader === "advanceSearchBtn" ? (
                <LoaderIcon
                  className="removeLoader tableLoader"
                  style={{
                    position: "relative",
                    top: "-10px",
                    right: "0px",
                  }}
                />
              ) : (
                <Icon>send</Icon>
              )
            }
            onClick={props.openUpgradeModal}
          ></Button>
        ) : (
          props.user.user.role === "admin" &&
          (props.countriesSelected.length > 0 &&
          props.industriesSelected.length > 0 &&
          props.sizesSelected.length > 0 ? (
            <Link to={`/advanced-search`}>
              <Button
                type="submit"
                variant="contained"
                className={classes.button + " searchBtn desktopSearchBtn"}
                endIcon={<Icon>send</Icon>}
                onClick={props.filterCompanies}
              ></Button>
            </Link>
          ) : (
            <Button
              type="button"
              variant="contained"
              className={classes.button + " searchBtn desktopSearchBtn grayBtn"}
              endIcon={<Icon>send</Icon>}
            ></Button>
          ))
        )}
      </div>
    )
  );
}
