import React, { useEffect } from "react";
import "../../styles/App.css";
// import Form from "react-bootstrap/Form";
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import CreateApiClient from "../../API";
// import Autocomplete from "@material-ui/lab/Autocomplete";
// import TextField from "@material-ui/core/TextField";
import LoaderIcon from "react-loader-icon";
import { MultiSelect } from "react-multi-select-component";
// import axios from "axios";
// import { APIFullAddress } from "../../Config";
// import Button from "@material-ui/core/Button";
// import Icon from "@material-ui/core/Icon";
import Select from "react-select";

const api = CreateApiClient();

const useStyles = makeStyles((theme) => ({
  button: {
    display: "block",
    marginTop: theme.spacing(2),
  },
  formControl: {
    margin: "0px 2px",
    minWidth: 200,
    maxWidth: 260,
    border: "2px solid black",
    borderRadius: 8,
  },
}));

function SearchCompany(props) {
  const classes = useStyles();

  const DefaultItemRenderer = ({ checked, option, onClick, disabled }) => (
    <div className={`item-renderer ${disabled && "disabled"}`}>
      {props.loadOptions ? (
        <LoaderIcon className="tabsLoader" type={"spinningBubbles"} />
      ) : (
        <>
          <input
            type="checkbox"
            onChange={onClick}
            checked={checked}
            tabIndex={-1}
            disabled={disabled}
            className="filter-input"
          />
          <span className="filter-label">{option.label}</span>
        </>
      )}
    </div>
  );

  const filterOptions = (options, filter) => {
    if (!filter) {
      return options;
    }
    const re = new RegExp(filter, "i");
    return options.filter(({ value }) => value && value.match(re));
  };

  return (
    // <div id="company-filters">
    //   <Form autoComplete="off">
    <>
      <FormControl className={classes.formControl + " inputs company-filters"}>
        <MultiSelect
          defaultValue={props.industries}
          overrideStrings={{
            allItemsAreSelected: "All Industries Are Selected.",
            selectSomeItems: "Industry",
          }}
          hasSelectAll={true}
          ItemRenderer={DefaultItemRenderer}
          shouldToggleOnHover={true}
          options={props.industries}
          value={props.industriesSelected && props.industriesSelected}
          onChange={props.setIndustriesSelected}
          labelledBy="Industry"
          filterOptions={filterOptions}
        />
      </FormControl>
      <FormControl className={classes.formControl + " inputs company-filters"}>
        <MultiSelect
          defaultValue={props.sizes}
          overrideStrings={{
            allItemsAreSelected: "All Sizes Are Selected.",
            selectSomeItems: "Company Size",
          }}
          hasSelectAll={true}
          ItemRenderer={DefaultItemRenderer}
          shouldToggleOnHover={true}
          options={props.sizes}
          value={props.sizesSelected && props.sizesSelected}
          onChange={props.setSizesSelected}
          labelledBy="Size"
          filterOptions={filterOptions}
        />
      </FormControl>
      <FormControl className={classes.formControl + " inputs company-filters"}>
        <MultiSelect
          defaultValue={props.countries}
          overrideStrings={{
            allItemsAreSelected: "All Countries Are Selected.",
            selectSomeItems: "Country",
          }}
          hasSelectAll={true}
          ItemRenderer={DefaultItemRenderer}
          shouldToggleOnHover={true}
          options={props.countries}
          value={props.countriesSelected && props.countriesSelected}
          onChange={props.setCountriesSelected}
          labelledBy="Country"
          filterOptions={filterOptions}
        />
      </FormControl>
      {props.isUsa && (
        <FormControl
          className={classes.formControl + " inputs company-filters"}
        >
          <MultiSelect
            defaultValue={props.regions}
            overrideStrings={{
              allItemsAreSelected: "All Regions Are Selected.",
              selectSomeItems: "Region",
            }}
            hasSelectAll={true}
            ItemRenderer={DefaultItemRenderer}
            shouldToggleOnHover={true}
            options={props.regions}
            value={props.regionsSelected && props.regionsSelected}
            onChange={props.setRegionsSelected}
            labelledBy="Regions"
            filterOptions={filterOptions}
          />
        </FormControl>
      )}
      {props.page === "systems" && (
        <FormControl
          className={classes.formControl + " inputs company-filters"}
        >
          <MultiSelect
            // defaultValue={props.timeOptions[0]}
            overrideStrings={{
              // allItemsAreSelected: "All Time",
              selectSomeItems: "All Time",
            }}
            hasSelectAll={false}
            ItemRenderer={DefaultItemRenderer}
            shouldToggleOnHover={true}
            options={props.timeOptions}
            value={props.timeSelected && props.timeSelected}
            onChange={(e) => {
              if (props.timeSelected.length > 0) {
                props.setTimeSelected([]);
                e.shift();
                props.setTimeSelected(e);
              } else {
                props.setTimeSelected(e);
              }
            }}
            labelledBy="Last Compromised"
            // filterOptions={filterOptions}
          />
          {/* <Select
            value={props.timeSelected && props.timeSelected}
            defaultValue={props.timeOptions[0]}
            onChange={props.setTimeSelected}
            placeholder="Last Compromised"
            className="search-by-select desktop"
            options={props.timeOptions}
            theme={(theme) => ({
              ...theme,
              borderRadius: 0,
              colors: {
                ...theme.colors,
                primary25: "#FBCD4E",
                primary: "black",
              },
            })}
          /> */}
        </FormControl>
      )}
    </>
  );
}

export default SearchCompany;
