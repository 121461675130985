import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { MdOutlineMail } from "react-icons/md";
import { FaCreditCard, FaRegCalendarAlt } from "react-icons/fa";
import { RiSecurePaymentLine } from "react-icons/ri";
import FeaturesTable from "../Partials/FeaturesTable";
export default function Modal(props) {
  const [error, setError] = useState("");
  const [cvv, setCvv] = useState("");

  const monthly = [2741315, 3332639, 2801843, 4013076, 3763056, 3346067];
  // const annual = [2741325, 3332681, 2801841, 4025330];
  // const advanced_monthly = [2801843, 4013076];
  // const advanced_annual = [2801841, 4025330];

  const onChange = (e) => {
    const re = /^[0-9\b]+$/;
    if (e.target.value === "" || re.test(e.target.value)) {
      setCvv(e.target.value);
    }
  };

  useEffect(() => {
    cvv.length === 3 && setError("");
  }, [cvv]);

  useEffect(() => {
    setCvv("");
  }, [props.user]);

  return (
    <div>
      <Dialog
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="form-dialog-title"
        id={props.sub ? "styledModal" : "featuresModal"}
      >
        {props.sub ? (
          <>
            <DialogTitle
              id="form-dialog-title"
              className={props.subject !== "user" ? "lightText" : "lightText"}
            >
              {props.subject === "user" ? props.userTitle : props.title}
            </DialogTitle>
            {props.confirm && (
              <DialogContent>
                {props.type === "end-trial" && (
                  <DialogContentText className="lightText">
                    Please confirm an initial charge of $
                    {props.selectedPlan.initialChargeAmount}.00 in order to end
                    your trial.
                  </DialogContentText>
                )}
                {props.type === "cancel" ? (
                  <DialogContentText className="lightText">
                    Please note that your subscription will remain active until{" "}
                    {props.subExpiry}
                  </DialogContentText>
                ) : props.type === "upgrade" &&
                  props.shopper &&
                  props.shopper.shopper &&
                  props.shopper.shopper.paymentSources ? (
                  <>
                    {props.shopper.topup ? (
                      <p className="lightText">
                        Please confirm an initial charge of $
                        {props.shopper.amount} for purchasing the top-up
                        package, your account will be charged with another{" "}
                        {props.shopper.credits} credits.
                      </p>
                    ) : (
                      <p className="lightText">
                        Please confirm an initial upgrade charge of $
                        {props.shopper.amount} for the remaining{" "}
                        {monthly.includes(props.sub.plan_id) ? "month" : "year"}
                        , and a new recurring{" "}
                        {monthly.includes(props.sub.plan_id)
                          ? "monthly"
                          : "annual"}{" "}
                        charge of ${props.shopper.reccuring_amount}{" "}
                      </p>
                    )}
                    <div className="inline-icon align-center roundBlackBorder">
                      <span style={{ float: "left" }}>
                        <MdOutlineMail size={20} /> Email
                      </span>
                      <span style={{ float: "right" }}>
                        {props.shopper.shopper.email}
                      </span>
                    </div>
                    <div className="inline-icon align-center roundBlackBorder">
                      <span style={{ float: "left" }}>
                        <FaCreditCard size={20} /> Credit Card
                      </span>
                      <span style={{ float: "right" }}>
                        {"************"}
                        {
                          props.shopper.shopper.paymentSources.creditCardInfo[0]
                            .creditCard.cardLastFourDigits
                        }
                      </span>
                    </div>
                    <div className="inline-icon align-center roundBlackBorder">
                      <span style={{ float: "left" }}>
                        <FaRegCalendarAlt size={20} /> Exp. Date
                      </span>
                      <span style={{ float: "right" }}>
                        {
                          props.shopper.shopper.paymentSources.creditCardInfo[0]
                            .creditCard.expirationMonth
                        }
                        /
                        {
                          props.shopper.shopper.paymentSources.creditCardInfo[0]
                            .creditCard.expirationYear
                        }
                      </span>
                    </div>
                    <div
                      className="inline-icon align-center roundBlackBorder"
                      style={{ padding: "0px" }}
                    >
                      <span style={{ float: "left", padding: "5px" }}>
                        <RiSecurePaymentLine size={20} /> CVV
                      </span>
                      <input
                        placeholder="Enter your CVV"
                        required
                        minLength={3}
                        maxLength={3}
                        style={{
                          // float: "right",
                          // border: "2px solid black",
                          // padding: "2px 6px",
                          // borderRadius: "15px",
                          float: "right",
                          border: "1px solid",
                          borderLeft: "2px solid black",
                          padding: "2px 6px",
                          borderRadius: "0px 20px 20px 0px",
                          minHeight: "36px",
                        }}
                        value={cvv}
                        onChange={onChange}
                      ></input>
                    </div>
                  </>
                ) : props.type === "downgrade" ? (
                  <DialogContentText className="lightText">
                    Please note that you can still use advanced features until{" "}
                    {props.subExpiry}. Downgrading will remove all advanced
                    features and your recurring{" "}
                    {monthly.includes(props.sub.plan_id) ? "monthly" : "annual"}{" "}
                    charge will then be reduced to $
                    {props.selectedPlan.recurringChargeAmount}{" "}
                  </DialogContentText>
                ) : (
                  props.type === "renew" && (
                    <DialogContentText className="lightText">
                      Please note that you can reactivate your subscription
                      untill {props.subExpiry}, your recurring amount will stay
                      the same.
                    </DialogContentText>
                  )
                )}
              </DialogContent>
            )}
            <DialogActions>
              <span className="error"> {error} </span>
              {props.confirm || props.subject === "user" ? (
                <>
                  {/* <Button onClick={props.handleClose} color="secondary">
                Cancel
              </Button> */}
                  {props.type === "upgrade" ? (
                    <Button
                      onClick={() => {
                        cvv && cvv.length === 3
                          ? props.func(cvv)
                          : setError("Invalid CVV");
                      }}
                      style={{
                        color: "black",
                      }}
                    >
                      Confirm
                    </Button>
                  ) : (
                    <Button
                      onClick={() => {
                        props.func();
                      }}
                      style={{
                        color: "black",
                      }}
                    >
                      Confirm
                    </Button>
                  )}
                </>
              ) : (
                <Button onClick={props.handleClose} color="primary">
                  Close
                </Button>
              )}
            </DialogActions>
          </>
        ) : (
          <FeaturesTable />
        )}
      </Dialog>
    </div>
  );
}
