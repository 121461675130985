import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import Button from "@material-ui/core/Button";
import FullScreenDialog from "./DialogModal";
import CreateApiClient from "../../API";
import { ImLinkedin, ImFacebook2, ImTwitter } from "react-icons/im";
import { FaTrash, FaUserTie } from "react-icons/fa";
import { RiNotification3Fill } from "react-icons/ri";
import FormDialog from "../Partials/FormDialog";
import LoaderIcon from "react-loader-icon";
import cavaLogo from "../../images/thumbnail.jpeg";
import Select from "react-select";
import { Link } from "react-router-dom";
import Icon from "@material-ui/core/Icon";

const api = CreateApiClient();
const useRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
});

export default function CollapsibleTable(props) {
  // const { row } = props;
  const [open, setOpen] = useState();
  const classes = useRowStyles();
  const [selected, setSelected] = useState([]);
  const [isOpen, setIsOpen] = React.useState(false);
  const [prospects, setProspects] = useState([]);
  const [formOpen, setFormOpen] = React.useState(false);
  const [modalAction, setModalAction] = useState("");
  const [modalSubject, setModalSubject] = useState("");
  const basicPlans = [2741315, 2741325, 3332681, 3332639];

  const options = [
    { value: "Domain", label: "Domain" },
    { value: "APK", label: "APK" },
    { value: "3rd Party", label: "3rd Party" },
  ];

  const basicOptions = [{ value: "Domain", label: "Domain" }];

  // const socialOptions = [
  //   { value: "linkedin", label: "linkedin" },
  //   { value: "facebook", label: "facebook" },
  //   { value: "twitter", label: "twitter" },
  // ];

  const openForm = (action, subject, name) => {
    setSelected(subject);
    setModalAction(action);
    setModalSubject(name);
    setFormOpen(true);
  };

  const closeForm = () => {
    setFormOpen(false);
  };

  const config = {
    headers: {
      "auth-token": props.user.token,
    },
  };
  const defaultSrc = (ev) => {
    ev.target.src = cavaLogo;
  };

  const handleClickOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const saveProspect = async (company, domains, apps, parties) => {
    const response = await api.editUser(
      "add-prospect",
      {
        name: company.job_company_name,
        company: {
          primary_domain: company.job_company_website,
          name: company.job_company_name,
          industry: company.job_company_industry,
          continent: company.job_company_location_continent,
          country: company.job_company_location_country,
          city: company.job_company_location_locality,
          full_location: company.job_company_location_name,
          size: company.job_company_size,
          linkedin: company.job_company_linkedin_url,
          facebook: company.job_company_facebook_url,
          twitter: company.job_company_twitter_url,
        },
        domains: domains,
        apps: apps,
        parties: parties,
      },
      config
    );
    handleClose();
    if (response.prospects) {
      props.resetUser(response);
      props.setProspects(response.prospects);
      props.updateUserFields("prospects", response.prospects);
    }
    // window.location.reload();
  };

  // const onSelectAllClick = (event) => {
  //   if (event.target.checked) {
  //     const newSelecteds = rows.map((n) => n.name);
  //     setSelected(newSelecteds);
  //     return;
  //   }
  //   setSelected([]);
  // };

  // const handleClick = (event, name) => {
  //   const selectedIndex = selected.indexOf(name);
  //   let newSelected = [];

  //   if (selectedIndex === -1) {
  //     newSelected = newSelected.concat(selected, name);
  //   } else if (selectedIndex === 0) {
  //     newSelected = newSelected.concat(selected.slice(1));
  //   } else if (selectedIndex === selected.length - 1) {
  //     newSelected = newSelected.concat(selected.slice(0, -1));
  //   } else if (selectedIndex > 0) {
  //     newSelected = newSelected.concat(
  //       selected.slice(0, selectedIndex),
  //       selected.slice(selectedIndex + 1)
  //     );
  //   }

  //   setSelected(newSelected);
  // };

  // const isSelected = (name) => selected.indexOf(name) !== -1;

  // const selectRow = (row) => {
  //   if (row !== "all") {
  //     selected.indexOf(row) !== -1
  //       ? setSelected(selected.filter((s) => s !== row))
  //       : setSelected((selected) => [...selected, row]);
  //   } else {
  //     selected.length != prospects.length
  //       ? prospects.map((item) => {
  //           setSelected((selected) => [...selected, item._id]);
  //         })
  //       : setSelected([]);
  //   }
  // };

  const prospectsToEdit = async (prospect) => {
    closeForm();
    const response = await api.editUser(
      "remove-prospects",
      { prospect: prospect },
      config
    );
    props.resetUser(response);
    props.setProspects(response.prospects);
    // setProspects(response.prospects);
  };

  return (
    <>
      <FormDialog
        handleClose={closeForm}
        open={formOpen}
        action={modalAction}
        subject={modalSubject}
        type="prospect"
        prospectsToEdit={() => prospectsToEdit(selected)}
      />
      <FullScreenDialog
        open={isOpen}
        handleClose={handleClose}
        handleClickOpen={handleClickOpen}
        saveProspect={(domain, domainsToAdd, appsToAdd, partiesToAdd) =>
          saveProspect(domain, domainsToAdd, appsToAdd, partiesToAdd)
        }
        user={props.user}
      />
      <div
        className="flex-div-full-page"
        style={{ margin: "10px 0px", padding: "1rem" }}
      >
        <Button
          type="button"
          variant="contained"
          className={"searchBtn desktopSearchBtn"}
          startIcon={
            props.topUpLoader ? (
              <LoaderIcon
                className="removeLoader tableLoader"
                style={{
                  position: "relative",
                  top: "-10px",
                  right: "0px",
                }}
              />
            ) : (
              <Icon>add</Icon>
            )
          }
          onClick={handleClickOpen}
        >
          Add Prospect
        </Button>
        {/* <Button
          type="button"
          variant="contained"
          className={"searchBtn desktopSearchBtn"}
          startIcon={
            props.topUpLoader ? (
              <LoaderIcon
                className="removeLoader tableLoader"
                style={{
                  position: "relative",
                  top: "-10px",
                  right: "0px",
                }}
              />
            ) : (
              <FaUserTie />
            )
          }
          onClick={handleClickOpen}
        >
          Find Contacts
        </Button> */}
      </div>
      <TableContainer
        component={Paper}
        style={{
          overflowX: "hidden",
          borderRadius: "0px",
          boxShadow: "none",
          borderBottomLeftRadius: "10px",
          borderBottomRightRadius: "10px",
          backgroundColor: "transparent",
        }}
      >
        <Table aria-label="collapsible table" style={{ overflowX: "hidden" }}>
          <TableHead>
            <TableRow style={{ backgroundColor: "#222831" }}>
              <TableCell className="zeroWidth" />
              {/* <TableCell padding="checkbox">
                <Checkbox
                  indeterminate={selected.length === prospects.length}
                  checked={selected.length === prospects.length}
                  onChange={() => selectRow("all")}
                />
              </TableCell> */}

              <TableCell align="center">Company</TableCell>
              <TableCell align="left"></TableCell>
              <TableCell align="left">Industry</TableCell>
              <TableCell align="left">Domain</TableCell>
              <TableCell align="left">Employees</TableCell>
              {/* <TableCell align="left">Continent</TableCell> */}
              <TableCell align="left">Country</TableCell>
              {/* <TableCell align="left">City</TableCell> */}
              {/* <TableCell align="center">Alerts</TableCell> */}
              <TableCell align="center">Remove</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.prospects.length > 0 &&
              props.prospects.map((row, index) => (
                <div key={index} style={{ display: "contents" }}>
                  <TableRow
                    style={{ backgroundColor: "#222831" }}
                    key={index}
                    className={
                      open === index
                        ? classes.root +
                          " activeParentRow prospectTableRow cursor"
                        : classes.root + " prospectTableRow cursor"
                    }
                  >
                    <TableCell className="zeroWidth" align="center">
                      <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() =>
                          open != index ? setOpen(index) : setOpen(null)
                        }
                      >
                        {open === index ? (
                          <KeyboardArrowUpIcon />
                        ) : (
                          <KeyboardArrowDownIcon />
                        )}
                      </IconButton>
                    </TableCell>
                    {/* <TableCell padding="checkbox">
                      <Checkbox
                        onChange={() => selectRow(row._id)}
                        checked={selected.includes(row._id)}
                      />
                    </TableCell> */}
                    <TableCell
                      component="th"
                      scope="row"
                      align="center"
                      // className="bgLogoImg"
                      // style={{
                      //   backgroundImage: `url(https://logo.clearbit.com/${row.company.primary_domain}?size=200)`,
                      // }}
                      onClick={() =>
                        open != index ? setOpen(index) : setOpen(null)
                      }
                    >
                      <img
                        className="roundSmallImg borderBlack"
                        src={`https://logo.clearbit.com/${
                          row.company && row.company.primary_domain
                        }`}
                        onError={defaultSrc}
                      />
                    </TableCell>
                    <TableCell
                      align="left"
                      className="capitalize"
                      onClick={() =>
                        open != index ? setOpen(index) : setOpen(null)
                      }
                    >
                      {row.name}
                    </TableCell>
                    <TableCell
                      align="left"
                      className="capitalize"
                      onClick={() =>
                        open != index ? setOpen(index) : setOpen(null)
                      }
                    >
                      {row.company.industry ? row.company.industry : "UNKNOWN"}
                    </TableCell>
                    <TableCell
                      align="left"
                      onClick={() =>
                        open != index ? setOpen(index) : setOpen(null)
                      }
                    >
                      {row.company.primary_domain
                        ? row.company.primary_domain
                        : "UNKNOWN"}
                    </TableCell>
                    <TableCell
                      align="left"
                      onClick={() =>
                        open != index ? setOpen(index) : setOpen(null)
                      }
                    >
                      {row.company.size ? row.company.size : "UNKNOWN"}
                    </TableCell>
                    {/* <TableCell
                      align="left"
                      className="capitalize"
                      onClick={() =>
                        open != index ? setOpen(index) : setOpen(null)
                      }
                    >
                      {row.company.continent
                        ? row.company.continent
                        : "UNKNOWN"}
                    </TableCell> */}
                    <TableCell
                      align="left"
                      className="capitalize"
                      onClick={() =>
                        open != index ? setOpen(index) : setOpen(null)
                      }
                    >
                      {row.company.country ? row.company.country : "UNKNOWN"}
                    </TableCell>
                    {/* <TableCell
                      align="left"
                      className="capitalize"
                      onClick={() =>
                        open != index ? setOpen(index) : setOpen(null)
                      }
                    >
                      {row.company.city ? row.company.city : "UNKNOWN"}
                    </TableCell> */}
                    {/* <TableCell align="center">
                      {props.upgradeLoader === row._id ||
                      props.loader === row._id ? (
                        <LoaderIcon
                          className="removeLoader tabsLoader"
                          style={{
                            position: "relative",
                            top: "0px",
                            right: "0px",
                          }}
                        />
                      ) : (
                        <RiNotification3Fill
                          id="alertsIcon"
                          size={20}
                          className="credsIcons accountIcons cursor"
                          onClick={() => {
                            props.user && row.alerts
                              ? props.toggleAlerts(row)
                              : !basicPlans.includes(props.user.sub.plan_id)
                              ? props.toggleAlerts(row)
                              : !row.alerts && props.openUpgradeModal(row._id);
                          }}
                          style={{
                            color: row.alerts ? "#fecd4e" : "white",
                            zIndex: "99",
                          }}
                        />
                      )}
                    </TableCell> */}
                    <TableCell align="center">
                      <Button
                        style={{ color: "white" }}
                        startIcon={<FaTrash />}
                        onClick={() => openForm("remove", row._id, row.name)}
                      ></Button>
                    </TableCell>
                  </TableRow>
                  <TableRow
                    className={
                      open === index ? "activeRow expandedRow" : "expandedRow"
                    }
                  >
                    <TableCell
                      style={{ paddingBottom: 0, paddingTop: 0 }}
                      colSpan={12}
                    >
                      <Collapse
                        in={open === index}
                        timeout="auto"
                        unmountOnExit
                        style={{
                          boxShadow: "inset 0 0 10px #000000",
                        }}
                      >
                        <Box
                          margin={1}
                          style={{
                            display: "flex",
                            minHeight: "300px",
                            margin: "50px",
                          }}
                          className="prospectBox"
                        >
                          <div style={{ width: "40%" }} className="data-types">
                            <div
                              className="styledBox yellow-bg"
                              // style={{ width: "40%" }}
                            >
                              <Typography
                                variant="h6"
                                gutterBottom
                                component="div"
                                className="lightText"
                              >
                                Data Types
                              </Typography>
                              <div
                                className="addDomainToProspect inline-flex full-width"
                                style={{
                                  display: "block",
                                }}
                              >
                                {/* <Select
                                defaultValue={props.inputValue}
                                onChange={props.onChangeDomainType}
                                placeholder={"Add"}
                                className="search-by-select desktop spaced"
                                options={
                                  basicPlans.includes(props.user.sub.plan_id)
                                    ? basicOptions
                                    : options
                                }
                                theme={(theme) => ({
                                  ...theme,
                                  borderRadius: 0,
                                  colors: {
                                    ...theme.colors,
                                    primary25: "black",
                                    primary: "black",
                                  },
                                })}
                              /> */}
                                <div
                                  style={{
                                    display: "inline-flex",
                                  }}
                                >
                                  <input
                                    style={{ height: "40px" }}
                                    value={
                                      props.domainInputValue &&
                                      props.domainInputValue
                                    }
                                    type="domain"
                                    placeholder="New Domain"
                                    margin="normal"
                                    id="newDomainField"
                                    label="New Domain"
                                    name="domain"
                                    className="form-control spaced"
                                    onChange={(e) =>
                                      props.onAddDomain(e, "domain")
                                    }
                                    maxLength={500}
                                  />
                                  <div>
                                    {props.addedDomainType &&
                                    props.domainInputValue.includes(".") &&
                                    !props.domainInputValue.includes(":") &&
                                    !props.domainInputValue.includes("/") &&
                                    /^[a-zA-Z]+$/.test(
                                      props.domainInputValue[
                                        props.domainInputValue.length - 1
                                      ]
                                    ) ? (
                                      <button
                                        onClick={() =>
                                          props.addDomain(row._id, "domain")
                                        }
                                        type="submit"
                                        className="iconBtn spaced"
                                      >
                                        {props.addLoader === "domain" ? (
                                          <LoaderIcon
                                            style={{
                                              margin: "auto",
                                              width: "24px",
                                              height: "24px",
                                            }}
                                          />
                                        ) : (
                                          "+"
                                        )}
                                      </button>
                                    ) : (
                                      <button
                                        type="submit"
                                        className={"iconBtn grayBtn spaced"}
                                      >
                                        +
                                      </button>
                                    )}
                                  </div>
                                </div>
                                <br />
                                <div
                                  style={{
                                    display: "inline-flex",
                                    margin: "5px 0px",
                                  }}
                                >
                                  <input
                                    style={{ height: "40px", width: "100%" }}
                                    value={
                                      props.apkInputValue && props.apkInputValue
                                    }
                                    type="domain"
                                    placeholder="New APK"
                                    margin="normal"
                                    id="newApkField"
                                    label="New APK"
                                    name="apk"
                                    className="form-control spaced"
                                    onChange={(e) =>
                                      props.onAddDomain(e, "apk")
                                    }
                                    maxLength={500}
                                  />
                                  <div>
                                    {props.addedDomainType &&
                                    props.apkInputValue.includes(".") &&
                                    !props.apkInputValue.includes(":") &&
                                    !props.apkInputValue.includes("/") &&
                                    /^[a-zA-Z]+$/.test(
                                      props.apkInputValue[
                                        props.apkInputValue.length - 1
                                      ]
                                    ) ? (
                                      <button
                                        onClick={() =>
                                          props.addDomain(row._id, "apk")
                                        }
                                        type="submit"
                                        className="iconBtn spaced"
                                      >
                                        {props.addLoader === "apk" ? (
                                          <LoaderIcon
                                            style={{
                                              margin: "auto",
                                              width: "24px",
                                              height: "24px",
                                            }}
                                          />
                                        ) : (
                                          "+"
                                        )}
                                      </button>
                                    ) : (
                                      <button
                                        type="submit"
                                        className={"iconBtn grayBtn spaced"}
                                      >
                                        +
                                      </button>
                                    )}
                                  </div>
                                </div>
                                <br />
                                {/* <div
                                  style={{
                                    display: "inline-flex",
                                  }}
                                >
                                  <input
                                    style={{ height: "40px" }}
                                    value={
                                      props.partyInputValue &&
                                      props.partyInputValue
                                    }
                                    type="domain"
                                    placeholder="New External Domain"
                                    margin="normal"
                                    id="newPartyField"
                                    label="New External Domain"
                                    name="domain"
                                    className="form-control spaced"
                                    onChange={(e) =>
                                      props.onAddDomain(e, "3rd party")
                                    }
                                    maxLength={500}
                                  />
                                  <div>
                                    {props.addedDomainType &&
                                    props.partyInputValue.includes(".") &&
                                    !props.partyInputValue.includes(":") &&
                                    !props.partyInputValue.includes("/") &&
                                    /^[a-zA-Z]+$/.test(
                                      props.partyInputValue[
                                        props.partyInputValue.length - 1
                                      ]
                                    ) ? (
                                      <button
                                        onClick={() =>
                                          props.addDomain(row._id, "external")
                                        }
                                        type="submit"
                                        className="iconBtn spaced"
                                      >
                                        {props.addLoader === "external" ? (
                                          <LoaderIcon
                                            style={{
                                              margin: "auto",
                                              width: "24px",
                                              height: "24px",
                                            }}
                                          />
                                        ) : (
                                          "+"
                                        )}
                                      </button>
                                    ) : (
                                      <button
                                        type="submit"
                                        className={"iconBtn grayBtn spaced"}
                                      >
                                        +
                                      </button>
                                    )}
                                  </div>
                                </div> */}
                              </div>
                              <br />
                              <br />

                              {/* {!basicPlans.includes(props.user.sub.plan_id) && (
                              <div className="addDomainToProspect inline-flex full-width">
                                <Select
                                  defaultValue={props.inputValue}
                                  onChange={props.onChangeSocialType}
                                  placeholder={"Social Link"}
                                  className="search-by-select desktop spaced"
                                  options={socialOptions}
                                  theme={(theme) => ({
                                    ...theme,
                                    borderRadius: 0,
                                    colors: {
                                      ...theme.colors,
                                      primary25: "black",
                                      primary: "black",
                                    },
                                  })}
                                />
                                <input
                                  style={{ height: "40px", width: "30%" }}
                                  value={
                                    props.socialInputValue &&
                                    props.socialInputValue
                                  }
                                  type="domain"
                                  placeholder="New social link"
                                  margin="normal"
                                  id="newDomainField"
                                  label="New Domain"
                                  name="domain"
                                  className="form-control spaced"
                                  onChange={(e) => props.onAddSocial(e)}
                                  maxLength={50}
                                />

                                <div>
                                  {props.socialType &&
                                  props.socialInputValue.includes(".") ? (
                                    <button
                                      onClick={() => props.addSocial(row._id)}
                                      type="submit"
                                      className="iconBtn spaced"
                                    >
                                      +
                                    </button>
                                  ) : (
                                    <button
                                      type="submit"
                                      className={"iconBtn grayBtn spaced"}
                                    >
                                      +
                                    </button>
                                  )}
                                </div>
                              </div>
                            )} */}
                            </div>
                          </div>

                          <div
                            className="full-width prospect-data-table"
                            style={{ width: "60%" }}
                          >
                            <Table size="small" aria-label="purchases">
                              <TableHead>
                                <TableRow className="lightText">
                                  <TableCell>Domains</TableCell>
                                  <TableCell>APK's</TableCell>
                                  {/* <TableCell>3rd Parties</TableCell> */}
                                  {!props.user.sub ||
                                    (!basicPlans.includes(
                                      props.user.sub.plan_id
                                    ) && <TableCell>Social</TableCell>)}
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                <TableRow key={index} className="lightText">
                                  <TableCell component="th" scope="row">
                                    {row.domains && (
                                      <ul className="domainList">
                                        {row.domains.map((domain, index) => (
                                          <li
                                            key={index}
                                            style={{
                                              border: "1px solid #6c6565",
                                              borderRadius: "20px",
                                              margin: "4px 0px",
                                            }}
                                          >
                                            {
                                              <button
                                                className="remove"
                                                onClick={() =>
                                                  props.removeDomain(
                                                    row._id,
                                                    domain,
                                                    "domain"
                                                  )
                                                }
                                                value={domain}
                                              >
                                                {props.domainLoader ===
                                                domain ? (
                                                  <LoaderIcon
                                                    className="tableLoader"
                                                    style={{
                                                      width: "24px",
                                                      height: "24px",
                                                    }}
                                                  />
                                                ) : (
                                                  <>X</>
                                                )}
                                              </button>
                                            }

                                            {domain}
                                          </li>
                                        ))}
                                      </ul>
                                    )}
                                  </TableCell>
                                  <TableCell component="th" scope="row">
                                    {!props.user.sub ||
                                    !basicPlans.includes(
                                      props.user.sub.plan_id
                                    ) ? (
                                      row.apps && (
                                        <ul className="domainList">
                                          {row.apps.map((app, index) => (
                                            <li
                                              key={index}
                                              style={{
                                                border: "1px solid #6c6565",
                                                borderRadius: "20px",
                                                margin: "4px 0px",
                                              }}
                                            >
                                              {
                                                <button
                                                  className="remove"
                                                  onClick={() =>
                                                    props.removeDomain(
                                                      row._id,
                                                      app,
                                                      "app"
                                                    )
                                                  }
                                                  value={app}
                                                >
                                                  {props.domainLoader ===
                                                  app ? (
                                                    <LoaderIcon
                                                      className="tableLoader"
                                                      style={{
                                                        width: "24px",
                                                        height: "24px",
                                                      }}
                                                    />
                                                  ) : (
                                                    <>X</>
                                                  )}
                                                </button>
                                              }
                                              {app}
                                            </li>
                                          ))}
                                        </ul>
                                      )
                                    ) : props.upgradeLoader ===
                                      `${row._id}apps` ? (
                                      <LoaderIcon
                                        className="removeLoader tableLoader"
                                        style={{
                                          position: "relative",
                                          top: "0px",
                                          right: "0px",
                                          margin: "0px 60px 0px 0px",
                                        }}
                                      />
                                    ) : (
                                      <Button
                                        className="searchBtn"
                                        onClick={() =>
                                          props.openUpgradeModal(
                                            `${row._id}apps`
                                          )
                                        }
                                      >
                                        Upgrade
                                      </Button>
                                    )}
                                  </TableCell>
                                  {/* <TableCell component="th" scope="row">
                                    {!props.user.sub ||
                                    !basicPlans.includes(
                                      props.user.sub.plan_id
                                    ) ? (
                                      row.monitored_domains && (
                                        <ul className="domainList">
                                          {row.monitored_domains.map(
                                            (domain, index) => (
                                              <li
                                                key={index}
                                                style={{
                                                  border: "1px solid #6c6565",
                                                  borderRadius: "20px",
                                                  margin: "4px 0px",
                                                }}
                                              >
                                                {
                                                  <button
                                                    className="remove"
                                                    onClick={() =>
                                                      props.removeDomain(
                                                        row._id,
                                                        domain,
                                                        "monitored"
                                                      )
                                                    }
                                                    value={domain}
                                                  >
                                                    {props.domainLoader ===
                                                    domain ? (
                                                      <LoaderIcon
                                                        className="tableLoader"
                                                        style={{
                                                          width: "24px",
                                                          height: "24px",
                                                        }}
                                                      />
                                                    ) : (
                                                      <>X</>
                                                    )}
                                                  </button>
                                                }
                                                {domain}
                                              </li>
                                            )
                                          )}
                                        </ul>
                                      )
                                    ) : props.upgradeLoader ===
                                      `${row._id}parties` ? (
                                      <LoaderIcon
                                        className="removeLoader tableLoader"
                                        style={{
                                          position: "relative",
                                          top: "0px",
                                          right: "0px",
                                          margin: "0px 60px 0px 0px",
                                        }}
                                      />
                                    ) : (
                                      <Button
                                        className="searchBtn"
                                        onClick={() =>
                                          props.openUpgradeModal(
                                            `${row._id}parties`
                                          )
                                        }
                                      >
                                        Upgrade
                                      </Button>
                                    )}
                                  </TableCell> */}
                                  {!props.user.sub ||
                                    (!basicPlans.includes(
                                      props.user.sub.plan_id
                                    ) && (
                                      <TableCell component="th" scope="row">
                                        <div>
                                          <div className="card-footer-box">
                                            <a
                                              className={
                                                row.company &&
                                                row.company.linkedin
                                                  ? "socialIcon linkedin"
                                                  : "socialIcon grayIcon"
                                              }
                                              href={
                                                row.company &&
                                                row.company.linkedin
                                                  ? `https://${row.company.linkedin}`
                                                  : null
                                              }
                                              target="_blank"
                                              rel="noreferrer"
                                            >
                                              <ImLinkedin size={20} />
                                            </a>
                                            <a
                                              className={
                                                row.company &&
                                                row.company.facebook
                                                  ? "socialIcon facebook"
                                                  : "socialIcon grayIcon"
                                              }
                                              href={
                                                row.company &&
                                                row.company.facebook
                                                  ? `https://${row.company.facebook}`
                                                  : null
                                              }
                                              target="_blank"
                                              rel="noreferrer"
                                            >
                                              <ImFacebook2 size={20} />
                                            </a>
                                            <a
                                              className={
                                                row.company &&
                                                row.company.twitter
                                                  ? "socialIcon twitter"
                                                  : "socialIcon grayIcon"
                                              }
                                              href={
                                                row.company &&
                                                row.company.twitter
                                                  ? `https://${row.company.twitter}`
                                                  : null
                                              }
                                              target="_blank"
                                              rel="noreferrer"
                                            >
                                              <ImTwitter size={20} />
                                            </a>
                                          </div>
                                        </div>
                                      </TableCell>
                                    ))}
                                </TableRow>
                              </TableBody>
                            </Table>
                            <br />
                            {row.domains.length > 0 ||
                            row.apps.length > 0 ||
                            row.monitored_domains.length > 0 ? (
                              <Link to={`/search-by-prospect/${row._id}`}>
                                <Button className="searchBtn">
                                  Display Data
                                </Button>
                              </Link>
                            ) : (
                              <Button className="searchBtn grayBtn">
                                Display Data
                              </Button>
                            )}
                          </div>
                        </Box>
                      </Collapse>
                    </TableCell>
                  </TableRow>
                </div>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
