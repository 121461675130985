import React, { useState, useLayoutEffect, useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import App from "./App";
import "./styles/App.css";
import SignInSide from "./components/Auth/SignIn";
import UrlSignIn from "./components/Auth/UrlSignIn";

import { createBrowserHistory } from "history";
import NotFound from "./components/Content/404";
import { authAddress, blueSnapAddress, APIFullAddress, bsEnv } from "./Config";
import axios from "axios";
import LoggedOutModal from "../src/components/Partials/LoggedOutModal";
import Domain from "./components/Data/Domain";
import Faq from "./components/Content/Faq";
import Tos from "./components/Content/Tos";
import Account from "./components/Account/Account";
import Contact from "./components/Content/Contact";
import Checkout from "./components/Checkout/Checkout";
import ThankYou from "./components/Checkout/ThankYou";
import Prospects from "./components/CRM/Prospects";
import Systems from "./components/Data/Systems";
import UnlockedContent from "./components/Data/UnlockedContent";
import CreateApiClient from "./API";
import AdminPanel from "./components/Admin/AdminPanel";
import Modal from "./components/Account/Modal";
import Overlay from "./components/Account/Overlay";
import Footer from "./components/Partials/Footer";
import Help from "./components/Content/Help";
import ResetPassword from "./components/Auth/ResetPassword";
import { checkUser } from "./hooks/CheckUserSession";
import SignUp from "./components/Auth/SignUp";
import NewCheckout from "./components/Checkout/NewCheckout";
import { isValidEmail } from "./hooks/Validation";
import Tutorials from "./components/Content/Tutorials";

// import Test from "./Test";
// import Interceptors from "./Interceptors";
import Activation from "./components/Account/Activation";
const history = createBrowserHistory();
const api = CreateApiClient();

function Main(props) {
  // Interceptors(axios);

  const [logEmail, setLogEmail] = useState("");
  const [logPassword, setLogPassword] = useState("");
  const [loginError, setLoginError] = useState("");

  const [signUpEmail, setSignUpEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [signUpError, setSignUpError] = useState("");
  const [stage, setStage] = useState("email");

  // validate new password
  const [validLength, setValidLength] = useState(false);
  const [validUppercase, setValidUppercase] = useState(false);
  const [validLowercase, setValidLowercase] = useState(false);
  const [validNumber, setValidNumber] = useState(false);
  const [validSign, setValidSign] = useState(false);

  const [loggingOut, isLoggingOut] = useState(false);
  const [companies, setCompanies] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState();
  const [openModal, setOpenModal] = useState(false);
  const [resultsCount, setResultsCount] = useState(0);
  const [loader, setLoader] = useState(false);
  const [finalRes, setFinalRes] = useState(false);
  const [page, setPage] = useState(0);
  const [user, setUser] = useState(JSON.parse(props.user));
  const [now, setNow] = useState("");
  const [upgradeOpen, setUpgradeOpen] = useState(false);
  const [shopper, setShopper] = useState("");
  const [upgradeLoader, setUpgradeLoader] = useState("");
  const [topUpLoader, setTopUpLoader] = useState("");
  const [finish, setFinish] = useState(false);
  const [overlay, setOverlay] = useState("");
  const [dehashLoader, setDehashLoader] = useState(false);
  const [dehashedMessage, setDehashedMessage] = useState("");
  const currentPage = history.location;
  const [industries, setIndustries] = useState([]);
  const [countries, setCountries] = useState([]);
  const [sizes, setSizes] = useState([]);
  const [regions, setRegions] = useState([]);
  const [cardsLoader, setCardsLoader] = useState(false);
  const [activeTimeFilter, setActiveTimeFilter] = useState("all");
  const [activeTypeFilter, setActiveTypeFilter] = useState("all");

  const [role, setRole] = useState("");
  const [pageLoading, setPageLoading] = useState(false);

  // const isValidEmail = (email) => {
  //   return /^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/.test(email);
  // };

  const getFilters = async () => {
    setLoading(true);
    await axios
      .get(`${APIFullAddress}/companies/get-filters`)
      .then((res) => {
        setIndustries(res.data.industries);
        setCountries(res.data.countries);
        setSizes(res.data.sizes);
        setRegions(res.data.usa_regions);
      })
      .catch((err) => console.log(err));
  };

  const checkRole = async (_user) => {
    setPageLoading(true);
    await axios
      .post(
        `${authAddress}/role`,
        {},
        { headers: { "auth-token": _user.token } }
      )
      .then((res) => {
        setRole(res.data);
        setPageLoading(false);
      })
      .catch((err) => {
        setPageLoading(false);
        console.log(err);
      });
  };

  useEffect(() => {
    const time = Date.now().toString().substring(0, 10);
    const nowNum = Number(time);
    setNow(nowNum);
  }, [now]);

  useEffect(() => {
    getFilters();
    const filters = localStorage.getItem("filters");
    const loggedInUser = localStorage.getItem("user");
    if (loggedInUser) {
      const foundUser = JSON.parse(loggedInUser);
      setUser(foundUser);
      checkRole(foundUser);
      if (filters) {
        const jsonFilters = JSON.parse(filters);
        setFilters(jsonFilters);
        const _industries = jsonFilters.industries;
        const _sizes = jsonFilters.sizes;
        const _countries = jsonFilters.countries;
        const _regions = jsonFilters.regions;
        const _time = jsonFilters.time;
        const _type = jsonFilters.type;

        filterCompanies(
          _industries,
          _sizes,
          _countries,
          _regions,
          _time,
          _type
        );
      }
    }
  }, []);

  const handleLogOut = () => {
    setOpenModal(true);
    console.log("Logging out");
    setTimeout(() => {
      localStorage.clear();
      setUser(!user);
      redirectToLoginPage();
      setOpenModal(false);
    }, 2000);
  };

  const handleHomeLogOut = () => {
    localStorage.clear();
    setUser(!user);
    redirectToLoginPage();
  };

  const redirectToLoginPage = () => {
    history.push("/");
    window.location.reload();
  };

  const onChangeEmail = (e) => {
    setLogEmail(e.target.value);
  };

  const onChangePassword = (e) => {
    setLogPassword(e.target.value);
  };

  const changeEmail = (e) => {
    setSignUpError("");
    setSignUpEmail(e.target.value);
  };

  const changeFname = (e) => {
    setSignUpError("");
    setFirstName(e.target.value);
  };

  const changeLname = (e) => {
    setSignUpError("");
    setLastName(e.target.value);
  };

  const changePassword = (e) => {
    setSignUpError("");
    const uppercasePass = /[A-Z]/;
    const lowercasePass = /[a-z]/;
    const numberPass = /[0-9]/;
    const signPass = /[!@#$%^&*+=]/;

    let pass = e.target.value;

    if (uppercasePass.test(pass)) {
      setValidUppercase(true);
    } else {
      setValidUppercase(false);
    }

    if (lowercasePass.test(pass)) {
      setValidLowercase(true);
    } else {
      setValidLowercase(false);
    }

    if (numberPass.test(pass)) {
      setValidNumber(true);
    } else {
      setValidNumber(false);
    }

    if (signPass.test(pass)) {
      setValidSign(true);
    } else {
      setValidSign(false);
    }

    if (pass.length >= 8) {
      setValidLength(true);
    } else {
      setValidLength(false);
    }

    setNewPassword(pass);
  };

  const login = (e) => {
    isLoggingOut(false);
    e.preventDefault();
    setLoginError("");
    const user = {
      email: logEmail,
      password: logPassword,
    };

    axios
      .post(`${authAddress}/login`, user)
      .then((res) => {
        localStorage.setItem("user", JSON.stringify(res.data));
        setUser(res.data);
        setRole(res.data.user.role);
      })
      .catch((error) => {
        if (error.response) {
          setLoginError(error.response.data);
        }
        console.log(error);
      });
  };

  const urlHashLogin = (hash) => {
    setLoading(true);
    isLoggingOut(false);
    setLoginError(null);

    axios
      .post(`${authAddress}/hashlogin?token=${hash}`)
      .then((res) => {
        localStorage.setItem("user", JSON.stringify(res.data));
        setUser(res.data);
        setRole(res.data.user.role);
      })
      .catch((error) => {
        if (error.response.data) {
          console.log(error.response.data);
          setLoginError(error.response.data);
        } else {
          setLoginError("Wrong Token");
        }
        setLoading(false);
        console.log(error);
      });
    setLoading(false);
  };

  const refresh = () => {
    window.location.reload();
  };

  const redirect = (e) => {
    history.push(e);
    window.location.reload();
  };

  const timeOptions = [
    { value: "week", label: "Last 7 Days" },
    { value: "month", label: "Last 30 Days" },
    { value: "quarter", label: "Last 3 Months" },
    { value: "year", label: "Last 12 Months" },
    { value: "all", label: "All Time" },
  ];

  const getCards = async (oldData, page, filters) => {
    const config = {
      headers: {
        "auth-token": user.token,
      },
    };
    if (!filters) {
      if (page > 0) {
        setLoader(true);
      } else {
        setLoading(true);
        setFinalRes(false);
      }
      const companiesData = await api.fetchData(
        `companies?filters=false&page=${page}`,
        {},
        config
      );

      if (companiesData.length > 0) {
        setCompanies([...oldData, ...companiesData]);
      } else {
        setFinalRes(true);
      }
      if (page > 0) {
        setLoader(false);
      }
    } else {
      if (page > 0) {
        setLoader(true);
      } else {
        setFinalRes(false);
        if (filters.time) {
          setCardsLoader(true);
        } else {
          setLoading(true);
          setActiveTimeFilter("all");
          setActiveTypeFilter("all");
        }
      }

      const companiesData = await api.fetchData(
        `companies?filters=true&page=${page}`,
        {
          filters: filters,
          all: {
            industries: industries,
            sizes: sizes,
            countries: countries,
            regions: regions,
            time: timeOptions,
          },
        },
        config
      );
      if (page > 0) {
        setLoader(false);
      } else {
        if (filters.time) {
          setCardsLoader(false);
        } else {
          setLoading(false);
        }
      }

      if (companiesData.companies.length > 0) {
        setResultsCount(companiesData.count);
        setCompanies([...oldData, ...companiesData.companies]);
      } else {
        if (page > 0) {
          setFinalRes(true);
        } else {
          setResultsCount(0);
          setCompanies([]);
        }
      }
    }
    setLoading(false);
  };

  const filterCompanies = (
    _industries,
    _sizes,
    _countries,
    _regions,
    _time,
    _type
  ) => {
    const filters = {
      industries: _industries,
      sizes: _sizes,
      countries: _countries,
      regions: _regions ? _regions : [],
      time: _time,
      type: _type,
    };
    localStorage.setItem("filters", JSON.stringify(filters));
    setFilters(filters);
    setPage(0);
    getCards([], 0, filters);
  };

  const filterByTime = (period) => {
    setActiveTimeFilter(period);
    filterCompanies(
      filters.industries,
      filters.sizes,
      filters.countries,
      filters.regions,
      period,
      activeTypeFilter
    );
  };

  const filterByType = (type) => {
    setActiveTypeFilter(type);
    filterCompanies(
      filters.industries,
      filters.sizes,
      filters.countries,
      filters.regions,
      activeTimeFilter,
      type
    );
  };

  const resetUser = (user) => {
    const loggedInUser = localStorage.getItem("user");
    const jsonUser = JSON.parse(loggedInUser);
    jsonUser.user = user;
    localStorage.setItem("user", JSON.stringify(jsonUser));
    setUser(jsonUser);
  };

  const resetSub = (sub) => {
    const loggedInUser = localStorage.getItem("user");
    const jsonUser = JSON.parse(loggedInUser);
    jsonUser.sub = sub;
    localStorage.setItem("user", JSON.stringify(jsonUser));
    setUser(jsonUser);
  };

  const openUpgradeModal = async (e) => {
    if (!user.sub && user.user.role === "mssp") {
      setUpgradeOpen(true);
      return;
    }
    const config = {
      headers: {
        "auth-token": user.token,
      },
    };

    if (e) {
      e.target && e.target.id
        ? setUpgradeLoader(e.target.id)
        : e.target && e.target.offsetParent && e.target.offsetParent.id
        ? setUpgradeLoader(e.target.offsetParent.id)
        : !e
        ? setUpgradeLoader(true)
        : setUpgradeLoader(e);
    }

    await axios
      .post(
        `${blueSnapAddress}/custom-checkout?action=upgrade&sub=${user.sub.subscriptionId}&shopper=${user.sub.vaultedShopperId}&env=${bsEnv}`,
        {},
        config
      )
      .then((res) => {
        setUpgradeOpen(true);
        setShopper(res.data);
      })
      .catch((err) => {
        if (err.response && err.response.data) {
          if (err.response.data === "Invalid Token") {
            props.handleHomeLogOut();
          } else {
            alert(err.response.data);
          }
        } else {
          console.log(err);
        }
      });

    setUpgradeLoader(null);
  };

  const closeUpgradeModal = () => {
    setUpgradeOpen(false);
  };

  const openTopUpModal = async (item) => {
    const config = {
      headers: {
        "auth-token": user.token,
      },
    };

    setTopUpLoader(item.name);

    await axios
      .post(
        `${blueSnapAddress}/custom-checkout/top-up?sub=${user.sub.subscriptionId}&shopper=${user.sub.vaultedShopperId}&env=${bsEnv}`,
        { credits: item.credits, price: item.price },
        config
      )
      .then((res) => {
        setShopper(res.data);
        setUpgradeOpen(true);
      })
      .catch((err) => {
        if (err.response && err.response.data) {
          if (err.response.data === "Invalid Token") {
            props.handleHomeLogOut();
          } else {
            console.log(err.response.data);
          }
        } else {
          console.log(err);
        }
      });

    setTopUpLoader(null);
  };

  const updateSubscription = async (cvv) => {
    const config = {
      headers: {
        "auth-token": user.token,
      },
    };

    setFinish(false);
    setOverlay("upgrade");
    const body = {
      shopper: shopper,
      cvv: cvv,
    };

    await axios
      .post(
        `${blueSnapAddress}/subscription/update?action=upgrade&sub=${user.sub.subscriptionId}&env=${bsEnv}`,
        body,
        config
      )
      .then((res) => {
        resetSub(res.data);
      })
      .catch((err) => console.log(err));
    setFinish(true);
    setTimeout(() => {
      setOverlay("");
      closeUpgradeModal();
    }, 2000);
    window.location.reload();
  };

  const purchaseTopUp = async (cvv) => {
    const config = {
      headers: {
        "auth-token": user.token,
      },
    };

    setFinish(false);
    setOverlay("upgrade");
    const body = {
      shopper: shopper,
      cvv: cvv,
    };

    await axios
      .post(
        `${blueSnapAddress}/subscription/top-up?sub=${user.sub.subscriptionId}&env=${bsEnv}`,
        body,
        config
      )
      .then((res) => {
        resetUser(res.data);
      })
      .catch((err) => console.log(err));
    setFinish(true);
    setTimeout(() => {
      setOverlay("");
      closeUpgradeModal();
    }, 2000);
    // window.location.reload();
  };

  const fetchDehashed = async (domains) => {
    const config = {
      headers: {
        "auth-token": user.token,
      },
    };
    await axios
      .post(`${APIFullAddress}/dbs/fetch-dbs`, { domains: domains }, config)
      .then((res) => {
        if (res.data) {
          // console.log(res.data);
          // window.location.reload();
          setDehashedMessage(
            "Data is being fetched, please check back for results in 10 minutes"
          );
        }
      })
      .catch((err) => {
        // setDehashLoader(false);
        setDehashedMessage("No data found for this domain");
      });
  };

  const signUp = async (e) => {
    e.preventDefault();
    setOverlay("signup");
    const body = {
      first_name: firstName,
      last_name: lastName,
      email: signUpEmail,
      password: newPassword,
    };

    await axios
      .post(`${authAddress}/sign-up`, body)
      .then(async (res) => {
        redirect(`/activate-account/${res.data._id}`);
        // await axios
        //   .post(`${authAddress}/login`, newUser)
        //   .then((res) => {
        //     localStorage.setItem("user", JSON.stringify(res.data));
        //     setUser(res.data);
        //     redirect("/");
        //     setOverlay("");
        //   })
        //   .catch((error) => {
        //     if (error.response) {
        //       setLoginError(error.response.data);
        //     }
        //     setOverlay("");
        //     console.log(error);
        //   });
      })
      .catch((error) => {
        if (error.response) {
          setSignUpError(error.response.data);
        }
        setOverlay("");
        console.log(error);
      });
  };

  const nextStage = async (e) => {
    e.preventDefault();
    if (signUpEmail && firstName && lastName) {
      if (isValidEmail(signUpEmail)) {
        await axios
          .post(`${authAddress}/validate-corp-email`, { email: signUpEmail })
          .then((res) => {
            setSignUpError("");
            setStage("password");
          })
          .catch((error) => {
            if (error.response) {
              setSignUpError(error.response.data);
            }
            console.log(error);
          });
      } else {
        setSignUpError("Email is not valid");
      }
    } else {
      setSignUpError("One or more fields are missing");
    }
  };

  return (
    <Router history={history}>
      <Routes>
        {user && user.expiry > now ? (
          <>
            <Route
              exact
              path={process.env.PUBLIC_URL + "/sign-up"}
              element={
                <App
                  activeTimeFilter={activeTimeFilter}
                  activeTypeFilter={activeTypeFilter}
                  cardsLoader={cardsLoader}
                  filterByTime={(period) => filterByTime(period)}
                  filterByType={(type) => filterByType(type)}
                  openTopUpModal={(item) => openTopUpModal(item)}
                  topUpLoader={topUpLoader}
                  openUpgradeModal={openUpgradeModal}
                  upgradeLoader={upgradeLoader}
                  shopper={shopper}
                  resultsCount={resultsCount}
                  page={page}
                  setPage={(page) => setPage(page)}
                  finalRes={finalRes}
                  loader={loader}
                  resetUser={(user) => resetUser(user)}
                  resetSub={(sub) => resetSub(sub)}
                  filters={filters}
                  loading={loading}
                  getCards={getCards}
                  filterCompanies={(industries, sizes, countries, regions) =>
                    filterCompanies(industries, sizes, countries, regions)
                  }
                  companies={companies}
                  user={user}
                  handleLogOut={() => handleLogOut()}
                  handleHomeLogOut={() => handleHomeLogOut()}
                />
              }
            ></Route>
            <Route
              exact
              path={process.env.PUBLIC_URL + "/"}
              element={
                <App
                  activeTimeFilter={activeTimeFilter}
                  activeTypeFilter={activeTypeFilter}
                  cardsLoader={cardsLoader}
                  filterByTime={(period) => filterByTime(period)}
                  filterByType={(type) => filterByType(type)}
                  openTopUpModal={(item) => openTopUpModal(item)}
                  topUpLoader={topUpLoader}
                  openUpgradeModal={openUpgradeModal}
                  upgradeLoader={upgradeLoader}
                  shopper={shopper}
                  resultsCount={resultsCount}
                  page={page}
                  setPage={(page) => setPage(page)}
                  finalRes={finalRes}
                  loader={loader}
                  resetUser={(user) => resetUser(user)}
                  resetSub={(sub) => resetSub(sub)}
                  filters={filters}
                  loading={loading}
                  getCards={getCards}
                  filterCompanies={(industries, sizes, countries, regions) =>
                    filterCompanies(industries, sizes, countries, regions)
                  }
                  companies={companies}
                  user={user}
                  handleLogOut={() => handleLogOut()}
                  handleHomeLogOut={() => handleHomeLogOut()}
                />
              }
            ></Route>
            <Route
              path={process.env.PUBLIC_URL + "/tokenaccess"}
              element={
                <UrlSignIn
                  login={urlHashLogin}
                  user={user}
                  loading={loading}
                  // token={token}
                  logEmail={logEmail}
                  handleLogOut={() => handleLogOut()}
                  handleHomeLogOut={() => handleHomeLogOut()}
                  loginError={loginError}
                />
              }
            ></Route>
            {/* <Route
              exact
              path={process.env.PUBLIC_URL + "/tokenaccess"}
              element={
                <App
                  activeTimeFilter={activeTimeFilter}
                  activeTypeFilter={activeTypeFilter}
                  cardsLoader={cardsLoader}
                  filterByTime={(period) => filterByTime(period)}
                  filterByType={(type) => filterByType(type)}
                  openTopUpModal={(item) => openTopUpModal(item)}
                  topUpLoader={topUpLoader}
                  openUpgradeModal={openUpgradeModal}
                  upgradeLoader={upgradeLoader}
                  shopper={shopper}
                  resultsCount={resultsCount}
                  page={page}
                  setPage={(page) => setPage(page)}
                  finalRes={finalRes}
                  loader={loader}
                  resetUser={(user) => resetUser(user)}
                  resetSub={(sub) => resetSub(sub)}
                  filters={filters}
                  loading={loading}
                  getCards={getCards}
                  filterCompanies={(industries, sizes, countries, regions) =>
                    filterCompanies(industries, sizes, countries, regions)
                  }
                  companies={companies}
                  user={user}
                  handleLogOut={() => handleLogOut()}
                  handleHomeLogOut={() => handleHomeLogOut()}
                />
              }
            ></Route> */}
            <Route
              exact
              path={process.env.PUBLIC_URL + "/prospects"}
              element={
                <Prospects
                  openTopUpModal={(item) => openTopUpModal(item)}
                  topUpLoader={topUpLoader}
                  openUpgradeModal={openUpgradeModal}
                  upgradeLoader={upgradeLoader}
                  shopper={shopper}
                  resetUser={(user) => resetUser(user)}
                  resetSub={(sub) => resetSub(sub)}
                  user={user}
                  redirect={(e) => redirect(e)}
                  handleHomeLogOut={() => handleHomeLogOut()}
                  handleLogOut={() => handleLogOut()}
                  loading={loading}
                  getCards={getCards}
                  filterCompanies={(industries, sizes, countries, regions) =>
                    filterCompanies(industries, sizes, countries, regions)
                  }
                  companies={companies}
                />
              }
            ></Route>
            <Route
              exact
              path={process.env.PUBLIC_URL + "/advanced-search"}
              element={
                <App
                  activeTimeFilter={activeTimeFilter}
                  activeTypeFilter={activeTypeFilter}
                  cardsLoader={cardsLoader}
                  filterByTime={(period) => filterByTime(period)}
                  filterByType={(type) => filterByType(type)}
                  openTopUpModal={(item) => openTopUpModal(item)}
                  topUpLoader={topUpLoader}
                  openUpgradeModal={openUpgradeModal}
                  upgradeLoader={upgradeLoader}
                  shopper={shopper}
                  resultsCount={resultsCount}
                  page={page}
                  setPage={(page) => setPage(page)}
                  finalRes={finalRes}
                  loader={loader}
                  resetUser={(user) => resetUser(user)}
                  resetSub={(sub) => resetSub(sub)}
                  filters={filters}
                  loading={loading}
                  getCards={getCards}
                  filterCompanies={(industries, sizes, countries, regions) =>
                    filterCompanies(industries, sizes, countries, regions)
                  }
                  companies={companies}
                  user={user}
                  handleLogOut={() => handleLogOut()}
                  handleHomeLogOut={() => handleHomeLogOut()}
                />
              }
            ></Route>
            <Route
              exact
              path={process.env.PUBLIC_URL + "/unlocked-data"}
              element={
                user.user &&
                user.user.unlocked_content &&
                user.user.unlocked_content.length > 0 ? (
                  <UnlockedContent
                    openTopUpModal={(item) => openTopUpModal(item)}
                    topUpLoader={topUpLoader}
                    openUpgradeModal={openUpgradeModal}
                    upgradeLoader={upgradeLoader}
                    shopper={shopper}
                    resetUser={(user) => resetUser(user)}
                    resetSub={(sub) => resetSub(sub)}
                    config={props.config}
                    userRole={props.role}
                    selectedDomain={props.domain}
                    unlockedStealers={props.unlockedStealers}
                    loader={props.loader}
                    employeesCount={props.employeesCount}
                    finalRes={props.finalRes}
                    path={props.path}
                    currentProspect={props.currentProspect}
                    user={user}
                    handleLogOut={() => handleLogOut()}
                    handleHomeLogOut={() => handleHomeLogOut()}
                    now={now}
                    token={user.token}
                    email={user.user.email}
                    role={user.user.role}
                    filterCompanies={(industries, sizes, countries, regions) =>
                      filterCompanies(industries, sizes, countries, regions)
                    }
                  />
                ) : (
                  <div>No Unlocked Data</div>
                )
              }
            ></Route>
            <Route
              exact
              path={process.env.PUBLIC_URL + "/search-by-domain/:domain"}
              element={
                <Domain
                  dehashedMessage={dehashedMessage}
                  dehashLoader={dehashLoader}
                  overlay={overlay}
                  openTopUpModal={(item) => openTopUpModal(item)}
                  topUpLoader={topUpLoader}
                  openUpgradeModal={openUpgradeModal}
                  upgradeLoader={upgradeLoader}
                  shopper={shopper}
                  resetUser={(user) => resetUser(user)}
                  resetSub={(sub) => resetSub(sub)}
                  loading={loading}
                  getCards={getCards}
                  filterCompanies={(industries, sizes, countries, regions) =>
                    filterCompanies(industries, sizes, countries, regions)
                  }
                  companies={companies}
                  unlockedStealers={
                    user.user.role !== "admin" ? user.user.unlockedStealers : []
                  }
                  monitoredDomains={
                    user.user.role !== "admin" && user.sub
                      ? user.sub.monitoredDomains
                      : []
                  }
                  user={user}
                  token={user.token}
                  email={logEmail}
                  handleLogOut={() => handleLogOut()}
                  handleHomeLogOut={() => handleHomeLogOut()}
                  fetchDehashed={(domain) => fetchDehashed(domain)}
                />
              }
            ></Route>
            <Route
              exact
              path={process.env.PUBLIC_URL + "/search-by-prospect/:prospect"}
              element={
                <Domain
                  dehashedMessage={dehashedMessage}
                  dehashLoader={dehashLoader}
                  overlay={overlay}
                  openTopUpModal={(item) => openTopUpModal(item)}
                  topUpLoader={topUpLoader}
                  openUpgradeModal={openUpgradeModal}
                  upgradeLoader={upgradeLoader}
                  shopper={shopper}
                  resetUser={(user) => resetUser(user)}
                  resetSub={(sub) => resetSub(sub)}
                  loading={loading}
                  getCards={getCards}
                  filterCompanies={(industries, sizes, countries, regions) =>
                    filterCompanies(industries, sizes, countries, regions)
                  }
                  companies={companies}
                  unlockedStealers={
                    user.user.role !== "admin" ? user.user.unlockedStealers : []
                  }
                  monitoredDomains={
                    user.user.role !== "admin" && user.sub
                      ? user.sub.monitoredDomains
                      : []
                  }
                  user={user}
                  token={user.token}
                  // role={user.user.role}
                  email={logEmail}
                  handleLogOut={() => handleLogOut()}
                  handleHomeLogOut={() => handleHomeLogOut()}
                  fetchDehashed={(domain) => fetchDehashed(domain)}
                />
              }
            ></Route>
            <Route
              exact
              path={process.env.PUBLIC_URL + "/search-by-system/:system"}
              element={
                <Systems
                  timeOptions={timeOptions}
                  openTopUpModal={(item) => openTopUpModal(item)}
                  topUpLoader={topUpLoader}
                  openUpgradeModal={openUpgradeModal}
                  upgradeLoader={upgradeLoader}
                  shopper={shopper}
                  resetUser={(user) => resetUser(user)}
                  resetSub={(sub) => resetSub(sub)}
                  loading={loading}
                  getCards={getCards}
                  filterCompanies={(industries, sizes, countries, regions) =>
                    filterCompanies(industries, sizes, countries, regions)
                  }
                  filters={filters}
                  companies={companies}
                  user={user}
                  token={user.token}
                  email={logEmail}
                  handleLogOut={() => handleLogOut()}
                  handleHomeLogOut={() => handleHomeLogOut()}
                />
              }
            ></Route>
            <Route
              exact
              path={process.env.PUBLIC_URL + "/my-account"}
              element={
                <Account
                  overlay={overlay}
                  openTopUpModal={(item) => openTopUpModal(item)}
                  topUpLoader={topUpLoader}
                  openUpgradeModal={openUpgradeModal}
                  upgradeLoader={upgradeLoader}
                  shopper={shopper}
                  resetUser={(user) => resetUser(user)}
                  resetSub={(sub) => resetSub(sub)}
                  refresh={refresh}
                  now={now}
                  token={user.token}
                  handleLogOut={() => handleLogOut()}
                  handleHomeLogOut={() => handleHomeLogOut()}
                  user={user}
                  email={user.user.email}
                  role={user.user.role}
                  filterCompanies={(industries, sizes, countries, regions) =>
                    filterCompanies(industries, sizes, countries, regions)
                  }
                />
              }
            ></Route>
            <Route
              exact
              path={process.env.PUBLIC_URL + "/help"}
              element={
                <Help
                  openTopUpModal={(item) => openTopUpModal(item)}
                  topUpLoader={topUpLoader}
                  handleHomeLogOut={() => handleHomeLogOut()}
                  user={user}
                  currentPage={currentPage.pathname}
                  role={user.user.role}
                  token={user.token}
                  email={logEmail}
                  handleLogOut={() => handleLogOut()}
                  filterCompanies={(industries, sizes, countries, regions) =>
                    filterCompanies(industries, sizes, countries, regions)
                  }
                />
              }
            ></Route>
            <Route
              exact
              path={process.env.PUBLIC_URL + "/checkout"}
              element={
                <NewCheckout
                  user={user}
                  handleLogOut={() => handleLogOut()}
                  handleHomeLogOut={() => handleHomeLogOut()}
                  stage="company"
                />
              }
            ></Route>
            <Route
              exact
              path={process.env.PUBLIC_URL + "/tutorials"}
              element={
                <Tutorials
                  openTopUpModal={(item) => openTopUpModal(item)}
                  topUpLoader={topUpLoader}
                  handleHomeLogOut={() => handleHomeLogOut()}
                  user={user}
                  currentPage={currentPage.pathname}
                  role={user.user.role}
                  token={user.token}
                  email={logEmail}
                  handleLogOut={() => handleLogOut()}
                  filterCompanies={(industries, sizes, countries, regions) =>
                    filterCompanies(industries, sizes, countries, regions)
                  }
                />
              }
            ></Route>
            <Route
              exact
              path={process.env.PUBLIC_URL + "/payment"}
              element={
                <NewCheckout
                  user={user}
                  handleLogOut={() => handleLogOut()}
                  handleHomeLogOut={() => handleHomeLogOut()}
                  stage="payment"
                />
              }
            ></Route>
            {user &&
              (role === "admin" ||
                user.user.email === "beta@hudsonrock.com") && (
                <Route
                  exact
                  path={process.env.PUBLIC_URL + "/admin-panel"}
                  element={
                    <AdminPanel
                      user={user}
                      // handleLogOut={() => handleLogOut()}
                      handleHomeLogOut={() => handleHomeLogOut()}
                    />
                  }
                ></Route>
              )}
            <Route path="*" element={<NotFound pageLoading={pageLoading} />} />
          </>
        ) : (
          <>
            {/* <Route
              exact
              path={process.env.PUBLIC_URL + "/checkout"}
              element={
                <NewCheckout
                  user={user}
                  handleLogOut={() => handleLogOut()}
                  handleHomeLogOut={() => handleHomeLogOut()}
                  stage="company"
                />
              }
            ></Route>
            <Route
              exact
              path={process.env.PUBLIC_URL + "/payment"}
              element={
                <NewCheckout
                  user={user}
                  handleLogOut={() => handleLogOut()}
                  handleHomeLogOut={() => handleHomeLogOut()}
                  stage="payment"
                />
              }
            ></Route> */}
            {/* {currentPage.pathname != "/" && (
              <Route
                path={process.env.PUBLIC_URL + "*"}
                element={<Navigate to={currentPage.pathname} />}
              ></Route>
            )} */}
            <Route
              path={process.env.PUBLIC_URL + "/password-reset/:id/:token"}
              element={<ResetPassword />}
            ></Route>
            <Route
              path={process.env.PUBLIC_URL + "/activate-account/:id"}
              element={<Activation />}
            ></Route>
            <Route
              path={process.env.PUBLIC_URL + "/activate-account/:id/:token"}
              element={<Activation />}
            ></Route>
            <Route
              path={process.env.PUBLIC_URL + "/sign-up"}
              element={
                <SignUp
                  newPassword={newPassword}
                  firstName={firstName}
                  lastName={lastName}
                  signUpEmail={signUpEmail}
                  changeFname={changeFname}
                  changeLname={changeLname}
                  changeEmail={changeEmail}
                  signUpError={signUpError}
                  loginError={loginError}
                  signUp={signUp}
                  nextStage={nextStage}
                  stage={stage}
                  changePassword={changePassword}
                  validLength={validLength}
                  validUppercase={validUppercase}
                  validLowercase={validLowercase}
                  validNumber={validNumber}
                  validSign={validSign}
                  prevStage={() => setStage("email")}
                />
              }
            ></Route>
            <Route
              path={process.env.PUBLIC_URL + "/tos"}
              element={<Tos />}
            ></Route>
            {/* {user && (
              <Route
                path={process.env.PUBLIC_URL + "/checkout"}
                element={<NewCheckout />}
              ></Route>
            )} */}
            <Route
              path={process.env.PUBLIC_URL + "/"}
              element={
                <SignInSide
                  loggingOut={loggingOut}
                  login={login}
                  email={onChangeEmail}
                  password={onChangePassword}
                  error={loginError}
                />
              }
            ></Route>
            <Route
              path={process.env.PUBLIC_URL + "/tokenaccess"}
              element={
                <UrlSignIn
                  login={urlHashLogin}
                  user={user}
                  loading={loading}
                  // token={token}
                  logEmail={logEmail}
                  handleLogOut={() => handleLogOut()}
                  handleHomeLogOut={() => handleHomeLogOut()}
                  loginError={loginError}
                />
              }
            ></Route>
            <Route
              path={process.env.PUBLIC_URL + "*"}
              element={<Navigate to="/" />}
            ></Route>
          </>
        )}
      </Routes>
      {user && user.user.role !== "admin" && (
        <Modal
          user={user}
          sub={user.sub}
          shopper={shopper}
          open={upgradeOpen}
          handleClickOpen={openUpgradeModal}
          handleClose={closeUpgradeModal}
          confirm={true}
          type="upgrade"
          subExpiry={
            user.sub &&
            new Date(user.sub.next_billing_date)
              .toString()
              .split(" ")
              .slice(0, 6)
              .join(" ")
          }
          func={(cvv) => {
            shopper.topup ? purchaseTopUp(cvv) : updateSubscription(cvv);
          }}
          title={shopper.topup ? "Top-Up Purchase" : "Subscription Upgrade"}
        />
      )}
      {overlay === "upgrade" && (
        <Overlay
          title="Payment is being processed..."
          content="Please do not leave or refresh the page."
          successTitle="Your subscription has been successfully upgraded"
          successContent=""
          finish={finish}
        />
      )}
      {overlay === "signup" && (
        <Overlay
          title="Your account is being created..."
          content="Please do not leave or refresh the page."
          successTitle="Welcome To Bayonet!"
          successContent=""
          finish={finish}
        />
      )}
      <LoggedOutModal open={openModal} />
      {/* <Interceptors axios={axios} /> */}
    </Router>
  );
}

export default Main;
