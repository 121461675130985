import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import DialogContent from "@material-ui/core/DialogContent";
import AddProspect from "../Partials/AddProspect";
import CreateApiClient from "../../API";

const api = CreateApiClient();

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function FullScreenDialog(props) {
  const classes = useStyles();
  const [suggestions, setSuggestions] = useState([]);
  const [subject, setSubject] = useState("");
  const [results, setResults] = useState();
  const [loading, setLoading] = useState(false);
  const [allDomains, setAllDomains] = useState([]);
  const [domainsToAdd, setDomainsToAdd] = useState([]);
  const [appsToAdd, setAppsToAdd] = useState([]);
  const [partiesToAdd, setPartiesToAdd] = useState([]);
  const [domainInput, setDomainInput] = useState("");
  const [appInput, setAppInput] = useState("");
  const [partyInput, setPartyInput] = useState("");
  const [industry, setIndustry] = useState("");
  const [country, setCountry] = useState("");
  const [size, setSize] = useState("");
  const [logo, setLogo] = useState("");

  const [prospectDomain, setProspectDomain] = useState("");

  const onChange = (e) => {
    const response = api.fetchSuggestions(e.target.value.toLowerCase());
    response.then((result) => {
      setSuggestions(result);
    });
    setSubject(e.target.value.toLowerCase());
    // setResults([]);
    // setProspectDomain("");
    // setIndustry("");
    // setCountry("");
    // setSize("");
    // setAvatar(e.target.value.substr(0, 1).toUpperCase());
  };

  const onChangeDomain = (e, type) => {
    switch (type) {
      case "domain":
        setDomainInput(e.target.value);
        break;
      case "app":
        setAppInput(e.target.value);
        break;
      case "party":
        setPartyInput(e.target.value);
        break;
    }
    setAllDomains({ ...allDomains, [e.target.name]: e.target.value });
  };

  const addDomains = () => {
    allDomains.domainsToAdd = domainsToAdd.concat([
      ...new Set(allDomains.domainsToAdd.replace(/ /g, "").split(",")),
    ]);

    setDomainsToAdd(allDomains.domainsToAdd);
    setDomainInput("");
  };

  const addApps = () => {
    allDomains.appsToAdd = appsToAdd.concat([
      ...new Set(allDomains.appsToAdd.replace(/ /g, "").split(",")),
    ]);
    setAppsToAdd(allDomains.appsToAdd);
    setAppInput("");
  };

  const addParties = () => {
    allDomains.partiesToAdd = partiesToAdd.concat([
      ...new Set(allDomains.partiesToAdd.replace(/ /g, "").split(",")),
    ]);
    setPartiesToAdd(allDomains.partiesToAdd);
    setPartyInput("");
  };

  const pickDomain = (company) => {
    setResults([]);
    setLoading(true);
    setLogo(company.domain);
    setSubject(company.name);
    const response = api.getCompanies(company.domain);
    response.then((result) => {
      setResults(result);
      setSize(result.job_company_size);
      setProspectDomain(company.domain);
      setCountry(result.job_company_location_country);
      setIndustry(result.job_company_industry);
    });
    setSuggestions([]);
    setLoading(false);
  };

  const removeDomain = (e, type) => {
    switch (type) {
      case "domain":
        setDomainsToAdd(domainsToAdd.filter((s) => s !== e.target.value));
        break;
      case "app":
        setDomainsToAdd(appsToAdd.filter((s) => s !== e.target.value));
        break;
      case "party":
        setDomainsToAdd(partiesToAdd.filter((s) => s !== e.target.value));
        break;
    }
  };

  return (
    <div className="fullScreenDialog">
      <Dialog
        className="addProspectDialog"
        // fullScreen
        open={props.open}
        onClose={props.handleClose}
        TransitionComponent={Transition}
      >
        <AppBar
          className={classes.appBar}
          style={{
            backgroundColor: "#fecd4e",
            padding: "15px",
          }}
        >
          <Toolbar className="addProspectHeader">
            <IconButton
              edge="start"
              onClick={props.handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <h2
              variant="h6"
              className={classes.title}
              style={{ color: "black" }}
            >
              Add Prospect
            </h2>
          </Toolbar>
        </AppBar>
        <DialogContent style={{ padding: "0px" }}>
          <AddProspect
            pickDomain={pickDomain}
            results={results}
            loading={loading}
            suggestions={suggestions}
            subject={subject}
            onChange={onChange}
            onChangeDomain={onChangeDomain}
            addDomains={addDomains}
            addParties={addParties}
            addApps={addApps}
            allDomains={allDomains}
            domainsToAdd={domainsToAdd}
            appsToAdd={appsToAdd}
            partiesToAdd={partiesToAdd}
            domainInput={domainInput}
            appInput={appInput}
            partyInput={partyInput}
            removeDomain={(e, type) => removeDomain(e, type)}
            user={props.user}
            saveProspect={(company, domains, apps, parties) =>
              props.saveProspect(company, domains, apps, parties)
            }
            setIndustry={setIndustry}
            setCountry={setCountry}
            setSize={setSize}
            setProspectDomain={setProspectDomain}
            prospectDomain={prospectDomain}
            size={size}
            country={country}
            industry={industry}
            logo={logo}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
}
