import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import BarChartIcon from "@material-ui/icons/BarChart";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import FormControl from "@material-ui/core/FormControl";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import {
  FaUserTie,
  FaUsers,
  FaSitemap,
  FaExternalLinkAlt,
} from "react-icons/fa";
import { DiAndroid } from "react-icons/di";
import { AiFillDatabase } from "react-icons/ai";
import { ImCheckmark, ImCross } from "react-icons/im";
import { MdRefresh } from "react-icons/md";
import CountUp from "react-countup";
import EmployeeTabs from "../Data/Tabs";
import Container from "../Partials/ProgressBar";
import CustomPaginationActionsTable from "../Data/Table";
import DbTabs from "../Data/DbTabs";
import LineChart from "../Statistics/LineChart";
import BarChart from "../Statistics/BarChart";
import LoaderIcon from "react-loader-icon";
import Pins from "../Statistics/MarkersMap";
import ExportToCsv from "../Partials/ExportToCsv";
import CityInfo from "../Statistics/CityInfo";
import MapGL, {
  Popup,
  NavigationControl,
  FullscreenControl,
  ScaleControl,
  GeolocateControl,
} from "react-map-gl";
import { MapApi, APIFullAddress } from "../../Config";
import "mapbox-gl/dist/mapbox-gl.css";
import mapboxgl from "mapbox-gl";
import { ImShare2 } from "react-icons/im";
import ThirdPartyTabs from "../Data/ThirdPartyTabs";
import { BsUnlockFill } from "react-icons/bs";
import UpgradeTier from "../Partials/UpgradeTier";
import UnlockSelected from "../Partials/UnlockSelected";
import AddProspectBtn from "../Partials/AddProspectBtn";
import { CheckUserSession } from "../../hooks/CheckUserSession";
import cavaLogo from "../../images/thumbnail.jpeg";
import SearchBy from "../Search/SearchBy";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { text } from "../../fonts/Roboto/base64bold.js";
import androidImg from "../../icons/android.png";
import chartImg from "../../icons/chart-column-solid.png";
import dbImg from "../../icons/database-solid.png";
import userImg from "../../icons/user-tie-solid.png";
import usersImg from "../../icons/users-solid.png";
import darkBg from "../../images/dark-bg.png";
import { GrDocumentPdf } from "react-icons/gr";
import pdfLogo from "../../images/pdf_logo.png";
import crop from "../../icons/crop.png";
import { VscGistSecret } from "react-icons/vsc";
import AssetsDiscovery from "./AssetsDiscovery";
import TimeFilters from "../Partials/TimeFilters";
import Autocomplete from "../Partials/Autocomplete";
import axios from "axios";
import { MultiSelect } from "react-multi-select-component";

/* eslint import/no-webpack-loader-syntax: off */
mapboxgl.workerClass =
  require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Box>{children}</Box>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-force-tab-${index}`,
    "aria-controls": `scrollable-force-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: "#1A1C20",
  },
}));

export default function ScrollableTabsButtonForce(props) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [type, setType] = useState("");
  const [page, setPage] = useState(0);
  const [prospectNames, setProspectNames] = useState([]);
  const [loader, setLoader] = useState(false);
  const basicPlans = [2741315, 2741325, 3332681, 3332639];
  const [sortType, setSortType] = useState("date");
  const [pdfLoader, setPdfLoader] = useState(false);
  const [timeSelected, setTimeSelected] = useState([]);
  const [keyword, setKeyword] = useState("");
  const [keywordInput, setKeywordInput] = useState("");
  const [keywordSuggestions, setKeywordSuggestions] = useState(null);
  const [keywordLoading, setKeywordLoading] = useState(false);
  const [cancelToken, setCancelToken] = useState(null);
  const [keywordType, setKeywordType] = useState("");
  // const [isSticky, setIsSticky] = useState(false);

  // useEffect(() => {
  //   const handleScroll = () => {
  //     const divElement = document.getElementById('your-div-id');
  //     const divTop = divElement.getBoundingClientRect().top;

  //     setIsSticky(divTop <= 0);
  //   };

  //   window.addEventListener('scroll', handleScroll);

  //   return () => {
  //     window.removeEventListener('scroll', handleScroll);
  //   };
  // }, []);

  const timeOptions = [
    { value: "week", label: "Last 7 Days" },
    { value: "month", label: "Last 30 Days" },
    { value: "quarter", label: "Last 3 Months" },
    { value: "year", label: "Last 12 Months" },
    { value: "all", label: "All Time" },
  ];

  const externalOptions = props.externalSuggestions.map((s) => {
    return {
      label: s.domain,
      value: s.domain,
    };
  });

  const DefaultItemRenderer = ({ checked, option, onClick, disabled }) => (
    <div className={`item-renderer ${disabled && "disabled"}`}>
      <>
        <input
          type="checkbox"
          onChange={onClick}
          checked={checked}
          tabIndex={-1}
          disabled={disabled}
        />
        <span>{option.label}</span>
      </>
    </div>
  );

  const changeSortType = (type, subject, android, thirdParty) => {
    setSortType(type.value);

    props.getData(
      [],
      `${subject}?sortby=${
        type.value
      }&android=${android}&thirdparties=${thirdParty}&time=${
        timeSelected[0] ? timeSelected[0].value : "all"
      }&keyword=${keywordInput}`,
      0,
      true,
      true
    );
  };

  const options = [
    { value: "date", label: "Date Compromised" },
    { value: "date_added", label: "Date Added" },
  ];

  const defaultSrc = (ev) => {
    ev.target.src = cavaLogo;
  };

  useEffect(() => {
    setPage(0);
    setSortType("date");
    setKeywordInput("");
    setKeyword("");
    setTimeSelected([]);
    setKeywordSuggestions([]);
  }, [value]);

  useEffect(() => {
    if (CheckUserSession(props.user)) {
      let names = [];
      props.prospects &&
        props.prospects.map((item) => {
          names.push(item.name);
        });
      setProspectNames(names);
    } else {
      props.handleLogOut();
    }
  }, [props.prospects]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleMapChange = async (e) => {
    setType(e.target.value);
    await props.getMapData(`stats/ips?type=${e.target.value}`, props.user);
    popupInfo && setPopupInfo(null);
  };

  useEffect(() => {
    setValue(0);
  }, [props.domain]);

  useEffect(() => {
    if (props.employeesCount > 0) {
      setType("employees");
    } else {
      setType("users");
    }
  }, [props.employeesCount]);

  const loadMore = (type) => {
    setLoader(true);
    setPage(page + 1);
    props.getData(props.data, type, page + 1, false);
  };

  const filterByKeyword = (type, subject, thirdParty, android) => {
    setPage(0);
    setSortType(type);
    props.getData(
      [],
      `${subject}?android=${android}&thirdparties=${thirdParty}&sortby=${
        type.value
      }&time=${
        timeSelected[0] ? timeSelected[0].value : "all"
      }&keyword=${keywordInput}`,
      0,
      true,
      true
    );
  };

  const filterByTime = (e, type, subject, android, thirdParty) => {
    setPage(0);
    if (timeSelected.length > 0) {
      setTimeSelected([]);
      e.shift();
      setTimeSelected(e);
    } else {
      setTimeSelected(e);
    }
    props.getData(
      [],
      `${subject}?thirdparties=${thirdParty}&sortby=${type}&android=${android}&time=${
        e[0] ? e[0].value : "all"
      }&keyword=${keywordInput}`,
      0,
      true,
      true
    );
  };

  const counters = [
    {
      label: (
        <div className="counter tabsCounter">
          <CountUp
            separator=","
            start={0}
            end={props.stealersCount}
            duration={2.75}
          />
          Total <br /> Stealers
        </div>
      ),
      icon: <BarChartIcon className="materialIcons" id="firstIcon" />,
      count: props.stealersCount,
      path: "overview",
      class: "overviewTab",
      func: () => {
        setPage(0);
        {
          props.year != "All Time"
            ? props.getStats(
                `stats?frame=monthly&year=${props.year}`,
                props.user
              )
            : props.getStats(
                `stats?frame=all&currentyear=${props.currentYear}`,
                props.user
              );
        }
        props.getUrls("stats/urls", props.user);
      },
    },
    {
      label: (
        <div className="counter tabsCounter">
          <CountUp
            separator=","
            start={0}
            end={props.employeesCount}
            duration={2.75}
          />
          Compromised <br /> Employees
        </div>
      ),
      icon: <FaUserTie size={30} className="counterIcon" />,
      count: props.employeesCount,
      class: "employeeHorizontalTab",
      func: () => {
        setSortType("date");
        setPage(0);
        props.getData([], "employees?sortby=date", 0, false);
      },
    },
    {
      label: (
        <div className="counter tabsCounter">
          <CountUp
            separator=","
            start={0}
            end={props.partiesCount}
            duration={2.75}
          />
          Third Party <br /> Credentials
        </div>
      ),
      icon: <FaSitemap size={30} className="counterIcon" />,
      count: props.partiesCount,
      class: "employeeHorizontalTab",
      func: () => {
        // setTimeSelected([]);
        setSortType("date");
        setPage(0);
        props.getData([], "employees?thirdparties=true&sortby=date", 0, false);
      },
    },
    {
      label: (
        <div className="counter tabsCounter">
          <CountUp
            separator=","
            start={0}
            end={props.clientsCount}
            duration={2.75}
          />
          Compromised <br /> Users
        </div>
      ),
      icon: <FaUsers size={30} className="counterIcon" />,
      count: props.clientsCount,
      path: "clients",
      class: "clientsTab",
      func: () => {
        setSortType("date");
        setPage(0);
        props.getData([], "users?android=false&sortby=date", 0, false);
      },
    },
    {
      label: (
        <div className="counter tabsCounter">
          {typeof props.androidsCount != "boolean" ? (
            <CountUp
              separator=","
              start={0}
              end={props.androidsCount}
              duration={2.75}
            />
          ) : (
            <div>
              {props.androidsCount === true ? (
                <ImCheckmark className="iconIndicator" size={25} />
              ) : (
                <ImCross className="iconIndicator" size={25} />
              )}
            </div>
          )}
          Compromised <br /> App Users
        </div>
      ),
      icon: <DiAndroid size={30} className="counterIcon" />,
      count: props.androidsCount,
      // disabled: props.tier === "basic" ? true : false,
      path: "androids",
      class: "appsTab",
      func: () => {
        setSortType("date");
        setPage(0);
        props.getData([], "users?android=true&sortby=date", 0, false);
      },
    },
    // {
    //   label: (
    //     <div className="counter tabsCounter">
    //       <CountUp
    //         separator=","
    //         start={0}
    //         end={props.dbsCount}
    //         duration={2.75}
    //       />
    //       Compromised <br /> DB Accounts
    //     </div>
    //   ),
    //   icon: <AiFillDatabase size={30} className="counterIcon" />,
    //   count: props.dbsCount,
    //   // disabled: props.tier === "basic" ? true : false,
    //   path: "databases",
    //   class: "dbsTab",
    //   func: () => {
    //     setPage(0);
    //     props.getData([], "dbs/domainsearch", 0, false);
    //   },
    // },
    // {
    //   label: (
    //     <div className="counter tabsCounter">
    //       <CountUp
    //         separator=","
    //         start={0}
    //         end={props.monitorCount}
    //         duration={2.75}
    //       />
    //       3rd Party <br /> Risk Assessment
    //     </div>
    //   ),
    //   icon: <ImShare2 size={30} className="counterIcon" />,
    //   count: props.monitorCount,
    //   path: "thirdparty",
    //   class: "monitorTab",
    //   func: () => {
    //     setPage(0);
    //     props.getMonitoredDomains();
    //   },
    // },
    {
      label: (
        <div className="counter tabsCounter">
          <CountUp
            separator=","
            start={0}
            end={props.externalCount}
            duration={2.75}
            suffix="+"
          />
          External <br /> Domains
        </div>
      ),
      icon: <FaExternalLinkAlt size={30} className="counterIcon" />,
      count: props.externalCount,
      path: "externals",
      class: props.externalCount > 0 ? "monitorTab" : "hiddenTab",
      func: () => {
        setPage(0);
        setTimeSelected([]);
        let _type;
        if (props.path === "search-by-prospect") {
          _type = "prospect";
        } else {
          _type = "domain";
        }
        props.getData([], `external-domains/${_type}?sortby=date`, 0, false);
      },
    },
    {
      label: (
        <div className="counter tabsCounter">
          <CountUp
            separator=","
            start={0}
            end={props.assetsCount}
            duration={2.75}
          />
          External <br /> Attack Surface
        </div>
      ),
      icon: <VscGistSecret size={30} className="counterIcon" />,
      count: props.assetsCount,
      path: "assets",
      class: "monitorTab",
      func: () => {
        setPage(0);
        props.getAssetDiscovery();
      },
    },
  ];
  const geolocateStyle = {
    top: 0,
    left: 0,
    padding: "10px",
  };

  const fullscreenControlStyle = {
    top: 36,
    left: 0,
    padding: "10px",
  };

  const navStyle = {
    top: 72,
    left: 0,
    padding: "10px",
  };

  const scaleControlStyle = {
    bottom: 36,
    left: 0,
    padding: "10px",
  };

  const [viewport, setViewport] = useState({
    latitude: 20,
    longitude: 0,
    zoom: 1.5,
    bearing: 0,
    pitch: 0,
  });
  const [popupInfo, setPopupInfo] = useState(null);

  const [settings, setSettings] = useState({
    scrollZoom: false,
    dragRotate: false,
    // dragPan: false,
  });

  const closeOnMapClick = () => {
    popupInfo && setPopupInfo(null);
  };

  const div2PDF = async (name, domain) => {
    /////////////////////////////
    // Hide/show button if you need
    /////////////////////////////
    setPdfLoader(true);
    // const but = e.target;
    // but.style.display = "none";
    let input = window.document.getElementsByClassName("div2PDF")[0];

    const options = {
      backgroundColor: "#1A1C20",
    };

    const countersOptions = {
      backgroundColor: "#FBCD4E",
    };
    // const logoImg = window.document.getElementById("logoImg");
    // const logoCanvas = await html2canvas(logoImg).then((canvas) =>
    //   canvas.toDataURL("image/png")
    // );

    const counters =
      window.document.getElementsByClassName("navigationTabs")[0];
    const countersCanvas = await html2canvas(counters, countersOptions).then(
      (canvas) => canvas.toDataURL("image/png")
    );
    const linechart = window.document.getElementsByClassName("lineChart")[0];
    const chartCanvas = await html2canvas(linechart, options).then((canvas) =>
      canvas.toDataURL("image/png")
    );

    const empdonut = window.document.getElementsByClassName("leftCol")[0];
    const empdonutCanvas = await html2canvas(empdonut, options).then((canvas) =>
      canvas.toDataURL("image/png")
    );
    const usersdonut = window.document.getElementsByClassName("rightCol")[0];
    const usersdonutCanvas = await html2canvas(usersdonut, options).then(
      (canvas) => canvas.toDataURL("image/png")
    );
    const map = window.document.getElementsByClassName("mapboxgl-canvas")[0];
    const pins = window.document.getElementsByClassName("mapboxgl-marker");

    const logoImg = window.document.getElementById("logoImg");
    const logoCanvas = await html2canvas(logoImg, options).then((canvas) =>
      canvas.toDataURL("image/png")
    );

    const pdf = new jsPDF("p", "mm", "a4");

    pdf.setFillColor(210, 210, 210);
    pdf.rect(0, 0, 500, 50, "F");

    // Register font
    pdf.addFileToVFS("Roboto-Black.ttf", text);
    pdf.addFont("Roboto-Black.ttf", "Roboto", "Bold");
    pdf.setFont("Roboto", "Bold");
    pdf.setFontSize(14);

    // pdf.addImage(pdfLogo, "png", 5, 5, 51, 15, "logo", "SLOW");
    // pdf.addImage(
    //   `https://logo.clearbit.com/${domain}`,
    //   "png",
    //   5,
    //   5,
    //   20,
    //   20,
    //   "logo",
    //   "SLOW"
    // );

    pdf.addImage(
      logoImg,
      "png",
      5,
      5,
      logoImg.width / 2,
      logoImg.height / 2,
      "logo",
      "SLOW"
    );

    // Add icons and counters:
    pdf.addImage(chartImg, "png", 32.5, 25, 5, 5, "alias", "SLOW");
    pdf.text(35, 35, props.stealersCount.toString(), "center");
    pdf.text(35, 40, "Total \n Stealers", "center");

    pdf.addImage(userImg, "png", 72.5, 25, 5, 5, "alias1", "SLOW");
    pdf.text(75, 35, props.employeesCount.toString(), "center");
    pdf.text(75, 40, "Compromised \n Employees", "center");

    pdf.addImage(usersImg, "png", 112.5, 25, 5, 5, "alias2", "SLOW");
    pdf.text(115, 35, props.clientsCount.toString(), "center");
    pdf.text(115, 40, "Compromised \n Users", "center");

    pdf.addImage(androidImg, "png", 152.5, 25, 5, 5, "alias3", "SLOW");
    pdf.text(155, 35, props.androidsCount.toString(), "center");
    pdf.text(155, 40, "Compromised \n APKs", "center");

    // pdf.addImage(dbImg, "png", 182.5, 25, 5, 5, "alias4", "SLOW");
    // pdf.text(185, 35, props.dbsCount.toString(), "center");
    // pdf.text(185, 40, "Compromised \n DB Accounts", "center");

    pdf.setFillColor(26, 28, 32);
    pdf.rect(0, 50, 500, 500, "F");

    pdf.addImage(chartCanvas, "png", 5, 50, 200, 80, "alias5", "SLOW");
    // pdf.setFillColor(210, 210, 210);
    pdf.setFillColor(26, 28, 32);
    pdf.rect(0, 48, 500, 8, "F");

    if (props.employeesUrls.length > 0 && props.clientsUrls.length > 0) {
      pdf.addImage(empdonutCanvas, "png", 25, 130, 160, 80, "alias6", "SLOW");
      pdf.addImage(usersdonutCanvas, "png", 25, 212, 160, 80, "alias7", "SLOW");
    } else if (props.clientsUrls.length > 0) {
      pdf.addImage(
        usersdonutCanvas,
        "png",
        0,
        130,
        210.5,
        103,
        "alias7",
        "SLOW"
      );
    } else if (props.employeesUrls.length > 0) {
      pdf.addImage(empdonutCanvas, "png", 0, 130, 210.5, 103, "alias6", "SLOW");
    }

    pdf.save(`${domain}.pdf`);
    setPdfLoader(false);
  };

  useEffect(() => {
    const logoImg = window.document.getElementById("logoImg");
  }, []);

  const onChangeSystem = async (e, type) => {
    const q = e.target.value;
    if (q.length > 0) {
      setKeyword(q);
    } else {
      setKeyword("*");
    }
    setKeywordInput(q);
    setKeywordType(type);
  };

  useEffect(() => {
    if (keyword) {
      if (cancelToken) {
        cancelToken.cancel("Canceled");
      }
      const source = axios.CancelToken.source();
      setCancelToken(source);
      setKeywordLoading(true);
      let domains;
      if (props.path === "search-by-domain") {
        domains = props.domain;
      } else {
        domains = props.currentProspect.domains.join(",");
      }
      const keywordTabs = [1, 3, 4, 5];
      if (keywordTabs.includes(value)) {
        const externals = props.selectedExternals.map((s) => s.value).join(",");
        axios
          .get(
            `${APIFullAddress}/autocomplete/keywords?query=${keyword}&domains=${domains}&type=${keywordType}&externals=${externals}`,
            {
              cancelToken: source.token,
              headers: {
                "auth-token": props.user.token,
              },
            }
          )
          .then((res) => {
            setKeywordLoading(false);
            setKeywordSuggestions(res.data);
          })
          .catch((err) => {
            // setKeywordLoading(false);
            console.log(err);
          });
      } else if (value === 2) {
        axios
          .get(
            `${APIFullAddress}/autocomplete/third-party-domains?query=${keyword}&domains=${domains}&type=employee`,
            {
              cancelToken: source.token,
              headers: {
                "auth-token": props.user.token,
              },
            }
          )
          .then((res) => {
            setKeywordLoading(false);
            setKeywordSuggestions(res.data);
          })
          .catch((err) => {
            // setKeywordLoading(false);
            console.log(err);
          });
      }
    } else {
      setKeywordSuggestions(null);
      setKeyword("");
      setKeywordInput("");
      setKeywordLoading(false);
    }
    return () => {
      if (cancelToken) {
        cancelToken.cancel("Canceled");
      }
    };
  }, [keyword]);

  useEffect(() => {
    setPage(0);
    if (
      props.selectedExternals &&
      props.selectedExternals.length > 0 &&
      props.section.includes("external-domains")
    ) {
      let _type;
      if (props.path === "search-by-prospect") {
        _type = "prospect";
      } else {
        _type = "domain";
      }
      props.getData(
        [],
        `external-domains/${_type}?sortby=${sortType}`,
        0,
        false
      );
    }
  }, [props.selectedExternals]);

  return (
    <div className={classes.root + " div2PDF"}>
      <AppBar position="static" className="navigationTabs">
        <Tabs
          id="horizontalTabs"
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="on"
          indicatorColor="primary"
          textColor="primary"
          aria-label="scrollable force tabs"
        >
          {!props.loading ? (
            counters.map((counter, index) =>
              counter.count > 0 ? (
                <Tab
                  className={counter.class}
                  key={index}
                  onClick={counter.func}
                  label={counter.label}
                  icon={counter.icon}
                  {...a11yProps(index)}
                />
              ) : (
                <Tab
                  disabled
                  className={counter.class}
                  key={index}
                  label={counter.label}
                  icon={counter.icon}
                  {...a11yProps(index)}
                />
              )
            )
          ) : (
            <LoaderIcon className="homeLoader" type={"spinningBubbles"} />
          )}
        </Tabs>
      </AppBar>
      <hr id="hthr"></hr>
      {props.path === "search-by-domain" &&
        !props.loading &&
        props.currentCompany &&
        !prospectNames.includes(props.currentCompany.name.toLowerCase()) && (
          <div
            id={"slide"}
            className="inline-flex cursor shadow-box"
            onClick={() =>
              props.addProspect(
                props.currentCompany.name,
                props.domain,
                props.androidsCount
              )
            }
            style={{ padding: props.addProspectLoader && "10px" }}
          >
            {props.addProspectLoader && !props.prospectAdded ? (
              <>
                <LoaderIcon
                  className="tableLoader"
                  type={"spin"}
                  style={{ width: "30px", height: "auto" }}
                />
                <p className="lightText" style={{ margin: "0px 5px" }}>
                  Adding {props.currentCompany.name}...
                </p>
              </>
            ) : !props.addProspectLoader && !props.prospectAdded ? (
              <>
                <img
                  style={{ width: "50px", padding: "5px" }}
                  className="roundSmallImg borderBlack"
                  src={`https://logo.clearbit.com/${props.domain}`}
                  onError={defaultSrc}
                />
                <p className="lightText" style={{ margin: "0px 5px" }}>
                  Add {props.currentCompany.name} as a Prospect
                </p>
              </>
            ) : (
              props.prospectAdded && (
                <>
                  <ImCheckmark
                    className="iconIndicator"
                    size={20}
                    style={{ marginRight: "10px" }}
                  />
                  <p className="lightText" style={{ margin: "0px" }}>
                    {props.currentCompany.name} has been added to your Prospects
                  </p>
                </>
              )
            )}
          </div>
        )}

      <TabPanel value={value} index={0} className="desktop">
        {/* {!props.loading && (
          <button
            onClick={() => div2PDF(props.currentCompany.name, props.domain)}
            className="cardBtn"
            style={{
              zIndex: "999",
              position: "absolute",
              top: "10.5rem",
              left: "-20px",
              borderRadius: "0px 50px 50px 0px",
              display: "flex",
              alignItems: "center",
            }}
          >
            Generate Report
            {pdfLoader ? (
              <LoaderIcon
                className="removeLoader tableLoader"
                style={{
                  position: "relative",
                  top: "0px",
                  left: "5px",
                }}
                type={"spinningBubbles"}
              />
            ) : (
              <GrDocumentPdf size={15} className="smIcons" />
            )}
          </button>
        )} */}
        {!props.loading && props.stealersCount && props.stealersCount > 0 ? (
          <div className="main-screen">
            <LineChart
              title={
                props.currentProspect && props.path === "search-by-prospect"
                  ? props.currentProspect.name.toUpperCase()
                  : props.domain.toUpperCase()
              }
              chartLoader={props.chartLoader}
              labelColor="#fff"
              domains={props.domains}
              yearsOptions={props.yearsOptions}
              monthly={props.monthly}
              switchToAllTimeChart={props.switchToAllTimeChart}
              switchToMonthlyChart={props.switchToMonthlyChart}
              handleYearChange={props.handleYearChange}
              year={props.year}
              employeesStats={props.employeesStats}
              clientsStats={props.clientsStats}
              data={props.counts}
              labels={props.labels}
              loading={props.loading}
              stealersCount={props.stealersCount}
              currentCompany={props.currentCompany}
              screen="main"
            />
            <div className="donuts desktop">
              {props.employeesCount > 0 && (
                <div className="leftCol">
                  <BarChart
                    labelColor="#fff"
                    screen="main"
                    data={props.employeesUrls}
                    dataCount={props.employeesUrlsCount}
                    loading={props.pieLoading}
                    type="(Employees)"
                    height={20}
                    width={100}
                    subtitle={"DISCOVER MORE"}
                    discoverMore={() => {
                      props.getAssetDiscovery();
                      window.location.href = "#horizontalTabs";
                      setValue(6);
                    }}
                  />
                </div>
              )}
              <div
                className="rightCol"
                style={{
                  margin: "auto",
                  float: props.employeesCount > 0 ? "right" : "none",
                }}
              >
                <BarChart
                  height={20}
                  width={100}
                  labelColor="#fff"
                  screen="main"
                  data={props.clientsUrls}
                  dataCount={props.clientsUrlsCount}
                  loading={props.pieLoading}
                  type="(Users)"
                  subtitle={"DISCOVER MORE"}
                  discoverMore={() => {
                    props.getAssetDiscovery();
                    window.location.href = "#horizontalTabs";
                    setValue(6);
                  }}
                />
              </div>
            </div>

            <div className="map desktop">
              <br />
              <br />
              <h3 id="geoTitle" className="chartTitle">
                Geo’s of Compromised Computers{" "}
              </h3>
              {props.employeesCount > 0 && props.clientsCount > 0 && (
                <FormControl component="fieldset" className="inputs">
                  <RadioGroup
                    onChange={handleMapChange}
                    row
                    aria-label="position"
                    name="position"
                    value={type ? type : "employees"}
                  >
                    <FormControlLabel
                      value="employees"
                      control={<Radio color="primary" />}
                      label={
                        <div className="typeIcon">
                          <FaUserTie size={22} className="" />
                          Employees
                        </div>
                      }
                      labelPlacement="top"
                    />
                    <FormControlLabel
                      value="users"
                      control={<Radio color="primary" />}
                      label={
                        <div className="typeIcon">
                          <FaUsers size={25} className="" />
                          Users
                        </div>
                      }
                      labelPlacement="top"
                    />
                  </RadioGroup>
                </FormControl>
              )}
              <hr></hr>
              {props.mapLoading ? (
                <LoaderIcon
                  className="tabsLoader mapLoader"
                  type={"spinningBubbles"}
                />
              ) : (
                <MapGL
                  {...viewport}
                  {...settings}
                  width="100%"
                  height="500px"
                  mapStyle="mapbox://styles/mapbox/dark-v9"
                  onViewportChange={setViewport}
                  mapboxApiAccessToken={MapApi}
                  onClick={closeOnMapClick}
                  style={{
                    borderRadius: "20px",
                    boxShadow:
                      "0 5px 5px 0 rgba(0, 0, 0, 0.2), 0 5px 5px 0 rgba(0, 0, 0, 0.19)",
                  }}
                >
                  <Pins
                    data={props.cities}
                    onClick={setPopupInfo}
                    clientsCities={props.clientsCities}
                    employeesCities={props.employeesCities}
                    type={type}
                  />
                  {popupInfo && (
                    <Popup
                      tipSize={5}
                      anchor="top"
                      longitude={popupInfo.longitude}
                      latitude={popupInfo.latitude}
                      city={popupInfo.city}
                      country={popupInfo.country}
                      region={popupInfo.region}
                      timezone={popupInfo.timezone}
                      isp={popupInfo.isp}
                      proxy={popupInfo.proxy}
                      closeOnClick={false}
                      onClose={setPopupInfo}
                    >
                      <CityInfo info={popupInfo} type={type} />
                    </Popup>
                  )}

                  <GeolocateControl style={geolocateStyle} />
                  <FullscreenControl style={fullscreenControlStyle} />
                  <NavigationControl style={navStyle} />
                  <ScaleControl style={scaleControlStyle} />
                </MapGL>
              )}
            </div>
          </div>
        ) : props.loading ? (
          <Container paddingTop={"4rem"} classes={"mainNewLoader"} />
        ) : (
          <></>
        )}
      </TabPanel>
      <TabPanel value={value} index={1}>
        {!props.dataLoading ? (
          <div className="tabScreen">
            <div
              style={{
                backgroundColor: "#E0E8EB",
                borderRadius: "10px",
                padding: "1rem",
                marginBottom: "2rem",
                width: "100%",
                position: "relative",
                top: "-20px",
                textAlign: "center",
              }}
            >
              <div
                style={{
                  display: "inline-flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "90%",
                }}
              >
                <MdRefresh
                  className="cursor"
                  size={35}
                  style={{
                    fill: "#222831",
                    position: "absolute",
                    top: "20px",
                    left: "20px",
                  }}
                  onClick={() => {
                    setKeywordInput("");
                    setPage(0);
                    setTimeSelected([]);
                    props.getData(
                      [],
                      `employees?sortby=${sortType}&time=all`,
                      0,
                      null,
                      true
                    );
                  }}
                />
                <div
                  className="screen-tools filtersBar"
                  style={{
                    display: "inline-flex",
                    position: "relative",
                    width: "90%",
                  }}
                >
                  <TimeFilters
                    timeOptions={timeOptions}
                    timeSelected={timeSelected}
                    onChange={(e) =>
                      filterByTime(e, sortType, "employees", false, false)
                    }
                  />
                  <SearchBy
                    searchType={sortType}
                    changeSearchType={(sortType) =>
                      changeSortType(sortType, "employees", false)
                    }
                    options={options}
                    placeholder="Sort By"
                  />
                  <Autocomplete
                    empLength={props.empLength}
                    setKeywordInput={setKeywordInput}
                    loading={keywordLoading}
                    placeholder="Filter by Keyword"
                    onChange={(e) => {
                      onChangeSystem(e, "employee");
                    }}
                    onFocus={(e) => {
                      if (keywordInput === "") {
                        onChangeSystem(e, "employee");
                      }
                    }}
                    value={keywordInput}
                    suggestions={keywordSuggestions}
                    fields={["url_part", "occurrence"]}
                    filterKeyword={() => {
                      filterByKeyword(sortType, "employees", false);
                    }}
                  />
                </div>
              </div>
            </div>

            {!props.inPageLoading && props.data && props.data.length > 0 ? (
              <EmployeeTabs
                hasNextPage={props.hasNextPage}
                data={props.data}
                userRole={props.role}
                selectedDomain={props.domain}
                clientsCount={props.clientsCount}
                machines={props.machines}
                reRender={() =>
                  props.getData(props.data, "employees?sortby=date", page, true)
                }
                loadMore={() =>
                  loadMore(
                    `employees?sortby=${sortType}&time=${
                      timeSelected[0] ? timeSelected[0].value : "all"
                    }&keyword=${keywordInput}`
                  )
                }
                loader={props.loader}
                employeesCount={props.employeesCount}
                finalRes={props.finalRes}
                config={props.config}
                unlockedStealers={props.unlockedStealers}
                overlay={props.overlay}
                page={page}
                getCounters={props.getCounters}
                refreshUser={props.refreshUser}
                user={props.user}
                path={props.path}
                currentProspect={props.currentProspect}
                setOpen={(action) => props.setOpen(action)}
                setMessage={(message) => props.setMessage(message)}
              />
            ) : props.inPageLoading ? (
              <Container />
            ) : (
              <div style={{ height: "100vh", textAlign: "center" }}>
                <h1 className="capitalize">No Results</h1>
              </div>
            )}
          </div>
        ) : props.dataLoading ? (
          <Container />
        ) : (
          <h1>No Results</h1>
        )}
      </TabPanel>
      <TabPanel value={value} index={2}>
        {!props.dataLoading ? (
          <div className="tabScreen">
            <div
              style={{
                backgroundColor: "#E0E8EB",
                borderRadius: "10px",
                padding: "1rem",
                marginBottom: "2rem",
                width: "100%",
                position: "relative",
                top: "-20px",
                textAlign: "center",
              }}
            >
              <div
                style={{
                  display: "inline-flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "90%",
                }}
              >
                <MdRefresh
                  className="cursor"
                  size={35}
                  style={{
                    fill: "#222831",
                    position: "absolute",
                    top: "20px",
                    left: "20px",
                  }}
                  onClick={() => {
                    setKeywordInput("");
                    setPage(0);
                    setTimeSelected([]);
                    props.getData(
                      [],
                      `employees?sortby=${sortType}&thirdparties=true&time=all`,
                      0,
                      null,
                      true
                    );
                  }}
                />
                <div
                  className="screen-tools filtersBar"
                  style={{
                    display: "inline-flex",
                    position: "relative",
                    width: "90%",
                  }}
                >
                  <TimeFilters
                    timeOptions={timeOptions}
                    timeSelected={timeSelected}
                    onChange={(e) =>
                      filterByTime(e, sortType, "employees", false, true)
                    }
                  />
                  <SearchBy
                    searchType={sortType}
                    changeSearchType={(sortType) =>
                      changeSortType(sortType, "employees", false, true)
                    }
                    options={options}
                    placeholder="Sort By"
                  />
                  <Autocomplete
                    setKeywordInput={setKeywordInput}
                    loading={keywordLoading}
                    placeholder="Filter by Third Party Domain"
                    onChange={(e) => {
                      onChangeSystem(e, "employees");
                    }}
                    onFocus={(e) => {
                      if (keywordInput === "") {
                        onChangeSystem(e, "employees");
                      }
                    }}
                    value={keywordInput}
                    suggestions={keywordSuggestions}
                    fields={["url_part", "occurrence"]}
                    filterKeyword={() => {
                      filterByKeyword(sortType, "employees", true);
                    }}
                  />
                </div>
              </div>
            </div>
            {!props.inPageLoading && props.data && props.data.length > 0 ? (
              <EmployeeTabs
                hasNextPage={props.hasNextPage}
                isThirdParty={true}
                reRender={() =>
                  props.getData(
                    props.data,
                    "employees?thirdparties=true&sortby=date",
                    page,
                    true
                  )
                }
                loadMore={() =>
                  loadMore(
                    `employees?thirdparties=true&sortby=${sortType}&time=${
                      timeSelected[0] ? timeSelected[0].value : "all"
                    }&keyword=${keywordInput}`
                  )
                }
                data={props.data}
                userRole={props.role}
                selectedDomain={props.domain}
                clientsCount={props.clientsCount}
                machines={props.machines}
                loader={props.loader}
                employeesCount={props.employeesCount}
                finalRes={props.finalRes}
                config={props.config}
                unlockedStealers={props.unlockedStealers}
                overlay={props.overlay}
                page={page}
                getCounters={props.getCounters}
                refreshUser={props.refreshUser}
                user={props.user}
                path={props.path}
                currentProspect={props.currentProspect}
                setOpen={(action) => props.setOpen(action)}
                setMessage={(message) => props.setMessage(message)}
              />
            ) : props.inPageLoading ? (
              <Container />
            ) : (
              <div style={{ height: "100vh", textAlign: "center" }}>
                <h1>No Results</h1>
              </div>
            )}
          </div>
        ) : props.dataLoading ? (
          <Container />
        ) : (
          <div style={{ height: "100vh", textAlign: "center" }}>
            <h1>No Results</h1>
          </div>
        )}
      </TabPanel>
      <TabPanel value={value} index={3}>
        {!props.dataLoading ? (
          <div className="tabScreen">
            <div
              style={{
                backgroundColor: "#E0E8EB",
                borderRadius: "20px",
                padding: "1rem 8rem",
                // marginBottom: "2rem",
                width: "100%",
                position: "relative",
                // left: "-25px",
                top: "-20px",
                margin: "auto",
              }}
            >
              <MdRefresh
                className="cursor"
                size={35}
                style={{
                  fill: "#222831",
                  position: "absolute",
                  top: "20px",
                  left: "20px",
                }}
                onClick={() => {
                  setKeywordInput("");
                  setPage(0);
                  setTimeSelected([]);
                  props.getData(
                    [],
                    `users?android=false&sortby=${sortType}&time=all`,
                    0,
                    null,
                    true
                  );
                }}
              />
              <div
                className="tableTools screen-tools filtersBar"
                style={{
                  display: "inline-flex",
                  position: "relative",
                  // top: "-0.5rem",
                  width: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <UnlockSelected
                  user={props.user}
                  selected={props.selected}
                  unlockStealer={() =>
                    props.unlockStealer("users?android=false", page)
                  }
                />
                <TimeFilters
                  timeOptions={timeOptions}
                  timeSelected={timeSelected}
                  onChange={(e) => filterByTime(e, sortType, "users", false)}
                />
                <SearchBy
                  searchType={sortType}
                  changeSearchType={(sortType) =>
                    changeSortType(sortType, "users", false)
                  }
                  options={options}
                  placeholder="Sort By"
                />
                <Autocomplete
                  setKeywordInput={setKeywordInput}
                  loading={keywordLoading}
                  placeholder="Filter by Keyword"
                  onChange={(e) => {
                    onChangeSystem(e, "client");
                  }}
                  onFocus={(e) => {
                    if (keywordInput === "") {
                      onChangeSystem(e, "client");
                    }
                  }}
                  value={keywordInput}
                  suggestions={keywordSuggestions}
                  fields={["url_part", "occurrence"]}
                  filterKeyword={() => {
                    filterByKeyword(sortType, "users", false, false);
                  }}
                />
              </div>
              {/* <ExportToCsv
                type="users"
                handleHomeLogOut={props.handleHomeLogOut}
                selectedDomain={props.domain}
                data={props.data}
                section={props.section}
                user={props.user}
              /> */}
            </div>
            {!props.inPageLoading && props.data && props.data.length > 0 ? (
              <CustomPaginationActionsTable
                credits={props.credits}
                currentProspect={props.currentProspect}
                hasNextPage={props.hasNextPage}
                data={props.data}
                userRole={props.role}
                selectedDomain={props.domain}
                config={props.config}
                clientsCount={props.clientsCount}
                machines={props.machines}
                finalRes={props.finalRes}
                loadMore={() =>
                  loadMore(
                    `users?android=false&sortby=${sortType}&time=${
                      timeSelected[0] ? timeSelected[0].value : "all"
                    }&keyword=${keywordInput}`
                  )
                }
                loader={props.loader}
                selectRow={(row) => props.selectRow(row)}
                selected={props.selected}
                handleSelectAllClick={(e) => props.handleSelectAllClick(e)}
                sortType={sortType}
                unlockStealer={() =>
                  props.unlockStealer("users?android=false", page)
                }
              />
            ) : props.inPageLoading ? (
              <Container />
            ) : (
              <div style={{ height: "100vh", textAlign: "center" }}>
                <h1>No Results</h1>
              </div>
            )}
          </div>
        ) : props.dataLoading ? (
          <Container />
        ) : (
          <div style={{ height: "100vh", textAlign: "center" }}>
            <h1>No Results</h1>
          </div>
        )}
      </TabPanel>
      <TabPanel value={value} index={4}>
        {!props.dataLoading ? (
          <div className="tabScreen">
            <div
              style={{
                backgroundColor: "#E0E8EB",
                borderRadius: "20px",
                padding: "1rem 8rem",
                // marginBottom: "2rem",
                width: "100%",
                position: "relative",
                // left: "-25px",
                top: "-20px",
                margin: "auto",
              }}
            >
              <MdRefresh
                className="cursor"
                size={35}
                style={{
                  fill: "#222831",
                  position: "absolute",
                  top: "20px",
                  left: "20px",
                }}
                onClick={() => {
                  setKeywordInput("");
                  setPage(0);
                  setTimeSelected([]);
                  props.getData(
                    [],
                    `users?android=true&sortby=${sortType}&time=all`,
                    0,
                    null,
                    true
                  );
                }}
              />
              <div
                className="tableTools screen-tools filtersBar"
                style={{
                  display: "inline-flex",
                  position: "relative",
                  // top: "-0.5rem",
                  width: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <TimeFilters
                  timeOptions={timeOptions}
                  timeSelected={timeSelected}
                  onChange={(e) => filterByTime(e, sortType, "users", true)}
                />
                <SearchBy
                  searchType={sortType}
                  changeSearchType={(sortType) =>
                    changeSortType(sortType, "users", true)
                  }
                  options={options}
                  placeholder="Sort By"
                />
                <UnlockSelected
                  user={props.user}
                  selected={props.selected}
                  unlockStealer={() =>
                    props.unlockStealer("users?android=true", page)
                  }
                />
              </div>
            </div>

            {!props.inPageLoading &&
            props.data &&
            props.data.length > 0 &&
            props.androidsCount > 0 ? (
              <CustomPaginationActionsTable
                credits={props.credits}
                currentProspect={props.currentProspect}
                hasNextPage={props.hasNextPage}
                data={props.data}
                userRole={props.role}
                selectedDomain={props.domain}
                config={props.config}
                clientsCount={props.clientsCount}
                machines={props.machines}
                finalRes={props.finalRes}
                // loadMore={() => loadMore(`users?android=true&sortby=${sortType}`)}
                loadMore={() =>
                  loadMore(
                    `users?android=true&sortby=${sortType}&time=${
                      timeSelected[0] ? timeSelected[0].value : "all"
                    }&keyword=${keywordInput}`
                  )
                }
                loader={props.loader}
                selectRow={(row) => props.selectRow(row)}
                selected={props.selected}
                handleSelectAllClick={(e) => props.handleSelectAllClick(e)}
                sortType={sortType}
                unlockStealer={() =>
                  props.unlockStealer("users?android=false", page)
                }
              />
            ) : props.inPageLoading ? (
              <Container />
            ) : (
              <div style={{ height: "100vh", textAlign: "center" }}>
                <h1>No Results</h1>
              </div>
            )}
          </div>
        ) : props.dataLoading ? (
          <Container />
        ) : props.user.user.role != "admin" &&
          props.user.sub &&
          basicPlans.includes(props.user.sub.plan_id) ? (
          <UpgradeTier
            openUpgradeModal={props.openUpgradeModal}
            upgradeLoader={props.upgradeLoader}
          />
        ) : (
          <AddProspectBtn type="APK's" />
        )}
      </TabPanel>
      {/* <TabPanel value={value} index={4}>
        {!props.dataLoading &&
        props.data &&
        props.data.length > 0 &&
        props.dbsCount > 0 ? (
          <div className="tabScreen">
            <DbTabs
              clientsCount={props.dbsCount}
              loading={props.loading}
              finalRes={props.finalRes}
              domain={props.domain}
              token={props.token}
              data={props.data}
              userRole={props.role}
              selectedDomain={props.domain}
              currentProspect={props.currentProspect}
              currentCompany={props.currentCompany}
              machines={props.machines}
              loadMore={() => loadMore("dbs/domainsearch")}
              loader={props.loader}
              employeesCount={props.employeesCount}
              config={props.config}
              getCounters={props.getCounters}
              setOpen={(action) => props.setOpen(action)}
              setMessage={(message) => props.setMessage(message)}
              refreshUser={props.refreshUser}
            />
          </div>
        ) : props.dataLoading ? (
          <Container />
        ) : props.user.user.role != "admin" &&
          basicPlans.includes(props.user.sub.plan_id) ? (
          <UpgradeTier
            openUpgradeModal={props.openUpgradeModal}
            upgradeLoader={props.upgradeLoader}
          />
        ) : (
          <>
            {props.dehashedMessage ? (
              <>
                <h1 className="darkText">{props.dehashedMessage}</h1>
              </>
            ) : (
              <>
                <h1>No Results</h1>
                <button
                  className="searchBtn"
                  style={{
                    border: "0px",
                    borderRadius: "5px",
                    padding: "5px 10px",
                  }}
                  onClick={() => props.fetchDehashed()}
                >
                  Fetch Data
                </button>
              </>
            )}
          </>
        )}
      </TabPanel> */}
      {/* <TabPanel value={value} index={5}>
        {!props.dataLoading &&
        props.allMonitoredCounts &&
        props.monitorCount > 0 ? (
          <div className="tabScreen align-center">
            <ThirdPartyTabs
              data={props.allMonitoredCounts}
              monitoredDomains={props.monitoredDomains}
              token={props.token}
              yearsOptions={props.yearsOptions}
              monthly={props.monthly}
              switchToAllTimeChart={props.switchToAllTimeChart}
              switchToMonthlyChart={props.switchToMonthlyChart}
              handleYearChange={props.handleYearChange}
              year={props.year}
              employeesStats={props.employeesStats}
              clientsStats={props.clientsStats}
              counts={props.counts}
              labels={props.labels}
              loading={props.loading}
              userRole={props.role}
              // getStats={() => props.getStats("query", "User")}
              user={props.user}
            />
          </div>
        ) : props.dataLoading ? (
          <Container />
        ) : props.user.user.role != "admin" &&
          props.user.sub &&
          basicPlans.includes(props.user.sub.plan_id) ? (
          <UpgradeTier
            openUpgradeModal={props.openUpgradeModal}
            upgradeLoader={props.upgradeLoader}
          />
        ) : (
          <AddProspectBtn type="3rd Parties" />
        )}
      </TabPanel> */}
      <TabPanel value={value} index={5}>
        {!props.dataLoading ? (
          <div className="tabScreen">
            <div
              style={{
                backgroundColor: "#E0E8EB",
                borderRadius: "20px",
                padding: "1rem 8rem",
                // marginBottom: "2rem",
                width: "100%",
                position: "relative",
                // left: "-25px",
                top: "-20px",
                margin: "auto",
              }}
            >
              <MdRefresh
                className="cursor"
                size={35}
                style={{
                  fill: "#222831",
                  position: "absolute",
                  top: "20px",
                  left: "20px",
                }}
                onClick={() => {
                  setKeywordInput("");
                  setPage(0);
                  setTimeSelected([]);
                  props.getData(
                    [],
                    `external-domains/${
                      props.path === "search-by-prospect"
                        ? "prospect"
                        : "domain"
                    }?sortby=${sortType}&time=all`,
                    0,
                    null,
                    true
                  );
                }}
              />
              <div
                className="tableTools screen-tools filtersBar"
                style={{
                  display: "inline-flex",
                  position: "relative",
                  // top: "-0.5rem",
                  width: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <UnlockSelected
                  user={props.user}
                  selected={props.selected}
                  unlockStealer={() =>
                    props.unlockStealer("externals", page, true)
                  }
                />
                <MultiSelect
                  overrideStrings={{
                    allItemsAreSelected: "All domains are selected.",
                  }}
                  hasSelectAll={true}
                  ItemRenderer={DefaultItemRenderer}
                  shouldToggleOnHover={true}
                  options={externalOptions}
                  value={
                    props.selectedExternals.length > externalOptions.length
                      ? externalOptions
                      : props.selectedExternals
                  }
                  onChange={props.setSelectedExternals}
                  labelledBy="Select"
                />
                <SearchBy
                  searchType={sortType}
                  changeSearchType={(sortType) =>
                    changeSortType(
                      sortType,
                      `external-domains/${
                        props.path === "search-by-prospect"
                          ? "prospect"
                          : "domain"
                      }`,
                      false
                    )
                  }
                  options={options}
                  placeholder="Sort By"
                />
                <Autocomplete
                  setKeywordInput={setKeywordInput}
                  loading={keywordLoading}
                  placeholder="Filter by Keyword"
                  onChange={(e) => {
                    onChangeSystem(e, "external");
                  }}
                  onFocus={(e) => {
                    if (keywordInput === "") {
                      onChangeSystem(e, "external");
                    }
                  }}
                  value={keywordInput}
                  suggestions={keywordSuggestions}
                  fields={["url_part", "occurrence"]}
                  filterKeyword={() => {
                    filterByKeyword(
                      sortType,
                      `external-domains/${
                        props.path === "search-by-prospect"
                          ? "prospect"
                          : "domain"
                      }`,
                      false,
                      false
                    );
                  }}
                />
              </div>
              {/* <ExportToCsv
                type="users"
                handleHomeLogOut={props.handleHomeLogOut}
                selectedDomain={props.domain}
                data={props.data}
                section={props.section}
                user={props.user}
              /> */}
            </div>
            {!props.inPageLoading && props.data && props.data.length > 0 ? (
              <CustomPaginationActionsTable
                credits={props.credits}
                currentProspect={props.currentProspect}
                hasNextPage={props.hasNextPage}
                data={props.data}
                userRole={props.role}
                selectedDomain={props.domain}
                config={props.config}
                clientsCount={props.clientsCount}
                machines={props.machines}
                finalRes={props.finalRes}
                loadMore={() =>
                  loadMore(
                    `external-domains/${
                      props.path === "search-by-prospect"
                        ? "prospect"
                        : "domain"
                    }?sortby=${sortType}&time=${
                      timeSelected[0] ? timeSelected[0].value : "all"
                    }&keyword=${keywordInput}`
                  )
                }
                loader={props.loader}
                selectRow={(row) => props.selectRow(row)}
                selectedExternals={props.selectedExternals}
                selected={props.selected}
                handleSelectAllClick={(e) => props.handleSelectAllClick(e)}
                sortType={sortType}
                unlockStealer={() =>
                  props.unlockStealer("externals", page, true)
                }
              />
            ) : props.inPageLoading ? (
              <Container />
            ) : (
              <div style={{ height: "100vh", textAlign: "center" }}>
                <h1>No Results</h1>
              </div>
            )}
          </div>
        ) : props.dataLoading ? (
          <Container />
        ) : (
          <div style={{ height: "100vh", textAlign: "center" }}>
            <h1>No Results</h1>
          </div>
        )}
      </TabPanel>
      <TabPanel value={value} index={6}>
        {!props.dataLoading && props.assetsCount > 0 ? (
          <div className="tabScreen align-center">
            <AssetsDiscovery
              data={props.assets.data}
              numbers={props.assets.visualize}
              assets={props.assets}
              user={props.user}
              token={props.token}
              resetUser={props.resetUser}
            />
          </div>
        ) : props.dataLoading ? (
          <Container />
        ) : props.user.user.role != "admin" &&
          props.user.sub &&
          basicPlans.includes(props.user.sub.plan_id) ? (
          <UpgradeTier
            openUpgradeModal={props.openUpgradeModal}
            upgradeLoader={props.upgradeLoader}
          />
        ) : (
          <AddProspectBtn type="assets" />
        )}
      </TabPanel>
      <div className="mobile">
        {!props.loading ? (
          counters.map((counter, index) =>
            counter.count > 0 ? (
              <Tab
                className={counter.class}
                key={index}
                onClick={counter.count > 0 && counter.func}
                label={counter.label}
                icon={counter.icon}
                {...a11yProps(index)}
              />
            ) : (
              <Tab
                className={counter.class}
                disabled
                key={index}
                label={counter.label}
                icon={counter.icon}
                {...a11yProps(index)}
              />
            )
          )
        ) : (
          <LoaderIcon className="homeLoader" type={"spinningBubbles"} />
        )}
      </div>
    </div>
  );
}
