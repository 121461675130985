import React, { useState } from "react";
// import Contact from "./Contact";
// import Faq from "./Faq";
import AppBarNew from "../Partials/AppBar";
import SimpleAccordion from "./FaqAccordion";
import SimpleModal from "../Partials/BasicModal";
import ContactForm from "./ContactForm";
import Footer from "../Partials/Footer";
export default function Help(props) {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <div className="App">
      <SimpleModal
        title="Top-Up Your Credits Balance for Additional Unlocks:"
        open={open}
        handleOpen={handleOpen}
        handleClose={handleClose}
        topUpLoader={props.topUpLoader}
        openTopUpModal={(item) => props.openTopUpModal(item)}
        user={props.user}
      />
      <AppBarNew
        resetUser={(user) => props.resetUser(user)}
        handleHomeLogOut={props.handleHomeLogOut}
        className="mobileHeader"
        user={props.user}
        role={props.role}
        token={props.token}
        email={props.email}
        domains={props.domains}
        handleLogOut={props.handleLogOut}
        filterCompanies={(industries, sizes, countries, regions) =>
          props.filterCompanies(industries, sizes, countries, regions)
        }
        credits={props.user.user.credits}
        openTopUps={() =>
          props.user.sub && props.user.sub.status != "pending" && handleOpen()
        }
      />
      <div
        className="help-page flex-wrapper"
        style={
          {
            //   margin: "0px 100px",
            //   height: "100vh",
            //   maxHeight: "100vh",
            //   padding: "150px 100px",
          }
        }
      >
        <div
          style={{
            //   margin: "0px 100px",
            minHeight: "85vh",
            // maxHeight: "100vh",
            padding: "140px 100px",
          }}
        >
          <div style={{ width: "60%", float: "left" }}>
            <SimpleAccordion />
          </div>
          <div style={{ width: "40%", float: "right" }}>
            <ContactForm />
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
}
