import React, { useState, useEffect, useRef } from "react";
import "../../styles/App.css";
import Form from "react-bootstrap/Form";
import SearchBtn from "./SearchBtn";
import MobileSearchBtn from "./MobileSearchBtn";
import { useLocation } from "react-router";
import SearchBy from "./SearchBy";
import Select from "react-select";
import axios from "axios";
import { authAddress, APIFullAddress } from "../../Config";
import { useDetectClickOutside } from "react-detect-click-outside";
import SearchCompany from "./SearchCompany";
import { CheckUserSession } from "../../hooks/CheckUserSession";

function Search(props) {
  const mountedRef = useRef(true);

  const location = useLocation();
  const activeDomain = location.pathname.split("/")[2];
  const [options, setOptions] = useState([]);
  const [prospect, setProspect] = useState();
  const [displayDropdown, setDisplayDropdown] = useState(true);
  const [industries, setIndustries] = useState([]);
  const [countries, setCountries] = useState([]);
  const [regions, setRegions] = useState([]);
  const [sizes, setSizes] = useState([]);
  const [industriesSelected, setIndustriesSelected] = useState([]);
  const [sizesSelected, setSizesSelected] = useState([]);
  const [countriesSelected, setCountriesSelected] = useState([]);
  const [regionsSelected, setRegionsSelected] = useState([]);
  const [isUsa, setUsa] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadOptions, setLoadOptions] = useState(false);

  useEffect(() => {
    if (
      countriesSelected[0] &&
      countriesSelected[0].value === "united states" &&
      !countriesSelected[1]
    ) {
      setUsa(true);
    } else {
      setRegionsSelected([]);
      setUsa(false);
    }
  }, [countriesSelected]);

  useEffect(() => {
    if (CheckUserSession(props.user)) {
      getFilters();
      getProspectsOptions();
      if (props.path != "advanced-search") {
        setIndustriesSelected([]);
        setSizesSelected([]);
        setCountriesSelected([]);
        setRegionsSelected([]);
      }
    } else {
      props.handleLogOut();
    }
  }, [props.path, props.prospects]);

  const getFilters = async () => {
    setLoading(true);
    setLoadOptions(true);
    await axios
      .get(`${APIFullAddress}/companies/get-filters`)
      .then((res) => {
        setIndustries(res.data.industries);
        setCountries(res.data.countries);
        setSizes(res.data.sizes);
        setRegions(res.data.usa_regions);
      })
      .catch((err) => console.log(err));

    if (!mountedRef.current) return null;
    setLoading(false);
    setLoadOptions(false);
  };

  useEffect(() => {
    return () => {
      mountedRef.current = false;
    };
  }, []);

  const closeDropdown = (e) => {
    e.target.id != "formBasicEmail"
      ? setDisplayDropdown(false)
      : setDisplayDropdown(true);
    // setDisplayDropdown(!displayDropdown ? true : false);
  };

  const ref = useDetectClickOutside({ onTriggered: closeDropdown });

  const getProspectsOptions = async () => {
    await axios
      .post(
        `${authAddress}/get-prospects?options=true`,
        {},
        {
          headers: {
            "auth-token": props.user.token,
          },
        }
      )
      .then((res) => {
        setOptions(res.data);
        const found = res.data.find((o) => o.value === activeDomain);
        if (found) {
          setProspect(found.value.toLowerCase());
        } else {
          setProspect(res.data[0].value.toLowerCase());
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <div>
      <div className="formDiv">
        <Form
          // onSubmit={(e) => {
          //   e.preventDefault();
          // }}
          autoComplete="off"
          className="freeSearchForm"
        >
          {props.searchType === "domain" ? (
            <Form.Group className="searchForm" controlId="formBasicEmail">
              <Form.Control
                maxLength="999"
                value={props.value.toLowerCase()}
                onFocus={props.onChange}
                onChange={(e) => {
                  setDisplayDropdown(true);
                  props.onChange(e);
                }}
                placeholder={
                  activeDomain && activeDomain.includes(".")
                    ? activeDomain
                    : "example.com"
                }
                required={true}
                name="domain"
              />

              {props.suggestions.length > 0 && (
                <div className="suggestionsBox" id="suggestionsBox" ref={ref}>
                  {displayDropdown && (
                    <div>
                      {props.suggestions.map((company, index) => (
                        <Form.Text
                          value={company.domain}
                          onClick={props.pickDomain}
                          key={index}
                          className="text-muted companyRow"
                        >
                          <img
                            alt={company.domain}
                            value={company.domain}
                            className="companyLogo"
                            src={company.logo}
                          />
                          {company.name}
                          <p value={company.domain} className="companyDomain">
                            {company.domain}
                          </p>
                        </Form.Text>
                      ))}
                    </div>
                  )}
                </div>
              )}
            </Form.Group>
          ) : props.searchType === "prospect" ? (
            <Select
              onChange={(e) => {
                if (e.value === "Add Prospect") {
                  props.redirect("/prospects");
                }
                setProspect(e.value);
              }}
              // value={prospect}
              style={{ minWidth: "300px" }}
              placeholder={
                prospect
                  ? options.find((o) => o.value === prospect).label
                  : "Choose Prospect"
              }
              className="search-by-select prospectSelect"
              options={options}
              theme={(theme) => ({
                ...theme,
                borderRadius: 0,
                colors: {
                  ...theme.colors,
                  primary25: "black",
                  primary: "black",
                },
              })}
            />
          ) : props.searchType === "system" ? (
            <Form.Group className="searchForm" controlId="formBasicEmail">
              <Form.Control
                maxLength="999"
                value={props.value.toLowerCase()}
                // onFocus={(e) => (e.target.placeholder = "")}
                onBlur={(e) =>
                  (e.target.placeholder = "Search by subdomain or subdirectory")
                }
                onFocus={props.onChangeSystem}
                // onChange={props.onChangeSystem}
                onChange={(e) => {
                  setDisplayDropdown(true);
                  props.onChangeSystem(e);
                }}
                placeholder="Search by subdomain or subdirectory"
                required={true}
                name="system"
              />
              {props.systemSuggestions.length > 0 && (
                <div className="suggestionsBox" id="suggestionsBox" ref={ref}>
                  {displayDropdown && (
                    <div>
                      {props.systemSuggestions.map((item, index) => (
                        <Form.Text
                          value={item.url_part}
                          onClick={() => props.pickSystem(item.url_part)}
                          key={index}
                          className="text-muted companyRow"
                        >
                          {item.url_part}
                          <p value={item.occurrence} className="companyDomain">
                            {item.occurrence}+ Occurrences
                          </p>
                        </Form.Text>
                      ))}
                    </div>
                  )}
                </div>
              )}
            </Form.Group>
          ) : (
            props.searchType === "advanced" && (
              <SearchCompany
                loadOptions={props.loadOptions}
                industries={industries}
                sizes={sizes}
                countries={countries}
                regions={regions}
                industriesSelected={industriesSelected}
                setIndustriesSelected={setIndustriesSelected}
                sizesSelected={sizesSelected}
                setSizesSelected={setSizesSelected}
                countriesSelected={countriesSelected}
                setCountriesSelected={setCountriesSelected}
                regionsSelected={regionsSelected}
                setRegionsSelected={setRegionsSelected}
                isUsa={isUsa}
              />
            )
          )}

          <SearchBtn
            user={props.user}
            industriesSelected={industriesSelected}
            sizesSelected={sizesSelected}
            countriesSelected={countriesSelected}
            filterCompanies={() =>
              props.filterCompanies(
                industriesSelected,
                sizesSelected,
                countriesSelected,
                regionsSelected
              )
            }
            system={props.value}
            prospect={prospect}
            type={props.searchType}
            domain={props.selectedDomain}
            role={props.role}
            className="desktopSearchBtn"
            openUpgradeModal={props.openUpgradeModal}
            upgradeLoader={props.upgradeLoader}
          />
          <MobileSearchBtn
            type={props.searchType}
            domain={props.selectedDomain}
            role={props.role}
            className="mobileSearchBtn"
          />
        </Form>
      </div>
      <div>{!props.loader && props.machines < 0 && <h2>No Results</h2>}</div>
    </div>
  );
}

export default Search;
