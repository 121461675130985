import React from "react";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import logo from "../../images/logo-1.png";
import ChangePass from "./ChangePass";

function Copyright() {
  return (
    <Typography
      variant="body2"
      color="textSecondary"
      align="center"
      className="copyrights"
    >
      {"Copyright © "}
      <Link color="inherit" href="https://material-ui.com/">
        Hudson Rock
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
  },
  // image: {
  //   backgroundImage: { cavaLogo },
  //   backgroundRepeat: "no-repeat",
  //   backgroundColor:
  //     theme.palette.type === "light"
  //       ? theme.palette.grey[50]
  //       : theme.palette.grey[900],
  //   backgroundSize: "cover",
  //   backgroundPosition: "center",
  // },
  paper: {
    margin: theme.spacing(18, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function SignInSide(props) {
  const classes = useStyles();
  return (
    <div>
      {/* <PrimarySearchAppBar /> */}
      <Grid container component="main" className={classes.root}>
        <CssBaseline />
        {/* <Grid
          id="loginImg"
          item
          xs={false}
          sm={4}
          md={7}
          className={classes.image}
        /> */}
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          component={Paper}
          elevation={12}
          square
          className="loginPage"
        >
          <div className={classes.paper + " loginDiv"}>
            <img className="loginLogo" src={logo} alt="logo" />
            <Typography
              className="signInText cyberFont"
              component="h1"
              variant="h3"
            >
              Sign In
            </Typography>
            <form
              id="loginForm"
              onSubmit={props.login}
              className={classes.form}
              noValidate
            >
              <TextField
                className="loginInput"
                type="email"
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                onChange={props.email}
                autoComplete="email"
                autoFocus
              />
              <TextField
                className="loginInput"
                variant="outlined"
                margin="normal"
                required
                fullWidth
                onChange={props.password}
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
              />
              {/* <FormControlLabel
                control={
                  <Checkbox
                    value="remember"
                    color="primary"
                    className="lightText"
                  />
                }
                className="lightText"
                label="Remember me"
              /> */}
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit + " searchBtn bold"}
                // style={{ width: "30%" }}
              >
                Sign In
              </Button>
              <Grid container>
                <Grid item xs>
                  {/* <Link href="#" variant="body2" className="lightText">
                    Change Password Here For Better Security
                  </Link> */}
                  <p className="error">{props.error}</p>
                </Grid>
              </Grid>
              <ChangePass reset={true} />
              <p className="bold">
                Don't have an account?{" "}
                <a className="yellow bold" href="/sign-up">
                  Sign up here
                </a>
              </p>
              <Box mt={5} className="loginBottomBox">
                <Copyright />
              </Box>
            </form>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}
