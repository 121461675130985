import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import AppBarNew from "../Partials/AppBar";
import { APIFullAddress, clearbitApi } from "../../Config";
import CreateApiClient from "../../API";
import axios from "axios";
import CompanyCard from "../Partials/CompanyCard";
import Container from "../Partials/ProgressBar";
import { RiGroup2Fill } from "react-icons/ri";
import { FaNetworkWired } from "react-icons/fa";
import CountUp from "react-countup";
import SearchCompany from "../Search/SearchCompany";
import { CheckUserSession } from "../../hooks/CheckUserSession";
import Button from "@material-ui/core/Button";
import Icon from "@material-ui/core/Icon";
import { makeStyles } from "@material-ui/core/styles";
import LoadMore from "../Partials/LoadMore";
import LoaderIcon from "react-loader-icon";
import SimpleModal from "../Partials/BasicModal";
import Footer from "../Partials/Footer";
import SystemDiscovery from "./SystemDiscovery";
import { createBrowserHistory } from "history";

const history = createBrowserHistory();

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(0),
    verticalAlign: "bottom",
  },
}));

const api = CreateApiClient();

function Systems(props) {
  const classes = useStyles();

  const location = useLocation();

  const [prospects, setProspects] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [loading, setLoading] = useState(false);
  const system = location.pathname.split("/")[2];
  const path = location.pathname.split("/")[1];
  const [industries, setIndustries] = useState([]);
  const [countries, setCountries] = useState([]);
  const [sizes, setSizes] = useState([]);
  const [industriesSelected, setIndustriesSelected] = useState([]);
  const [sizesSelected, setSizesSelected] = useState([]);
  const [timeSelected, setTimeSelected] = useState([]);
  const [countriesSelected, setCountriesSelected] = useState([]);
  const [loadOptions, setLoadOptions] = useState(false);
  const [resultsCount, setResultsCount] = useState(0);
  const [loader, setLoader] = useState(false);
  const [finalRes, setFinalRes] = useState(false);
  const [page, setPage] = useState(0);
  const basicPlans = [2741315, 2741325, 3332681, 3332639];
  const [open, setOpen] = useState(false);
  const [assets, setAssets] = useState([]);
  const [activeDomainAssets, setActiveDomainAssets] = useState([]);
  const [employeeAssets, setEmployeesAssets] = useState([]);
  const [usersAssets, setUsersAssets] = useState([]);
  const [urlsLoading, setUrlsLoading] = useState(null);
  const [assetsView, setAssetsView] = useState(false);
  const [cardsLoader, setCardsLoader] = useState(false);
  const [assetsNumbers, setAssetsNumbers] = useState([]);
  const [timeOptions, setTimeOptions] = useState([]);
  const [selectedType, setSelectedType] = useState("all");

  const [regions, setRegions] = useState([]);
  const [regionsSelected, setRegionsSelected] = useState([]);
  const [isUsa, setUsa] = useState(false);

  useEffect(() => {
    if (
      countriesSelected[0] &&
      countriesSelected[0].value === "united states" &&
      !countriesSelected[1]
    ) {
      setUsa(true);
    } else {
      setRegionsSelected([]);
      setUsa(false);
    }
  }, [countriesSelected]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (CheckUserSession(props.user)) {
      if (companies.length > 0) {
        loadPageData(true);
      } else {
        loadPageData(false);
      }
    } else {
      props.handleLogOut();
    }
  }, [system, assetsView]);

  const loadPageData = async (cardsLoading) => {
    await getFilters(cardsLoading);
    await getCompanies([], 0, cardsLoading);
    await getProspects();
  };

  // const loadCards = async () => {
  //   await getCompanies([], 0);
  // };

  const getCompanies = async (oldData, page, cardsLoading, clear) => {
    setPage(page);
    if (page > 0) {
      setLoader(true);
    } else {
      if (cardsLoading) {
        setCardsLoader(true);
      } else {
        setLoading(true);
      }
      setFinalRes(false);
    }
    let obj = {};
    if (
      !clear &&
      industriesSelected.length > 0 &&
      sizesSelected.length > 0 &&
      countriesSelected.length > 0 &&
      timeSelected
    ) {
      obj = {
        industries: industriesSelected,
        sizes: sizesSelected,
        countries: countriesSelected,
        regions: regionsSelected,
        time: timeSelected,
        type: selectedType,
      };
      localStorage.setItem("system_filters", JSON.stringify(obj));
    } else if (!clear) {
      const systemFilters = localStorage.getItem("system_filters");
      if (systemFilters) {
        obj = JSON.parse(systemFilters);
        setIndustriesSelected(obj.industries);
        setCountriesSelected(obj.countries);
        setSizesSelected(obj.sizes);
        setRegionsSelected(obj.regions);
        setTimeSelected(obj.time);
        setSelectedType(obj.type);
      }
    } else {
      setIndustriesSelected([]);
      setCountriesSelected([]);
      setSizesSelected([]);
      setRegionsSelected([]);
      setTimeSelected([]);
      setSelectedType("all");
      localStorage.removeItem("system_filters");
    }

    await axios
      .post(
        `${APIFullAddress}/url-parts/get-companies?part=${system}&page=${page}&assets=${assetsView}`,
        obj,
        {
          headers: {
            "auth-token": props.user.token,
          },
        }
      )
      .then(async (res) => {
        if (assetsView) {
          if (res.data.length > 0) {
            if (oldData) {
              setAssets([...oldData, ...res.data]);
            } else {
              setAssets(res.data);
            }
          } else {
            setAssets([]);
            setFinalRes(true);
          }
        } else {
          const named_companies = await Promise.all(
            res.data.map(async (item) => {
              item["name"] = await axios
                .get(`${clearbitApi}${item.domain}`)
                .then((res) => {
                  if (res.data[0]) {
                    return res.data[0].name;
                  } else {
                    return item.domain;
                  }
                })
                .catch((err) => console.log(err));
              return item;
            })
          ).catch((err) => console.log(err));

          if (named_companies.length > 0) {
            setCompanies([...oldData, ...named_companies]);
            setResultsCount(named_companies[0].total);
          } else {
            setCompanies([]);
            setFinalRes(true);
          }
        }
      })
      .catch((err) => console.log(err));
    if (page > 0) {
      setLoader(false);
    } else {
      if (cardsLoading) {
        setCardsLoader(false);
      } else {
        setLoading(false);
      }
    }
  };

  const getFilters = async (cardsLoading) => {
    if (cardsLoading) {
      setCardsLoader(true);
    } else {
      setLoading(true);
    }
    setLoadOptions(true);
    await axios
      .get(`${APIFullAddress}/companies/get-filters`)
      .then((res) => {
        setIndustries(res.data.industries);
        setCountries(res.data.countries);
        setSizes(res.data.sizes);
        setRegions(res.data.usa_regions);
        setTimeOptions(res.data.time);
      })
      .catch((err) => console.log(err));

    if (cardsLoading) {
      setCardsLoader(false);
    } else {
      setLoading(false);
    }
    setLoadOptions(false);
  };

  const getProspects = async () => {
    const response = await api.editUser(
      "get-prospects",
      {},
      {
        headers: {
          "auth-token": props.user.token,
        },
      }
    );
    setProspects(response.prospects);
  };

  const loadMore = async (num) => {
    setPage(num);
    if (assetsView) {
      await getCompanies(assets, num);
    } else {
      await getCompanies(companies, num);
    }
  };

  const getUrlsByDomain = async (company) => {
    // history.push(`/#${company.id}`);
    setUrlsLoading(company.id);
    let body = {
      id: company.id,
    };

    let config = {
      headers: {
        "auth-token": props.user.token,
      },
    };
    await axios
      .post(`${APIFullAddress}/assets-discovery?system=${system}`, body, config)
      .then((res) => {
        if (res.data.data && res.data.data[0]) {
          setActiveDomainAssets(res.data.data[0]);
        }
      })
      .catch((err) => console.log(err));
    setUrlsLoading(null);
  };

  const counters = [
    {
      name: (
        <h2>
          Matched <br /> Companies
        </h2>
      ),
      icon: <RiGroup2Fill size={30} className="counterIcon" />,
      count:
        assets.length > 0 && assetsView
          ? assets[0].total
          : companies[0]
          ? Number(companies[0].total)
          : 0,
    },
    {
      name: (
        <h2>
          <span className="uppercase">{system}</span>
          <br /> <span>URLs</span>
        </h2>
      ),
      icon: <FaNetworkWired size={30} className="counterIcon" />,
      count:
        assets.length > 0 && assetsView
          ? assets[0].occurrence
          : companies[0]
          ? Number(companies[0].occurrence)
          : 0,
    },
  ];

  return (
    <div className="App">
      <AppBarNew
        openUpgradeModal={props.openUpgradeModal}
        upgradeLoader={props.upgradeLoader}
        handleHomeLogOut={props.handleHomeLogOut}
        currentType="system"
        user={props.user}
        token={props.user.token}
        email={props.logEmail}
        handleLogOut={() => props.handleLogOut()}
        prospects={prospects}
        path={path}
        inputValue={system}
        filterCompanies={(industries, sizes, countries, regions) =>
          props.filterCompanies(industries, sizes, countries, regions)
        }
        resetUser={(user) => props.resetUser(user)}
        credits={props.user.user.credits}
        openTopUps={() =>
          props.user.sub && props.user.sub.status != "pending" && handleOpen()
        }
      />
      <SimpleModal
        title="Top-Up Your Credits Balance for Additional Unlocks:"
        open={open}
        handleOpen={handleOpen}
        handleClose={handleClose}
        topUpLoader={props.topUpLoader}
        openTopUpModal={(item) => props.openTopUpModal(item)}
        user={props.user}
      />
      {loading && !cardsLoader ? (
        <div style={{ marginTop: "350px" }}>
          <Container />
        </div>
      ) : (
        <div className="margin-div">
          <div
            style={{
              minHeight: "18rem",
              backgroundColor: "#fecd4e",
              color: "black",
              paddingTop: "10px",
              paddingBottom: "10px",
              marginTop: "5rem",
              borderRadius: "50px",
            }}
          >
            <div style={{ display: "inline-flex" }}>
              {counters.map((counter, index) => (
                <div key={index} className="counter newCounter">
                  {counter.icon}
                  <CountUp
                    separator=","
                    start={0}
                    end={counter.count}
                    duration={2.75}
                    suffix="+"
                  />
                  {counter.name}
                </div>
              ))}
            </div>
            <div className="filters-inpage">
              <SearchCompany
                page="systems"
                timeOptions={timeOptions}
                loadOptions={props.loadOptions}
                industries={industries}
                sizes={sizes}
                countries={countries}
                regions={regions}
                industriesSelected={industriesSelected}
                setIndustriesSelected={setIndustriesSelected}
                sizesSelected={sizesSelected}
                timeSelected={timeSelected}
                setTimeSelected={(e) => {
                  setTimeSelected(e);
                }}
                setSizesSelected={setSizesSelected}
                countriesSelected={countriesSelected}
                setCountriesSelected={setCountriesSelected}
                regionsSelected={regionsSelected}
                setRegionsSelected={setRegionsSelected}
                isUsa={isUsa}
              />
              {industriesSelected.length > 0 &&
              sizesSelected.length > 0 &&
              countriesSelected.length > 0 ? (
                <Button
                  id="systemsFilterBtn"
                  type="button"
                  variant="contained"
                  className={classes.button + " searchBtn desktopSearchBtn"}
                  endIcon={
                    props.upgradeLoader === "systemsFilterBtn" ? (
                      <LoaderIcon
                        className="removeLoader tableLoader"
                        style={{
                          position: "relative",
                          top: "-10px",
                          right: "0px",
                        }}
                      />
                    ) : (
                      <Icon>send</Icon>
                    )
                  }
                  onClick={(e) => {
                    {
                      props.user &&
                      (!props.user.sub ||
                        !basicPlans.includes(props.user.sub.plan_id))
                        ? getCompanies([], 0, true)
                        : props.user && props.openUpgradeModal(e);
                    }
                  }}
                >
                  Filter
                </Button>
              ) : (
                <Button
                  type="button"
                  variant="contained"
                  className={
                    classes.button + " searchBtn grayBtn desktopSearchBtn"
                  }
                  endIcon={<Icon>send</Icon>}
                >
                  Filter
                </Button>
              )}
            </div>
            <div className="time-filters">
              <div
                onClick={() => setSelectedType("all")}
                className={
                  selectedType === "all" ? "darkSelected" : "dark-bg-hover"
                }
              >
                <span className="cyberFont">All</span>
              </div>
              <div
                onClick={() => setSelectedType("employees")}
                className={
                  selectedType === "employees"
                    ? "darkSelected"
                    : "dark-bg-hover"
                }
              >
                <span className="cyberFont">Employees</span>
              </div>
              <div
                onClick={() => setSelectedType("users")}
                className={
                  selectedType === "users" ? "darkSelected" : "dark-bg-hover"
                }
              >
                <span className="cyberFont">Users</span>
              </div>
            </div>
            <br />
            <div>
              <Button
                style={{ backgroundColor: "#222831", color: "#fecd4e" }}
                onClick={() => setAssetsView(!assetsView)}
              >
                SWITCH TO {assetsView ? "COMPANY" : "EXTERNAL ATTACK SURFACE"}{" "}
                VIEW
              </Button>
              <Button
                type="button"
                variant="contained"
                style={{
                  backgroundColor: "#222831",
                  color: "#fecd4e",
                  marginLeft: "5px",
                }}
                endIcon={<Icon>clear</Icon>}
                onClick={() => {
                  getCompanies([], 0, true, true);
                }}
              >
                clear filters
              </Button>
            </div>
          </div>
          <div
            id="cardsContainer"
            className="row"
            style={{ minHeight: "50vh" }}
          >
            {cardsLoader ? (
              <LoaderIcon
                type="spin"
                style={{
                  fill: "white",
                  width: "100px",
                  height: "100px",
                  margin: "200px auto",
                }}
              />
            ) : assetsView ? (
              <SystemDiscovery
                data={assets}
                getUrlsByDomain={(company) => getUrlsByDomain(company)}
                activeDomainAssets={activeDomainAssets}
                urlsLoading={urlsLoading}
                system={system}
                user={props.user}
                resetUser={(user) => props.resetUser(user)}
              />
            ) : (
              !assetsView &&
              companies &&
              companies.map((item, index) => (
                <CompanyCard
                  item={item}
                  key={index}
                  system={system}
                  type="system"
                  user={props.user}
                />
              ))
            )}
            {!cardsLoader && (
              <LoadMore
                resultsCount={resultsCount}
                setPage={() => loadMore(page + 1)}
                finalRes={finalRes}
                loader={loader}
                minLength={18}
                data={assetsView ? assets : companies}
              />
            )}
          </div>
        </div>
      )}
      {!loading && <Footer />}
    </div>
  );
}

export default Systems;
