import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import BlurryText from "../Partials/Blur";
import LoaderIcon from "react-loader-icon";
import Overlay from "../Partials/Overlay";
import TableHead from "@material-ui/core/TableHead";
import axios from "axios";
import { APIFullAddress } from "../../Config";
import { FaFacebook } from "react-icons/fa";
import { BsUnlockFill, BsEyeFill, BsFillInfoCircleFill } from "react-icons/bs";
import { GoDiffAdded } from "react-icons/go";
import { GrOrganization } from "react-icons/gr";
import {
  RiComputerLine,
  RiVirusLine,
  RiExternalLinkFill,
  RiLockPasswordFill,
  RiFolderOpenFill,
  RiGroup2Fill,
  RiInstallFill,
} from "react-icons/ri";
import {
  FaRegCalendarAlt,
  FaUserCircle,
  FaShieldAlt,
  FaUserSecret,
  FaRegIdCard,
  FaUnlockAlt,
  FaFile,
  FaViruses,
  FaFileCsv,
} from "react-icons/fa";
import {
  GiWireframeGlobe,
  GiFamilyTree,
  GiSherlockHolmes,
  GiArtificialIntelligence,
} from "react-icons/gi";
import { AiFillDatabase } from "react-icons/ai";
import {
  MdSettingsEthernet,
  MdSettingsApplications,
  MdOutlineWarning,
} from "react-icons/md";
import CustomizedTreeView from "../Partials/Tree";
import TreeView from "@material-ui/lab/TreeView";
import TreeItem from "@material-ui/lab/TreeItem";
import Collapse from "@material-ui/core/Collapse";
import { alpha, makeStyles, withStyles } from "@material-ui/core/styles";
import { useSpring, animated } from "@react-spring/web"; // web.cjs is required for IE 11 support
import SvgIcon from "@material-ui/core/SvgIcon";
import ExportToCsv from "../Partials/ExportToCsv";
import SimpleModal from "../Partials/SimpleModal";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import InstalledSoftware from "../Partials/InstalledSoftware";
import Chip from "@material-ui/core/Chip";
import { CSVLink, CSVDownload } from "react-csv";
import Typewriter from "../Partials/Typewriter";

function MinusSquare(props) {
  return (
    <SvgIcon fontSize="inherit" style={{ width: 14, height: 14 }} {...props}>
      {/* tslint:disable-next-line: max-line-length */}
      <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 11.023h-11.826q-.375 0-.669.281t-.294.682v0q0 .401.294 .682t.669.281h11.826q.375 0 .669-.281t.294-.682v0q0-.401-.294-.682t-.669-.281z" />
    </SvgIcon>
  );
}

function TransitionComponent(props) {
  const style = useSpring({
    from: { opacity: 0, transform: "translate3d(20px,0,0)" },
    to: {
      opacity: props.in ? 1 : 0,
      transform: `translate3d(${props.in ? 0 : 20}px,0,0)`,
    },
  });

  return (
    <animated.div style={style}>
      <Collapse {...props} />
    </animated.div>
  );
}

TransitionComponent.propTypes = {
  /**
   * Show the component; triggers the enter or exit states
   */
  in: PropTypes.bool,
};

const StyledTreeItem = withStyles((theme) => ({
  iconContainer: {
    "& .close": {
      opacity: 0.3,
    },
  },
  group: {
    marginLeft: 7,
    paddingLeft: 18,
    borderLeft: `1px dashed ${alpha("#1A1C20", 0.4)}`,
  },
}))((props) => (
  <TreeItem {...props} TransitionComponent={TransitionComponent} />
));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    display: "flex",
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
  },
}));

export default function EmployeeTabs(props) {
  const [activeTab, setActiveTab] = useState(0);
  const [machineDetails, setMachineDetails] = useState([]);
  const [machineData, setMachineData] = useState([]);
  const [blur, setBlur] = useState(true);
  const [loading, isLoading] = useState(true);
  const [unlockLoader, setUnlockLoader] = useState(false);
  const [unlockedTab, setUnlockedTab] = useState();
  const [overlay, setOverlay] = useState(false);
  const [unlockedStealers, setUnlockedStealers] = useState([]);
  const [externalView, setExternalView] = useState(false);
  const [viewLoading, setViewLoading] = useState(false);
  const [expandedApplication, setExpandedApplication] = useState("");
  const [modalState, setModalState] = useState(null);
  const [open, setOpen] = React.useState(false);
  const [applications, setApplications] = useState([]);

  const userRole = props.userRole;
  const selectedDomain = props.selectedDomain;
  const config = props.config;
  const tableData = props.data;

  useEffect(() => {
    userRole === "mssp" && !props.unmask ? setBlur(true) : setBlur(false);
  }, [blur, userRole]);

  const checkCensoredState = (row) => {
    const shownState = unlockedStealers.some(
      (e) => e.id === row._id && e.domain === props.selectedDomain
    );
    return shownState;
  };

  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const showSoftware = () => {
    setModalState("software");
    handleOpen();
  };

  const handleCollapse = async (e, id, machine, active, changeView) => {
    const ignoredValues = [
      "exportDiv",
      "exportBtn",
      "exportSpan",
      "csvDownload",
      "unlockBtn",
      "unlockIcon",
    ];

    if (active && e && e.target && ignoredValues.includes(e.target.id)) return;

    const activeIndex = tableData
      .map(function (e) {
        return e._id;
      })
      .indexOf(id);

    setActiveTab(activeIndex);

    if (changeView != undefined) {
      setViewLoading(true);
    } else {
      setExternalView(false);
      isLoading(true);
    }
    let query;

    if (!props.unmask) {
      if (props.path === "search-by-domain") {
        query = {
          domain: selectedDomain,
          id: machine,
        };
      } else if (props.path === "search-by-prospect") {
        query = {
          prospect: selectedDomain,
          id: machine,
        };
      }
    } else {
      query = {
        domain: machine.split("_")[1]
          ? machine.split("_")[1]
          : tableData[activeIndex].current_domain,
        id: machine.split("_")[0],
      };
    }

    getMachineData(query, config);
    async function getMachineData(query, config) {
      let endpoint;
      try {
        if (props.isThirdParty) {
          endpoint = `${APIFullAddress}/machine?unmask=${props.unmask}&thirdparties=true`;
        } else {
          endpoint = `${APIFullAddress}/machine?unmask=${props.unmask}&external=${changeView}`;
        }
        await axios.post(endpoint, query, config).then((res) => {
          setMachineData(res.data);
        });
      } catch (e) {
        console.log(e);
      }

      if (changeView != undefined) {
        setViewLoading(false);
      } else {
        isLoading(false);
      }
    }

    const shownState = machineDetails.slice();
    const index = shownState.indexOf(machine);
    if (index > 0) {
      shownState.splice(index, 1);
      setMachineDetails(shownState);
    } else {
      shownState.pop();
      shownState.push(machine);
      setMachineDetails(shownState);
    }
  };

  useEffect(() => {
    if (tableData.length > 0) {
      handleCollapse(
        null,
        tableData[activeTab]._id,
        tableData[activeTab]._id,
        true
      );
    }
  }, [tableData]);

  const unlockStealer = async (row, index, machineData) => {
    // if (!props.user.sub && props.user.user.role === "mssp") {
    //   props.setOpen(true);
    //   return;
    // }
    setUnlockedTab(index);
    setUnlockLoader(true);
    setOverlay(true);
    let query;
    // const relative_domain = machineData.find(
    //   (s) => s.domain != "microsoftonline.com"
    // );
    if (props.path === "search-by-domain") {
      query = {
        domain: machineData[0].domain,
        stealer: row._id,
      };
    } else if (props.path === "search-by-prospect") {
      query = {
        domain: machineData[0].domain,
        stealer: row._id,
      };
    }
    try {
      await axios
        .post(
          `${APIFullAddress}/unlock-stealer?thirdparty=${props.isThirdParty}`,
          query,
          config
        )
        .then((res) => {
          const localUser = JSON.parse(localStorage.getItem("user"));
          localUser.user.credits = res.data.credits;
          localStorage.setItem("user", JSON.stringify(localUser));
          setUnlockedStealers(res.data.unlockedStealers);
          const reveal = tableData.find((e) => e._id == res.data.stealer._id);
          reveal.censored = false;
          reveal.source = res.data.stealer.stealer;
          res.data.stealer.data.ip.length > 0
            ? (reveal.ip = res.data.stealer.data.ip[0].data)
            : (reveal.ip = "Not Found");
          res.data.stealer.data.malwarePath.length > 0
            ? (reveal.path = res.data.stealer.data.malwarePath[0].data)
            : (reveal.path = "Not Found");
        });
    } catch (error) {
      setUnlockLoader(false);
      setOverlay(false);
      props.setOpen(true);
      props.setMessage(error.response.data);
    }
    handleCollapse(null, tableData[index]._id, tableData[index]._id, true);
    props.refreshUser();
    setUnlockLoader(false);
    setOverlay(false);
  };

  const showApplication = (app) => {
    let _expanded = machineData[0].applications.find(
      (a) => a.name.toLowerCase() === app.toLowerCase()
    );
    setExpandedApplication(_expanded);
    setModalState("application");
    handleOpen();
  };

  const showApplications = (apps) => {
    setApplications(apps.filter((a) => a.ai_scenarios));
    setModalState("applications");
    handleOpen();
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setModalState(null);
    setOpen(false);
  };

  const populateListItem = (item) => {
    return (
      <>
        {expandedApplication[`${item}`] ? (
          <li className="capitalize">
            {item.replace(/_/g, " ")}: {expandedApplication[`${item}`]}
          </li>
        ) : (
          <></>
        )}
      </>
    );
  };

  const applicationHtml = (
    <div>
      <h2 className="capitalize">{expandedApplication.name}</h2>
      <div style={{ display: "inline-flex", width: "100%" }}>
        <div
          style={{
            width: expandedApplication.risk ? "50%" : "100%",
            margin: "15px",
          }}
        >
          <p className="lightText">{expandedApplication.description}</p>
          <ul style={{ listStyle: "none" }}>
            {populateListItem("category")}
            {populateListItem("subcategory")}
            {populateListItem("technology")}
            {populateListItem("saas")}
            {populateListItem("depends_on_applications")}
            {populateListItem("tunnels_other_applications")}
            {populateListItem("implicit_use_applications")}
            {populateListItem("widely_used")}
          </ul>
        </div>
        <div style={{ margin: "15px" }}>
          {expandedApplication.risk && (
            <p className="lightText">Risk: {expandedApplication.risk}</p>
          )}
          <ul style={{ listStyle: "none" }}>
            {populateListItem("has_known_vulnerabilities")}
            {populateListItem("data_breaches")}
            {populateListItem("prone_to_misuse")}
            {populateListItem("used_by_malware")}
            {populateListItem("ip_based_restrictions")}
            {populateListItem("standard_ports")}
            {populateListItem("evasive")}
            {populateListItem("excessive_bandwidth")}
            {populateListItem("certifications")}
            {populateListItem("poor_financial_viability")}
            {populateListItem("poor_terms_of_service")}
          </ul>
        </div>
      </div>
    </div>
  );

  const applicationsHtml = applications && applications.length > 0 && (
    <div style={{ color: "white" }}>
      <h2>
        <GiArtificialIntelligence size={45} style={{ fil: "white" }} /> AI
        Attack Scenarios
      </h2>
      <br />
      {applications.map((app, index) => (
        <div key={index}>
          <div>
            <span
              className="uppercase red-chip"
              style={{ marginBottom: "5px" }}
            >
              {app.name}{" "}
            </span>
            {app.ai_scenarios.map((item, index) => (
              <div key={index}>
                <Typewriter text={item} delay={15} infinite={false} />
              </div>
            ))}
          </div>
          <hr
            style={{ borderTop: "2px dashed #cc2f30", color: "transparent" }}
          />
        </div>
      ))}
    </div>
  );

  const softwareHtml = machineData[0] &&
    machineData[0].installed_software &&
    machineData[0] &&
    machineData[0].installed_software.length > 0 && (
      <div>
        <div>
          <h3 className="align-center">Installed Software</h3>
          <CSVLink
            data={machineData[0] && machineData[0].installed_software}
            filename={`${
              machineData[0] && machineData[0].source
            }_installed_software.csv`}
            className="cardBtn"
            target="_blank"
          >
            Export To CSV
            <FaFileCsv size={20} />
          </CSVLink>
        </div>
        <br />
        <Table
          size="small"
          aria-label="more"
          className="credsTable systemTable"
        >
          <TableHead>
            <TableRow className="credsRow" style={{ border: "1px solid" }}>
              <TableCell className="profileTh">Software Name</TableCell>
              <TableCell
                className="profileTh alignRight"
                style={{ textAlign: "right" }}
              >
                Version
              </TableCell>
            </TableRow>
          </TableHead>
          {machineData[0] &&
            machineData[0].installed_software.map((item, index) => (
              <TableRow
                key={index}
                className="credsRow"
                style={{ border: "1px solid" }}
              >
                <TableCell className="profileTd">
                  <Chip
                    size="small"
                    label={item.program}
                    style={{ backgroundColor: "#FBCD4E" }}
                  />
                </TableCell>
                <TableCell
                  className={"profileTd " + item.class}
                  style={{ textAlign: "right" }}
                >
                  {item.version ? item.version : "UNKNOWN"}
                </TableCell>
              </TableRow>
            ))}
        </Table>
      </div>
    );

  return (
    <>
      {props.data.length > 0 && (
        <div className={classes.root + " employeesTable cyberFont"}>
          <Tabs
            id="employeesTabs"
            orientation="vertical"
            variant="scrollable"
            value={value}
            onChange={handleChange}
            aria-label="Vertical tabs example"
            className={classes.tabs + " employeeTabsList"}
          >
            {props.data.map((row, index) => (
              <Tab
                className="employeeTab"
                key={index}
                onClick={(e) =>
                  handleCollapse(e, row._id, row._id, activeTab === index)
                }
                label={
                  <ul className="employee-nav">
                    {!row.censored && activeTab === index && (
                      <ExportToCsv
                        isThirdParty={props.isThirdParty}
                        unmask={true}
                        type={"creds"}
                        data={machineData}
                        user={props.user}
                        loading={loading}
                        domain={
                          machineData && machineData[0] && machineData[0].domain
                        }
                        id={row.id}
                        name={row.source}
                      />
                    )}
                    {row.current_domain && props.unmask && (
                      <li>
                        <RiGroup2Fill size={20} className="credsIcons" />
                        <span className="employee-label capitalize">
                          Company: {row.company}
                        </span>
                      </li>
                    )}
                    <li>
                      <FaRegIdCard size={20} className="credsIcons" />
                      <span className="employee-label">Machine ID:</span>
                      {blur && row.censored ? (
                        <>
                          <BlurryText value={" " + row.source} />
                        </>
                      ) : (
                        <>{" " + row.source}</>
                      )}
                      {userRole === "mssp" &&
                        row.censored &&
                        (unlockLoader ? (
                          <LoaderIcon
                            className="unlockLoader"
                            type={"spinningBubbles"}
                          />
                        ) : (
                          machineData &&
                          machineData[0] &&
                          machineData[0]._id === row._id && (
                            <button
                              title="unlockBtn"
                              id="unlockBtn"
                              onClick={() =>
                                activeTab === index &&
                                unlockStealer(row, index, machineData)
                              }
                              className="cardBtn unlockBtn shadow-box"
                            >
                              Unlock
                              <BsUnlockFill
                                size={15}
                                className="smIcons unlockIcon"
                                onClick={(e) => {
                                  activeTab === index && e.stopPropagation();
                                  activeTab === index &&
                                    unlockStealer(row, index, machineData);
                                }}
                              />
                            </button>
                          )
                        ))}
                      {/* {props.unmask && activeTab === index && (
                        <ExportToCsv
                          unmask={true}
                          type={"creds"}
                          data={machineData}
                          user={props.user}
                          loading={loading}
                          domain={row.current_domain}
                          id={row._id}
                          name={row.source}
                        />
                      )} */}
                    </li>
                    <li>
                      <FaViruses size={20} className="credsIcons" />
                      <span className="employee-label capitalize">
                        Stealer Family: {row.stealer_family}
                      </span>
                    </li>

                    <li>
                      <MdSettingsEthernet size={20} className="credsIcons" />
                      <span className="employee-label">IP Address:</span>
                      {blur && row.censored && row.ip != "Not Found" ? (
                        <>
                          <BlurryText value={" " + row.ip} />
                        </>
                      ) : (
                        <>{" " + row.ip}</>
                      )}
                    </li>
                    <li>
                      <RiVirusLine size={20} className="credsIcons" />
                      <span className="employee-label">Malware Path:</span>
                      {blur && row.censored && row.path != "Not Found" ? (
                        <span>
                          {row.path && row.path.includes(".") ? (
                            <span>
                              <BlurryText value={row.path.split(".")[0]} />.
                              {row.path.split(".")[1]}
                            </span>
                          ) : (
                            <BlurryText value={row.path} />
                          )}
                        </span>
                      ) : (
                        <>{row.path}</>
                      )}
                    </li>
                    <li>
                      <FaRegCalendarAlt size={20} className="credsIcons" />
                      <span className="employee-label">Date Compromised:</span>
                      {" " + row.date_compromised}
                    </li>
                    <li>
                      <GoDiffAdded size={20} className="credsIcons" />
                      <span className="employee-label">Latest Detection:</span>
                      {" " + row.last_time_added}
                    </li>

                    {!row.censored && props.unmask && (
                      <li>
                        <BsUnlockFill size={20} className="credsIcons" />
                        <span className="employee-label">
                          Unlocked at {row.unlock_date}
                        </span>
                      </li>
                    )}
                  </ul>
                }
                {...a11yProps(index)}
              />
            ))}

            {props.loader ? (
              <LoaderIcon className="tabsLoader" type={"spin"} />
            ) : (
              <div className="align-center">
                {props.hasNextPage ? (
                  <button className="cardBtn loadMore" onClick={props.loadMore}>
                    Load More
                  </button>
                ) : (
                  <div>
                    <h3 style={{ color: "white" }}>No More Results</h3>
                  </div>
                )}
              </div>
            )}
          </Tabs>
          {props.data.map((row, index) => (
            <TabPanel
              value={value}
              index={index}
              key={index}
              className="tabPanel cyberFont"
              id="contentTab"
            >
              {machineDetails &&
              machineDetails.includes(row._id) &&
              !loading ? (
                <div>
                  <h3 id="profileTitle" className="lightText credTitle bold">
                    <span className="titleIcon">
                      <FaUserSecret size={30} />
                    </span>
                    Profile
                  </h3>
                  <Table
                    size="small"
                    aria-label="more"
                    className="credsTable systemTable"
                  >
                    <TableHead>
                      <TableRow className="credsRow">
                        <TableCell className="profileTh">
                          <span>
                            <RiComputerLine size={20} className="credsIcons" />
                          </span>
                          Computer Name
                        </TableCell>
                        <TableCell className="profileTh">
                          <span>
                            <AiFillDatabase size={20} className="credsIcons" />
                          </span>
                          Operating System
                        </TableCell>
                        <TableCell className="profileTh">
                          <span>
                            <FaShieldAlt size={20} className="credsIcons" />
                          </span>
                          Anti Virus
                        </TableCell>
                        <TableCell className="profileTh">
                          <span>
                            <FaFacebook size={20} className="credsIcons" />
                          </span>
                          Facebook
                        </TableCell>
                        <TableCell className="profileTh">
                          <span>
                            <GiFamilyTree size={20} className="credsIcons" />
                          </span>
                          Virtualization
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    {machineData.length > 0 && (
                      <TableBody>
                        <TableRow className="credsRow credsTable">
                          {blur &&
                          row.censored &&
                          machineData[0].pc !== "Not Found" ? (
                            <TableCell className="bold">
                              <BlurryText value={machineData[0].pc} />
                            </TableCell>
                          ) : (
                            <TableCell className="bold">
                              {machineData[0].pc}
                            </TableCell>
                          )}
                          <TableCell className="bold">
                            {machineData[0].os}
                          </TableCell>
                          <TableCell className="bold">
                            {machineData[0].av}
                          </TableCell>
                          <TableCell className="bold">
                            {machineData[0].fb !== "Not Found" ? (
                              <button className="cardBtn">
                                <a
                                  rel="noreferrer"
                                  target="_blank"
                                  href={`https://facebook.com/${machineData[0].fb}`}
                                >
                                  View Profile
                                  <RiExternalLinkFill
                                    size={20}
                                    className="smIcons"
                                  />
                                </a>
                              </button>
                            ) : (
                              <button className="cardBtn grayBtn">
                                Not Found
                              </button>
                            )}
                          </TableCell>
                          <TableCell className="bold">
                            {machineData[0].tree ? (
                              <TreeView
                                className={classes.root + " bold"}
                                defaultExpanded={["1"]}
                                defaultCollapseIcon={<MinusSquare />}
                                defaultExpandIcon={
                                  <RiFolderOpenFill size={25} />
                                }
                                defaultEndIcon={<FaFile size={12} />}
                              >
                                <StyledTreeItem
                                  nodeId={1}
                                  label={<span>Directory</span>}
                                >
                                  <CustomizedTreeView
                                    data={machineData[0].tree}
                                  />
                                </StyledTreeItem>
                              </TreeView>
                            ) : (
                              <span className="bold">Not Found</span>
                            )}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )}
                  </Table>
                  {machineData.length > 0 && (
                    <>
                      <>
                        <h6 className="lightText">
                          <BsEyeFill size={25} /> Initial Detection:{" "}
                          <span>{machineData[0].firstSeen}</span>
                        </h6>
                      </>
                      {machineData[0].applications.length > 0 && (
                        <>
                          <h6 className="lightText">
                            <MdSettingsApplications size={25} /> Applications
                            found:{" "}
                            {machineData[0].applications.map((app, index) => (
                              <span
                                onClick={() => showApplication(app.name)}
                                className="yellow-chip cursor"
                                key={index}
                              >
                                {app.name}
                              </span>
                            ))}
                            {machineData[0].applications.find(
                              (app) => app.ai_scenarios
                            ) && (
                              <div
                                onClick={() =>
                                  showApplications(machineData[0].applications)
                                }
                                className="purple-chip cursor"
                                style={{
                                  color: "white",
                                  borderRadius: "20px",
                                }}
                              >
                                <GiArtificialIntelligence size={25} /> AI Attack
                                Scenarios →
                              </div>
                            )}
                          </h6>
                        </>
                      )}
                      {machineData &&
                        machineData[0].installed_software &&
                        machineData[0].installed_software.length > 0 && (
                          <>
                            <h6 className="lightText">
                              <RiInstallFill size={25} /> Installed software:{" "}
                              <InstalledSoftware
                                data={machineData[0].installed_software}
                              />
                              <span
                                onClick={() => showSoftware()}
                                className="cursor"
                                style={{
                                  fontSize: "14px",
                                  marginLeft: "10px",
                                  // color: "#FBCD4E",
                                  color: "#222831",
                                  backgroundColor: "lightgray",
                                  padding: "5px 10px",
                                  borderRadius: "20px",
                                }}
                              >
                                View →
                              </span>
                              {<br />}
                            </h6>
                          </>
                        )}
                      {machineData &&
                        machineData[0].password_reuse &&
                        machineData[0].password_reuse != "" && (
                          <>
                            <h6 className="lightText">
                              <MdOutlineWarning size={25} />{" "}
                              {machineData[0].password_reuse}
                            </h6>
                          </>
                        )}
                      {machineData[0] &&
                        machineData[0].top_logins &&
                        machineData[0].top_logins.length > 0 && (
                          <div
                            style={{
                              color: "black",
                              fontSize: "13px",
                            }}
                          >
                            <div
                              style={{
                                display: "inline-flex",
                                justifyContent: "center",
                                alignItems: "center",
                                marginBottom: "10px",
                              }}
                            >
                              <FaUserCircle size={20} className="credsIcons" />
                              <h6 style={{ marginBottom: "0px" }}>
                                Most Common Logins:
                              </h6>
                            </div>
                            <div
                              style={{
                                display: "inline-flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              {machineData[0].top_logins.map((i) => (
                                <div
                                  style={{
                                    backgroundColor: "#222831",
                                    color: "#FBCD4E",
                                    borderRadius: "20px",
                                    padding: "4px 15px",
                                    margin: "0px 5px",
                                    display: "inline-flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                  }}
                                >
                                  {blur && row.censored ? (
                                    <div>
                                      {i.login.includes("@") ? (
                                        <span>
                                          <BlurryText
                                            value={i.login.split("@")[0]}
                                          />
                                          @{i.login.split("@")[1]}
                                        </span>
                                      ) : (
                                        <BlurryText value={i.login} />
                                      )}
                                    </div>
                                  ) : (
                                    <div>{i.login}</div>
                                  )}
                                </div>
                              ))}
                            </div>
                          </div>
                        )}
                    </>
                  )}
                  <br />
                  <br />
                  {!viewLoading ? (
                    <>
                      <h3 className="lightText credTitle bold">
                        <span className="titleIcon">
                          <FaUnlockAlt size={30} />
                        </span>
                        {!externalView
                          ? `Corporate Credentials Found: ${machineData.length}`
                          : `Third Party Credentials Found: ${machineData.length}`}
                      </h3>
                      <Table
                        size="small"
                        aria-label="more"
                        className="credsTable"
                      >
                        <TableHead>
                          <TableRow className="credsRow">
                            <TableCell className="bold">
                              <span>
                                <GiWireframeGlobe
                                  size={20}
                                  className="credsIcons"
                                />
                              </span>
                              URL
                            </TableCell>
                            <TableCell className="bold">
                              <span>
                                <FaUserCircle
                                  size={20}
                                  className="credsIcons"
                                />
                              </span>
                              Login
                            </TableCell>
                            <TableCell className="bold">
                              <span>
                                <RiLockPasswordFill
                                  size={20}
                                  className="credsIcons"
                                />
                              </span>
                              Password
                            </TableCell>
                            <TableCell className="bold">
                              <span>
                                <GiSherlockHolmes
                                  size={20}
                                  className="credsIcons"
                                />
                              </span>
                              Password Strength
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {machineData.map((item, index) => (
                            <TableRow
                              className="credsRow credsTable"
                              key={index}
                            >
                              <TableCell className="bold">{item.url}</TableCell>
                              {blur && row.censored ? (
                                <TableCell className="bold">
                                  {item.login.includes("@") ? (
                                    <span>
                                      <BlurryText
                                        value={item.login.split("@")[0]}
                                      />
                                      @{item.login.split("@")[1]}
                                    </span>
                                  ) : item.login === "Not Found" ? (
                                    <div>
                                      {item.suggested_login ? (
                                        <>
                                          <Tooltip title="No login found in this credential, this login has the highest probability.">
                                            <span
                                              style={{
                                                textDecoration: "underline",
                                              }}
                                            >
                                              Suggested Login ↓
                                            </span>
                                          </Tooltip>
                                          <br />
                                          {item.suggested_login.includes(
                                            "@"
                                          ) ? (
                                            <span>
                                              <BlurryText
                                                value={
                                                  item.suggested_login.split(
                                                    "@"
                                                  )[0]
                                                }
                                              />
                                              @
                                              {
                                                item.suggested_login.split(
                                                  "@"
                                                )[1]
                                              }
                                            </span>
                                          ) : (
                                            <BlurryText
                                              value={item.suggested_login}
                                            />
                                          )}
                                        </>
                                      ) : (
                                        <span>Not Found</span>
                                      )}
                                    </div>
                                  ) : (
                                    <BlurryText value={item.login} />
                                  )}
                                </TableCell>
                              ) : (
                                <TableCell className="bold">
                                  {item.login === "Not Found" ? (
                                    item.suggested_login ? (
                                      <>
                                        <Tooltip title="No login found in this credential, this login has the highest probability.">
                                          <span
                                            style={{
                                              textDecoration: "underline",
                                            }}
                                          >
                                            Suggested Login ↓
                                          </span>
                                        </Tooltip>
                                        <br />
                                        {item.suggested_login}
                                      </>
                                    ) : (
                                      <span>Not Found</span>
                                    )
                                  ) : (
                                    item.login
                                  )}
                                </TableCell>
                              )}
                              <TableCell className="bold">
                                {item.blurPasswords ? (
                                  <span
                                    style={{
                                      backgroundColor: "gray",
                                      borderRadius: "20px",
                                      padding: "4px 10px 4px 0px",
                                    }}
                                  >
                                    <Tooltip
                                      title={
                                        "Passwords remain blurred after an unlock"
                                      }
                                    >
                                      <IconButton aria-label="data">
                                        <BsFillInfoCircleFill
                                          style={{
                                            fill: "#fecd4e",
                                            backgroundColor: "black",
                                            borderRadius: "100px",
                                            padding: "1px",
                                          }}
                                          size={16}
                                        />
                                      </IconButton>
                                    </Tooltip>

                                    <BlurryText value={item.password} />
                                  </span>
                                ) : (
                                  item.password
                                )}
                              </TableCell>
                              <TableCell
                                className={item.passStrength.class + " bold"}
                              >
                                {item.passStrength.value}
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </>
                  ) : (
                    <LoaderIcon type="spin" />
                  )}
                </div>
              ) : (
                <LoaderIcon
                  className="tableLoader"
                  type={"spin"}
                  style={{ marginTop: "20%" }}
                />
              )}
            </TabPanel>
          ))}
          {overlay && <Overlay content="unlock" charge={1} />}

          {open && (
            <SimpleModal
              halfHeight={modalState != "passwords"}
              halfWidth={modalState != "passwords"}
              open={open}
              handleClose={handleClose}
              bg={modalState === "applications" && "#222831"}
              borderRadius={modalState === "applications" && "10px"}
              html={
                modalState === "application"
                  ? applicationHtml
                  : modalState === "applications"
                  ? applicationsHtml
                  : softwareHtml
              }
            />
          )}
        </div>
      )}
    </>
  );
}
