import React, { useState } from "react";
import Button from "@material-ui/core/Button";
// import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import CreatePlan from "./CreatePlan";
import { publicAddress } from "../../Config";

export default function FormDialog(props) {
  return (
    <div>
      <Dialog
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="form-dialog-title"
        className="adminModal"
      >
        <DialogTitle id="form-dialog-title" className="lightText">
          {props.actionText}
        </DialogTitle>
        {props.subject != "plan" ? (
          <DialogContent>
            <DialogContentText className="lightText">
              <ul className="domainList">
                {props.selectedRows.map((row, index) =>
                  props.subject === "sub" ? (
                    <li key={index}>
                      {row.subscription && row.subscription.subscriptionId}
                    </li>
                  ) : props.action === "add-credits" ? (
                    <div key={index}>
                      <input
                        type="number"
                        onChange={(e) => props.setNewCredits(e.target.value)}
                        id="blackTextInput"
                        value={props.newCredits}
                      />
                      <li style={{ textTransform: "lowercase" }} key={index}>
                        {row.email}
                      </li>
                    </div>
                  ) : (
                    <li style={{ textTransform: "lowercase" }} key={index}>
                      {row.email}
                    </li>
                  )
                )}
              </ul>
            </DialogContentText>
          </DialogContent>
        ) : (
          <>
            {props.action === "create" ? (
              <>
                {props.createdPlan ? (
                  <a
                    href={`${publicAddress}/checkout?plan=${props.createdPlan.planId}`}
                    target="_blank"
                    style={{ textAlign: "center" }}
                  >
                    {`${publicAddress}/checkout?plan=${props.createdPlan.planId}`}
                  </a>
                ) : (
                  <CreatePlan
                    newPlan={props.newPlan}
                    loadNewPlan={props.loadNewPlan}
                  />
                )}
              </>
            ) : (
              <div style={{ padding: "20px" }}>
                {props.allPlans &&
                  props.allPlans.map((item) => (
                    <ul
                      className="lightText"
                      style={{
                        borderTop: "1px solid black",
                        paddingTop: "10px",
                        listStyle: "none",
                      }}
                    >
                      <li>ID: {item.planId}</li>
                      <li>NAME: {item.name}</li>
                      <li>
                        RECURRING CHARGE AMOUNT: {item.recurringChargeAmount}
                      </li>
                      <li>CHARGE FREQUENCY: {item.chargeFrequency}</li>
                    </ul>
                  ))}
              </div>
            )}
          </>
        )}

        {props.subject != "plan" && (
          <DialogActions>
            <Button onClick={props.handleClose} color="primary">
              Cancel
            </Button>
            <Button onClick={props.editRow} color="primary">
              Confirm
            </Button>
          </DialogActions>
        )}
      </Dialog>
    </div>
  );
}
