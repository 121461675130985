import React, { useEffect, useState } from "react";
import cavaLogo from "../../images/thumbnail.jpeg";
import { authAddress, blueSnapAddress, bsEnv } from "../../Config";
import axios from "axios";
import { RiGroup2Fill, RiSecurePaymentLine, RiEditFill } from "react-icons/ri";
import { BsUnlockFill } from "react-icons/bs";
import { GiTwoCoins } from "react-icons/gi";
import ChangePass from "./ChangePass";
import CreateApiClient from "../../API";
import LoaderIcon from "react-loader-icon";
import RegisterUser from "./RegisterUser";
import Modal from "./Modal";
import Overlay from "./Overlay";
import { CheckUserSession } from "../../hooks/CheckUserSession";
import Button from "@material-ui/core/Button";
import Icon from "@material-ui/core/Icon";
import logo from "../../images/logo-1.png";
import { FaCreditCard, FaRegCalendarAlt } from "react-icons/fa";
import Billing from "./Billing";

const api = CreateApiClient();

export default function Profile(props) {
  const [user, setUser] = useState([]);
  const [sub, setSub] = useState([]);
  const [tier, setTier] = useState(null);
  const [oldPassword, setOldPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [title, setTitle] = useState("");
  const [tel, setTel] = useState("");
  const [pageLoading, setPageLoading] = useState(false);
  // Error States:
  const [fNameError, setFnameError] = useState(false);
  const [lNameError, setLnameError] = useState(false);
  const [telError, setTelError] = useState(false);
  const [titleError, setTitleError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loader, setLoader] = useState(null);
  const [alertEmail, setAlertEmail] = useState("");
  const [alertEmailError, setAlertEmailError] = useState("");
  const [planId, setPlanId] = useState(0);
  const [updateType, setUpdateType] = useState("");
  const [open, setOpen] = useState(false);
  const [subject, setSubject] = useState("");
  const [shopper, setShopper] = useState("");
  const [overlay, setOverlay] = useState("");
  const [finish, setFinish] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState("");
  const [resetLoader, setResetLoader] = useState(false);
  const [addCreditCardLoading, setAddCardLoading] = useState(false);
  const [removeCreditCardLoading, setRemoveCardLoading] = useState(null);
  const [primaryLoading, setPrimaryLoading] = useState(null);
  const [newCC, setNewCC] = useState("");
  const [newExp, setNewExp] = useState("");
  const [newCvv, setNewCvv] = useState("");
  const [editCC, setEditCC] = useState(false);

  const basic_plans = [2741315, 2741325, 3332681, 3332639];
  const advanced_plans = [
    2801843, 2801841, 4025330, 4013076, 3763056, 3346067, 3763058, 3346197,
  ];
  const enterprisePlan = 1;

  const config = {
    headers: {
      "auth-token": props.user.token,
    },
  };

  const onChangeNewCC = (e) => {
    setNewCC(e.target.value);
  };

  const onChangeNewExp = (e) => {
    setNewExp(e.target.value);
  };

  const onChangeNewCvv = (e) => {
    setNewCvv(e.target.value);
  };

  // const saveCC = async () => {
  //   if (newCC && newExp && newCvv) {
  //   }
  // };

  const finishSubmission = () => {
    setSuccess(true);
    setTimeout(() => {
      window.location.reload();
    }, 2000);
  };
  const submit = async () => {
    setLoading(true);
    const body = {
      email: user.email,
      first_name: firstName ? firstName : user.first_name,
      last_name: lastName ? lastName : user.last_name,
      title: title ? title : user.title,
      tel: tel ? tel : user.tel,
    };

    validateFields(body);

    if (
      oldPassword.length > 0 &&
      newPassword.length > 0 &&
      confirmPassword.length > 0
    ) {
      changePassword(body);
    } else {
      const response = await api.editUser(
        "edit-user?password=false",
        body,
        config
      );
      response === "OK" ? finishSubmission() : setError("Something went wrong");
    }
    setLoading(false);
  };

  const handleChange = (e) => {
    setFnameError(false);
    setLnameError(false);
    setTelError(false);
    setTitleError(false);
    setAlertEmailError("");
    const name = e.target.name;
    const value = e.target.value;
    switch (name) {
      case "first_name":
        setFirstName(value);
        break;
      case "last_name":
        setLastName(value);
        break;
      case "title":
        setTitle(value);
        break;
      case "tel":
        setTel(value);
        break;
      case "alertEmail":
        setAlertEmail(value);
        break;
    }
  };

  const onChangeOldPass = (e) => {
    e.preventDefault();
    setOldPassword(e.target.value);
  };

  const onChangeConfirmPass = (e) => {
    e.preventDefault();
    setConfirmPassword(e.target.value);
  };

  const onChangeNewPass = (e) => {
    e.preventDefault();
    setNewPassword(e.target.value);
  };

  const changePassword = async (body) => {
    setError("");
    const strongRegex = new RegExp(
      "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})"
    );

    if (newPassword === confirmPassword) {
      const data = {
        first_name: body.first_name,
        last_name: body.last_name,
        title: body.title,
        tel: body.tel,
        logEmail: user.email,
        oldPassword: oldPassword,
        newPassword: newPassword,
        confirmPassword: confirmPassword,
      };

      const config = {
        headers: {
          "auth-token": props.user.token,
        },
      };
      if (strongRegex.test(newPassword)) {
        const response = await api.editUser(
          "edit-user?password=true",
          data,
          config
        );
        response === "OK"
          ? finishSubmission()
          : setError("Something went wrong");
      } else {
        setError(
          "Password must be at least 8 character long and contain at least one special character, one lowercase character, one uppercase character, and one number"
        );
      }
    } else {
      setError("Passwords Do Not Match");
    }
  };
  const defaultSrc = (ev) => {
    ev.target.src = cavaLogo;
  };

  useEffect(() => {
    if (CheckUserSession(props.user)) {
      getMyUser();
    } else {
      props.handleHomeLogOut();
    }
  }, []);

  const getMyUser = async (noLoading) => {
    if (!noLoading) {
      setPageLoading(true);
    }
    await axios
      .post(
        `${authAddress}/get-my-user?env=${bsEnv}&shopper=${
          props.user.sub && props.user.sub.vaultedShopperId
        }`,
        {},
        config
      )
      .then((res) => {
        props.resetUser(res.data.user);
        setUser(res.data.user);
        if (res.data.sub) {
          let tempTier;
          setSub(res.data.sub);
          if (basic_plans.includes(res.data.sub.plan_id)) {
            tempTier = "basic";
          } else if (advanced_plans.includes(res.data.sub.plan_id)) {
            tempTier = "advanced";
          } else if (res.data.sub.plan_id === enterprisePlan) {
            tempTier = "Enterprise";
          }
          setTier(tempTier);
          setPlanId(res.data.sub.plan_id);
        }
      })
      .catch((err) => console.log(err));
    setPageLoading(false);
  };

  const counters = [
    {
      name: <h5>Credits Balance: </h5>,
      icon: <GiTwoCoins size={30} style={{ margin: "-2px 5px 0px 0px" }} />,
      count: user.credits,
    },
    {
      name: <h5>Unlocked Data: </h5>,
      icon: <BsUnlockFill size={30} style={{ margin: "-2px 5px 0px 0px" }} />,
      count: user.unlocked_content && user.unlocked_content.length,
    },
    {
      name: <h5>Total Prospects: </h5>,
      icon: <RiGroup2Fill size={30} style={{ margin: "-2px 5px 0px 0px" }} />,
      count: user.prospects && user.prospects.length,
    },
  ];

  const validateFields = (body) => {
    setFnameError(isValidName(body.first_name) ? false : true);
    setLnameError(isValidName(body.last_name) ? false : true);
    setTelError(isValidNumber(body.tel) ? false : true);
    setTitleError(isValidName(body.title) ? false : true);
  };

  const isValidNumber = (number) => {
    return /^[0-9]+$/.test(number);
  };

  const isValidName = (name) => {
    return name.length > 2 && /^[_A-z0-9]*((-|\s)*[_A-z0-9])*$/.test(name);
  };

  const removeAlertEmail = async (e, type) => {
    setLoader(e.target.value);
    const body = {
      email: e.target.value,
    };
    await axios
      .post(`${authAddress}/remove-alert-email`, body, config)
      .then((res) => {
        getMyUser();
      })
      .catch((err) => console.log(err));
    setLoader(null);
  };

  const addAlertEmail = async () => {
    const body = {
      email: alertEmail,
    };
    await axios
      .post(`${authAddress}/add-alert-email`, body, config)
      .then((res) => {
        getMyUser();
      })
      .catch((error) => setAlertEmailError(error.response.data));
    setAlertEmail("");
  };

  const updateSubscription = async (cvv, action) => {
    let body;
    setFinish(false);
    setOverlay(action);
    if (action === "upgrade") {
      body = {
        shopper: shopper,
        cvv: cvv,
      };
    } else if (action === "downgrade") {
      body = {
        selectedPlan: selectedPlan,
      };
    } else if (action === "end-trial") {
      body = {
        selectedPlan: selectedPlan,
      };
    }
    await axios
      .post(
        `${blueSnapAddress}/subscription/update?action=${action}&sub=${sub.subscriptionId}&env=${bsEnv}`,
        body,
        config
      )
      .then((res) => {
        getMyUser();
        // setSub(res.data);
        // props.resetSub(res.data);
        // let tempTier;

        // if (basic_plans.includes(res.data.plan_id)) {
        //   tempTier = "basic";
        // } else if (advanced_plans.includes(res.data.sub.plan_id)) {
        //   tempTier = "advanced";
        // } else if (res.data.sub.plan_id === enterprisePlan) {
        //   tempTier = "Enterprise";
        // }
        // setTier(tempTier);
        // setPlanId(res.data.plan_id);
      })
      .catch((err) => console.log(err));
    setFinish(true);

    setTimeout(() => {
      setOverlay("");
      handleClose();
    }, 2000);
  };

  const handleClickOpen = async (subject, action) => {
    setSubject(subject);
    setUpdateType(action);
    if (action === "upgrade") {
      setLoader(sub.vaultedShopperId);
      await axios
        .post(
          `${blueSnapAddress}/custom-checkout?action=${action}&sub=${sub.subscriptionId}&shopper=${sub.vaultedShopperId}&env=${bsEnv}`,
          {},
          config
        )
        .then((res) => setShopper(res.data))
        .catch((err) => {
          if (err.response && err.response.data) {
            if (err.response.data === "Invalid Token") {
              props.handleHomeLogOut();
            } else {
              console.log(err.response.data);
            }
          } else {
            console.log(err);
          }
        });
    } else if (action === "downgrade") {
      setLoader("downgrade");
      await axios
        .post(
          `${blueSnapAddress}/plans/get-one?sub=${sub.subscriptionId}&action=downgrade&env=${bsEnv}`,
          {},
          config
        )
        .then((res) => setSelectedPlan(res.data))
        .catch((err) => {
          if (err.response && err.response.data) {
            if (err.response.data === "Invalid Token") {
              props.handleHomeLogOut();
            } else {
              console.log(err.response.data);
            }
          } else {
            console.log(err);
          }
        });
    } else if (action === "end-trial") {
      setLoader("end-trial");
      await axios
        .post(
          `${blueSnapAddress}/plans/get-one?sub=${sub.subscriptionId}&action=end-trial&env=${bsEnv}`,
          {},
          config
        )
        .then((res) => {
          console.log(res.data);
          setSelectedPlan(res.data);
        })
        .catch((err) => {
          if (err.response && err.response.data) {
            if (err.response.data === "Invalid Token") {
              props.handleHomeLogOut();
            } else {
              console.log(err.response.data);
            }
          } else {
            console.log(err);
          }
        });
    }
    setLoader(null);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const deleteUser = async (email) => {
    setLoader(email);
    await axios
      .post(`${authAddress}/delete`, { email: email }, config)
      .then((res) => {
        setUser(res.data.user);
        if (res.data.sub) {
          let tempTier;
          setSub(res.data.sub);
          if (basic_plans.includes(res.data.sub.plan_id)) {
            tempTier = "basic";
          } else if (advanced_plans.includes(res.data.sub.plan_id)) {
            tempTier = "advanced";
          } else if (res.data.sub.plan_id === enterprisePlan) {
            tempTier = "Enterprise";
          }
          setTier(tempTier);
          setPlanId(res.data.sub.plan_id);
        }
      })
      .catch((err) => console.log(err.response.data));
    setLoader(null);
    handleClose();
  };

  const resetUser = async () => {
    setResetLoader(true);
    await axios
      .post(`${authAddress}/reset-user-data`, {}, config)
      .then((res) => {
        setUser(res.data);
        props.resetUser(res.data);
      })
      .catch((err) => console.log(err.response.data));

    setResetLoader(false);
  };

  const toggleUserActive = async (user) => {
    await axios
      .post(`${authAddress}/toggle-active`, { email: user.email }, config)
      .then((res) => {
        setUser(res.data.user);
        if (res.data.sub) {
          let tempTier;
          setSub(res.data.sub);
          if (basic_plans.includes(res.data.sub.plan_id)) {
            tempTier = "basic";
          } else if (advanced_plans.includes(res.data.sub.plan_id)) {
            tempTier = "advanced";
          } else if (res.data.sub.plan_id === enterprisePlan) {
            tempTier = "Enterprise";
          }
          setTier(tempTier);
          setPlanId(res.data.sub.plan_id);
        }
      })
      .catch((err) => console.log(err.response.data));
  };

  const addCreditCard = async (body) => {
    setAddCardLoading(true);
    await axios
      .post(
        `${blueSnapAddress}/shopper/add-credit-card?env=${bsEnv}`,
        body,
        config
      )
      .then(async (res) => {
        // await getMyUser(true);
        const card =
          res.data.paymentSources.creditCardInfo[
            res.data.paymentSources.creditCardInfo.length - 1
          ];
        changePrimaryCreditCard(card);
      })
      .catch((err) => {
        console.log(err);
        getMyUser(true);
        setAddCardLoading(false);
      });
  };

  const changePrimaryCreditCard = async (card) => {
    setPrimaryLoading(card.creditCard.cardLastFourDigits);
    const body = {
      creditCard: {
        cardLastFourDigits: card.creditCard.cardLastFourDigits,
        cardType: card.creditCard.cardType,
      },
    };
    await axios
      .post(
        `${blueSnapAddress}/subscription/change-primary-card?env=${bsEnv}`,
        body,
        config
      )
      .then(async (res) => {
        await getMyUser(true);
        setPrimaryLoading(null);
        setAddCardLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setPrimaryLoading(null);
        setAddCardLoading(false);
        getMyUser(true);
      });
  };

  const removeCard = async (card) => {
    const confirmed = window.confirm(
      `Are you sure you want to remove card ${card.creditCard.cardLastFourDigits}?`
    );
    if (confirmed) {
      setRemoveCardLoading(card.creditCard.cardLastFourDigits);
      const body = {
        firstName: card.billingContactInfo.firstName,
        lastName: card.billingContactInfo.lastName,
        paymentSources: {
          creditCardInfo: [
            {
              creditCard: {
                cardType: card.creditCard.cardType,
                cardLastFourDigits: card.creditCard.cardLastFourDigits,
              },
              status: "D",
            },
          ],
        },
      };

      await axios
        .post(
          `${blueSnapAddress}/shopper/remove-credit-card?env=${bsEnv}`,
          body,
          config
        )
        .then(async (res) => {
          await getMyUser(true);
          setRemoveCardLoading(null);
        })
        .catch((err) => {
          console.log(err);
          setRemoveCardLoading(null);
          getMyUser(true);
        });
    }
  };

  return (
    <div className="rounded bg-white mt-5" style={{ minHeight: "100vh" }}>
      {pageLoading ? (
        <div className="load-wrapper" style={{ minHeight: "100vh" }}>
          <LoaderIcon type={"spin"} className="tableLoader page-loader" />
        </div>
      ) : (
        <div className="row">
          <div className="col-md-3 border-right" style={{ minHeight: "100vh" }}>
            <div
              className="d-flex flex-column align-items-center text-center p-3 py-5 lightText"
              style={{ marginTop: "-50px" }}
            >
              {sub.owner === "beta@hudsonrock.com" ? (
                <img
                  className="mt-5"
                  style={{
                    border: "2px solid black",
                    borderRadius: "10px",
                    padding: "5px",
                  }}
                  width="100px"
                  src={logo}
                  alt="logo"
                />
              ) : (
                <img
                  className="mt-5"
                  style={{
                    border: "2px solid black",
                    borderRadius: "10px",
                    padding: "5px",
                  }}
                  width="100px"
                  src={`https://logo.clearbit.com/${user.company_website}`}
                  onError={defaultSrc}
                  alt="logo"
                />
              )}
              <span className="font-weight-bold capitalize">
                {user.first_name} {user.last_name}
              </span>
              <span className="text-black-50">{user.email}</span>
            </div>
            <div className="styledBox yellow-bg" style={{ margin: "auto" }}>
              <div style={{ display: "inline-grid" }}>
                {counters.map((counter, index) => (
                  <div
                    key={index}
                    className="counter lightText"
                    style={{
                      display: "inline-flex",
                      margin: "10px 10px",
                      // alignItems: "baseline",
                    }}
                  >
                    {counter.icon}
                    {counter.name}
                    <h5 style={{ marginLeft: "5px" }}>{counter.count}</h5>
                    {/* <CountUp
                  separator=","
                  start={0}
                  end={counter.count}
                  duration={2.75}
                /> */}
                    <br />
                    <br />
                  </div>
                ))}
              </div>
            </div>
            <br />
            {props.user.user.subscriptionId === 32200513 && (
              <Button
                type="button"
                variant="contained"
                className={"searchBtn desktopSearchBtn"}
                endIcon={
                  resetLoader ? (
                    <LoaderIcon
                      className="removeLoader tableLoader"
                      style={{
                        position: "relative",
                        top: "-10px",
                        right: "0px",
                      }}
                    />
                  ) : (
                    <Icon>refresh</Icon>
                  )
                }
                onClick={() => resetUser()}
              >
                Reset User
              </Button>
            )}
          </div>
          <div className="col-md-5 border-right" style={{ minHeight: "100vh" }}>
            <div className="p-3 py-5 text-left lightText">
              <div className="d-flex justify-content-between align-items-center mb-3">
                <h4 className="text-right lightText">Profile Settings</h4>
              </div>
              <div className="row mt-2">
                <div className="col-md-6">
                  <label className="labels">First Name</label>
                  <input
                    type="text"
                    className="form-control blackTextInput capitalize"
                    placeholder="First Name"
                    defaultValue={user.first_name}
                    name="first_name"
                    onChange={handleChange}
                  />
                  {fNameError && (
                    <p className="error text-left">Invalid first name.</p>
                  )}
                </div>
                <div className="col-md-6">
                  <label className="labels">Last Name</label>
                  <input
                    type="text"
                    className="form-control blackTextInput capitalize"
                    defaultValue={user.last_name}
                    placeholder="Last Name"
                    name="last_name"
                    onChange={handleChange}
                  />
                  {lNameError && (
                    <p className="error text-left">Invalid last name.</p>
                  )}
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-md-6">
                  <label className="labels">Job Title</label>
                  <input
                    type="text"
                    className="form-control blackTextInput capitalize"
                    placeholder="enter job title"
                    defaultValue={user.title}
                    name="title"
                    onChange={handleChange}
                  />
                  {titleError && (
                    <p className="error text-left">Invalid job title.</p>
                  )}
                </div>
                <div className="col-md-6">
                  <label className="labels">Mobile Number</label>
                  <input
                    type="tel"
                    className="form-control blackTextInput"
                    placeholder="Enter phone number"
                    defaultValue={user.tel}
                    name="tel"
                    onChange={handleChange}
                    minLength={8}
                  />
                  {telError && (
                    <p className="error text-left">Invalid mobile number.</p>
                  )}
                </div>
                <div style={{ width: "40%", float: "left" }}>
                  <h5 style={{ marginTop: "20px" }}>Change Password</h5>
                  <ChangePass
                    oldPassword={oldPassword}
                    onChangeOldPass={onChangeOldPass}
                    newPassword={newPassword}
                    onChangeNewPass={onChangeNewPass}
                    confirmPassword={confirmPassword}
                    onChangeConfirmPass={onChangeConfirmPass}
                    error={error}
                  />
                </div>
                {tier !== "basic" && (
                  <div
                    style={{ width: "60%", float: "right", margin: "auto" }}
                    className="styledBox gray-bg"
                  >
                    <div>
                      <h5 style={{ marginTop: "20px" }}>Alert Emails</h5>
                      <div className="col-md-12" style={{ float: "left" }}>
                        <label className="labels">New Email</label>
                        <div className="flex">
                          <input
                            type="tel"
                            className="form-control blackTextInput"
                            placeholder="New alert email"
                            name="alertEmail"
                            onChange={handleChange}
                            minLength={8}
                            value={alertEmail}
                          />
                          <button
                            onClick={() => addAlertEmail()}
                            type="submit"
                            className="iconBtn spaced"
                          >
                            +
                          </button>
                        </div>
                        <p className="error text-left">{alertEmailError}</p>
                        <ul className="domainList">
                          {user.alert_emails &&
                            user.alert_emails.map((email) => (
                              <li key={email}>
                                <>
                                  {email}
                                  <button
                                    className="remove"
                                    onClick={(e) =>
                                      removeAlertEmail(e, "email")
                                    }
                                    value={email}
                                  >
                                    {loader === email ? (
                                      <LoaderIcon
                                        className="tableLoader"
                                        style={{
                                          width: "24px",
                                          height: "24px",
                                        }}
                                      />
                                    ) : (
                                      <>X</>
                                    )}
                                  </button>
                                </>
                              </li>
                            ))}{" "}
                        </ul>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              {loading && <LoaderIcon style={{ margin: "0px" }} />}
              {success && !loading && (
                <h5 className="text-left green">Changes Successfully Saved</h5>
              )}
              <div className="text-left">
                <button
                  className="btn btn-primary profile-button searchBtn"
                  type="submit"
                  onClick={submit}
                >
                  Save Profile
                </button>
              </div>
            </div>
          </div>
          {props.user && props.user.user.role !== "admin" && (
            <div className="col-md-4">
              <div className="p-3 py-5 text-left lightText">
                <div className="col-md-12">
                  <div className="styledBox dark-bg" style={{ width: "100%" }}>
                    <div className="d-flex justify-content-between experience">
                      <h3>Subscription</h3>
                    </div>
                    <br />
                    {props.user.sub ? (
                      <>
                        <p className="capitalize spaced">
                          ID: {sub.subscriptionId}
                        </p>
                        {tier && (
                          <p className="capitalize spaced">tier: {tier}</p>
                        )}
                        <p className="capitalize spaced">seats: {sub.seats}</p>
                        <p className="capitalize spaced">
                          date joined:{" "}
                          {sub.date_added &&
                            new Date(sub.date_added)
                              .toString()
                              .split(" ")
                              .slice(0, 4)
                              .join(" ")}
                        </p>
                        {!sub.free_trial && (
                          <p className="capitalize spaced">
                            next billing date:{" "}
                            {sub.next_billing_date &&
                              new Date(sub.next_billing_date).getDate() +
                                "/" +
                                (new Date(sub.next_billing_date).getMonth() +
                                  1) +
                                "/" +
                                new Date(sub.next_billing_date).getFullYear()}
                          </p>
                        )}
                        <p className="spaced">Owner: {sub.owner}</p>
                        {sub.shopper && sub.raw && sub.raw.paymentSource && (
                          <Billing
                            shopper={sub.shopper}
                            rawSub={sub.raw}
                            submit={(body) => addCreditCard(body)}
                            loading={addCreditCardLoading}
                            primaryLoading={primaryLoading}
                            setPrimary={(card) => changePrimaryCreditCard(card)}
                            removeCard={(card) => removeCard(card)}
                            removeCreditCardLoading={removeCreditCardLoading}
                          />
                        )}
                      </>
                    ) : (
                      !props.user.user.credits > 0 && (
                        <Button
                          type="button"
                          variant="contained"
                          className={"searchBtn desktopSearchBtn"}
                          endIcon={
                            props.topUpLoader ? (
                              <LoaderIcon
                                className="removeLoader tableLoader"
                                style={{
                                  position: "relative",
                                  top: "-10px",
                                  right: "0px",
                                }}
                              />
                            ) : (
                              <Icon>send</Icon>
                            )
                          }
                          onClick={() => props.open()}
                        >
                          Get your first 10 credits
                        </Button>
                      )
                    )}
                  </div>
                  <br />
                  {sub.owner === user.email &&
                    sub.plan_id != enterprisePlan &&
                    sub.seats > 1 && (
                      <>
                        <RegisterUser
                          user={user}
                          sub={sub}
                          token={props.user.token}
                        />
                        {sub.users &&
                          sub.users.filter((s) => s !== sub.owner).length >
                            0 && (
                            <>
                              <h5>Users:</h5>
                              <ul className="domainList">
                                {sub.child_users.map((user) => (
                                  <li key={user.email}>
                                    <>
                                      {user.email}
                                      <button
                                        className="remove"
                                        onClick={() =>
                                          handleClickOpen("user", user.email)
                                        }
                                        value={user.email}
                                      >
                                        {loader === user.email ? (
                                          <LoaderIcon
                                            className="removeLoader tableLoader"
                                            style={{
                                              margin: "auto",
                                              position: "relative",
                                              right: "0px",
                                              top: "0px",
                                            }}
                                          />
                                        ) : (
                                          "X"
                                        )}
                                      </button>
                                      <span
                                        style={{
                                          marginLeft: "20px",
                                          color: user.active ? "green" : "red",
                                        }}
                                        className="cursor"
                                        onClick={() => toggleUserActive(user)}
                                      >
                                        {user.active ? "Active" : "Inactive"}
                                      </span>
                                    </>
                                  </li>
                                ))}
                              </ul>
                            </>
                          )}
                      </>
                    )}
                  {props.user.sub && props.user.sub.status != "pending" && (
                    <Button
                      type="button"
                      variant="contained"
                      className={"searchBtn desktopSearchBtn"}
                      endIcon={
                        props.topUpLoader ? (
                          <LoaderIcon
                            className="removeLoader tableLoader"
                            style={{
                              position: "relative",
                              top: "-10px",
                              right: "0px",
                            }}
                          />
                        ) : (
                          <Icon>send</Icon>
                        )
                      }
                      onClick={() => props.open()}
                    >
                      Top-Up Credits
                    </Button>
                  )}

                  {user.email === sub.owner && (
                    <div
                      style={{
                        position: "absolute",
                        top: "160px",
                        right: "25px",
                      }}
                    >
                      {/* {loader === "downgrade" ? (
                        <LoaderIcon
                          className="removeLoader tabsLoader"
                          style={{
                            position: "relative",
                            bottom: "0px",
                            right: "0px",
                            top: "-5px",
                            fontSize: "14px",
                          }}
                        />
                      ) : tier === "advanced" && sub.auto_renew ? (
                        <button
                          className="btn btn-primary profile-button searchBtn"
                          style={{
                            display: "block",
                            width: "200px",
                            margin: "8px auto",
                            fontSize: "14px",
                          }}
                          onClick={() =>
                            handleClickOpen("subscription", "downgrade")
                          }
                        >
                          Downgrade Subscription
                        </button>
                      ) : loader === sub.vaultedShopperId ? (
                        <LoaderIcon
                          className="removeLoader tabsLoader"
                          style={{
                            position: "relative",
                            bottom: "0px",
                            right: "0px",
                            top: "-5px",
                            fontSize: "14px",
                          }}
                        />
                      ) : (
                        tier === "basic" &&
                        sub.auto_renew && (
                          <button
                            className="btn btn-primary profile-button searchBtn"
                            style={{
                              display: "block",
                              width: "200px",
                              margin: "8px auto",
                              fontSize: "14px",
                            }}
                            onClick={() =>
                              handleClickOpen("subscription", "upgrade")
                            }
                          >
                            Upgrade Subscription
                          </button>
                        )
                      )} */}
                      {sub.free_trial &&
                        sub.plan_id != enterprisePlan &&
                        props.user.sub.status != "pending" && (
                          <button
                            className="btn btn-primary profile-button searchBtn"
                            style={{
                              display: "inline-flex",
                              justifyContent: "center",
                              width: "200px",
                              margin: "8px auto",
                              fontSize: "14px",
                            }}
                            onClick={() =>
                              handleClickOpen("subscription", "end-trial")
                            }
                          >
                            <span>End Trial</span>
                            {loader === "end-trial" && (
                              <LoaderIcon
                                type="spin"
                                className="removeLoader tableLoader"
                                style={{
                                  margin: "0px 5px",
                                  position: "relative",
                                  bottom: "0px",
                                  right: "0px",
                                  top: "-1px",
                                  fontSize: "14px",
                                }}
                              />
                            )}
                          </button>
                        )}
                      {sub.auto_renew && sub.plan_id != enterprisePlan ? (
                        <button
                          className="btn btn-primary profile-button searchBtn"
                          style={{
                            display: "block",
                            width: "200px",
                            margin: "8px auto",
                            fontSize: "14px",
                          }}
                          onClick={() =>
                            handleClickOpen("subscription", "cancel")
                          }
                        >
                          Cancel Subscription
                        </button>
                      ) : (
                        sub.auto_renew === false && (
                          <button
                            className="btn btn-primary profile-button searchBtn"
                            style={{
                              display: "block",
                              width: "200px",
                              margin: "8px auto",
                              fontSize: "14px",
                            }}
                            onClick={() =>
                              handleClickOpen("subscription", "renew")
                            }
                          >
                            Renew Subscription
                          </button>
                        )
                      )}
                      {/* <button
                        className="btn btn-primary profile-button searchBtn"
                        style={{
                          display: "block",
                          width: "200px",
                          margin: "8px auto",
                          fontSize: "14px",
                        }}
                        onClick={() => setEditCC(!editCC)}
                      >
                        Edit Payment Source
                      </button> */}
                      {/* {editCC && (
                        <div className="changeCCModal">
                          <div className="changeCC">
                            <h6>
                              Edit Payment Source
                            </h6>
                            <div>
                              <div style={{ padding: "5px" }}>
                                <FaCreditCard size={20} /> CC
                              </div>
                              <div>
                                {editCC ? (
                                  <input
                                    placeholder="New CC"
                                    required
                                    minLength={3}
                                    maxLength={3}
                                    value={newCC}
                                    onChange={onChangeNewCC}
                                  ></input>
                                ) : (
                                  sub &&
                                  sub.shopper && (
                                    <span style={{ float: "right" }}>
                                      {"************"}
                                      {
                                        sub.shopper.paymentSources
                                          .creditCardInfo[0].creditCard
                                          .cardLastFourDigits
                                      }
                                    </span>
                                  )
                                )}
                              </div>
                            </div>
                            <div>
                              <div style={{ padding: "5px" }}>
                                <FaRegCalendarAlt size={20} /> Exp
                              </div>
                              <div>
                                {editCC ? (
                                  <input
                                    placeholder="New expiration date"
                                    required
                                    minLength={3}
                                    maxLength={3}
                                    value={newExp}
                                    onChange={onChangeNewExp}
                                  ></input>
                                ) : (
                                  sub &&
                                  sub.shopper && (
                                    <span style={{ float: "right" }}>
                                      {
                                        sub.shopper.paymentSources
                                          .creditCardInfo[0].creditCard
                                          .expirationMonth
                                      }
                                      /
                                      {
                                        sub.shopper.paymentSources
                                          .creditCardInfo[0].creditCard
                                          .expirationYear
                                      }
                                    </span>
                                  )
                                )}
                              </div>
                            </div>
                            <div>
                              <div style={{ padding: "5px" }}>
                                <RiSecurePaymentLine size={20} /> CVV
                              </div>
                              <div>
                                {editCC ? (
                                  <input
                                    placeholder="New CVV"
                                    required
                                    minLength={3}
                                    maxLength={3}
                                    value={newCvv}
                                    onChange={onChangeNewCvv}
                                  ></input>
                                ) : (
                                  <span style={{ float: "right" }}>***</span>
                                )}
                              </div>
                            </div>
                            <button
                        className="btn btn-primary profile-button searchBtn"
                        style={{
                          display: "block",
                          width: "200px",
                          margin: "8px auto",
                          fontSize: "14px",
                        }}
                        onClick={() => saveCC()}
                      >
                        Save Payment Source
                      </button>
                          </div>
                        </div>
                      )} */}
                      {overlay === "end-trial" && (
                        <Overlay
                          title="Your subscription is being updated..."
                          content="Please do not leave or refresh the page."
                          successTitle="Your trial has been successfully ended"
                          successContent=""
                          finish={finish}
                        />
                      )}
                      {overlay === "upgrade" && (
                        <Overlay
                          title="Payment is being processed..."
                          content="Please do not leave or refresh the page."
                          successTitle="Your subscription has been successfully upgraded"
                          successContent=""
                          finish={finish}
                        />
                      )}
                      {overlay === "downgrade" && (
                        <Overlay
                          title="Your tier is being changed..."
                          content="Please do not leave or refresh the page."
                          successTitle="Your subscription has been successfully downgraded"
                          successContent=""
                          finish={finish}
                        />
                      )}
                      {overlay === "cancel" && (
                        <Overlay
                          title="Canceling subscription"
                          content="Please do not leave or refresh the page."
                          successTitle="Your subscription has been successfully canceled"
                          successContent=""
                          finish={finish}
                        />
                      )}
                      {overlay === "renew" && (
                        <Overlay
                          title="Activating subscription..."
                          content="Please do not leave or refresh the page."
                          successTitle="Your subscription has been successfully activated"
                          successContent=""
                          finish={finish}
                        />
                      )}
                      <Modal
                        selectedPlan={selectedPlan}
                        user={user}
                        sub={sub}
                        shopper={shopper}
                        planId={planId}
                        open={open}
                        handleClickOpen={handleClickOpen}
                        handleClose={handleClose}
                        confirm={true}
                        // confirm={
                        //   sub.users && sub.users.length > 2 ? false : true
                        // }
                        subject={subject}
                        type={updateType}
                        subExpiry={
                          new Date(sub.next_billing_date).getDate() +
                          "/" +
                          (new Date(sub.next_billing_date).getMonth() + 1) +
                          "/" +
                          new Date(sub.next_billing_date).getFullYear()
                        }
                        // .split(" ")
                        // .slice(0, 6)
                        // .join(" ")}
                        func={(cvv) =>
                          subject !== "user"
                            ? updateSubscription(cvv, updateType)
                            : deleteUser(updateType)
                        }
                        userTitle={`You are about to delete ${subject} ${updateType} and all of his data, are you sure?`}
                        title={
                          updateType === "end-trial"
                            ? `End your trial and get 40 credits now`
                            : `${updateType} ${subject}`
                        }
                      />
                    </div>
                  )}
                </div>
                <br />
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
}
