import React from "react";
import "react-sweet-progress/lib/style.css";
import "../../styles/Loader.css";
import logo from "../../images/hrlogo_new.png";

export default function Container(props) {
  return (
    <div className="loadingBar" style={{ minHeight: "100vh" }}>
      <div className={`newLoader ${props.classes}`}></div>
      <div className="svg-container">
        <img src={logo} style={{ width: "250px" }} />
      </div>
      {/* <div className="loader"></div> */}
      {!props.csv ? (
        <div style={{ textAlign: "center" }}>
          {props.domain ? (
            <h2 className="loaderHeadline">
              Loading Data For {props.domain}...
            </h2>
          ) : (
            props.redirect && <h2 className="loaderHeadline">Redirecting...</h2>
          )}
        </div>
      ) : (
        <h2 className="loaderHeadline csvHeading">
          Preparing CSV File For {props.domain} {props.type}
        </h2>
      )}
    </div>
  );
}
