import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import logo from "../../images/logo-1.png";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import CountrySelector from "../Partials/CountrySelector";
import { useLocation } from "react-router";
import CheckoutTable from "./CheckoutTable";
import PaymentFields from "./PaymentFields";
import Footer from "../Partials/Footer";
import { createBrowserHistory } from "history";
import { authAddress, blueSnapAddress, bsEnv } from "../../Config";
import { ImCheckmark, ImTwitter } from "react-icons/im";
import Button from "@material-ui/core/Button";
import axios from "axios";
import LoaderIcon from "react-loader-icon";
import { isValidEmail } from "../../hooks/Validation";
// var perf = require("./PaymentFields.html");
const history = createBrowserHistory();

const queryString = require("query-string");

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },

  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
}));

export default function Checkout(props) {
  const classes = useStyles();
  const [price, setPrice] = useState();
  const [countryCode, setCountryCode] = useState("");
  const [fName, setFname] = useState("");
  const [lName, setLname] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [cName, setCname] = useState("");
  const [cWeb, setCweb] = useState("");
  const [tel, setTel] = useState("");
  const [title, setTitle] = useState("");
  const [userState, setUserState] = useState("");
  const [city, setCity] = useState("");
  const [address, setAddress] = useState("");
  const [address2, setAddress2] = useState("");
  const [zip, setZip] = useState("");
  const [tier, setTier] = useState();
  const [period, setPeriod] = useState();
  const [plan, setPlan] = useState();
  const [paid, setPaid] = useState(false);
  const [accept, setAccept] = useState(false);
  // Error States:
  const [fNameError, setFnameError] = useState(false);
  const [lNameError, setLnameError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [cNameError, setCnameError] = useState(false);
  const [cWebError, setCwebError] = useState(false);
  const [telError, setTelError] = useState(false);
  const [titleError, setTitleError] = useState(false);
  const [countryError, setCountryError] = useState(false);
  const [cityError, setCityError] = useState(false);
  const [addressError, setAddressError] = useState(false);
  const [switchTo, setSwitch] = useState("");
  const [initialCharge, setInitialCharge] = useState(0);

  const basic_monthly = ["2741315", "3332639"];
  const basic_annual = ["2741325", "3332681"];
  const advanced_monthly = ["2801843", "4013076"];
  const advanced_annual = ["2801841", "4025330"];
  const all_sb_plans = ["2741315", "2741325", "2801843", "2801841"];
  const all_prod_plans = ["3332639", "3332681", "4013076", "4025330"];

  const [loading, setLoading] = useState(false);
  const [qty, setQty] = useState(1);

  const location = useLocation();

  const planString = queryString.parse(location.search).plan;

  // useEffect(() => {
  //   window.location.reload();
  // }, [planString]);

  const fixPath = (plan) => {
    history.push(`/checkout/?plan=${plan}`);
    window.location.reload();
  };

  useEffect(() => {
    if (
      !planString ||
      (!all_prod_plans.includes(planString) &&
        !all_sb_plans.includes(planString))
    ) {
      fixPath(all_prod_plans[2]);
    }
    // if (bsEnv === "sandbox") {
    //   if (
    //     !planString ||
    //     (!all_prod_plans.includes(planString) &&
    //       !all_sb_plans.includes(planString))
    //   ) {
    //     fixPath(all_sb_plans[2]);
    //   }
    // }
    getPlan(planString);
  }, []);

  const getPlan = async (planString) => {
    setLoading(true);
    await axios
      .get(`${blueSnapAddress}/plans/get-plan?plan=${planString}&env=${bsEnv}`)
      .then((res) => {
        setInitialCharge(res.data.initialChargeAmount);
        setPrice(res.data.recurringChargeAmount);
        setPeriod(res.data.chargeFrequency.toLowerCase());
        setPlan(res.data.planId);
        if (basic_annual.includes(planString)) {
          setTier("basic");
          // setPeriod("annually");
          // setPrice(1000.0);
          setSwitch(3332639);
        } else if (basic_monthly.includes(planString)) {
          setTier("basic");
          // setPeriod("monthly");
          // setPrice(99.0);
          setSwitch(3332681);
        } else if (advanced_annual.includes(planString)) {
          setTier("professional");
          // setPeriod("annually");
          // setPrice(1500.0);
          setSwitch(4013076);
        } else if (advanced_monthly.includes(planString)) {
          setTier("professional");
          // setPeriod("monthly");
          // setPrice(149.0);
          setSwitch(4025330);
        } else {
          setTier("professional");
        }
      })
      .catch((err) => {
        if (err.response && err.response.data) {
          if (err.response.data === "Invalid Token") {
            props.handleHomeLogOut();
          } else {
            console.log(err.response.data);
          }
        } else {
          console.log(err);
        }
      });
    setLoading(false);
  };

  const onSelect = (code) => setCountryCode(code);

  const onFieldChange = (e, field) => {
    // setTelError(null);
    e.preventDefault();
    const value = e.target.value;
    const states = [
      setFname,
      setLname,
      setEmail,
      setPassword,
      setCname,
      setCweb,
      setTel,
      setTitle,
      setUserState,
      setCity,
      setAddress,
      setAddress2,
      setZip,
    ];
    states.map((s, index) => {
      index + 1 === field && s(value);
    });
  };

  const validateFields = () => {
    setFnameError(fName ? false : true);
    setLnameError(lName ? false : true);
    setEmailError(isValidEmail(email) ? false : true);
    setPasswordError(password ? false : true);
    setCnameError(cName ? false : true);
    setCwebError(cWeb ? false : true);
    setTelError(isValidNumber(tel) ? false : true);
    setTitleError(title ? false : true);
    setCountryError(countryCode ? false : true);
    setCityError(city ? false : true);
    setAddressError(address ? false : true);
  };

  // const isValidEmail = (email) => {
  //   return /^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/.test(email);
  // };

  const isValidNumber = (number) => {
    return /^[0-9]+$/.test(number);
  };

  const success = () => {
    setPaid(true);
  };

  return !loading ? (
    <div>
      <div className={classes.grow}>
        <AppBar position="static">
          <Toolbar className="Appbar-header">
            <Link className="logoLink" to="/">
              <img
                src={logo}
                className="Appbar-logo"
                alt="logo"
                width="60"
                height="60"
              />
            </Link>
          </Toolbar>
        </AppBar>
      </div>
      {!paid ? (
        <>
          <div className="leftColCheckout" style={{ minHeight: "99vh" }}>
            <Form id="checkout-main-form">
              <h2 className="capitalize">
                Checkout: {tier} ({period}){" "}
                <span>
                  {(all_prod_plans.includes(planString) ||
                    all_sb_plans.includes(planString)) && (
                    <a
                      style={{ fontSize: "16px" }}
                      className="yellow"
                      href={`/checkout?plan=${switchTo}`}
                    >
                      Switch to {period === "monthly" ? "annual" : "monthly"}?
                    </a>
                  )}
                </span>
              </h2>
              <Row className="mb-3">
                <Form.Group as={Col} sm="6" controlId="formGridFname">
                  <Form.Label>
                    First Name <span>*</span>
                  </Form.Label>
                  <Form.Control
                    value={fName}
                    required
                    type="text"
                    placeholder="First Name"
                    onChange={(e) => onFieldChange(e, 1)}
                    className="textField"
                  />
                  {fNameError && !fName ? (
                    <div className="alert alert-danger mt-2">
                      First name is a required field.
                    </div>
                  ) : (
                    ""
                  )}
                </Form.Group>

                <Form.Group as={Col} sm="6" controlId="formGridLname">
                  <Form.Label>
                    Last Name <span>*</span>
                  </Form.Label>
                  <Form.Control
                    required
                    type="text"
                    placeholder="Last Name"
                    onChange={(e) => onFieldChange(e, 2)}
                    className="textField"
                    value={lName}
                  />
                  {lNameError && !lName ? (
                    <div className="alert alert-danger mt-2">
                      Last name is a required field.
                    </div>
                  ) : (
                    ""
                  )}
                </Form.Group>
              </Row>

              <Row className="mb-3">
                <Form.Group as={Col} sm="6" controlId="formGridEmail">
                  <Form.Label>
                    Email <span>*</span>
                  </Form.Label>
                  <Form.Control
                    required
                    type="email"
                    placeholder="Enter email"
                    value={email}
                    onChange={(e) => onFieldChange(e, 3)}
                  />
                  {emailError && !email ? (
                    <div className="alert alert-danger mt-2">
                      Email is a required field.
                    </div>
                  ) : email && emailError ? (
                    <div className="alert alert-danger mt-2">
                      Email is not valid.
                    </div>
                  ) : (
                    ""
                  )}
                </Form.Group>
                <Form.Group as={Col} sm="6" controlId="formGridPassword">
                  <Form.Label>
                    Password <span>*</span>
                  </Form.Label>
                  <Form.Control
                    required
                    type="password"
                    placeholder="Password"
                    onChange={(e) => onFieldChange(e, 4)}
                    value={password}
                  />
                  {passwordError && !password ? (
                    <div className="alert alert-danger mt-2">
                      Password is a required field.
                    </div>
                  ) : (
                    ""
                  )}
                </Form.Group>
              </Row>
              <Row className="mb-3">
                <Form.Group as={Col} sm="6" controlId="formGridCname">
                  <Form.Label>
                    Company Name <span>*</span>
                  </Form.Label>
                  <Form.Control
                    required
                    onChange={(e) => onFieldChange(e, 5)}
                    className="textField"
                    value={cName}
                  />
                  {cNameError && !cName ? (
                    <div className="alert alert-danger mt-2">
                      Company name is a required field.
                    </div>
                  ) : (
                    ""
                  )}
                </Form.Group>

                <Form.Group as={Col} sm="6" controlId="formGridCweb">
                  <Form.Label>
                    Company Website <span>*</span>
                  </Form.Label>
                  <Form.Control
                    required
                    onChange={(e) => onFieldChange(e, 6)}
                    value={cWeb}
                  />
                  {cWebError && !cWeb ? (
                    <div className="alert alert-danger mt-2">
                      Company website is a required field.
                    </div>
                  ) : (
                    ""
                  )}
                </Form.Group>
              </Row>
              <Row className="mb-3">
                <Form.Group as={Col} sm="6" controlId="formGridTel">
                  <Form.Label>
                    Telephone <span>*</span>
                  </Form.Label>
                  <Form.Control
                    required
                    type="tel"
                    placeholder="Enter Telephone"
                    onChange={(e) => onFieldChange(e, 7)}
                    value={tel}
                  />
                  {telError && !tel ? (
                    <div className="alert alert-danger mt-2">
                      Telephone is a required field.
                    </div>
                  ) : tel && telError ? (
                    <div className="alert alert-danger mt-2">
                      Telephone is not valid.
                    </div>
                  ) : tel && tel.length < 7 ? (
                    <div className="alert alert-danger mt-2">
                      Telephone is not valid.
                    </div>
                  ) : (
                    ""
                  )}
                </Form.Group>

                <Form.Group as={Col} sm="6" controlId="formGridTitle">
                  <Form.Label>
                    Title <span>*</span>
                  </Form.Label>
                  <Form.Control
                    required
                    onChange={(e) => onFieldChange(e, 8)}
                    className="textField"
                    value={title}
                  />
                  {titleError && !title ? (
                    <div className="alert alert-danger mt-2">
                      Title is a required field.
                    </div>
                  ) : (
                    ""
                  )}
                </Form.Group>
              </Row>
              <br />
              <h2>Billing</h2>
              <Row className="mb-3">
                <Form.Group as={Col} sm="4" controlId="formGridCountry">
                  <Form.Label>
                    Country <span>*</span>
                  </Form.Label>
                  <CountrySelector
                    onSelect={onSelect}
                    selected={countryCode}
                    value={countryCode}
                  />
                  {countryError && !countryCode ? (
                    <div className="alert alert-danger mt-2">
                      Country is a required field.
                    </div>
                  ) : (
                    ""
                  )}
                </Form.Group>
                <Form.Group as={Col} sm="4">
                  <Form.Label>State</Form.Label>
                  <Form.Control
                    onChange={(e) => onFieldChange(e, 9)}
                    placeholder="Your State"
                    className="textField"
                    value={userState}
                  />
                </Form.Group>
                <Form.Group as={Col} sm="4" controlId="formGridCity">
                  <Form.Label>
                    City <span>*</span>
                  </Form.Label>
                  <Form.Control
                    value={city}
                    required
                    onChange={(e) => onFieldChange(e, 10)}
                    className="textField"
                  />
                  {cityError && !city ? (
                    <div className="alert alert-danger mt-2">
                      City is a required field.
                    </div>
                  ) : (
                    ""
                  )}
                </Form.Group>
              </Row>
              <Row className="mb-3">
                <Form.Group as={Col} sm="4" controlId="formGridAddress1">
                  <Form.Label>
                    Address <span>*</span>
                  </Form.Label>
                  <Form.Control
                    required
                    placeholder="1234 Main St"
                    onChange={(e) => onFieldChange(e, 11)}
                    className="textField"
                    value={address}
                  />
                  {addressError && !address ? (
                    <div className="alert alert-danger mt-2">
                      Address is a required field.
                    </div>
                  ) : (
                    ""
                  )}
                </Form.Group>

                <Form.Group as={Col} sm="4" controlId="formGridAddress2">
                  <Form.Label>Address 2</Form.Label>
                  <Form.Control
                    placeholder="Apartment, studio, or floor"
                    onChange={(e) => onFieldChange(e, 12)}
                    className="textField"
                    value={address2}
                  />
                </Form.Group>
                <Form.Group as={Col} sm="4" controlId="formGridZip">
                  <Form.Label>Zip</Form.Label>
                  <Form.Control
                    value={zip}
                    onChange={(e) => onFieldChange(e, 13)}
                    className="textField"
                  />
                </Form.Group>
              </Row>

              {/* 
          <Button variant="warning" onClick={onSubmit}>
            Submit
          </Button> */}
            </Form>
          </div>
          <div className="rightColCheckout">
            <h2>Order Information</h2>
            <hr />
            <CheckoutTable
              id="order-info"
              tier={tier}
              price={price}
              initialCharge={initialCharge}
              qty={qty}
            />
            <div className="seats">
              <span style={{ fontSize: "20px" }}>Seats</span>
              <div className="qtyDiv">
                <button
                  style={{ borderRadius: "50px 0px 0px 50px" }}
                  onClick={() => {
                    if (qty > 1) {
                      setQty(qty - 1);
                    }
                  }}
                >
                  -
                </button>
                <input
                  type="text"
                  value={qty}
                  onChange={(e) => {
                    if (e.target.value <= 5 && e.target.value >= 1) {
                      setQty(e.target.value);
                    }
                  }}
                  id="qtyInput"
                />
                <button
                  style={{ borderRadius: "0px 50px 50px 0px" }}
                  onClick={() => {
                    if (qty < 5) {
                      setQty(qty + 1);
                    }
                  }}
                >
                  +
                </button>
              </div>
            </div>

            <br />
            <h5>Reccuring Payment Terms:</h5>
            <ul className="noDots">
              <>
                <li>No Initial Payment</li>
                <li>
                  ${price}.00, charged {period} after 7 day trial expires.
                </li>
              </>
            </ul>
            <Form.Group className="mb-3" id="formGridCheckbox">
              <Form.Check
                onChange={(e) => {
                  accept ? setAccept(false) : setAccept(true);
                }}
                value={accept}
                type="checkbox"
                label="I acknowledge my card will be charged automatically at the end of the trial period and will be kept for future charges"
              />
            </Form.Group>
            <PaymentFields
              // onSubmit={onSubmit}
              accept={accept}
              firstName={fName}
              lastName={lName}
              email={email}
              password={password}
              companyName={cName}
              companyWebsite={cWeb}
              phone={tel}
              title={title}
              country={countryCode}
              state={userState}
              city={city}
              address={address}
              address2={address2}
              zip={zip}
              tier={tier}
              period={period}
              plan={plan}
              validateFields={validateFields}
              success={success}
              qty={qty}
              overRideAmount={price * qty}
            />
          </div>
        </>
      ) : (
        <div className="thankyou_details" style={{ minHeight: "90vh" }}>
          <h1 className="anton">Thank You For Your Order!</h1>
          <ImCheckmark size={50} className="yellow" />
          {/* <div className="alert alert-success mt-3">
              <p className="thankyou-content">
                Thank for your order. <br /> Your account is under review now,
                you will be notified soon about your account activation by
                email.
              </p>
            </div> */}
          <h3 style={{ fontWeight: "bolder" }}>
            You will receive an email with your order information.
          </h3>
          <div
            style={{
              color: "black",
              margin: "auto",
              width: "30%",
              marginTop: "30px",
            }}
            className="styledBox yellow-bg"
          >
            <h4 style={{ textAlign: "left" }}>Order Details</h4>
            <ul className="order-details-list">
              <li>First Name: {fName}</li>
              <li>Last Name: {lName}</li>
              <li>Email: {email}</li>
              <li>Telephone: {tel}</li>
              <li>Company: {cName}</li>
              <li>Job Title: {title}</li>
              <li>Tier: {tier}</li>
              <li>Period: {period}</li>
            </ul>
          </div>
          <br />
          <a href="/login">
            <Button
              type="button"
              variant="contained"
              className={"searchBtn desktopSearchBtn"}
            >
              Login Here
            </Button>
          </a>
          <br />
          <br />
          <ImTwitter size={20} />
          <a
            className="darkText"
            href="https://twitter.com/rockhudsonrock"
            target="_blank"
          >
            Follow Us on Twitter.
          </a>
        </div>
      )}
      <Footer checkout={true} noSocial={true} />
    </div>
  ) : (
    <div>
      <LoaderIcon
        type="spin"
        className="tabsLoader"
        style={{ position: "relative", top: "20rem" }}
      />
    </div>
  );
}
