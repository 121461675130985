import React, { useState } from "react";
import CreateApiClient from "../../API";
import Button from "@material-ui/core/Button";
import { FaFileCsv } from "react-icons/fa";
import { CSVLink, CSVDownload } from "react-csv";
import LoaderIcon from "react-loader-icon";
import { useLocation } from "react-router";

const queryString = require("query-string");

const api = CreateApiClient();

export default function ExportToCsv(props) {
  const location = useLocation();
  const basicPlans = [2741315, 2741325, 3332681, 3332639];
  const path = location.pathname.split("/")[1];

  const [csv, setCsv] = useState([]);
  const [csvLoading, setCsvLoading] = useState(false);

  const exportToCsv = async () => {
    const loggedInUser = localStorage.getItem("user");

    if (loggedInUser) {
      const foundUser = await JSON.parse(loggedInUser);
      const time = Date.now().toString().substr(0, 10);
      const nowNum = Number(time);
      if (foundUser.expiry < nowNum) {
        props.handleHomeLogOut();
      } else {
        setCsvLoading(true);
        let endpoint, domainQuery, query;
        if (!props.unmask) {
          switch (props.section) {
            case "employees":
              endpoint = "employees/csv";
              break;
            case "users?android=false":
              endpoint = "users/csv?android=false";
              break;
            case "users?android=true":
              endpoint = "users/csv?android=true";
              break;
            case "dbs/domainsearch":
              endpoint = "dbs?csv=true";
              break;
          }

          if (path === "search-by-domain") {
            query = {
              domain: props.selectedDomain,
            };
          } else if (path === "search-by-prospect") {
            query = {
              prospect: props.selectedDomain,
            };
          }
        } else if (props.unmask) {
          if (props.type === "creds") {
            query = {
              domain: props.id.split("_")[1]
                ? props.id.split("_")[1]
                : props.domain,
              id: props.id.split("_")[0],
            };
          } else {
            query = {
              company: props.company,
            };
          }

          switch (props.type) {
            case "employees":
              endpoint = `employees/get-unlocked-content?csv=true&sortby=${props.sortType}`;
              break;
            case "third-parties":
              endpoint = `employees/get-unlocked-content?csv=true&sortby=${props.sortType}&thirdparty=${props.isThirdParty}`;
              break;
            case "users":
              endpoint = `users/get-unlocked-content?csv=true&android=false&sortby=${props.sortType}`;
              break;
            case "apps":
              endpoint = `users/get-unlocked-content?csv=true&android=true&sortby=${props.sortType}`;
              break;
            case "externals":
              endpoint = `external-domains/get-unlocked-content?unmask=true&csv=true&sortby=${props.sortType}`;
              break;
            case "dbs":
              endpoint = `dbs/get-unlocked-content?csv=true&sortby=${props.sortType}`;
              break;
            case "creds":
              endpoint = `machine?unmask=true&csv=true&thirdparties=${props.isThirdParty}`;
              break;
            default:
              endpoint = `employees/get-unlocked-content?csv=true&sortby=${props.sortType}`;
          }
        }

        const config = {
          headers: {
            "auth-token": foundUser.token,
          },
        };

        const csvData = await api.fetchData(endpoint, query, config);

        csvData.length > 0
          ? setCsv(csvData) && setCsvLoading(true)
          : setCsv([]);
        setCsvLoading(false);
        if (document.getElementById("csvDownload")) {
          click();
          // document.getElementById("csvDownload").click();
        }
      }
    }
  };

  const click = () => {
    document.getElementById("csvDownload").click();
  };

  return (
    !props.loading && (
      <div
        id="exportDiv"
        className={
          props.type === "creds" ? "cardBtn unlockBtn shadow-box" : "exportDiv"
        }
        style={{
          top: props.type === "creds" && "0px",
          padding: props.type === "creds" && "3px 8px",
          minWidth: props.type === "creds" && "95px",
          borderRadius: props.type === "creds" && "0px 0px 0px 30px",
          textAlign: props.type === "creds" && "center",
        }}
      >
        <>
          {csv.length <= 0 && !csvLoading && props.data.length > 0 && (
            <Button
              variant="contained"
              className={props.type === "creds" ? "trans-bg" : "searchBtn"}
              // endIcon={<Icon>send</Icon>}
              onClick={exportToCsv}
              id="exportBtn"
              style={{
                padding: props.type === "creds" && "5px 10px",
              }}
            >
              <>
                {props.type === "creds" ? (
                  <span id="exportSpan">Export</span>
                ) : (
                  <span>Export All</span>
                )}
                <FaFileCsv
                  size={16}
                  id="exportSvg"
                  onClick={(e) => {
                    e.stopPropagation();
                    exportToCsv();
                  }}
                />
              </>
            </Button>
          )}

          {csvLoading && props.type != "creds" ? (
            <>
              <LoaderIcon
                id="csvLoader"
                className="tabsLoader csvLoader"
                type={"spin"}
              />
              {!props.unmask && (
                <span
                  className="csvLoadingLabel darkText"
                  style={{ fontSize: "16px", lineHeight: "2" }}
                >
                  Preparing CSV file, this may take up to several minutes
                </span>
              )}
            </>
          ) : (
            csvLoading &&
            props.type === "creds" && (
              <div
                className="cardBtn unlockBtn shadow-box"
                style={{
                  top: props.type === "creds" && "0px",
                  padding: props.type === "creds" && "5px 5px",
                  borderRadius: props.type === "creds" && "0px 0px 0px 30px",
                  width: "95px",
                  textAlign: "center",
                }}
              >
                <LoaderIcon
                  id="csvLoader"
                  className="tableLoader csvLoader"
                  type={"spin"}
                />
              </div>
            )
          )}
          {csv.length > 0 && !csvLoading && props.type != "creds" ? (
            <CSVLink
              id="csvDownload"
              className="csvDownload"
              data={csv}
              filename={`${
                props.selectedDomain ? props.selectedDomain : "unlocked_content"
              }_${props.type}.csv`}
              target="_blank"
            >
              {props.type != "creds"
                ? "Click here if the file has not been downloaded"
                : "Export"}
            </CSVLink>
          ) : (
            csv.length > 0 &&
            !csvLoading &&
            props.type === "creds" && (
              <CSVLink
                id="csvDownload"
                data={csv}
                filename={`${props.name}.csv`}
                target="_blank"
                style={{ fontSize: "0.875rem", textAlign: "center" }}
              >
                Download
              </CSVLink>
            )
          )}
        </>
      </div>
    )
  );
}
