import React from "react";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import Icon from "@material-ui/core/Icon";
import { useNavigate, Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
}));

export default function MobileSearchBtn(props) {
  const history = useNavigate();
  const classes = useStyles();

  const search = async (e) => {
    e.preventDefault();
    const domainsArr = await Promise.all(
      props.activeDomains.map((domain) => {
        return domain.value;
      })
    ).catch((err) => console.log(err));
    let queryTxt = domainsArr.join("&");
    history.push(`/${queryTxt}`);
  };

  return (
    <div style={{ marginLeft: "-10px", height: "38px" }}>
      {props.domain != null &&
        props.domain.includes(".") &&
        !props.domain.includes(":") && (
          <Link to={`/search-by-domain/${props.domain}`}>
            <Button
              type="button"
              variant="contained"
              className={classes.button + " searchBtn mobileSearchBtn"}
              endIcon={<Icon>send</Icon>}
            ></Button>
          </Link>
        )}
    </div>
  );
}
