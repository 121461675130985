import React from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

export default function FormDialog(props) {
  return (
    <div>
      <Dialog
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="form-dialog-title"
      >
        {props.type === "prospect" ? (
          <DialogTitle id="form-dialog-title" className="lightText">
            You are about to {props.action} {props.subject} from your prospects{" "}
            <br />
            {/* <ul className="domainList">
              {props.subject.length > 0 &&
                props.subject.map((item) => <li>{item}</li>)}
            </ul> */}
          </DialogTitle>
        ) : (
          <DialogTitle id="form-dialog-title" className="lightText">
            You are about to unlock {props.credsToUnlock.length} stealers, you
            can select up to {Math.ceil(props.credsToUnlock.length / 10) * 10}{" "}
            items for {Math.ceil(props.credsToUnlock.length / 10)} credit, are
            you sure you want to proceed with {props.credsToUnlock.length}?{" "}
            <br />
            {/* <ul className="domainList">
              {props.credsToUnlock.length > 0 &&
                props.credsToUnlock.map((item) => <li>{item}</li>)}
            </ul> */}
          </DialogTitle>
        )}

        <DialogContent>
          <DialogContentText className="lightText capitalize"></DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={props.handleClose} color="primary">
            Cancel
          </Button>
          {props.type === "prospect" ? (
            <Button
              onClick={() => props.prospectsToEdit(props.subject)}
              color="primary"
            >
              Confirm
            </Button>
          ) : (
            <Button
              onClick={() => props.unlockCreds(props.credsToUnlock)}
              color="primary"
            >
              Confirm
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
}
