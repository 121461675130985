import React from "react";
import { ImCheckmark } from "react-icons/im";

export default function Overlay(props) {
  return (
    <div className="overlay">
      <div className="overlay__inner">
        <div className="overlay__content">
          {props.success ? (
            <>
              <ImCheckmark />
              <h1 className="darkText">{props.successTitle}</h1>
              <br />
              <h4 className="darkText">{props.successContent}</h4>
            </>
          ) : (
            <>
              <span className="spinner"></span>
              <h1 className="darkText">{props.title}</h1>
              <br />
              <h4 className="darkText">{props.content}</h4>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
