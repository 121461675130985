import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import CreateApiClient from "../../API";
import Link from "@material-ui/core/Link";
import { FcCheckmark } from "react-icons/fc";
import { authAddress } from "../../Config";
import axios from "axios";
import LoaderIcon from "react-loader-icon";

const api = CreateApiClient();

function rand() {
  return Math.round(Math.random() * 20) - 10;
}

function getModalStyle() {
  const top = 50 + rand();
  const left = 50 + rand();

  return {
    top: `${50}%`,
    left: `${50}%`,
    transform: `translate(-${50}%, -${50}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: 600,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

export default function ChangePass(props) {
  const classes = useStyles();
  // getModalStyle is not a pure function, we roll the style only on the first render
  const [modalStyle] = React.useState(getModalStyle);
  const [open, setOpen] = React.useState(false);
  const [logEmail, setLogEmail] = useState("");
  const [oldPassword, setOldPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);
  const [email, setEmail] = useState("");
  const [loader, setLoader] = useState(false);

  const onChangeEmail = (e) => {
    e.preventDefault();
    setEmail(e.target.value);
  };

  const sendResetLink = async () => {
    setLoader(true);
    await axios
      .post(`${authAddress}/reset-password`, { email: email })
      .then((res) => {
        setSuccess(true);
      })
      .catch((err) => {
        setError(err.response.data);
      });
    setLoader(false);
  };

  const onChangeOldPass = (e) => {
    e.preventDefault();
    setOldPassword(e.target.value);
  };

  const onChangeConfirmPass = (e) => {
    e.preventDefault();
    setConfirmPassword(e.target.value);
  };

  const onChangeNewPass = (e) => {
    e.preventDefault();
    setNewPassword(e.target.value);
  };

  const handleOpen = () => {
    setError("");
    setSuccess(false);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const changePassword = async (e) => {
    e.preventDefault();
    const loggedInUser = localStorage.getItem("user");
    if (loggedInUser) {
      const foundUser = JSON.parse(loggedInUser);
      setLogEmail(foundUser.email);
      setError("");
      const strongRegex = new RegExp(
        "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})"
      );

      if (newPassword === confirmPassword) {
        const data = {
          logEmail: foundUser.email,
          oldPassword: oldPassword,
          newPassword: newPassword,
          confirmPassword: confirmPassword,
        };
        const config = {
          headers: {
            "auth-token": foundUser.Token,
          },
        };
        if (strongRegex.test(newPassword) && !newPassword.includes("$")) {
          const response = await api.changePassword(
            "password-change",
            data,
            config
          );
          response === "OK" ? setSuccess(true) : setSuccess(false);
        } else {
          if (newPassword.includes(".") || newPassword.includes("$")) {
            setError("Passwords must not contain '.' or '$'.");
          } else {
            setError(
              "Password must be at least 8 character long and contain at least one special character, one lowercase character, one uppercase character, and one number."
            );
          }
        }
      } else {
        setError("Passwords Do Not Match");
      }
    }
  };

  const body = (
    <div style={modalStyle} className={classes.paper}>
      {!success ? (
        <>
          <h2 id="simple-modal-title">Change Password</h2>
          <form>
            <TextField
              onChange={onChangeOldPass}
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              //   autoComplete="current-password"
              value={oldPassword}
            />
            <TextField
              onChange={onChangeNewPass}
              type="password"
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="New Password"
              name="new_pass"
              value={newPassword}
              minLength={8}
            />
            <TextField
              onChange={onChangeConfirmPass}
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Repeat New Password"
              type="password"
              id="password"
              //   autoComplete="current-password"
              value={confirmPassword}
            />
            <Button
              onClick={changePassword}
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit + " searchBtn"}
            >
              Submit
            </Button>
            <p className="error">{error}</p>
          </form>
        </>
      ) : (
        <>
          <h3 className="success">Password has successfully changed!</h3>
          <Button
            onClick={handleClose}
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit + " searchBtn"}
          >
            Close Window
          </Button>
        </>
      )}
    </div>
  );

  const resetBody = (
    <div style={modalStyle} className={classes.paper}>
      {!success ? (
        <>
          <h2 className="align-left">Reset Password</h2>
          <p className="lightText">
            Enter your email, and we'll send you instructions on how to reset
            your password.
          </p>
          <form>
            <TextField
              onChange={onChangeEmail}
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="email"
              label="Email"
              type="email"
              value={email}
            />
            <Button
              onClick={sendResetLink}
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit + " searchBtn"}
            >
              Submit
              {loader && (
                <span>
                  <LoaderIcon
                    type="spin"
                    className="tableLoader"
                    style={{
                      height: "16px",
                      width: "16px",
                      marginLeft: "5px",
                      position: "relative",
                      top: "-5px",
                    }}
                  />
                </span>
              )}
            </Button>
            <p className="error">{error}</p>
          </form>
        </>
      ) : (
        <div style={{ textAlign: "center" }}>
          <FcCheckmark size={35} />
          <h2 className="align-center">We've just sent you an email</h2>
          <p className="lightText align-center">
            Please follow the link in the email to reset your password.
          </p>
          <Button
            onClick={handleClose}
            variant="contained"
            color="primary"
            className={classes.submit + " searchBtn"}
          >
            Close Window
          </Button>
        </div>
      )}
    </div>
  );

  return (
    <div className="loginBottomBox" style={{ marginBottom: "5px" }}>
      {props.reset ? (
        <p className="yellow cursor bold" onClick={handleOpen}>
          Forgot your password?
        </p>
      ) : (
        <Button
          type="button"
          onClick={handleOpen}
          className={classes.submit + " searchBtn"}
          style={{ margin: "auto", width: "180px" }}
        >
          Change Password
        </Button>
      )}

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {props.reset ? resetBody : body}
      </Modal>
    </div>
  );
}
