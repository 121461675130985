import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import axios from "axios";
import { APIFullEmailAddress } from "../../Config";

export default function FeedbackModal(props) {
  const [text, setText] = useState("");
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(null);

  const onChangeText = (e) => {
    setError(null);
    setSuccess(false);
    setText(e.target.value);
  };

  const sendFeedback = async () => {
    const response = axios.post(
      `${APIFullEmailAddress}/send/feedback`,
      {
        text: text,
      },
      { headers: { "auth-token": props.user.token } }
    );
    response
      .then((res) => {
        setSuccess(true);
      })
      .catch((err) => {
        setError(err.response.data);
      });
  };
  return (
    <div>
      {success ? (
        <Dialog
          open={props.open}
          onClose={props.handleClose}
          aria-labelledby="form-dialog-title"
          id="feedbackModal"
        >
          <DialogTitle id="form-dialog-title" style={{ textAlign: "center" }}>
            Thank you for your feedback!
          </DialogTitle>
        </Dialog>
      ) : error ? (
        <Dialog
          open={props.open}
          onClose={props.handleClose}
          aria-labelledby="form-dialog-title"
          id="feedbackModal"
        >
          <DialogTitle id="form-dialog-title" style={{ textAlign: "center" }}>
            {error}
          </DialogTitle>
        </Dialog>
      ) : (
        <Dialog
          open={props.open}
          onClose={props.handleClose}
          aria-labelledby="form-dialog-title"
          id="feedbackModal"
        >
          <DialogTitle id="form-dialog-title">Send Feedback</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Please tell us your suggestion or report an issue.
            </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              id="feedback"
              label="Your Feedback"
              type="text"
              fullWidth
              onChange={onChangeText}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={props.handleClose} color="primary">
              Cancel
            </Button>
            <Button onClick={sendFeedback} color="primary">
              Send
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </div>
  );
}
