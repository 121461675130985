import React from "react";
import { FaCheckCircle } from "react-icons/fa";
import Button from "@material-ui/core/Button";

export default function FeaturesTable() {
  return (
    <div style={{ paddingTop: "50px" }}>
      <h2 className="cyberFont align-center">
        Activate your subscription and unlock multiple features
      </h2>
      <br />
      <table className="featuresTable">
        <thead>
          <th></th>
        </thead>
        <tbody>
          <tr style={{ marginBottom: "10px" }}>
            <td className="featureTd">
              <div>Compromised URLs & Credentials</div>
            </td>
            <td>
              <FaCheckCircle size={30} style={{ color: "#4A40C5" }} />
            </td>
          </tr>
          <tr>
            <td className="featureTd">
              <div>Search by Domain, Keyword and Demographic</div>
            </td>
            <td>
              <FaCheckCircle size={30} style={{ color: "#4A40C5" }} />
            </td>{" "}
          </tr>
          <tr>
            <td className="featureTd">
              <div>Supply Chain Risk Assessment</div>
            </td>
            <td>
              <FaCheckCircle size={30} style={{ color: "#4A40C5" }} />
            </td>
          </tr>
          <tr>
            <td className="featureTd">
              <div>Compromised Android Apps</div>
            </td>
            <td>
              <FaCheckCircle size={30} style={{ color: "#4A40C5" }} />
            </td>{" "}
          </tr>
          <tr>
            <td className="featureTd">
              <div>Assets Discovery</div>
            </td>
            <td>
              <FaCheckCircle size={30} style={{ color: "#4A40C5" }} />
            </td>{" "}
          </tr>
          <tr>
            <td className="featureTd">
              <div>Data Virtualization & Export</div>
            </td>
            <td>
              <FaCheckCircle size={30} style={{ color: "#4A40C5" }} />
            </td>{" "}
          </tr>
          <tr>
            <td className="featureTd">
              <div>Dedicated Account Manager</div>
            </td>
            <td>
              <FaCheckCircle size={30} style={{ color: "#4A40C5" }} />
            </td>{" "}
          </tr>
          <tr>
            <td className="featureTd">
              <div>Contact Finder</div>
            </td>
            <td>
              <FaCheckCircle size={30} style={{ color: "#4A40C5" }} />
            </td>{" "}
          </tr>
          <tr>
            <td className="featureTd">
              <div>Number of Credits</div>
            </td>
            <td style={{ fontSize: "24px" }}>40</td>{" "}
          </tr>
          <tr>
            <td className="featureTd">
              <div>Number of Seats</div>
            </td>
            <td style={{ fontSize: "24px" }}>Up to 5</td>
          </tr>
        </tbody>
      </table>
      <br />
      <div
        style={{
          display: "inline-flex",
          justifyContent: "center",
          width: "100%",
        }}
      >
        <Button
          style={{
            color: "black",
            margin: "0px 5px",
            backgroundColor: "#fecc4c",
          }}
          href="/checkout"
        >
          Start your free trial now
        </Button>
        <Button
          className="darkText"
          style={{
            color: "white",
            margin: "0px 5px",
            backgroundColor: "#4a40c5",
          }}
          href="https://www.hudsonrock.com/schedule-demo"
          target="_blank"
        >
          Need more credits or seats? Go enterprise
        </Button>
      </div>
    </div>
  );
}
