import React, { useState } from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";

import logo from "../../images/hrlogo_new.png";
import homeImg from "../../images/homepage.jpeg";
function Copyright() {
  return (
    <Typography
      variant="body2"
      color="textSecondary"
      align="left"
      className="copyrights"
    >
      {"Copyright © "}
      <Link color="inherit" href="https://material-ui.com/">
        Hudson Rock
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const theme = createTheme();

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  margin: {
    margin: theme.spacing(1),
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  textField: {
    width: "25ch",
  },
}));

export default function SignUp(props) {
  const classes = useStyles();
  const [showPassword, setShowPassword] = useState(false);
  const [agreed, setAgreed] = useState(false);
  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    console.log({
      email: data.get("email"),
      password: data.get("password"),
    });
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <ThemeProvider theme={theme}>
      <Grid container component="main" sx={{ height: "100vh" }}>
        <CssBaseline />
        <Grid
          item
          xs={12}
          sm={8}
          md={5}
          component={Paper}
          elevation={6}
          square
          style={{ backgroundColor: "#222831", padding: "20px" }}
          className={props.stage === "email" ? "ex-card" : "flip"}
        >
          <img src={logo} alt="logo" width="400px" className="signupLogo" />

          <Box
            sx={{
              my: 8,
              mx: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              textAlign: "left",
              marginTop: props.stage === "email" ? "25%" : "25%",
            }}
            className="card"
          >
            {props.stage === "email" ? (
              <div className="signupDiv card-front">
                <h4 className="cyberFont yellow">
                  Start Creating Your Account
                </h4>
                <Typography className="darkText" component="p" variant="p">
                  Accelerate Your Customer Prospecting With Bayonet
                </Typography>
                <form id="signupForm" onSubmit={props.nextStage} noValidate>
                  <TextField
                    type="text"
                    variant="outlined"
                    margin="normal"
                    required
                    //   fullWidth
                    id="fName"
                    label="First Name"
                    name={"" + Math.random()}
                    onChange={props.changeFname}
                    className="signupInput halfWidthInput marginRight1"
                    autoComplete="new-password"
                    value={props.firstName}
                  />
                  <TextField
                    type="text"
                    variant="outlined"
                    margin="normal"
                    required
                    //   fullWidth
                    id="lName"
                    label="Last Name"
                    name={"" + Math.random()}
                    onChange={props.changeLname}
                    className="signupInput halfWidthInput marginLeft1"
                    autoComplete="new-password"
                    value={props.lastName}
                  />
                  <TextField
                    className="signupInput"
                    type="email"
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="Email Address"
                    name={"" + Math.random()}
                    autoComplete="new-password"
                    onChange={props.changeEmail}
                    value={props.signUpEmail}
                  />
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={"searchBtn"}
                  >
                    Get Started
                  </Button>
                  <Grid container>
                    <Grid item xs>
                      <p className="error">{props.signUpError}</p>
                    </Grid>
                  </Grid>
                  <p>
                    Already have an account?{" "}
                    <a className="yellow" href="/login">
                      Login here
                    </a>
                  </p>
                </form>
              </div>
            ) : (
              <div className="signupDiv card-back passwordDiv">
                <h4 className="cyberFont yellow">Set Up Your Password</h4>
                <div
                  style={{
                    display: "inline-flex",
                    justifyContent: "center",
                    width: "100%",
                  }}
                >
                  <div
                    className={
                      props.validLength
                        ? "passCube yellow-bg"
                        : "passCube disabled"
                    }
                  >
                    <span>
                      8+
                      <br />
                      <span className="desktop">Characters</span>
                    </span>
                  </div>
                  <div
                    className={
                      props.validUppercase
                        ? "passCube yellow-bg"
                        : "passCube disabled"
                    }
                  >
                    <span>
                      A-Z
                      <br />
                      <span className="desktop">Uppercase</span>
                    </span>
                  </div>
                  <div
                    className={
                      props.validLowercase
                        ? "passCube yellow-bg"
                        : "passCube disabled"
                    }
                  >
                    <span>
                      a-z
                      <br />
                      <span className="desktop">Lowercase</span>
                    </span>
                  </div>
                  <div
                    className={
                      props.validNumber
                        ? "passCube yellow-bg"
                        : "passCube disabled"
                    }
                  >
                    <span>
                      0-9
                      <br />
                      <span className="desktop">Number</span>
                    </span>
                  </div>
                  <div
                    className={
                      props.validSign
                        ? "passCube yellow-bg"
                        : "passCube disabled"
                    }
                  >
                    <span>
                      !*#
                      <br />
                      <span className="desktop">Sign</span>
                    </span>
                  </div>
                </div>
                <form id="signupForm" onSubmit={props.signUp}>
                  <FormControl
                    style={{ width: "99%", margin: "15px auto" }}
                    // className={clsx(classes.margin, classes.textField)}
                  >
                    <InputLabel htmlFor="standard-adornment-password">
                      Password
                    </InputLabel>
                    <Input
                      autoFocus
                      autoComplete="new-password"
                      value={props.newPassword}
                      className="signupInput"
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      id="newPassword"
                      label="Password"
                      name={"" + Math.random()}
                      onChange={props.changePassword}
                      type={showPassword ? "text" : "password"}
                      style={{ width: "100%" }}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPassword ? (
                              <Visibility style={{ fill: "white" }} />
                            ) : (
                              <VisibilityOff style={{ fill: "white" }} />
                            )}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  </FormControl>
                  {/* <TextField
                    className="signupInput"
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="password"
                    label="Password"
                    name="password"
                    onChange={props.setNewPassword}
                    autoComplete="password"
                    type={showPassword ? "text" : "password"}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? (
                            <Visibility style={{ fill: "black" }} />
                          ) : (
                            <VisibilityOff style={{ fill: "black" }} />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                  /> */}
                  <div style={{ display: "inline-flex", alignItems: "center" }}>
                    <Checkbox
                      sx={{
                        color: "#fecc4c",
                        "&.Mui-checked": {
                          color: "#fecc4c",
                        },
                      }}
                      checked={agreed}
                      onChange={() => setAgreed(!agreed)}
                    />
                    <span style={{ color: "white" }}>
                      I have read and agreed to the{" "}
                      <a href="/tos" target="_blank" className="yellow">
                        Terms and Conditions
                      </a>
                    </span>
                  </div>

                  <div
                    style={{
                      width: "100%",
                      margin: "auto",
                      position: "relative",
                      top: "1rem",
                    }}
                  >
                    {props.validLength &&
                    props.validLowercase &&
                    props.validUppercase &&
                    props.validNumber &&
                    props.validSign &&
                    agreed ? (
                      <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={"searchBtn"}
                        id="sign-up-trigger"
                      >
                        Sign Up
                      </Button>
                    ) : (
                      <Button
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={"searchBtn grayBtn"}
                      >
                        Sign Up
                      </Button>
                    )}
                  </div>
                  <br />
                  {props.signUpError && (
                    <Grid container>
                      <Grid item xs>
                        <p className="error">
                          {props.signUpError
                            ? props.signUpError
                            : props.loginError}
                        </p>
                      </Grid>
                    </Grid>
                  )}
                  <p style={{ marginTop: "5px" }}>
                    Need to change some detail?{" "}
                    <span className="yellow cursor" onClick={props.prevStage}>
                      Go back
                    </span>
                  </p>
                </form>
              </div>
            )}
          </Box>
        </Grid>
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundColor: "#1A1C20",
            padding: "10%",
          }}
          className="desktop"
        >
          <h3 className="yellow cyberFont">
            Bayonet™: A Sales Prospect Tool for Cybersecurity Sales Teams &
            MSSPs.
          </h3>
          <img
            src={homeImg}
            style={{
              borderRadius: "10px",
              boxShadow: "35px 35px #fecd4e",
              border: "2px solid black",
              width: "600px",
              marginTop: "10%",
            }}
          />
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}
