import React, { useState, useEffect } from "react";
// import PropTypes from "prop-types";
import IconButton from "@material-ui/core/IconButton";
import TextField from "@material-ui/core/TextField";
import {
  DataGrid,
  GridToolbarDensitySelector,
  GridToolbarFilterButton,
  GridToolbarExport,
} from "@mui/x-data-grid";
// import { useDemoData } from "@mui/x-data-grid-generator";
import ClearIcon from "@material-ui/icons/Clear";
import SearchIcon from "@material-ui/icons/Search";
import { createTheme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/styles";

function escapeRegExp(value) {
  return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
}

const defaultTheme = createTheme();
const useStyles = makeStyles(
  (theme) => ({
    root: {
      padding: theme.spacing(0.5, 0.5, 0),
      justifyContent: "space-between",
      display: "flex",
      alignItems: "flex-start",
      flexWrap: "wrap",
    },
    textField: {
      [theme.breakpoints.down("xs")]: {
        width: "100%",
      },
      margin: theme.spacing(1, 0.5, 1.5),
      "& .MuiSvgIcon-root": {
        marginRight: theme.spacing(0.5),
      },
      "& .MuiInput-underline:before": {
        borderBottom: `1px solid ${theme.palette.divider}`,
      },
    },
  }),
  { defaultTheme }
);

function QuickSearchToolbar(props) {
  const classes = useStyles();
  useEffect(() => {
    console.log("render");
  }, []);

  return (
    <div className={classes.root}>
      <div>
        <GridToolbarFilterButton id="filterTable" />
        <GridToolbarDensitySelector />
        <GridToolbarExport />
        <>
          {/* <div className="width-divider"></div> */}
          Actions:
          {props.extraButtons.map((btn) => (
            <button
              key={btn.content}
              className="MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeSmall MuiButton-textSizeSmall MuiButtonBase-root  css-1knaqv7-MuiButtonBase-root-MuiButton-root"
              onClick={() => btn.func(props.selectedRows)}
            >
              <span>{btn.icon}</span>
              <span>{btn.content}</span>
            </button>
          ))}
        </>
      </div>
      <TextField
        variant="standard"
        value={props.value}
        onChange={(event) => props.onChange(event)}
        placeholder="Search…"
        id="blackTextInput"
        className={classes.textField}
        InputProps={{
          startAdornment: <SearchIcon fontSize="small" />,
          endAdornment: (
            <IconButton
              title="Clear"
              aria-label="Clear"
              size="small"
              style={{ visibility: props.value ? "visible" : "hidden" }}
              onClick={() => props.clearSearch("")}
            >
              <ClearIcon fontSize="small" />
            </IconButton>
          ),
        }}
      />
    </div>
  );
}

export default function QuickFilteringGrid(props) {
  const [rows, setRows] = React.useState(props.data);
  const [selectedRows, setSelectedRows] = useState([]);
  const [searchText, setSearchText] = React.useState("");

  const requestSearch = (searchValue) => {
    setSearchText(searchValue);
    const searchRegex = new RegExp(escapeRegExp(searchValue), "i");
    const filteredRows = props.data.filter((row) => {
      return Object.keys(row).some((field) => {
        return searchRegex.test(row[field].toString());
      });
    });
    setRows(filteredRows);
  };

  React.useEffect(() => {
    setRows(props.data);
  }, [props.data]);

  return (
    <div style={{ height: "100vh", width: "100%" }}>
      <DataGrid
        components={{ Toolbar: QuickSearchToolbar }}
        rows={rows}
        columns={props.columns}
        // hideFooter
        checkboxSelection={true}
        disableSelectionOnClick
        componentsProps={{
          toolbar: {
            value: searchText,
            onChange: (event) => requestSearch(event.target.value),
            clearSearch: () => requestSearch(""),
            extraButtons: props.extraButtons,
            selectedRows: selectedRows,
          },
        }}
        onSelectionModelChange={(ids) => {
          const selectedIDs = new Set(ids);
          const selectedRows = rows.filter((row) => selectedIDs.has(row._id));
          setSelectedRows(selectedRows);
        }}
        filterMode="server"
        onFilterModelChange={(e) => props.filterTable(e)}
        rowsPerPageOptions={[100]}
        rowCount={props.userCount}
        pagination
        paginationMode="server"
        pageSize={props.pageSize}
        onPageChange={(newPage) => props.setPage(newPage)}
        loading={props.loading}
        page={props.page}
        getRowId={(row) => row._id}
      />
    </div>
  );
}
