import React from "react";
import { Link } from "react-router-dom";
import logo from "../../images/hrlogo_new.png";
import LoaderIcon from "react-loader-icon";

function NotFound(props) {
  return (
    <div className="App">
      <br />
      <br />
      <img src={logo} width="300" />
      <div className="content" style={{ margin: "5rem" }}>
        {props.pageLoading ? (
          <LoaderIcon type="spin" style={{ fill: "white" }} />
        ) : (
          <>
            <h1>Page Not Found</h1>
            <br />
            <h3>We're sorry, the page you requested could not be found.</h3>
            <h4>Please go back to the homepage</h4>
            <Link to="/">
              <button className="loginBtn">Go Home</button>
            </Link>
          </>
        )}
      </div>
    </div>
  );
}

export default NotFound;
