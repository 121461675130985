import React from "react";
import { Link } from "react-router-dom";

export default function AddProspectBtn(props) {
  return (
    <>
      <h1>
        No Results <br /> Attach this domain to a prospect in order to add{" "}
        {props.type} to it
      </h1>
    </>
  );
}
