import React from "react";
import LoaderIcon from "react-loader-icon";

export default function LoadMore(props) {
  return (
    <>
      {props.loader ? (
        <LoaderIcon className="tabsLoader" type={"spinningBubbles"} />
      ) : (
        <div className="align-center">
          {!props.finalRes &&
          props.data.length >= props.minLength &&
          props.data.length != props.resultsCount ? (
            <button
              className="cardBtn loadMore"
              onClick={props.setPage}
              style={{ width: "250px" }}
            >
              Load More
            </button>
          ) : (
            <div>
              <h3 style={{ color: "white" }}></h3>
            </div>
          )}
        </div>
      )}
    </>
  );
}
