import React, { useEffect, useState } from "react";
import QuickFilteringGrid from "./Datagrid";
import AppBarNew from "../Partials/AppBar";
import axios from "axios";
import { authAddress, blueSnapAddress, publicAddress } from "../../Config";
import cavaLogo from "../../images/thumbnail.jpeg";
import { FaTrash } from "react-icons/fa";
import { BsFillPersonCheckFill } from "react-icons/bs";
import { RiShutDownLine } from "react-icons/ri";
import FormDialog from "./FormDialog";
import logo from "../../images/logo-1.png";
import LoaderIcon from "react-loader-icon";
import { FaPaperPlane, FaEye, FaUsers } from "react-icons/fa";
import { GiTwoCoins } from "react-icons/gi";
import { FcApproval, FcDisapprove } from "react-icons/fc";
import Button from "@material-ui/core/Button";

export default function AdminPanel(props) {
  const [data, setData] = useState([]);
  const [subject, setSubject] = useState("");
  const [action, setAction] = useState("");
  const [actionText, setActionText] = useState("");
  const [open, setOpen] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [pageLoading, setPageLoading] = useState(false);
  const [createdPlan, setCreatedPlan] = useState(null);
  const [allPlans, setAllPlans] = useState([]);
  const [loadNewPlan, setLoadNewPlan] = useState(false);
  const [newCredits, setNewCredits] = useState(10);
  const [userCount, setUserCount] = useState(0);
  const [page, setPage] = useState(0);
  const [filters, setFilters] = useState([]);
  const [pageSize, setPageSize] = useState(100);
  const [copied, isCopied] = useState("");

  const enterprisePlan = 1;
  const basic_plans = [2741315, 2741325, 3332681, 3332639];
  // const advanced_plans = [2801843, 2801841, 4025330, 4013076];

  const copyToClipboard = async (index, url) => {
    await navigator.clipboard.writeText(url);
    isCopied(index);
    setTimeout(() => {
      isCopied(null);
    }, 2000);
  };

  const defaultSrc = (ev) => {
    ev.target.src = cavaLogo;
  };

  const config = {
    headers: {
      "auth-token": props.user.token,
    },
  };

  const isAdmin = async () => {
    await axios
      .post(`${authAddress}/role`, {}, config)
      .then((res) => {
        if (res.data != "admin") {
          window.location.href = "/";
        }
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    isAdmin();
  }, []);

  useEffect(() => {
    getUsers();
  }, [page, filters]);

  const filterTable = async (e) => {
    setFilters(e.items[0]);
  };

  const getUsers = async () => {
    setPageLoading(true);
    await axios
      .post(
        `${authAddress}/get-all-users?page=${page}`,
        { filters: filters },
        config
      )
      .then((res) => {
        setData(res.data.users);
        setUserCount(res.data.count);
        //   setUsersTable(res.data.users);
        //   setSubsTable(res.data.subs);
      })
      .catch((err) => console.log(err));
    setPageLoading(false);
  };

  const handleClickOpen = async (selectedRows, action, subject, text) => {
    if (action === "read") {
      await axios
        .post(`${blueSnapAddress}/plans`, {}, config)
        .then((res) => setAllPlans(res.data))
        .catch((err) => err);
    }
    setSubject(subject);
    setAction(action);
    setSelectedRows(selectedRows);
    setActionText(text);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setCreatedPlan(null);
  };

  const columns = [
    // { field: "_id", headerName: "ID", width: 90 },
    {
      field: "company_website",
      headerName: "Company",
      width: 180,
      sortable: true,
      renderCell: (params) => (
        <>
          <img
            style={{ width: "50px", padding: "5px" }}
            className="roundSmallImg borderBlack"
            src={
              params.value === "hudsonrock.com"
                ? logo
                : `https://logo.clearbit.com/${params.value}`
            }
            onError={defaultSrc}
          />
          {params.getValue(params.id, "company_name") || ""}
        </>
      ),
    },
    // { field: "_id", headerName: "ID", width: 90, sortable: true },
    {
      field: "first_name",
      headerName: "Full name",
      sortable: true,
      width: 130,
      // valueGetter: (params) => {
      //   <p className="capitalize lightText">{params.row.first_name}</p>;
      // },
      renderCell: (params) => (
        <p className="capitalize lightText">
          {params.getValue(params.id, "first_name") || ""}{" "}
          {params.getValue(params.id, "last_name") || ""}
        </p>
      ),
    },
    {
      field: "email",
      headerName: "Email",
      width: 250,
    },
    {
      field: "tel",
      headerName: "Tel",
      width: 120,
    },
    {
      field: "url_hash",
      headerName: "URL",
      width: 200,
      renderCell: (params) =>
        params.row && params.row.url_hash ? (
          <div className="copyUrlBtn tableBtn">
            <Button
              onClick={() =>
                copyToClipboard(
                  params.row._id,
                  `${publicAddress}/tokenaccess?token=${params.row.url_hash}`
                )
              }
              className="searchBtn"
            >
              Copy
            </Button>
            {copied === params.row._id && (
              <span className="success">Copied!</span>
            )}
          </div>
        ) : (
          "X"
        ),
    },
    {
      field: "linkedin_profile",
      headerName: "LinkedIn",
      width: 100,
    },
    {
      field: "sub",
      headerName: "Sub ID",
      // sortable: true,
      width: 100,
      valueGetter: (params) =>
        params.row.subscription && `${params.row.subscription.subscriptionId}`,
    },
    {
      field: "plan",
      headerName: "Plan",
      // sortable: true,
      width: 100,
      valueGetter: (params) =>
        params.row.subscription &&
        `${
          basic_plans.includes(params.row.subscription.plan_id)
            ? "Basic"
            : enterprisePlan != params.row.subscription.plan_id
            ? "Advanced"
            : "Enterprise"
        }`,
    },
    {
      field: "status",
      headerName: "User Status",
      // sortable: true,
      width: 100,
      valueGetter: (params) =>
        params.row && `${params.row.active ? "Active" : "Inactive"}`,

      // params.row.subscription &&
      // `${params.row.subscription.active ? "Active" : "Inactive"}`,
    },
    {
      field: "role",
      headerName: "Role",
      // sortable: true,
      width: 80,
      valueGetter: (params) =>
        params.row.subscription &&
        `${
          params.row.subscription.owner === params.getValue(params.id, "email")
            ? "Owner"
            : "User"
        }`,
    },
    {
      field: "credits",
      headerName: "Credits",
      // type: Number,
      width: 80,
    },
    {
      field: "approval",
      headerName: "Approval",
      // sortable: true,
      width: 100,
      valueGetter: (params) =>
        params.row.status &&
        `${
          params.row.status === "pending"
            ? "Pending"
            : params.row.status === "approved"
            ? "Approved"
            : "Disapproved"
        }`,
    },
    {
      field: "sub_status",
      headerName: "Subscription Status",
      // sortable: true,
      width: 100,
      valueGetter: (params) =>
        params.row.subscription && `${params.row.subscription.status}`,
    },
    {
      field: "date",
      headerName: "Date",
      // type: Number,
      width: 100,
      renderCell: (params) => <>{params.value.split("T")[0]}</>,
    },
  ];

  const extraButtons = [
    {
      icon: <FaTrash />,
      content: "Delete",
      func: (selectedRows) =>
        handleClickOpen(
          selectedRows,
          "remove",
          "user",
          "You are about to delete the following users:"
        ),
    },
    {
      icon: <FcDisapprove />,
      content: "Disapprove",
      func: (selectedRows) => {
        // let onlySubs = selectedRows.filter((s) => s.subscriptionId);
        // if (onlySubs.length > 0) {
        //   handleClickOpen(
        //     onlySubs,
        //     "disapprove",
        //     "sub",
        //     "You are about to disapprove the following subscriptions:"
        //   );
        // }
        handleClickOpen(
          selectedRows,
          "disapprove",
          "user",
          "You are about to disapprove the following users:"
        );
      },
    },
    {
      icon: <FcApproval />,
      content: "Approve",
      func: (selectedRows) => {
        // let onlySubs = selectedRows.filter((s) => s.subscriptionId);
        // if (onlySubs.length > 0) {
        //   handleClickOpen(
        //     selectedRows,
        //     "approve",
        //     "sub",
        //     "You are about to approve the following subscriptions:"
        //   );
        // }
        handleClickOpen(
          selectedRows,
          "approve",
          "user",
          "You are about to approve the following users:"
        );
      },
    },
    {
      icon: <BsFillPersonCheckFill />,
      content: "Activate User",
      func: (selectedRows) =>
        handleClickOpen(
          selectedRows,
          "activate",
          "user",
          "You are about to activate the following users:"
        ),
    },
    {
      icon: <FaUsers />,
      content: "Activate Subscription",
      func: (selectedRows) =>
        handleClickOpen(
          selectedRows,
          "activate",
          "sub",
          "You are about to activate the following subscriptions:"
        ),
    },
    {
      icon: <RiShutDownLine />,
      content: "Deactivate",
      func: (selectedRows) =>
        handleClickOpen(
          selectedRows,
          "deactivate",
          "user",
          "You are about to deactivate the following users:"
        ),
    },
    {
      icon: <FaPaperPlane />,
      content: "Create Enterprise Plan",
      func: (selectedRows) =>
        handleClickOpen(null, "create", "plan", "New Enterprise Plan"),
    },
    {
      icon: <GiTwoCoins />,
      content: "Add Credits",
      func: (selectedRows) =>
        handleClickOpen(selectedRows, "add-credits", "user", "Add Credits"),
    },
    {
      icon: <FaEye />,
      content: "Show All Plans",
      func: (selectedRows) =>
        handleClickOpen(null, "read", "plan", "All Plans"),
    },
  ];

  const editRow = async () => {
    const _users = selectedRows.map((s) => {
      return {
        _id: s._id,
        subscription: s.subscription,
      };
    });
    await axios
      .post(
        `${authAddress}/update?subject=${subject}&action=${action}&credits=${newCredits}&page=${page}`,
        { selectedRows: _users },
        {
          headers: {
            "auth-token": props.user.token,
          },
        }
      )
      .then((res) => {
        console.log(res.data);
        setData(res.data.users);
      })
      .catch((err) => {
        console.log(err);
      });
    handleClose();
  };

  const newPlan = async (e) => {
    e.preventDefault();
    setLoadNewPlan(true);
    const body = {
      frequency: e.target.freq.value,
      name: e.target.name.value,
      trial: e.target.trial.value,
      credits: e.target.credits.value,
      refill: e.target.refill.value,
      initial: e.target.initial.value,
      recurring: e.target.recurring.value,
    };

    await axios
      .post(`${blueSnapAddress}/create-plan`, body, {
        headers: {
          "auth-token": props.user.token,
        },
      })
      .then((res) => setCreatedPlan(res.data))
      .catch((err) => err);
    setLoadNewPlan(false);
  };

  return (
    <>
      <AppBarNew
        handleHomeLogOut={props.handleHomeLogOut}
        className="mobileHeader"
        user={props.user}
        role={props.role}
        token={props.token}
        email={props.email}
        domains={props.domains}
        // handleLogOut={props.handleLogOut}
        filterCompanies={(industries, sizes, countries, regions) =>
          props.filterCompanies(industries, sizes, countries, regions)
        }
        resetUser={(user) => props.resetUser(user)}
        credits={props.user.user.credits}
      />
      <div style={{ marginTop: "64px", backgroundColor: "white" }}>
        <>
          <QuickFilteringGrid
            loading={pageLoading}
            pageSize={pageSize}
            page={page}
            setPage={(page) => setPage(page)}
            filterTable={filterTable}
            userCount={userCount}
            data={data}
            columns={columns}
            extraButtons={extraButtons}
          />
        </>
        {/* {pageLoading ? (
          <div className="load-wrapper" style={{ minHeight: "100vh" }}>
            <LoaderIcon type={"spin"} className="tableLoader page-loader" />
          </div>
        ) : (
          <>
            <QuickFilteringGrid
            loading={pageLoading}
              pageSize={pageSize}
              page={page}
              setPage={(page) => setPage(page)}
              filterTable={filterTable}
              userCount={userCount}
              data={data}
              columns={columns}
              extraButtons={extraButtons}
            />
          </>
        )} */}
      </div>
      <FormDialog
        open={open}
        handleClose={handleClose}
        selectedRows={selectedRows}
        subject={subject}
        action={action}
        actionText={actionText}
        editRow={editRow}
        newPlan={newPlan}
        createdPlan={createdPlan}
        allPlans={allPlans.plans}
        newCredits={newCredits}
        setNewCredits={(credits) => setNewCredits(credits)}
      />
    </>
  );
}
