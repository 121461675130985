import { ImLinkedin, ImFacebook2, ImTwitter } from "react-icons/im";

export default function SocialIcons(props) {
  const basicPlans = [2741315, 2741325, 3332681, 3332639];

  return (
    <div className="">
      {(props.data && props.user.user.role === "admin") ||
      (props.data &&
        (!props.user.sub || !basicPlans.includes(props.user.sub.plan_id))) ? (
        <>
          <a
            className={
              props.data.job_company_linkedin_url
                ? "socialIcon linkedin"
                : "socialIcon grayIcon"
            }
            href={
              props.data.job_company_linkedin_url
                ? `https://${props.data.job_company_linkedin_url}`
                : null
            }
            target="_blank"
            rel="noreferrer"
          >
            <ImLinkedin size={20} />
          </a>
          <a
            className={
              props.data.job_company_facebook_url
                ? "socialIcon facebook"
                : "socialIcon grayIcon"
            }
            href={
              props.data.job_company_facebook_url
                ? `https://${props.data.job_company_facebook_url}`
                : null
            }
            target="_blank"
            rel="noreferrer"
          >
            <ImFacebook2 size={20} />
          </a>
          <a
            className={
              props.data.job_company_twitter_url
                ? "socialIcon twitter"
                : "socialIcon grayIcon"
            }
            href={
              props.data.job_company_twitter_url
                ? `https://${props.data.job_company_twitter_url}`
                : null
            }
            target="_blank"
            rel="noreferrer"
          >
            <ImTwitter size={20} />
          </a>
        </>
      ) : (
        <>
          <a
            className="card-link socialIcon grayIcon"
            href={null}
            target=""
            rel="noreferrer"
          >
            <ImLinkedin size={20} />
          </a>
          <a
            className="card-link socialIcon grayIcon"
            href={null}
            target=""
            rel="noreferrer"
          >
            <ImFacebook2 size={20} />
          </a>
          <a
            className="card-link socialIcon grayIcon"
            href={null}
            target=""
            rel="noreferrer"
          >
            <ImTwitter size={20} />
          </a>
        </>
      )}
    </div>
  );
}
