import React, { useEffect } from "react";
import Container from "../Partials/ProgressBar";
import { useLocation } from "react-router";
import { createBrowserHistory } from "history";

const queryString = require("query-string");
const history = createBrowserHistory();

export default function UrlSignIn(props) {
  const location = useLocation();
  const queryToken = queryString.parse(location.search).token;

  useEffect(() => {
    props.login(queryToken);
  }, []);

  useEffect(() => {
    if (props.user) {
      redirectSuccessLogin();
    }
  }, [props.user]);

  const redirectSuccessLogin = () => {
    history.push(`/`);
    window.location.reload();
  };

  return (
    <>
      {props.loading ? (
        <Container redirect={true} />
      ) : props.user ? (
        <div className="App">
          <h1 className="">Redirecting...</h1>
        </div>
      ) : (
        <>
          {!props.loginError ? (
            <Container redirect={true} />
          ) : (
            <div className="App">
              <h1 className="">Access Denied</h1>
            </div>
          )}
        </>
      )}
    </>
  );
}
