import React, { useState, useEffect } from "react";
import AppBarNew from "../Partials/AppBar";
import Profile from "./Profile";
import SimpleModal from "../Partials/BasicModal";
import Footer from "../Partials/Footer";

export default function Account(props) {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    handleClose();
  }, [props.user]);

  return (
    props.user && (
      <div className="App" style={{ backgroundColor: "#FFFFFF" }}>
        <AppBarNew
          openUpgradeModal={props.openUpgradeModal}
          upgradeLoader={props.upgradeLoader}
          handleHomeLogOut={props.handleHomeLogOut}
          className="mobileHeader"
          user={props.user}
          role={props.role}
          token={props.token}
          email={props.email}
          domains={props.domains}
          handleLogOut={props.handleLogOut}
          filterCompanies={(industries, sizes, countries, regions) =>
            props.filterCompanies(industries, sizes, countries, regions)
          }
          resetUser={(user) => props.resetUser(user)}
          credits={props.user.user.credits}
          openTopUps={() =>
            props.user.sub && props.user.sub.status != "pending" && handleOpen()
          }
        />
        <SimpleModal
          user={props.user}
          title="Top-Up Your Credits Balance for Additional Unlocks:"
          open={open}
          handleOpen={handleOpen}
          handleClose={handleClose}
          topUpLoader={props.topUpLoader}
          openTopUpModal={(item) => props.openTopUpModal(item)}
        />
        <Profile
          resetUser={(user) => props.resetUser(user)}
          user={props.user}
          handleHomeLogOut={props.handleHomeLogOut}
          resetSub={(sub) => props.resetSub(sub)}
          open={() => handleOpen()}
        />
        {!props.loading && <Footer />}
      </div>
    )
  );
}
