import React from "react";
import logo from "../../images/hrlogo_new.png";

function Tos(props) {
  return (
    <div className="App">
      <br />
      <br />
      <img src={logo} width="300" alt="Hudson Rock Logo" />
      <br />
      <br />
      <h2>HUDSON ROCK END USER AGREEMENT - BAYONET</h2>
      <hr />
      <div
        className="content"
        style={{
          width: "60%",
          margin: "auto",
          paddingBottom: "1rem",
          textAlign: "left",
        }}
      >
        <p>
          THIS HUDSON ROCK END USER AGREEMENT, TOGETHER WITH THE HUDSON ROCK
          PRIVACY POLICY (COLLECTIVELY, THE "AGREEMENT") IS A LEGAL AGREEMENT
          BETWEEN YOU ("YOU" OR "CUSTOMER") AND HUDSON ROCK, LTD (“HUDSON
          ROCK”).
        </p>
        <p>
          Hudson Rock may unilaterally change or add to the terms of this
          Agreement at any time. In the event of a material change, Hudson Rock
          shall notify you via email or by means of a prominent notice on the
          Hudson Rock website available at: HudsonRock.com, which you should
          check periodically. By continuing to use the Solution following such
          modifications, you agree to be bound by such modifications.
        </p>

        <h3>1. Grant of Right to Use and Restrictions</h3>
        <h4>1.1 Right to Use</h4>
        <p>
          Subject to the terms and conditions of this Agreement and the terms
          and conditions of a written order provided by Hudson Rock and signed
          by you or otherwise signed by both parties or the terms set forth in
          the subscription plan you selected on Hudson Rock’s website (the
          "Order"), Hudson Rock hereby grants you during the applicable
          subscription term specified in the Order, and you accept, a limited,
          non-exclusive, non-sublicensable and non-transferable right to use the
          Solution during the Term internally, solely for the purpose of ethical
          disclosure of Deliverables to prospective customers and subject to any
          specific use limitations specified in the Order. "Solution" means the
          Hudson Rock’s proprietary solution identified in the applicable Order
          and all manuals, specifications, and other documentation provided by
          or on behalf of Hudson Rock, as well as any updates or upgrades
          provided by Hudson Rock herein. "Deliverables" mean the content or
          data provided by Hudson Rock.
        </p>
        <h4>1.2 Prohibited Uses</h4>
        <p>
          Except as expressly permitted herein, you shall not, directly or
          indirectly: (i) modify, incorporate into or use the Solution with
          other software, or create a derivative work of any part of the
          Solution; (ii) sell, resell, license (or sub-license), lease, assign,
          transfer, pledge, or share the Solution or Deliverables or any of your
          rights under this Agreement with or to anyone else; (iii) copy or
          reproduce, distribute or publish the Solution or the Deliverables;
          (iv) disclose, publish or otherwise make publicly available the
          results of any benchmarking of the Solution or Deliverables, or use
          such results for your own competing software development activities;
          (v) modify, disassemble, decompile, reverse engineer, revise or
          enhance the Solution or attempt to reconstruct or discover any source
          code or underlying ideas or algorithms of the Solution or use the
          Solution for purposes of competitive analysis or the development of a
          competing software product or service; (vi) remove or otherwise alter
          any of Hudson Rock's trademarks, logos, copyrights, notices or other
          proprietary notices or indicia, if any, fixed or attached to the
          Solution or Deliverables; (vii) ship, transfer, or export the Solution
          or Deliverables or use the Solution or Deliverables in any manner that
          is prohibited by law, including without limitation, to sell,
          distribute, download or export the Solution: (a) into (or to a
          national or resident of) Cuba, Iran, Iraq, Libya, North Korea, Sudan,
          Lebanon or Syria, (b) to anyone on the U.S. Commerce Department’s
          Table of Denial Orders or U.S. Treasury Department’s list of Specially
          Designated Nationals, (c) to any country to which such export or
          re-export is restricted or prohibited, or as to which the U.S. or
          Israeli government or any agency thereof requires an export license or
          other governmental approval at the time of export or re-export without
          first obtaining such license or approval, or (d) otherwise in
          violation of any export or import restrictions, laws or regulations of
          the U.S. or Israel or any foreign agency or authority. You agree to
          the foregoing and warrant that you are not located in, under the
          control of, or a national or resident of any such prohibited country
          or on any such prohibited party list; (viii) exceed any use
          limitations or other restrictions which are specified in the Order;
          (ix) contest Hudson Rock’s Intellectual Property Rights (as defined
          below) to the Hudson Rock IPR (as defined below); (x) use the Solution
          or Deliverables for the purpose of providing services to any third
          party or any purpose other than as permitted by this Agreement or
          attempt to access any part of the solution without authorization or of
          Hudson Rock's servers by unauthorized means or overburden the Solution
          or its servers with excessive system or API calls; (xi) circumvent,
          disable or otherwise interfere with security-related or technical
          features or protocols of the Solution, such as features that restrict
          or monitor use of the Solution or access the Hudson Rock API by any
          means other than authorized means described in the Hudson Rock
          documentation of that API provided to you by Hudson Rock or in
          accordance with developer credentials assigned to you by Hudson Rock;
          (xii) use the Solution to access any confidential or other non-public
          information of any third party without such third party’s permission
          and/or collect, copy or use any Deliverables in a manner that infringe
          or violate the rights of any third party, including without limitation
          the right to privacy and Intellectual Property Rights; (xiii) charge
          any fees or other consideration for the Deliverables; or (xiv) cause
          or permit any third party to do any of the foregoing. You are solely
          responsible for acquiring and maintaining all of the hardware,
          software and services necessary to access and make use of the
          Solution, including without limitation paying all fees and other costs
          related to internet access, server or cloud account subscription and
          maintenance.
        </p>

        <h3>2. Consideration</h3>
        <p>
          The consideration for the right to use granted hereunder, will be in
          accordance with the payment terms, subscription plans and metrics
          specified in the Order. Unless otherwise specified in the Order, (i)
          you will pay all amounts due under this Agreement in U.S. Dollars, and
          (ii) all amounts invoiced hereunder are due and payable within thirty
          (30) days of the date of the invoice. All amounts payable under this
          Agreement are exclusive of sales, use, value-added, withholding, and
          other taxes and duties. You shall pay all taxes and duties assessed in
          connection with this Agreement by any authority. If any such tax or
          duty has to be withheld or deducted from any payment under this
          Agreement, you shall gross-up the payment under this Agreement by such
          amount to ensure that after such withholding or deduction Hudson Rock
          shall receive an amount equal to the payment otherwise required. All
          payments not made when due shall bear interest at the rate of 1.5% per
          month, or at the highest interest rate allowed by law, whichever is
          less, from the due date until paid.
        </p>

        <h3>3. Confidentiality</h3>
        <p>
          You may have access to certain non-public or proprietary information
          or materials of Hudson Rock whether in tangible or intangible form
          ("Confidential Information"). Without derogating from the foregoing,
          the Solution, Deliverables and terms of the Order shall be deemed as
          Confidential Information. You shall use the Confidential Information
          solely for the purpose of performing your obligations and/or
          exercising your rights under this Agreement and you shall not disclose
          or make available the Confidential Information to any third party,
          except to your employees that have a need to know such information and
          that are bound by obligations at least as protective as provided
          herein. You shall take measures at a level at least as protective as
          those taken to protect your own confidential information of like
          nature (but in no event less than a reasonable level) to protect the
          Confidential Information. You will promptly notify Hudson Rock in
          writing in the event of any actual or suspected unauthorized use or
          disclosure of any Confidential Information.
        </p>

        <h3>4. Title & Ownership; Anonymous Data</h3>
        <h4>4.1 Title & Ownership</h4>
        <p>
          The Solution is not sold but provided for use for a limited term.
          Hudson Rock and/or its licensors or designees are and shall retain all
          right, title, interest and ownership of all intellectual property
          rights in and to the Solution and related documentation and
          Confidential Information as well as any modifications, improvements
          and derivatives thereof ("Hudson Rock IPR"). This Agreement does not
          convey to you an interest in or to the Hudson Rock IPR but only a
          limited revocable right to use the Solution in accordance with the
          terms of this Agreement. Nothing in this Agreement constitutes a
          waiver of the Hudson Rock IPR under any law and you undertake not to
          contest Hudson Rock's ownership in the Hudson Rock IPR. If you contact
          Hudson Rock with feedback data (e.g. questions, comments, ideas,
          suggestions or the like) regarding the Solution (collectively,
          “Feedback”) such Feedback shall be deemed Hudson Rock IPR. Hudson Rock
          may, at no cost, freely use such Feedback, for any purpose whatsoever
          and you hereby assign all right, title and interest in and to all
          Feedback to Hudson Rock upon creation thereof.
        </p>
        <h4>4.2 Anonymous Data</h4>
        <p>
          Hudson Rock may collect, disclose, publish, store and use in any other
          manner any anonymous and non-identifiable information which is derived
          from your use of the Solution ("Anonymous Information") and
          information derived from Deliverables, in order to provide and improve
          Hudson Rock's Solution and related services and for any business
          purposes. Hudson Rock is and shall remain the owner of the Anonymous
          Information which shall be deemed Hudson Rock IPR.
        </p>

        <h3>5. Disclaimer of Warranty</h3>
        <p>
          5.1 THE SOLUTION AND DELIVERABLES ARE PROVIDED “AS IS”, WITHOUT ANY
          REPRESENTATIONS OR WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED.
          TO THE MAXIMUM EXTENT PERMITTED BY LAW, HUDSON ROCK DISCLAIMS ALL
          WARRANTIES, EITHER EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO
          IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR
          PURPOSE OR USE, SECURITY AND NON-INFRINGEMENT. THE ENTIRE RISK ARISING
          OUT OF THE USE OR PERFORMANCE OF THE SOLUTION REMAINS WITH YOU.
        </p>
        <p>
          5.2 HUDSON ROCK DOES NOT WARRANT THAT THE SOLUTION WILL BE
          UNINTERRUPTED OR ERROR-FREE; OR THAT ERRORS/BUGS ARE REPRODUCIBLE OR
          THAT ERRORS/BUGS ARE REPAIRABLE AND DOES NOT WARRANT OR MAKE ANY
          REPRESENTATIONS REGARDING THE USE OR THE RESULTS OF THE USE OF THE
          SOLUTION IN TERMS OF THEIR CORRECTNESS, USEFULNESS, ACCURACY,
          RELIABILITY, OR OTHERWISE. YOU SHALL BE RESPONSIBLE FOR TAKING ALL
          PRECAUTIONS YOU BELIEVE ARE NECESSARY OR ADVISABLE TO PROTECT YOU
          AGAINST ANY CLAIM, DAMAGE, LOSS OR HAZARD THAT MAY ARISE BY VIRTUE OF
          ANY USE OF OR RELIANCE UPON THE SOLUTION AND FOR VERIFYING ANY OUTPUT
          RESULTING FROM USE OF THE SOLUTION.
        </p>
        <p>
          5.3 THE DELIVERABLES ARE BASED ON INFORMATION AND CONTENT COLLECTED
          FROM THE DARK AND DEEP-WEB AND SUCH OTHER THIRD PARTY SOURCES AND
          THEREFORE HUDSON ROCK DOES NOT WARRANT THAT THEY ARE CORRECT,
          COMPLETE, ACCURATE OR RELIABLE.
        </p>

        <h3>6. Limitation of Liability</h3>
        <p>
          TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW: (A) HUDSON ROCK
          AND/OR ITS AFFILIATES, SHAREHOLDERS, SUPPLIERS, MANAGERS, DIRECTORS,
          OFFICERS, EMPLOYEES AND/OR LICENSORS (COLLECTIVELY, “AFFILIATES”)
          SHALL NOT BE LIABLE WHETHER UNDER CONTRACT, TORT OR OTHERWISE, TO YOU
          OR ANY THIRD PARTY FOR ANY LOSS OR DAMAGE, INCLUDING, WITHOUT
          LIMITATION, INDIRECT, INCIDENTAL, PUNITIVE, EXEMPLARY, SPECIAL OR
          CONSEQUENTIAL DAMAGES OF ANY KIND (INCLUDING BUT NOT LIMITED TO, ANY
          LOSS OR DAMAGE TO BUSINESS EARNINGS, LOSS OF BUSINESS, LOSS OF
          BUSINESS OPPORTUNITIES, LOST PROFITS OR GOODWILL, BUSINESS
          INTERRUPTION AND/OR LOST OR DAMAGED DATA OR DOCUMENTATION), SUFFERED
          BY ANY PERSON OR ENTITY, INCLUDING WITHOUT LIMITATION ARISING FROM
          AND/OR RELATED WITH AND/OR CONNECTED TO THE SOLUTION OR DELIVERABLES
          PROVIDED BY HUDSON ROCK (IF ANY) AND/OR ANY USE OF OR INABILITY TO USE
          THE SOLUTION OR DELIVERABLES PROVIDED BY HUDSON ROCK (IF ANY), EVEN IF
          HUDSON ROCK HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES; AND
          (B) IN NO EVENT SHALL HUDSON ROCK'S AND ITS AFFILIATES’ TOTAL
          LIABILITY ARISING OUT OF OR RELATING TO THIS AGREEMENT FROM ALL CLAIMS
          OR CAUSES OF ACTION AND UNDER ALL THEORIES OF LIABILITY, EXCEED THE
          TOTAL PAYMENTS ACTUALLY MADE TO HUDSON ROCK FOR THE SOLUTION, IF ANY,
          DURING THE TWELVE (12) MONTH PERIOD PRIOR TO THE EVENT THAT GAVE RISE
          TO THE CLAIM.
        </p>

        <h3>7. Indemnification</h3>
        <p>
          You agree to defend, indemnify and hold harmless Hudson Rock, its
          affiliates, licensors, suppliers, officers, directors, employees and
          agents, from and against any and all claims, damages, obligations,
          losses, liabilities, costs, debts, and expenses (including but not
          limited to attorney's fees) arising from: (i) your use of the Solution
          or Deliverables; and/or (ii) your violation of any term of this
          Agreement or any law, rule, regulation or order.
        </p>

        <h3>8. Term and Termination</h3>
        <p>
          The term of this Agreement shall be as set forth in the Order and may
          be terminated earlier in accordance with this Section (“Term”). Hudson
          Rock may terminate this Agreement immediately without notice if you
          fail to comply or breach any provision of this Agreement. Upon
          termination of this Agreement: (i) the rights granted to you in this
          Agreement shall expire and you, upon termination, shall discontinue
          all further use of the Solution or Deliverables; (ii) you shall
          promptly remove the Solution and Deliverables from all hard drives,
          networks and other storage media and destroy all copies of the
          Solution in your possession or under your control; (iii) any sums paid
          by you until the date of termination are non-refundable, and you shall
          not be relieved of your duty to discharge in full all due sums owed by
          you to Hudson Rock under this Agreement, which sums shall become
          immediately due and payable on the date of termination of the
          Agreement; and (iv) you shall, at Hudson Rock's election, erase or
          return to Hudson Rock all Confidential Information in your possession
          or under your control. Sections 1.2, 2-10 shall survive any
          termination of this Agreement.
        </p>

        <h3>9. Privacy</h3>
        <p>
          Hudson Rock's privacy practices are governed by Hudson Rock's Privacy
          Policy available at HudsonRock.com/cavalier/privacy, which is an
          integral part of this Agreement.
        </p>

        <h3>10. Miscellaneous</h3>
        <p>
          This Agreement shall be construed and governed in accordance with the
          laws of the State of New York, USA and the Federal and State courts of
          New York, USA shall have exclusive jurisdiction over this Agreement,
          and each party hereby submits itself to the exclusive jurisdiction of
          these courts. The application of the United Nations Convention of
          Contracts for the International Sale of Goods is expressly excluded.
          This Agreement represents the complete agreement concerning the rights
          granted to you herein and the subject matter hereof and may be amended
          as set forth in the preamble to this Agreement above or by a written
          agreement executed by both parties. To the extent any conflict arises
          between the terms and conditions of this Agreement and those contained
          in the Order, the terms and conditions contained in this Agreement
          shall prevail. The failure of either party to enforce any rights
          granted hereunder or to take action against the other party in the
          event of any breach hereunder shall not be deemed a waiver by that
          party as to subsequent enforcement of rights or subsequent actions in
          the event of future breaches. If any provision of this Agreement is
          held to be unenforceable, such provision shall be reformed only to the
          extent necessary to make it enforceable. You may not assign your
          rights or obligations under this Agreement without the prior written
          consent of Hudson Rock. Hudson Rock may assign or transfer its rights
          and/or obligations under this Agreement without restriction or
          notification.
        </p>
      </div>
    </div>
  );
}

export default Tos;
