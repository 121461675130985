import React, { useEffect, useRef } from "react";
import { Bar, Doughnut } from "react-chartjs-2";
import Container from "../Partials/ProgressBar";
import ChartDataLabels from "chartjs-plugin-datalabels";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import LoaderIcon from "react-loader-icon";

function BarChart(props) {
  const myContainer = useRef(null);
  const { height, width } = useWindowDimensions();

  let hovering = false;
  // tooltip = document.getElementById("tooltip");
  const data = {
    labels: props.data,
    datasets: [
      {
        data: props.dataCount,
        fill: true,
        color: props.labelColor,
        backgroundColor: [
          "rgba(255, 99, 132, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(255, 206, 86, 0.2)",
          "rgba(75, 192, 192, 0.2)",
          "rgba(153, 102, 255, 0.2)",
          "rgba(255, 159, 64, 0.2)",
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(255, 159, 64, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };

  const options = {
    responsive: true,
    layout: {
      padding: 0,
    },
    plugins: {
      datalabels: {
        formatter: (value) => {
          return value;
        },
        display: true,
        color: props.labelColor,
        // clip: true,
      },
      legend: {
        onHover: function (event, legendItem) {
          if (hovering) {
            return;
          }
          hovering = true;
          myContainer.current.style.display = "block";
          myContainer.current.innerHTML = legendItem.text;
          if (props.screen === "main") {
            // if (props.type === "(Users)") {
            //   console.log(height);
            //   console.log(width);
            //   if (width >= 1440) {
            //     myContainer.current.style.left = event.x + 600 + "px";
            //     myContainer.current.style.top = event.y + 850 + "px";
            //   }
            // } else {
            //   myContainer.current.style.left = event.x + 10 + "px";
            //   myContainer.current.style.top = event.y + 840 + "px";
            // }
          } else {
            if (props.type === "(Users)") {
              myContainer.current.style.left = event.x + 10 + "px";
              myContainer.current.style.top = event.y + 1060 + "px";
            } else {
              myContainer.current.style.left = event.x + 10 + "px";
              myContainer.current.style.top = event.y + 530 + "px";
            }
          }
        },
        onLeave: function () {
          hovering = false;
          myContainer.current.innerHTML = "";
          myContainer.current.style.display = "none";
        },
        display: true,
        position: "left",
        labels: {
          usePointStyle: true,
          color: props.labelColor,
        },
      },
    },
    indexAxis: "y",
    elements: {
      bar: {
        borderWidth: 2,
      },
    },
  };

  return (
    <>
      {props.data && props.data.length > 0 ? (
        !props.loading ? (
          <>
            <div className="header">
              <h2 className="title chartTitle">
                Top Compromised URLs {props.type}
              </h2>
              {props.subtitle && (
                <div
                  onClick={props.discoverMore}
                  className="cursor"
                  style={{
                    backgroundColor: "#FBCD4E",
                    color: "#222831",
                    padding: "10px",
                    borderRadius: "5px",
                    width: "30%",
                    margin: "auto",
                  }}
                >
                  <h6 style={{ margin: "0px" }}>{props.subtitle}</h6>
                </div>
              )}
              <hr></hr>
            </div>
            <div ref={myContainer} id="tooltip"></div>
            <Doughnut
              className="donutChart"
              data={data}
              options={options}
              plugins={[ChartDataLabels]}
              height={props.height}
              width={props.width}
              style={{
                backgroundColor: props.screen === "main" && "#222831",
                padding: props.screen === "main" && "30px",
                borderRadius: props.screen === "main" && "50px",
                // boxShadow:
                //   props.screen === "main" &&
                //   "0 5px 5px 0 rgba(0, 0, 0, 0.2), 0 5px 5px 0 rgba(0, 0, 0, 0.19)",
              }}
            />
          </>
        ) : (
          <LoaderIcon
            type="spin"
            style={{
              fill: "white",
              width: "80px",
              height: "80px",
              position: "relative",
              top: "5rem",
            }}
          />
        )
      ) : props.loading ? (
        <LoaderIcon
          type="spin"
          style={{
            fill: "white",
            width: "80px",
            height: "80px",
            position: "relative",
            top: "5rem",
          }}
        />
      ) : (
        <h2 className={props.textColor === "black" && "lightText"}>
          No Stats To Show {props.type}
        </h2>
      )}
    </>
  );
}

export default BarChart;
