import React from "react";
import logo from "../../images/logo.png";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },

  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
}));

export default function ThankYou(props) {
  const classes = useStyles();

  return (
    <>
      <div className={classes.grow}>
        <AppBar position="static">
          <Toolbar className="Appbar-header">
            <Link className="logoLink" to="/">
              <img
                src={logo}
                className="Appbar-logo"
                alt="logo"
                width="60"
                height="60"
              />
            </Link>
          </Toolbar>
        </AppBar>
      </div>
      <div className="thankyou-page">
        <h1>Welcome To Bayonet</h1>
        <p className="thankyou-content">
          Your account is under review now, you will be notified soon about your
          account activation by email.
        </p>
      </div>
    </>
  );
}
