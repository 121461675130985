/* global bluesnap */
import React, { useEffect, useState, useRef } from "react";
// import "./css/credit-card.css";
// import "./css/form-style.css";
// import "./css/main.css";
import useScript from "react-script-hook";
import CreateApiClient from "../../API";
import { bsURL, bsEnv } from "../../Config";
import LoaderIcon from "react-loader-icon";
import Button from "@material-ui/core/Button";
import $ from "jquery";

const api = CreateApiClient();
function Creditcard(props) {
  const [cvc, setCvc] = useState("");
  const [pfToken, setPfToken] = useState();
  const [errorMessage, setErrorMessage] = useState(null);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");

  const cardNumberRef = useRef(null);
  const expRef = useRef(null);
  const cvvRef = useRef(null);

  const clearForm = () => {
    setFirstName("");
    setLastName("");
    setErrorMessage(null);
  };

  useEffect(() => {
    if (pfToken) {
      loadPaymentFields(pfToken);
    }
  }, [pfToken]);

  useScript({
    src: bsURL,
    onload: async () => {
      const token = await api.getPfToken(bsEnv);
      setPfToken(token);
    },
  });

  useScript({
    src: "https://cdnjs.cloudflare.com/ajax/libs/jquery/2.1.3/jquery.min.js",
    onload: () => {},
  });

  useScript({
    src: "https://maxcdn.bootstrapcdn.com/bootstrap/3.3.5/js/bootstrap.min.js",
    onload: () => {},
  });

  useScript({
    src: "https://cdnjs.cloudflare.com/ajax/libs/bootstrap-material-design/0.3.0/js/material.js",
    onload: () => {},
  });

  useScript({
    src: "https://cdnjs.cloudflare.com/ajax/libs/bootstrap-material-design/0.3.0/js/ripples.js",
    onload: () => {},
  });

  function changeImpactedElement(tagId, removeClass, addClass) {
    removeClass = removeClass || "";
    addClass = addClass || "";
    $("[data-bluesnap=" + tagId + "]")
      .removeClass(removeClass)
      .addClass(addClass);
  }
  var cardUrl = {
    AMEX: "https://files.readme.io/97e7acc-Amex.png",
    DINERS: "https://files.readme.io/8c73810-Diners_Club.png",
    DISCOVER: "https://files.readme.io/caea86d-Discover.png",
    JCB: "https://files.readme.io/e076aed-JCB.png",
    MASTERCARD: "https://files.readme.io/5b7b3de-Mastercard.png",
    VISA: "https://files.readme.io/9018c4f-Visa.png",
  };

  const loadPaymentFields = async (token) => {
    $(function () {
      const bsObj = {
        //insert your Hosted Payment Fields token
        token: token,
        onFieldEventHandler: {
          onFocus: function (tagId) {
            // Handle focus
            changeImpactedElement(tagId, "", "hosted-field-focus");
          },
          onBlur: function (tagId) {
            // Handle blur
            changeImpactedElement(tagId, "hosted-field-focus");
          },
          onError: function (tagId, errorCode, errorDescription) {
            // Handle a change in validation by displaying help text
            $("#" + tagId + "-help")
              .removeClass("helper-text-green")
              .text(errorCode + " - " + errorDescription);
          },
          onType: function (tagId, cardType, cardData) {
            // get card type from cardType and display card image
            $("#card-logo > img").attr("src", cardUrl[cardType]);
            if (null != cardData) {
              $("#" + tagId + "-help")
                .addClass("helper-text-green")
                .text(JSON.stringify(cardData));
            }
          },
          onValid: function (tagId) {
            // Handle a change in validation by removing any help text
            $("#" + tagId + "-help").text("");
          },
        },
        style: {
          // Styling all inputs
          input: {
            "font-size": "14px",
            "font-family":
              "RobotoDraft,Roboto,Helvetica Neue,Helvetica,Arial,sans-serif",
            "line-height": "1.42857143",
            color: "#555",
          },
          // Styling input state
          ":focus": {
            color: "#555",
          },
        },
        ccnPlaceHolder: "1234 5678 9012 3456",
        cvvPlaceHolder: "123",
        expPlaceHolder: "MM / YY",
      };
      bluesnap.hostedPaymentFieldsCreate(bsObj);
    });
  };

  function submitForm(e) {
    e.preventDefault();
    bluesnap.hostedPaymentFieldsSubmitData(async function (callback) {
      if (null != callback.error) {
        var errorArray = callback.error;
        for (var i in errorArray) {
          $("#" + errorArray[i].tagId + "-help").text(
            errorArray[i].errorCode + " - " + errorArray[i].errorDescription
          );
        }
      } else {
        var cardData = callback.cardData;
        await props.submit(firstName, lastName, pfToken);
        clearForm();
        loadPaymentFields(pfToken);
      }
    });
  }

  return (
    <form className="ccForm" onSubmit={submitForm}>
      <div
        className=""
        style={{
          width: "100%",
          display: "inline-flex",
          alignItems: "center",
        }}
      >
        <div style={{ display: "block", margin: "0px 2px" }}>
          <label htmlFor="first-name">First Name *</label>
          <input
            type="text"
            id="first-name"
            name="first-name"
            spellCheck="false"
            value={firstName}
            maxLength="20"
            autoComplete="off"
            onChange={(e) => setFirstName(e.target.value)}
            className="form-control-checkout form-control form-control-lg blackTextInput"
          />
          <span className="helper-text"></span>
        </div>
        <div style={{ display: "block", margin: "0px 2px" }}>
          <label htmlFor="first-name">Last Name *</label>
          <input
            type="text"
            id="last-name"
            name="last-name"
            spellCheck="false"
            value={lastName}
            maxLength="20"
            autoComplete="off"
            onChange={(e) => setLastName(e.target.value)}
            className="form-control-checkout form-control form-control-lg blackTextInput"
          />
          <span className="helper-text"></span>
        </div>
      </div>
      <div className="form-group">
        <label htmlFor="card-number">Card Number *</label>
        <div className="input-group">
          <div
            data-bluesnap="ccn"
            type="text"
            name="number"
            maxLength="19"
            id="card-number"
            className="form-control-checkout form-control form-control-lg"
          ></div>
          <input type="hidden" id="hiddenCardNumber" />
        </div>
        <span className="helper-text" id="ccn-help"></span>
      </div>
      <div className="date-cvv-box">
        <div className="form-group" style={{ minWidth: "25%" }}>
          <label htmlFor="exp-date">Exp. (MM/YY) *</label>
          <div
            className="form-control-checkout form-control"
            id="exp-date"
            data-bluesnap="exp"
          ></div>
          <span className="helper-text" id="exp-help"></span>
        </div>
        <div
          className="form-group"
          style={{ minWidth: "25%", justifyContent: "center" }}
        >
          <label htmlFor="cvv">CVV *</label>
          <div
            id="cvv"
            data-bluesnap="cvv"
            type="number"
            name="cvc"
            value={cvc}
            className="form-control-checkout form-control form-control-lg "
            maxLength="4"
          ></div>
          <span className="helper-text" id="cvv-help"></span>
        </div>
      </div>
      <Button
        type="submit"
        variant="contained"
        className={"searchBtn desktopSearchBtn"}
        fullWidth
      >
        Add
        {props.loading && (
          <LoaderIcon
            type={"spin"}
            style={{
              width: "16px",
              height: "auto",
              margin: "0px 2px 2px",
            }}
          />
        )}
      </Button>
      <p className="red">{errorMessage}</p>
    </form>
  );
}

export default Creditcard;
