/* global bluesnap */
import React, { useEffect, useState } from "react";
import Col from "react-bootstrap/Col";
import useScript from "react-script-hook";
import CreateApiClient from "../../API";
import { Helmet } from "react-helmet";
import cryptoRandomString from "crypto-random-string";
import Overlay from "../Partials/Overlay";
import { bsURL, bsEnv } from "../../Config";
import SimpleModal from "../Partials/BasicModal";
import { isValidEmail } from "../../hooks/Validation";
import $ from "jquery";

const api = CreateApiClient();

const PaymentFields = (props) => {
  const [pfToken, setPfToken] = useState(null);
  const [paymentLoader, setPaymentLoader] = useState(false);
  const [failed, setFailed] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  useScript({
    src: bsURL,
    onload: async () => {
      const token = await api.getPfToken(bsEnv);
      setPfToken(token);
    },
  });

  useScript({
    src: "https://cdnjs.cloudflare.com/ajax/libs/jquery/2.1.3/jquery.min.js",
    onload: () => {},
  });

  useScript({
    src: "https://maxcdn.bootstrapcdn.com/bootstrap/3.3.5/js/bootstrap.min.js",
    onload: () => {},
  });

  useScript({
    src: "https://cdnjs.cloudflare.com/ajax/libs/bootstrap-material-design/0.3.0/js/material.js",
    onload: () => {},
  });

  useScript({
    src: "https://cdnjs.cloudflare.com/ajax/libs/bootstrap-material-design/0.3.0/js/ripples.js",
    onload: () => {},
  });

  function changeImpactedElement(tagId, removeClass, addClass) {
    removeClass = removeClass || "";
    addClass = addClass || "";
    $("[data-bluesnap=" + tagId + "]")
      .removeClass(removeClass)
      .addClass(addClass);
  }
  var cardUrl = {
    AMEX: "https://files.readme.io/97e7acc-Amex.png",
    DINERS: "https://files.readme.io/8c73810-Diners_Club.png",
    DISCOVER: "https://files.readme.io/caea86d-Discover.png",
    JCB: "https://files.readme.io/e076aed-JCB.png",
    MASTERCARD: "https://files.readme.io/5b7b3de-Mastercard.png",
    VISA: "https://files.readme.io/9018c4f-Visa.png",
  };

  const loadPaymentFields = async (token) => {
    console.log(token);
    $(function () {
      const bsObj = {
        //insert your Hosted Payment Fields token
        token: token,
        onFieldEventHandler: {
          onFocus: function (tagId) {
            // Handle focus
            changeImpactedElement(tagId, "", "hosted-field-focus");
          },
          onBlur: function (tagId) {
            // Handle blur
            changeImpactedElement(tagId, "hosted-field-focus");
          },
          onError: function (tagId, errorCode, errorDescription) {
            // Handle a change in validation by displaying help text
            $("#" + tagId + "-help")
              .removeClass("helper-text-green")
              .text(errorCode + " - " + errorDescription);
          },
          onType: function (tagId, cardType, cardData) {
            // get card type from cardType and display card image
            $("#card-logo > img").attr("src", cardUrl[cardType]);
            if (null != cardData) {
              $("#" + tagId + "-help")
                .addClass("helper-text-green")
                .text(JSON.stringify(cardData));
            }
          },
          onValid: function (tagId) {
            // Handle a change in validation by removing any help text
            $("#" + tagId + "-help").text("");
          },
        },
        style: {
          // Styling all inputs
          input: {
            "font-size": "14px",
            "font-family":
              "RobotoDraft,Roboto,Helvetica Neue,Helvetica,Arial,sans-serif",
            "line-height": "1.42857143",
            color: "#555",
          },
          // Styling input state
          ":focus": {
            color: "#555",
          },
        },
        ccnPlaceHolder: "1234 5678 9012 3456",
        cvvPlaceHolder: "123",
        expPlaceHolder: "MM / YY",
      };
      bluesnap.hostedPaymentFieldsCreate(bsObj);
    });
  };

  function submitForm() {
    if (
      props.firstName &&
      props.lastName &&
      isValidEmail(props.email) &&
      props.password &&
      props.companyName &&
      props.companyWebsite &&
      isValidNumber(props.phone) &&
      props.title &&
      props.country &&
      props.city &&
      props.address
    ) {
      bluesnap.hostedPaymentFieldsSubmitData(async function (callback) {
        if (null != callback.error) {
          var errorArray = callback.error;
          for (var i in errorArray) {
            $("#" + errorArray[i].tagId + "-help").text(
              errorArray[i].errorCode + " - " + errorArray[i].errorDescription
            );
          }
        } else {
          setPaymentLoader(true);
          var cardData = callback.cardData;
          const url_hash = cryptoRandomString({
            length: 64,
            type: "url-safe",
          });

          const api_key = cryptoRandomString({
            length: 64,
            type: "url-safe",
          });

          const customer = {
            firstName: props.firstName,
            lastName: props.lastName,
            email: props.email,
            password: props.password,
            url_hash: url_hash,
            api_key: api_key,
            companyName: props.companyName,
            companyWebsite: props.companyWebsite,
            phone: props.phone,
            title: props.title,
            country: props.country,
            state: props.state,
            city: props.city,
            address: props.address,
            address2: props.address2,
            zip: props.zip,
            card_data: cardData,
            seats: props.qty,
            overRideAmount: props.overRideAmount,
          };

          const paid = await api.makePayment(
            bsEnv,
            props.plan,
            customer,
            {
              headers: { token: pfToken },
            },
            props.qty,
            props.overRideAmount
          );

          if (paid.status != 400) {
            props.success();
            setPaymentLoader(false);
          } else {
            if (typeof paid.data === "string") {
              setErrorMessage(paid.data);
            }
            setPaymentLoader(false);
            setFailed(true);
          }
        }
      });
    } else {
      props.validateFields();
    }
  }

  // const isValidEmail = (email) => {
  //   return /^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/.test(email);
  // };

  const isValidNumber = (number) => {
    return /^[0-9]+$/.test(number);
  };

  return (
    <div>
      <Helmet>
        <link
          rel="stylesheet"
          href="https://maxcdn.bootstrapcdn.com/bootstrap/3.3.4/css/bootstrap.min.css"
        />
      </Helmet>
      <div className="panel panel-primary">
        <div className="panel-heading">
          <h3 className="panel-title">Credit Card Payment</h3>
        </div>
        <form className="panel-body" id="checkout-form">
          <div className="row">
            <div className="form-group col-xs-8 paymentFields" as={Col} xs="8">
              <label htmlFor="card-number">Card Number</label>
              <div className="input-group">
                <div
                  className="form-control"
                  id="card-number"
                  data-bluesnap="ccn"
                ></div>
                <div id="card-logo" className="input-group-addon">
                  <img
                    alt="logo"
                    src="https://files.readme.io/d1a25b4-generic-card.png"
                    height="20px"
                  />
                </div>
              </div>
              <span className="helper-text" id="ccn-help"></span>
            </div>
            <div className="form-group col-xs-4 paymentFields" as={Col} xs="4">
              <label htmlFor="cvv">CVV</label>
              <div className="form-control" id="cvv" data-bluesnap="cvv"></div>
              <span className="helper-text" id="cvv-help"></span>
            </div>

            <div className="form-group col-md-8 paymentFields">
              <label htmlFor="cardholder-name">Cardholder Name</label>
              <input
                type="text"
                className="form-control"
                id="cardholder-name"
              />
              <span className="helper-text"></span>
            </div>
            <div className="form-group col-xs-4 paymentFields">
              <label htmlFor="exp-date">Exp. (MM/YY)</label>
              <div
                className="form-control"
                id="exp-date"
                data-bluesnap="exp"
              ></div>
              <span className="helper-text" id="exp-help"></span>
            </div>
            {props.firstName &&
            props.lastName &&
            props.email &&
            props.password &&
            props.companyName &&
            props.companyWebsite &&
            props.phone &&
            props.title &&
            props.country &&
            props.city &&
            props.accept &&
            props.address ? (
              <div className="form-group col-xs-12">
                <button
                  className="btn btn-raised btn-warning btn-lg col-md-4"
                  type="button"
                  id="submit-button"
                  onClick={submitForm}
                >
                  Pay Now
                </button>
              </div>
            ) : (
              <div className="form-group col-xs-12">
                <button
                  className="btn btn-lg col-md-4 grayBtn payNowGray"
                  type="button"
                  onClick={props.validateFields}
                >
                  Pay Now
                </button>
              </div>
            )}
          </div>
        </form>
      </div>
      {paymentLoader && <Overlay content="payment" />}
      <SimpleModal
        open={failed}
        handleClose={() => setFailed(false)}
        type="failed"
        errorMessage={errorMessage}
      />
    </div>
  );
};

export default PaymentFields;
