import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import TableHead from "@material-ui/core/TableHead";
import BlurryText from "../Partials/Blur";
import LoaderIcon from "react-loader-icon";
import {
  RiLockPasswordFill,
  RiGroup2Fill,
  RiComputerLine,
  RiVirusLine,
  RiExternalLinkFill,
} from "react-icons/ri";
import {
  FaViruses,
  FaRegIdCard,
  FaUserSecret,
  FaShieldAlt,
  FaFacebook,
  FaRegCalendarAlt,
  FaUserCircle,
  FaUnlockAlt,
} from "react-icons/fa";
import { AiFillDatabase } from "react-icons/ai";
import { GiWireframeGlobe, GiFamilyTree } from "react-icons/gi";
import { MdSettingsEthernet } from "react-icons/md";
import Checkbox from "@material-ui/core/Checkbox";
import { GoDiffAdded } from "react-icons/go";
import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import FlowChart from "../Partials/FlowChart";
import { BsEyeFill, BsFillInfoCircleFill } from "react-icons/bs";
import axios from "axios";
import { APIFullAddress } from "../../Config";
import Tooltip from "@material-ui/core/Tooltip";
import { CSVLink } from "react-csv";

const useStyles2 = makeStyles({
  table: {
    minWidth: 500,
  },
});

export default function CustomPaginationActionsTable(props) {
  const classes = useStyles2();
  const [blur, setBlur] = useState(true);
  const [open, setOpen] = useState();
  const [stealerDataLoading, setStealerDataLoading] = useState(false);
  const [currentFirstSeen, setCurrentFirstSeen] = useState(null);
  const [currentTopLogins, setCurrentTopLogins] = useState(null);
  const [blurLogins, setBlurLogins] = useState(true);
  const [openStealerData, setOpenStealerData] = useState(null);

  const userRole = props.userRole;
  const config = props.config;

  useEffect(() => {
    userRole === "mssp" ? setBlur(true) : setBlur(false);
  }, [blur, userRole]);

  useEffect(() => {
    console.log(openStealerData);
    if (open) {
      getStealerData(
        openStealerData.id,
        openStealerData.date_uploaded,
        openStealerData.date_compromised,
        openStealerData.index,
        openStealerData.selectedDomain,
        true,
        openStealerData.domain
      );
    }
  }, [props.credits]);

  const getStealerData = async (
    id,
    date_uploaded,
    date_compromised,
    index,
    selectedDomain,
    unlock,
    domain
  ) => {
    setOpenStealerData({
      id: id,
      date_uploaded: date_uploaded,
      date_compromised: date_compromised,
      index: index,
      selectedDomain: selectedDomain,
      domain: domain,
    });
    if (!unlock) {
      setOpen(null);
    }
    setStealerDataLoading(true);
    if (open != index || unlock) {
      setOpen(index);
      await axios
        .post(
          `${APIFullAddress}/machine/get-first-added?id=${id}&date_uploaded=${date_uploaded}&date_compromised=${date_compromised}`,
          {
            domains: props.unmask ? domain : selectedDomain,
            externals: props.selectedExternals,
            prospect:
              props.currentProspect && props.currentProspect._id
                ? props.currentProspect
                : null,
          },
          config
        )
        .then((res) => {
          setCurrentFirstSeen(res.data.firstSeen);
          setCurrentTopLogins(res.data.topLogins);
          setBlurLogins(res.data.blur);
        })
        .catch((err) => console.log(err));
    } else {
      setOpen(null);
      setCurrentFirstSeen(null);
    }
    setStealerDataLoading(false);
  };

  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      {props.selected && props.selected.length > 0 && (
        <div id="unlockFloatingIcon" onClick={props.unlockStealer}>
          <span>
            <FaUnlockAlt /> Unlock Selected
          </span>
        </div>
      )}
      {props.data && props.data.length > 0 ? (
        <TableContainer
          className=""
          style={{ overflowX: "unset" }}
          sx={{ maxHeight: 440 }}
        >
          <Table
            className={classes.table + " credsTable"}
            stickyHeader
            aria-label="custom pagination sticky collapsible table"
          >
            <TableHead>
              <TableRow>
                <TableCell className="zeroWidth bold" style={{ width: "5%" }} />
                {props.userRole != "admin" && !props.unmask && (
                  <TableCell
                    padding="checkbox"
                    style={{ width: "5%" }}
                    className="bold"
                  >
                    <Checkbox
                      indeterminate={
                        props.selected.length ===
                        props.data.filter((s) => s.censored != false).length
                      }
                      checked={
                        props.selected.length ===
                        props.data.filter((s) => s.censored != false).length
                      }
                      onChange={() => props.selectRow("all")}
                    />
                  </TableCell>
                )}

                {props.userRole != "admin" && props.unmask && (
                  <TableCell style={{ width: "13.6%" }} className="bold">
                    <RiGroup2Fill size={20} className="credsIcons" />
                    Company
                  </TableCell>
                )}
                <TableCell style={{ width: "13.6%" }} className="bold">
                  <FaRegIdCard size={20} className="credsIcons" />
                  Machine ID
                </TableCell>
                <TableCell style={{ width: "13.6%" }} className="bold">
                  <GiWireframeGlobe size={20} className="credsIcons" />
                  URL
                </TableCell>
                <TableCell style={{ width: "13.6%" }} className="bold">
                  <FaUserCircle size={20} className="credsIcons" />
                  Login
                </TableCell>
                <TableCell style={{ width: "13.6%" }} className="bold">
                  <RiLockPasswordFill size={20} className="credsIcons" />
                  Password
                </TableCell>
                <TableCell style={{ width: "13.6%" }} className="bold">
                  <MdSettingsEthernet size={20} className="credsIcons" />
                  IP
                </TableCell>
                <TableCell
                  className={
                    props.sortType === "date" ||
                    (props.sortType === "date_compromised" && "bold marked")
                  }
                  style={{ width: "13.6%" }}
                >
                  <FaRegCalendarAlt size={20} className="credsIcons bold" />
                  Date Compromised
                </TableCell>
                <TableCell
                  style={{ width: "13.6%" }}
                  className={props.sortType === "date_added" && "marked bold"}
                >
                  <GoDiffAdded size={20} className="credsIcons" />
                  Last Time Added
                </TableCell>

                {props.userRole != "admin" && props.unmask && (
                  <TableCell
                    style={{ width: "13.6%" }}
                    className={
                      props.sortType === "date_unlocked" && "bold marked"
                    }
                  >
                    <FaUnlockAlt size={20} className="credsIcons bold" />
                    Date Unlocked
                  </TableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {props.data.map((row, index) => (
                <React.Fragment key={index}>
                  <TableRow
                    className={
                      open === index
                        ? "activeRow credsRowUsers credsRow"
                        : "credsRowUsers credsRow"
                    }
                    onClick={() =>
                      getStealerData(
                        row._id,
                        row.date_added,
                        row.date,
                        index,
                        props.selectedDomain,
                        false,
                        row.domain
                      )
                    }
                  >
                    <TableCell className="zeroWidth bold" align="center">
                      <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() =>
                          getStealerData(
                            row._id,
                            row.date_added,
                            row.date,
                            index,
                            props.selectedDomain,
                            false,
                            row.domain
                          )
                        }
                      >
                        {open === index ? (
                          <KeyboardArrowUpIcon />
                        ) : (
                          <KeyboardArrowDownIcon />
                        )}
                      </IconButton>
                    </TableCell>
                    {props.userRole != "admin" && !props.unmask && (
                      <TableCell padding="checkbox" className="bold">
                        {row.censored && (
                          <Checkbox
                            className="blackCheckbox"
                            onChange={() => props.selectRow(index)}
                            checked={props.selected.includes(index)}
                            onClick={(e) => {
                              e.stopPropagation();
                            }}
                            // inputProps={{ "aria-labelledby": labelId }}
                          />
                        )}
                      </TableCell>
                    )}
                    {props.userRole != "admin" && props.unmask && (
                      <TableCell
                        className={
                          props.type != "apps" ? "bold capitalize" : "bold"
                        }
                      >
                        {row.company}
                      </TableCell>
                    )}
                    {blur ? (
                      row.censored ? (
                        <TableCell className="bold" value={row.source}>
                          <BlurryText value={row.source} />
                        </TableCell>
                      ) : (
                        <TableCell
                          className="bold"
                          component="th"
                          scope="row"
                          value={row.source}
                        >
                          {row.source}
                        </TableCell>
                      )
                    ) : (
                      <TableCell
                        className="bold"
                        component="th"
                        scope="row"
                        value={row.source}
                      >
                        {row.source}
                      </TableCell>
                    )}
                    <TableCell className="bold">{row.url}</TableCell>
                    {blur ? (
                      row.censored && row.login != "Not Found" ? (
                        <TableCell className="bold">
                          {row.login.includes("@") ? (
                            <span>
                              <BlurryText value={row.login.split("@")[0]} />@
                              {row.login.split("@")[1]}
                            </span>
                          ) : (
                            <BlurryText value={row.login} />
                          )}
                        </TableCell>
                      ) : row.login === "Not Found" ? (
                        <TableCell className="bold">
                          {" "}
                          <span
                            style={{
                              textDecoration: "underline",
                            }}
                          >
                            Suggested Logins ↓
                          </span>
                        </TableCell>
                      ) : (
                        <TableCell className="bold">{row.login}</TableCell>
                      )
                    ) : row.login === "Not Found" ? (
                      <TableCell className="bold">
                        {" "}
                        <span
                          style={{
                            textDecoration: "underline",
                          }}
                        >
                          Suggested Logins ↓
                        </span>
                      </TableCell>
                    ) : (
                      <TableCell className="bold">{row.login}</TableCell>
                    )}

                    <TableCell className="bold">
                      {row.blurPasswords ? (
                        <span
                          style={{
                            backgroundColor: "gray",
                            borderRadius: "20px",
                            padding: "4px 10px 4px 0px",
                          }}
                        >
                          <Tooltip
                            title={"Passwords remain blurred after an unlock"}
                          >
                            <IconButton aria-label="data">
                              <BsFillInfoCircleFill
                                style={{
                                  fill: "#fecd4e",
                                  backgroundColor: "black",
                                  borderRadius: "100px",
                                  padding: "1px",
                                }}
                                size={16}
                              />
                            </IconButton>
                          </Tooltip>

                          <BlurryText value={row.password} />
                        </span>
                      ) : (
                        row.password
                      )}
                      <br />
                      <span
                        className={row.passStrength && row.passStrength.class}
                      >
                        {row.passStrength && row.passStrength.value}
                      </span>
                    </TableCell>

                    {blur ? (
                      row.censored && row.ip != "Not Found" ? (
                        <TableCell className="bold">
                          <BlurryText value={row.ip} />
                        </TableCell>
                      ) : (
                        <TableCell className="bold">{row.ip}</TableCell>
                      )
                    ) : (
                      <TableCell className="bold">{row.ip}</TableCell>
                    )}
                    <TableCell className="bold">{row.date}</TableCell>
                    <TableCell className="bold">
                      {row.last_time_added}
                    </TableCell>
                    {props.userRole != "admin" && props.unmask && (
                      <TableCell className="bold">
                        {row.date_unlocked}
                      </TableCell>
                    )}
                  </TableRow>
                  <TableRow
                    style={{ backgroundColor: "#fbcd4e" }}
                    className={
                      open === index ? "activeRow expandedRow" : "expandedRow"
                    }
                  >
                    <TableCell
                      style={{ paddingBottom: 0, paddingTop: 0 }}
                      colSpan={12}
                    >
                      <Collapse
                        in={open === index}
                        timeout="auto"
                        unmountOnExit
                        style={{
                          boxShadow: "inset 0 0 10px #000000",
                        }}
                      >
                        <Box
                          margin={1}
                          style={{
                            minHeight: "450px",
                            margin: "30px",
                            backgroundColor: "#fbcd4e",
                          }}
                        >
                          {stealerDataLoading ? (
                            <div
                              style={{
                                minHeight: "250px",
                                backgroundColor: "#fbcd4e",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <LoaderIcon
                                className="tableLoader"
                                type={"spin"}
                              />
                            </div>
                          ) : (
                            <div className="machine-items">
                              <h3
                                id="profileTitle"
                                className="lightText credTitle"
                              >
                                <span className="titleIcon">
                                  <FaUserSecret size={30} />
                                </span>
                                Profile
                              </h3>
                              {!row.censored && (
                                <CSVLink
                                  id="csvDownload"
                                  className="csvDownload"
                                  data={[
                                    {
                                      stealer: row.source,
                                      url: row.url,
                                      login: row.login,
                                      suggested_logins:
                                        row.login === "Not Found"
                                          ? currentTopLogins
                                          : "",
                                      password: row.password,
                                      ip: row.ip,
                                      stealer_family: row.stealer_family,
                                      date_compromised: row.date,
                                      first_time_added: row.first_time_added,
                                      last_time_added: row.last_time_added,
                                      path: row.path,
                                      facebook: row.fb,
                                      antiviruses: row.av,
                                      pc: row.pc,
                                      operating_system: row.os,
                                    },
                                  ]}
                                  filename={`${row.source}.csv`}
                                  target="_blank"
                                  style={{
                                    backgroundColor: "black",
                                    position: "absolute",
                                    right: "20px",
                                    padding: "4px 15px",
                                    borderRadius: "4px",
                                  }}
                                >
                                  Export to CSV
                                </CSVLink>
                              )}

                              <Table
                                size="small"
                                aria-label="more"
                                className="credsTable systemTable"
                              >
                                <TableHead>
                                  <TableRow className="credsRow credsRowYellow">
                                    <TableCell className="profileTh bold">
                                      <span>
                                        <RiComputerLine
                                          size={20}
                                          className="credsIcons"
                                        />
                                      </span>
                                      Computer Name
                                    </TableCell>
                                    <TableCell className="profileTh bold">
                                      <span>
                                        <FaViruses
                                          size={20}
                                          className="credsIcons"
                                        />
                                      </span>
                                      Stealer Family
                                    </TableCell>
                                    <TableCell className="profileTh bold">
                                      <span>
                                        <RiVirusLine
                                          size={20}
                                          className="credsIcons"
                                        />
                                      </span>
                                      Malware Path
                                    </TableCell>
                                    <TableCell className="profileTh bold">
                                      <span>
                                        <AiFillDatabase
                                          size={20}
                                          className="credsIcons"
                                        />
                                      </span>
                                      Operating System
                                    </TableCell>
                                    <TableCell className="profileTh bold">
                                      <span>
                                        <FaShieldAlt
                                          size={20}
                                          className="credsIcons"
                                        />
                                      </span>
                                      Anti Virus
                                    </TableCell>
                                    <TableCell className="profileTh bold">
                                      <span>
                                        <FaFacebook
                                          size={20}
                                          className="credsIcons"
                                        />
                                      </span>
                                      Facebook
                                    </TableCell>
                                    <TableCell className="profileTh">
                                      <span>
                                        <BsEyeFill
                                          size={20}
                                          className="credsIcons"
                                        />
                                      </span>
                                      First time added
                                    </TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  <TableRow className="credsRow credsTable credsRowYellow">
                                    {blur ? (
                                      row.censored && row.pc != "Not Found" ? (
                                        <TableCell className="bold">
                                          <BlurryText value={row.pc} />
                                        </TableCell>
                                      ) : (
                                        <TableCell className="bold">
                                          {row.pc}
                                        </TableCell>
                                      )
                                    ) : (
                                      <TableCell className="bold">
                                        {row.pc}
                                      </TableCell>
                                    )}
                                    <TableCell className="bold">
                                      {row.stealer_family}
                                    </TableCell>
                                    {blur ? (
                                      row.censored &&
                                      row.path != "Not Found" ? (
                                        <TableCell className="bold">
                                          <span>
                                            {row.path &&
                                            row.path.includes(".") ? (
                                              <span>
                                                <BlurryText
                                                  value={row.path.split(".")[0]}
                                                />
                                                .{row.path.split(".")[1]}
                                              </span>
                                            ) : (
                                              <BlurryText value={row.path} />
                                            )}
                                          </span>
                                        </TableCell>
                                      ) : (
                                        <TableCell className="bold">
                                          {row.path}
                                        </TableCell>
                                      )
                                    ) : (
                                      <TableCell className="bold">
                                        {row.path}
                                      </TableCell>
                                    )}
                                    <TableCell className="bold">
                                      {row.os}
                                    </TableCell>
                                    <TableCell className="bold">
                                      {row.av}
                                    </TableCell>
                                    <TableCell className="bold">
                                      {row.fb !== "Not Found" ? (
                                        <button className="cardBtn">
                                          <a
                                            rel="noreferrer"
                                            target="_blank"
                                            href={`https://facebook.com/${row.fb}`}
                                          >
                                            View Profile
                                            <RiExternalLinkFill
                                              size={20}
                                              className="smIcons"
                                            />
                                          </a>
                                        </button>
                                      ) : (
                                        <button className="cardBtn grayBtn">
                                          Not Found
                                        </button>
                                      )}
                                    </TableCell>
                                    <TableCell>{currentFirstSeen}</TableCell>
                                  </TableRow>
                                </TableBody>
                              </Table>
                              {currentTopLogins &&
                                currentTopLogins.length > 0 &&
                                row.login === "Not Found" && (
                                  <div
                                    style={{
                                      color: "black",
                                      display: "flex",
                                      justifyContent: "start",
                                      alignItems: "center",
                                    }}
                                  >
                                    <FaUserCircle
                                      size={20}
                                      className="credsIcons"
                                    />
                                    Suggested Logins Found{" "}
                                    <Tooltip title="No login found in this credential, here are the most common logins found on this computer.">
                                      <div
                                        style={{
                                          backgroundColor: "#222831",
                                          color: "#FBCD4E",
                                          borderRadius: "20px",
                                          width: "15px",
                                          height: "15px",
                                          cursor: "pointer",
                                          display: "flex",
                                          justifyContent: "center",
                                          alignItems: "center",
                                          margin: "0px 2px",
                                        }}
                                      >
                                        ℹ
                                      </div>
                                    </Tooltip>
                                    <span>: </span>
                                    <div
                                      style={{
                                        display: "inline-flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                      }}
                                    >
                                      {currentTopLogins.map((item) => (
                                        <div
                                          style={{
                                            backgroundColor: "#222831",
                                            color: "#FBCD4E",
                                            borderRadius: "20px",
                                            padding: "4px 15px",
                                            margin: "0px 5px",
                                            display: "inline-flex",
                                            justifyContent: "space-between",
                                            alignItems: "center",
                                          }}
                                        >
                                          {blurLogins ? (
                                            <div>
                                              {item.includes("@") ? (
                                                <span>
                                                  <BlurryText
                                                    value={item.split("@")[0]}
                                                  />
                                                  @{item.split("@")[1]}
                                                </span>
                                              ) : (
                                                <BlurryText value={item} />
                                              )}
                                            </div>
                                          ) : (
                                            <div>{item}</div>
                                          )}
                                        </div>
                                      ))}
                                    </div>
                                  </div>
                                )}
                            </div>
                          )}
                          <br />
                          <br />

                          {row.tree && (
                            <div
                              className="flow-chart-div"
                              style={{
                                height: "550px",
                                width: "100%",
                                color: "white",
                                textAlign: "center",
                              }}
                            >
                              <div
                                style={{
                                  backgroundColor: "#222831",
                                  padding: "10px",
                                  borderRadius: "15px 15px 0px 0px",
                                }}
                              >
                                <GiFamilyTree
                                  size={25}
                                  className="credsIcons"
                                />

                                <h3>Virtualization</h3>
                              </div>
                              <FlowChart data={row.tree} />
                            </div>
                          )}
                        </Box>
                      </Collapse>
                    </TableCell>
                  </TableRow>
                </React.Fragment>
              ))}
            </TableBody>
          </Table>
          {props.loader ? (
            <LoaderIcon className="tableLoader" type={"spinningBubbles"} />
          ) : (
            <div className="align-center">
              {props.hasNextPage ? (
                <button
                  className="cardBtn loadMore tableLoadMore"
                  onClick={props.loadMore}
                >
                  Load More
                </button>
              ) : (
                <div>
                  <h3>No More Results</h3>
                </div>
              )}
            </div>
          )}
        </TableContainer>
      ) : (
        <>{!props.onSearch ? "" : <h2>No Results, Try Another Domain</h2>}</>
      )}
    </Paper>
  );
}
