import React, { useEffect, useState, useRef } from "react";
import { useDetectClickOutside } from "react-detect-click-outside";
import Form from "react-bootstrap/Form";
import CreateApiClient from "../../API";
import InputGroup from "react-bootstrap/InputGroup";
import { FaSearch } from "react-icons/fa";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";
import cavaLogo from "../../images/thumbnail.jpeg";

const api = CreateApiClient();

export default function AutoComplete(props) {
  const mountedRef = useRef(true);
  const [displayDropdown, setDisplayDropdown] = useState(true);
  // const [suggestions, setSuggestions] = useState([]);
  // const [domain, setDomain] = useState("");

  const defaultSrc = (ev) => {
    ev.target.src = cavaLogo;
  };

  useEffect(() => {
    return () => {
      mountedRef.current = false;
    };
  }, []);

  const closeDropdown = (e) => {
    e.target.id != "autocomplete"
      ? setDisplayDropdown(false)
      : setDisplayDropdown(true);
    // setDisplayDropdown(!displayDropdown ? true : false);
  };

  const ref = useDetectClickOutside({ onTriggered: closeDropdown });

  const onFocus = (e) => {
    setDisplayDropdown(true);
  };

  return (
    <Form autoComplete="off" className="autocompleteSearchForm">
      <Form.Group className="searchForm" controlId="autocomplete" ref={ref}>
        <TextField
          maxLength="999"
          value={props.domain}
          onFocus={onFocus}
          onChange={props.onChange}
          placeholder={props.placeholder}
          required={true}
          className={props.classes}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <FaSearch />
              </InputAdornment>
            ),
          }}
          style={{
            border: "0px",
          }}
        />

        {props.suggestions.length > 0 && (
          <div
            className="suggestionsBox"
            id="suggestionsBox"
            style={{ width: "360px" }}
          >
            {displayDropdown && (
              <div>
                {props.suggestions.map((company, index) => (
                  <Form.Text
                    value={company.domain}
                    onClick={() => props.fetchContacts(company.domain)}
                    key={index}
                    className="text-muted companyRow"
                  >
                    <img
                      alt={company.domain}
                      value={company.domain}
                      className="companyLogo"
                      src={company.logo}
                      onError={defaultSrc}
                    />
                    {company.name}
                    <p value={company.domain} className="companyDomain">
                      {company.domain}
                    </p>
                  </Form.Text>
                ))}
              </div>
            )}
          </div>
        )}
      </Form.Group>
    </Form>
  );
}
