import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import BlurryText from "../Partials/Blur";
import LoaderIcon from "react-loader-icon";
import TableHead from "@material-ui/core/TableHead";
import axios from "axios";
import { APIFullAddress } from "../../Config";
import { AiFillDatabase } from "react-icons/ai";
import { ImMail4 } from "react-icons/im";
import { RiLockPasswordFill, RiGroup2Fill } from "react-icons/ri";
import { FaUnlockAlt } from "react-icons/fa";
import {
  MdSettingsEthernet,
  MdNoEncryption,
  MdContactPhone,
} from "react-icons/md";
// import TreeItem from "@material-ui/lab/TreeItem";
import Collapse from "@material-ui/core/Collapse";
import { alpha, makeStyles, withStyles } from "@material-ui/core/styles";
import { useSpring, animated } from "@react-spring/web"; // web.cjs is required for IE 11 support
// import SvgIcon from "@material-ui/core/SvgIcon";
import CreateApiClient from "../../API";
import { BsUnlockFill } from "react-icons/bs";
import Overlay from "../Partials/Overlay";

const api = CreateApiClient();

// function MinusSquare(props) {
//   return (
//     <SvgIcon fontSize="inherit" style={{ width: 14, height: 14 }} {...props}>
//       {/* tslint:disable-next-line: max-line-length */}
//       <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 11.023h-11.826q-.375 0-.669.281t-.294.682v0q0 .401.294 .682t.669.281h11.826q.375 0 .669-.281t.294-.682v0q0-.401-.294-.682t-.669-.281z" />
//     </SvgIcon>
//   );
// }

function TransitionComponent(props) {
  const style = useSpring({
    from: { opacity: 0, transform: "translate3d(20px,0,0)" },
    to: {
      opacity: props.in ? 1 : 0,
      transform: `translate3d(${props.in ? 0 : 20}px,0,0)`,
    },
  });

  return (
    <animated.div style={style}>
      <Collapse {...props} />
    </animated.div>
  );
}

TransitionComponent.propTypes = {
  /**
   * Show the component; triggers the enter or exit states
   */
  in: PropTypes.bool,
};

// const StyledTreeItem = withStyles((theme) => ({
//   iconContainer: {
//     "& .close": {
//       opacity: 0.3,
//     },
//   },
//   group: {
//     marginLeft: 7,
//     paddingLeft: 18,
//     borderLeft: `1px dashed ${alpha("#1A1C20", 0.4)}`,
//   },
// }))((props) => (
//   <TreeItem {...props} TransitionComponent={TransitionComponent} />
// ));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    display: "flex",
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
  },
}));

export default function DbTabs(props) {
  const [activeTab, setActiveTab] = useState(0);
  const [machineDetails, setMachineDetails] = useState([]);
  const [machineData, setMachineData] = useState([]);
  const [unlockLoader, setUnlockLoader] = useState(false);
  const [unlockedTab, setUnlockedTab] = useState();
  const [overlay, setOverlay] = useState(false);
  const [unlockedDbs, setUnlockedDbs] = useState([]);
  const [blur, setBlur] = useState(true);
  const [loading, isLoading] = useState(true);
  const userRole = props.userRole;
  // const selectedDomain = props.domain;
  const tableData = props.data;
  const config = props.config;

  useEffect(() => {
    userRole === "mssp" && !props.unmask ? setBlur(true) : setBlur(false);
  }, [blur, userRole]);

  useEffect(() => {
    if (tableData.length > 0) {
      handleCollapse(
        tableData[activeTab].id,
        tableData[activeTab]._id,
        tableData[activeTab].domain
      );
    }
  }, [tableData]);

  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleCollapse = async (id, _id, domain) => {
    const activeIndex = tableData
      .map(function (e) {
        return e._id;
      })
      .indexOf(_id);

    setActiveTab(activeIndex);
    isLoading(true);

    const query = {
      domain: domain,
      id: _id,
    };

    const config = {
      headers: {
        "auth-token": props.token,
      },
    };

    getMachineData(query, config);

    async function getMachineData(query, config) {
      const response = await api.fetchData("dbs/emailsearch", query, config);
      setMachineData(response);
      isLoading(false);
    }

    const shownState = machineDetails.slice();
    const index = shownState.indexOf(id);

    if (index > 0) {
      shownState.splice(index, 1);
      setMachineDetails(shownState);
    } else {
      shownState.pop();
      shownState.push(id);
      setMachineDetails(shownState);
    }
  };

  useEffect(() => {
    getUnlockedData();
  }, []);

  async function getUnlockedData() {
    await axios
      .post(
        `${APIFullAddress}/unlock-stealer/get-unlocked-stealers`,
        {},
        config
      )
      .then((res) => {
        setUnlockedDbs(res.data.dbs);
      })
      .catch((err) => console.log(err));
  }

  const unlockDb = async (id, index, domain) => {
    setUnlockedTab(index);
    setUnlockLoader(true);
    setOverlay(true);

    const query = {
      type: "db",
      domain: domain,
      db: id,
      company:
        props.currentProspect != ""
          ? props.currentProspect.company.name
          : props.currentCompany.name,
    };
    try {
      await axios
        .post(`${APIFullAddress}/unlock-stealer/unlock-db`, query, config)
        .then((res) => {
          setUnlockedDbs(res.data.unlockedDbs);
          const localUser = JSON.parse(localStorage.getItem("user"));
          localUser.user.credits = res.data.credits;
          localStorage.setItem("user", JSON.stringify(localUser));
          const reveal = tableData.find((e) => e._id == res.data.id);
          reveal.email = res.data.db.email;
          // reveal.ip = res.data.stealer.data.ip[0].data;
          // reveal.path =
          //   res.data.stealer.data.malwarePath.length > 0
          //     ? res.data.stealer.data.malwarePath[0].data
          //     : "Not Found";
        });
    } catch (error) {
      setUnlockLoader(false);
      setOverlay(false);
      props.setOpen(true);
      props.setMessage(error.response.data);
    }
    handleCollapse(
      tableData[index]._id,
      tableData[index]._id,
      tableData[index].domain
    );
    props.getCounters();
    props.refreshUser();
    setUnlockLoader(false);
    setOverlay(false);
  };

  return (
    <>
      {overlay && <Overlay content="unlock" charge={1} />}
      {props.data.length > 0 ? (
        <div
          className={classes.root + " employeesTable dbsTable"}
          style={{ marginBottom: "80px" }}
        >
          <Tabs
            id="employeesTabs"
            orientation="vertical"
            variant="scrollable"
            value={value}
            onChange={handleChange}
            aria-label="Vertical tabs example"
            className={classes.tabs + " employeeTabsList"}
          >
            {props.data.map((row, index) => (
              <Tab
                className="employeeTab"
                key={index}
                onClick={() => handleCollapse(row._id, row._id, row.domain)}
                label={
                  <ul className="employee-nav">
                    {props.userRole != "admin" && props.unmask && (
                      <li>
                        <RiGroup2Fill size={20} className="credsIcons" />
                        <span className="employee-label">
                          Company: {row.company}
                        </span>
                      </li>
                    )}{" "}
                    <li>
                      <ImMail4 size={20} className="credsIcons" />
                      <span className="employee-label">Email:</span>
                      {blur && !unlockedDbs.some((e) => e.id === row._id) ? (
                        row.email.includes("@") ? (
                          <span>
                            <BlurryText value={row.email.split("@")[0]} />@
                            {row.email.split("@")[1]}
                          </span>
                        ) : (
                          <BlurryText value={row.email} />
                        )
                      ) : (
                        <span>{row.email}</span>
                      )}
                      {userRole === "mssp" &&
                        !props.unmask &&
                        !unlockedDbs.some((e) => e.email === row.email) &&
                        (unlockLoader ? (
                          <LoaderIcon
                            className="unlockLoader"
                            type={"spinningBubbles"}
                          />
                        ) : (
                          <button
                            onClick={() => unlockDb(row._id, index, row.domain)}
                            className="cardBtn unlockBtn"
                          >
                            Unlock
                            <BsUnlockFill size={15} className="smIcons" />
                          </button>
                        ))}
                    </li>
                    <li>
                      <span className="employee-label">
                        {row.users} Compromised Users
                      </span>
                    </li>
                    {row.unlock_date && (
                      <li>
                        <span className="employee-label">
                          Unlocked at {row.unlock_date}
                        </span>
                      </li>
                    )}
                  </ul>
                }
                {...a11yProps(index)}
              />
            ))}
            {props.loader ? (
              <LoaderIcon className="tabsLoader" type={"spinningBubbles"} />
            ) : (
              <div className="align-center">
                {props.clientsCount > 30 && !props.finalRes ? (
                  <button className="cardBtn loadMore" onClick={props.loadMore}>
                    Load More
                  </button>
                ) : (
                  <div>
                    <h3 style={{ color: "white" }}>No More Results</h3>
                  </div>
                )}
              </div>
            )}
          </Tabs>
          {props.data.map((row, index) => (
            <TabPanel
              value={value}
              index={index}
              key={row._id}
              className="tabPanel"
              id="contentTab"
            >
              {machineDetails && !loading ? (
                <div>
                  <h3 id="profileTitle" className="lightText credTitle">
                    <span className="titleIcon">
                      <FaUnlockAlt size={30} />
                    </span>
                    Corporate Credentials Found: {machineData.length}
                  </h3>
                  <Table size="small" aria-label="more" className="credsTable">
                    <TableHead>
                      <TableRow className="credsRow dbsCredsRow">
                        <TableCell>
                          <span>
                            <MdSettingsEthernet
                              size={20}
                              className="credsIcons"
                            />
                          </span>
                          IP Address
                        </TableCell>
                        <TableCell>
                          <span>
                            <MdNoEncryption size={20} className="credsIcons" />
                          </span>
                          Hashed Password
                        </TableCell>
                        <TableCell>
                          <span>
                            <RiLockPasswordFill
                              size={20}
                              className="credsIcons"
                            />
                          </span>
                          Password
                        </TableCell>

                        <TableCell>
                          <span>
                            <MdContactPhone size={20} className="credsIcons" />
                          </span>
                          Phone
                        </TableCell>

                        <TableCell>
                          <span>
                            <AiFillDatabase size={20} className="credsIcons" />
                          </span>
                          Database Name
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {machineData.map((item) => (
                        <TableRow className="credsRow credsTable" key={item.id}>
                          {blur &&
                          !unlockedDbs.some((e) => e.email === item.email) &&
                          item.ip_address != "Not Found" ? (
                            <TableCell>
                              <BlurryText value={item.ip_address} />
                            </TableCell>
                          ) : (
                            <TableCell
                              className={
                                item.ip_address === "Not Found" && "cross"
                              }
                            >
                              {item.ip_address}
                            </TableCell>
                          )}
                          {blur &&
                          !unlockedDbs.some((e) => e.email === item.email) &&
                          item.hashed_password != "Not Found" ? (
                            <TableCell>
                              <BlurryText value={item.hashed_password} />
                            </TableCell>
                          ) : (
                            <TableCell
                              className={
                                item.hashed_password === "Not Found" && "cross"
                              }
                            >
                              {item.hashed_password}
                            </TableCell>
                          )}
                          {blur &&
                          !unlockedDbs.some((e) => e.email === item.email) &&
                          item.password != "Not Found" ? (
                            <TableCell>
                              <BlurryText value={item.password} />
                            </TableCell>
                          ) : (
                            <TableCell
                              className={
                                item.password === "Not Found" && "cross"
                              }
                            >
                              {item.password}
                            </TableCell>
                          )}

                          {blur &&
                          !unlockedDbs.some((e) => e.email === item.email) &&
                          item.phone != "Not Found" ? (
                            <TableCell>
                              <BlurryText value={item.phone} />
                            </TableCell>
                          ) : (
                            <TableCell
                              className={item.phone === "Not Found" && "cross"}
                            >
                              {item.phone}
                            </TableCell>
                          )}
                          <TableCell>{item.database_name}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </div>
              ) : (
                <LoaderIcon className="tableLoader" type={"spinningBubbles"} />
              )}
            </TabPanel>
          ))}
        </div>
      ) : (
        <>
          {userRole === "Admin" || userRole === "MSSP" ? (
            <>
              {!props.onSearch ? "" : <h2>No Results, Try Another Domain</h2>}
            </>
          ) : (
            <>
              <h2>No Results, Try Another Domain</h2>
            </>
          )}
        </>
      )}
    </>
  );
}
