import React, { useEffect, useState } from "react";
import AppBarNew from "./components/Partials/AppBar";
import "./styles/App.css";
import CountUp from "react-countup";
import { RiComputerLine, RiGroup2Fill } from "react-icons/ri";
import CreateApiClient from "./API";
import { FaMapMarkerAlt, FaGlobe, FaUserTie } from "react-icons/fa";
import { DiAndroid } from "react-icons/di";
import { useLocation } from "react-router";
import Container from "./components/Partials/ProgressBar";
import CardsContainer from "./components/Partials/CardsContainer";
import { createBrowserHistory } from "history";
// import axios from "axios";
// import Modal from "./components/Account/Modal";
// import { blueSnapAddress } from "./Config";
import SimpleModal from "./components/Partials/BasicModal";
import Footer from "./components/Partials/Footer";
import LoaderIcon from "react-loader-icon";
import SearchBy from "./components/Search/SearchBy";

const history = createBrowserHistory();

const queryString = require("query-string");

const api = CreateApiClient();

function AppPreview(props) {
  const location = useLocation();
  const domain = queryString.parse(location.search).name;
  const path = location.pathname.split("/")[1];
  const [user, setUser] = useState(props.user);
  const [email, setEmail] = useState(props.user.user.email);
  const [role, setRole] = useState(props.user.user.role);
  const [token, setToken] = useState(props.user.token);
  const [loading, setLoading] = useState(false);
  const [stealersCount, setStealersCount] = useState("");
  const [employeesCount, setEmployeesCount] = useState("");
  const [androidsCount, setAndroidsCount] = useState("");
  const [domainsCount, setDomainsCount] = useState("");
  const [countriesCount, setCountriesCount] = useState("");
  const [prospects, setProspects] = useState([]);
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (user) {
      const time = Date.now().toString().substr(0, 10);
      const nowNum = Number(time);
      if (user.expiry < nowNum) {
        props.handleLogOut();
      } else {
        setUser(user);
        setEmail(user.user.email);
        setRole(user.user.role);
        setToken(user.user.token);
        getData();
        // if (props.filters && path === "advanced-search") {
        //   props.getCards([], 0, props.filters);
        // } else {
        //   console.log("called back home");
        //   props.getCards([], 0, null);
        // }
      }
    }
  }, [path]);

  const getData = async () => {
    setLoading(true);
    props.setPage(0);
    if (path !== "advanced-search") {
      await props.getCards([], 0, null);
    }
    if (props.filters && path === "advanced-search") {
      props.getCards([], 0, props.filters);
    } else {
      await getCounters();
      await getProspects();
    }
    setLoading(false);
  };

  useEffect(() => {
    if (props.filters && path === "advanced-search") {
      props.getCards([], 0, props.filters);
    }
  }, []);

  useEffect(() => {
    if (props.page > 0) {
      if (props.filters && path === "advanced-search") {
        props.getCards(props.companies, props.page, props.filters);
      } else {
        props.getCards(props.companies, props.page, null);
      }
    }
  }, [props.page]);

  // useEffect(() => {
  //   console.log("called app");
  //   if (CheckUserSession(props.user)) {
  //     loadPage();
  //     setUser(props.user);
  //     setEmail(props.user.user.email);
  //     setRole(props.user.user.role);
  //     setToken(props.user.user.token);
  //   } else {
  //     props.handleLogOut();
  //   }
  // }, [path]);

  // const loadPage = async () => {
  //   if (props.filters && path === "advanced-search") {
  //     await props.getCards(props.filters);
  //   } else {
  //     await props.getCards();
  //   }
  //   await getCounters();
  //   await getProspects();
  // };

  async function getProspects() {
    const response = await api.editUser("get-prospects", {}, config);
    setProspects(response.prospects);
  }

  async function getCounters() {
    let response;
    if (!domain) {
      response = await api.getCounts("counts");
    } else {
      response = await api.fetchData(
        "counts/domain",
        { domain: domain },
        {
          headers: {
            "auth-token": props.user.token,
          },
        }
      );
    }

    setStealersCount(response.stealers);
    setEmployeesCount(response.employees);
    setAndroidsCount(response.androids);
    setDomainsCount(response.domains);
    setCountriesCount(response.countries);
  }

  const config = {
    headers: {
      "auth-token": props.user.token,
    },
  };

  // const getCards = async (filters) => {
  //   if (!filters) {
  //     const companiesData = await api.fetchData("companies", {}, config);
  //     setCompanies(companiesData);
  //   } else {
  //     setLoading(true);
  //     const companiesData = await api.fetchData(
  //       `companies?filters=true&page=${0}`,
  //       filters,
  //       config
  //     );
  //     setLoading(false);
  //     setCompanies(companiesData);
  //   }
  // };

  const redirect = (e) => {
    history.push(e);
    window.location.reload();
  };

  const counters = [
    path !== "advanced-search" && {
      name: (
        <h2>
          Compromised <br /> Machines
        </h2>
      ),
      icon: <RiComputerLine size={30} className="counterIcon" />,
      count: Number(stealersCount),
    },
    path !== "advanced-search" && {
      name: (
        <h2>
          Compromised <br /> Employees
        </h2>
      ),
      icon: <FaUserTie size={30} className="counterIcon" />,
      count: Number(employeesCount),
    },
    path !== "advanced-search"
      ? {
          name: (
            <h2>
              Different <br /> Countries
            </h2>
          ),
          icon: <FaMapMarkerAlt size={30} className="counterIcon" />,
          count: Number(countriesCount),
        }
      : {
          name: (
            <h2>
              Matched <br /> Companies
            </h2>
          ),
          icon: <RiGroup2Fill size={30} className="counterIcon" />,
          count: Number(props.resultsCount),
        },
    path !== "advanced-search" && {
      name: (
        <h2>
          Compromised <br /> Androids
        </h2>
      ),
      icon: <DiAndroid size={30} className="counterIcon" />,
      count: Number(androidsCount),
    },
    path !== "advanced-search" && {
      name: (
        <h2>
          Compromised <br /> Domains
        </h2>
      ),
      icon: <FaGlobe size={30} className="counterIcon" />,
      count: domainsCount,
    },
  ];

  // const filterCompanies = (industries, sizes, countries) => {
  //   const filters = {
  //     industries: industries,
  //     sizes: sizes,
  //     countries: countries,
  //   };

  //   getCards(filters);
  // };

  // const openUpgradeModal = async () => {
  //   setLoader(user.sub.vaultedShopperId);
  //   await axios
  //     .post(
  //       `${blueSnapAddress}/custom-checkout?action=upgrade&sub=${user.sub.subscriptionId}&shopper=${user.sub.vaultedShopperId}`,
  //       {},
  //       config
  //     )
  //     .then((res) => setShopper(res.data))
  //     .catch((err) => {
  //       if (err.response && err.response.data) {
  //         if (err.response.data === "Invalid Token") {
  //           props.handleHomeLogOut();
  //         } else {
  //           console.log(err.response.data);
  //         }
  //       } else {
  //         console.log(err);
  //       }
  //     });

  //   setLoader(null);
  //   setUpgradeOpen(true);
  // };

  // const closeUpgradeModal = () => {
  //   setUpgradeOpen(false);
  // };

  // const updateSubscription = async (cvv) => {
  //   setFinsih(false);
  //   setOverlay("upgrade");
  //   const body = {
  //     shopper: shopper,
  //     cvv: cvv,
  //   };

  //   await axios
  //     .post(
  //       `${blueSnapAddress}/subscription/update?action=upgrade&sub=${user.sub.subscriptionId}`,
  //       body,
  //       config
  //     )
  //     .then((res) => {
  //       props.resetSub(res.data);
  //     })
  //     .catch((err) => console.log(err));
  //   setFinsih(true);
  //   setTimeout(() => {
  //     setOverlay("");
  //     closeUpgradeModal();
  //   }, 2000);
  // };

  const filterByTime = async (period) => {
    props.filterByTime(period);
  };

  const filterByType = async (type) => {
    props.filterByType(type);
  };

  return (
    <div className="App">
      <AppBarNew
        openUpgradeModal={props.openUpgradeModal}
        upgradeLoader={props.upgradeLoader}
        shopper={props.shopper}
        className="mobileHeader"
        path={path}
        user={user}
        role={role}
        token={token}
        email={email}
        handleLogOut={props.handleLogOut}
        handleHomeLogOut={props.handleHomeLogOut}
        redirect={(e) => redirect(e)}
        prospects={prospects}
        currentType={path === "advanced-search" ? "advanced" : "domain"}
        filterCompanies={(industries, sizes, countries, regions) =>
          props.filterCompanies(industries, sizes, countries, regions)
        }
        resetUser={(user) => props.resetUser(user)}
        credits={props.user.user.credits}
        openTopUps={() =>
          props.user.sub && props.user.sub.status != "pending" && handleOpen()
        }
      />
      <SimpleModal
        user={user}
        title="Top-Up Your Credits Balance for Additional Unlocks:"
        open={open}
        handleOpen={handleOpen}
        handleClose={handleClose}
        topUpLoader={props.topUpLoader}
        openTopUpModal={(item) => props.openTopUpModal(item)}
      />
      {/* <div id="generalCounters"> */}
      {loading || props.loading ? (
        <div style={{ marginTop: "350px" }}>
          <Container />
        </div>
      ) : (
        <div className="margin-div">
          <div
            id="generalCounters"
            style={{
              display: path === "advanced-search" && "block",
              height: path === "advanced-search" && "22rem",
            }}
          >
            <div className="counters">
              {counters.map(
                (counter, index) =>
                  counter && (
                    <div key={index} className="counter newCounter">
                      {counter.icon}
                      <CountUp
                        separator=","
                        start={0}
                        end={counter.count}
                        duration={1.75}
                      />
                      {counter.name}
                    </div>
                  )
              )}
            </div>
            {path === "advanced-search" && (
              <div
                style={{
                  backgroundColor: "#222831",
                  padding: "2rem",
                  borderRadius: "20px",
                  width: "80%",
                  margin: "auto",
                  color: "white",
                }}
              >
                <h4 className="cyberFont">Filter By Time</h4>
                {/* <SearchBy
                  searchType={activeTimeFilter}
                  changeSearchType={(activeTimeFilter) =>
                    filterByTime(activeTimeFilter)
                  }
                  options={timeOptions}
                  placeholder="All Time"
                /> */}
                <div className="time-filters">
                  <div
                    onClick={() => filterByTime("all")}
                    className={
                      props.activeTimeFilter === "all"
                        ? "yellow-bg"
                        : "yellowBgHover"
                    }
                  >
                    <span className="cyberFont">All Time</span>
                  </div>
                  <div
                    onClick={() => filterByTime("week")}
                    className={
                      props.activeTimeFilter === "week"
                        ? "yellow-bg"
                        : "yellowBgHover"
                    }
                  >
                    <span className="cyberFont">Last 7 Days</span>
                  </div>
                  <div
                    onClick={() => filterByTime("month")}
                    className={
                      props.activeTimeFilter === "month"
                        ? "yellow-bg"
                        : "yellowBgHover"
                    }
                  >
                    <span className="cyberFont">Last 30 days</span>
                  </div>
                  <div
                    onClick={() => filterByTime("quarter")}
                    className={
                      props.activeTimeFilter === "quarter"
                        ? "yellow-bg"
                        : "yellowBgHover"
                    }
                  >
                    <span className="cyberFont">Last 3 Months</span>
                  </div>
                  <div
                    onClick={() => filterByTime("year")}
                    className={
                      props.activeTimeFilter === "year"
                        ? "yellow-bg"
                        : "yellowBgHover"
                    }
                  >
                    <span className="cyberFont">Last 12 Months</span>
                  </div>
                </div>
                <div className="time-filters">
                  <div
                    onClick={() => filterByType("all")}
                    className={
                      props.activeTypeFilter === "all"
                        ? "yellow-bg"
                        : "yellowBgHover"
                    }
                  >
                    <span className="cyberFont">All</span>
                  </div>
                  <div
                    onClick={() => filterByType("employees")}
                    className={
                      props.activeTypeFilter === "employees"
                        ? "yellow-bg"
                        : "yellowBgHover"
                    }
                  >
                    <span className="cyberFont">Employees</span>
                  </div>
                  <div
                    onClick={() => filterByType("users")}
                    className={
                      props.activeTypeFilter === "users"
                        ? "yellow-bg"
                        : "yellowBgHover"
                    }
                  >
                    <span className="cyberFont">Users</span>
                  </div>
                </div>
              </div>
            )}
          </div>
          {props.cardsLoader ? (
            <LoaderIcon
              type="spin"
              style={{
                fill: "white",
                width: "100px",
                height: "100px",
                margin: "200px auto",
              }}
            />
          ) : (
            <CardsContainer
              resultsCount={props.resultsCount}
              companies={props.companies}
              page={props.page}
              setPage={(page) => props.setPage(page)}
              finalRes={props.finalRes}
              loader={props.loader}
              user={user}
            />
          )}

          {/* <Modal
            user={user}
            sub={user.sub}
            shopper={shopper}
            open={upgradeOpen}
            handleClickOpen={openUpgradeModal}
            handleClose={closeUpgradeModal}
            confirm={true}
            type="upgrade"
            subExpiry={new Date(user.sub.next_billing_date)
              .toString()
              .split(" ")
              .slice(0, 6)
              .join(" ")}
            func={(cvv) => updateSubscription(cvv)}
            title="Subscription Upgrade"
          /> */}
        </div>
      )}
      {/* </div> */}
      {!loading && !props.loading && <Footer />}
    </div>
  );
}

export default AppPreview;
