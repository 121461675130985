import React from "react";

export default function Overlay(props) {
  return (
    <div className="overlay">
      <div className="overlay__inner">
        {props.content === "unlock" ? (
          <div className="overlay__content">
            <span className="spinner"></span>
            <h1 className="darkText">Unlocking Data</h1>
            <br />
            {/* <h4 className="darkText">
              You will be charged for {props.charge} credits.
            </h4> */}
          </div>
        ) : (
          props.content === "payment" && (
            <div className="overlay__content" style={{ color: "white" }}>
              <span className="spinner"></span>
              <h1>Payment is being processed...</h1>
              <br />
              <h4>Please do not leave or refresh the page.</h4>
            </div>
          )
        )}
      </div>
    </div>
  );
}
