import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { authAddress, APIFullAddress } from "../../Config";
import AutoComplete from "../Search/AutoComplete";
import { FaSearch } from "react-icons/fa";
import Featured from "../Partials/Featured";
import CreateApiClient from "../../API";
import ContactCard from "../Partials/ContactCard";
import LoaderIcon from "react-loader-icon";
import { updateUserProperty, getItem } from "../../hooks/localStorage";
import SimpleModal from "../Partials/BasicModal";
import Select from "react-select";

const api = CreateApiClient();

export default function FindContacts(props) {
  const [featured, setFeatured] = useState([]);
  const [loadContacts, setLoadContacts] = useState(false);
  const [unlockLoading, setUnlockLoading] = useState(false);
  const [blur, setBlur] = useState(true);
  const [contacts, setContacts] = useState(null);
  const [suggestions, setSuggestions] = useState([]);
  const [domain, setDomain] = useState("");
  const [userContacts, setUserContacts] = useState([]);
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const [company, setCompany] = useState("");
  const [loadMyContacts, setLoadMyContacts] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const myRef = useRef(null);

  const headers = {
    headers: {
      "auth-token": props.user.token,
    },
  };

  let cancelToken;
  const onChange = async (e) => {
    e.preventDefault();
    setDomain(e.target.value.toLowerCase());

    // if (cancelToken) {
    //   cancelToken.cancel("Canceled");
    // }

    // cancelToken = axios.CancelToken.source();

    // let response;
    // try {
    //   response = await axios
    //     .get(
    //       `${APIFullAddress}/people/fetch-suggestions?query=${e.target.value.toLowerCase()}`,
    //       {
    //         cancelToken: cancelToken.token,
    //       }
    //     )
    //     .then((res) => {
    //       return res.data;
    //     });
    // } catch (err) {
    //   console.log(err);
    // }

    // setSuggestions(response);

    const response = api.fetchSuggestions(e.target.value.toLowerCase());
    response.then((result) => {
      setSuggestions(result);
    });
  };

  useEffect(() => {
    // getFeatured();
    getOptions();
  }, []);

  const getOptions = async () => {
    const results = await api.getContactsOptions(headers);
    setOptions(results);
  };

  const getUserContacts = async (domain) => {
    setLoadMyContacts(true);
    const results = await api.getUserContacts(headers, domain);
    setUserContacts(results);
    setLoadMyContacts(false);
  };

  const fetchContacts = async (domain) => {
    // executeScroll();
    setLoadContacts(true);
    setSuggestions([]);
    setDomain(domain);
    const results = await api.fetchContacts(headers, domain);
    setContacts(results);
    setLoadContacts(false);
  };

  const unlockContact = async (id) => {
    if (props.user.user.role === "mssp" && !props.user.sub) {
      handleOpen();
    } else {
      setUnlockLoading(id);
      const results = await api.unlockContact(headers, id);
      updateUserProperty("user", "credits", results.credits);
      updateUserProperty("user", "contacts", results.contacts);
      const localUser = getItem("user");
      props.resetUser(localUser.user);
      const reveal = contacts.contacts.find((s) => s.id == results.current.id);
      // reveal.full_name = results.current.full_name;
      for (const key in results.current) {
        if (Object.hasOwnProperty.call(results.current, key)) {
          reveal[key] = results.current[key];
        }
      }
      setUnlockLoading(null);
    }
  };

  const executeScroll = () => myRef.current.scrollIntoView();

  return (
    <div id="contacts-sidebar" className="sidebar max-100 scroll-y">
      <div
        style={{
          position: "sticky",
          top: "0px",
          zIndex: "99",
          backgroundColor: "#222831",
          padding: "1.1rem",
          borderBottom: "2px solid #15181c",
        }}
        id="contacts-autocomplete"
      >
        <AutoComplete
          placeholder="Find Contacts By Domain"
          classes="radius-50 autocompleteInput"
          user={props.user}
          fetchContacts={(domain) => fetchContacts(domain)}
          onChange={onChange}
          domain={domain}
          suggestions={suggestions}
        />
      </div>
      <div
        ref={myRef}
        style={{ position: "relative", top: "-300px" }}
        id="results"
      ></div>
      <br />
      <div
        id="contacts-div"
        className="contacts-div"
        style={{ margin: "1rem" }}
      >
        {contacts &&
        contacts.contacts &&
        contacts.contacts.length > 0 &&
        !loadContacts ? (
          <ContactCard
            user={props.user}
            data={contacts}
            blur={blur}
            unlockContact={(id) => unlockContact(id)}
            unlockLoading={unlockLoading}
          />
        ) : (
          loadContacts && (
            <LoaderIcon
              type={"spin"}
              className="tabsLoader"
              // style={{ marginBottom: "2rem" }}
            />
          )
        )}
      </div>
      <>
        {options.length > 0 && (
          <>
            <h4 className="align-center" style={{ color: "#fbcd4e" }}>
              My Contacts
            </h4>
            <div style={{ width: "70%", margin: "auto" }}>
              <Select
                value={company && company}
                defaultValue={options[0]}
                onChange={(company) => {
                  setCompany(company);
                  getUserContacts(company.value);
                }}
                placeholder="Select By Domain"
                className="search-by-select desktop"
                options={options}
                theme={(theme) => ({
                  ...theme,
                  borderRadius: 0,
                  colors: {
                    ...theme.colors,
                    primary25: "#FBCD4E",
                    primary: "black",
                  },
                })}
              />
            </div>
          </>
        )}

        <br />
        {userContacts && userContacts.length > 0 && !loadMyContacts
          ? userContacts.map((_company) => (
              <ContactCard
                user={props.user}
                data={_company}
                blur={blur}
                unlockContact={(id) => unlockContact(id)}
                unlockLoading={unlockLoading}
              />
            ))
          : loadMyContacts && (
              <LoaderIcon
                type={"spin"}
                className="tabsLoader"
                // style={{ marginBottom: "2rem" }}
              />
            )}
      </>
      <SimpleModal
        user={props.user}
        title="Top-Up Your Credits Balance for Additional Unlocks:"
        open={open}
        handleOpen={handleOpen}
        handleClose={handleClose}
        topUpLoader={props.topUpLoader}
        openTopUpModal={(item) => props.openTopUpModal(item)}
      />
      {/* <Featured
        // data={props.prospects}
        data={props.prospectsDomains}
        fetchContacts={(domain) => fetchContacts(domain)}
      /> */}
    </div>
  );
}
