// Global
export const serverAPIPort = 8080;
export const frontendPort = 3000;
export const APIEndpoint = "/api/data";
export const APIEmail = "/api/email";
export const APIauth = "/api/user";
export const bsEndpoint = "api/bluesnap";
export const MapApi =
  "pk.eyJ1IjoiZG91YmxlYmFja3NsYXNoIiwiYSI6ImNrdG10MDh3MDBmMjQydXM4ZG9jZ2l2bGkifQ.-hqbVHfS3AMn1RoS-WFoQQ";
export const clearbitApi =
  "https://autocomplete.clearbit.com/v1/companies/suggest?query=";

export const host =
  !process.env.NODE_ENV || process.env.NODE_ENV === "development"
    ? "http://localhost"
    : "https://bayonet.hudsonrock.com";
export const APIFullAddress =
  !process.env.NODE_ENV || process.env.NODE_ENV === "development"
    ? `${host}:${serverAPIPort}${APIEndpoint}`
    : `${host}${APIEndpoint}`;
export const APIFullEmailAddress =
  !process.env.NODE_ENV || process.env.NODE_ENV === "development"
    ? `${host}:${serverAPIPort}${APIEmail}`
    : `${host}${APIEmail}`;
export const blueSnapAddress =
  !process.env.NODE_ENV || process.env.NODE_ENV === "development"
    ? `${host}:${serverAPIPort}/${bsEndpoint}`
    : `${host}/${bsEndpoint}`;
export const authAddress =
  !process.env.NODE_ENV || process.env.NODE_ENV === "development"
    ? `${host}:${serverAPIPort}${APIauth}`
    : `${host}${APIauth}`;
export const publicAddress =
  !process.env.NODE_ENV || process.env.NODE_ENV === "development"
    ? `${host}:${frontendPort}`
    : `${host}`;
export const clearAddress =
  !process.env.NODE_ENV || process.env.NODE_ENV === "development"
    ? `${host}:${serverAPIPort}`
    : `${host}`;
export const bsURL =
  !process.env.NODE_ENV || process.env.NODE_ENV === "development"
    ? "https://sandbox.bluesnap.com/web-sdk/4/bluesnap.js"
    : "https://ws.bluesnap.com/web-sdk/4/bluesnap.js";
export const bsEnv =
  !process.env.NODE_ENV || process.env.NODE_ENV === "development"
    ? "sandbox"
    : "production";

// Production
// export const host = "https://bayonet.hudsonrock.com";
// export const APIFullAddress = `${host}${APIEndpoint}`;
// export const APIFullEmailAddress = `${host}${APIEmail}`;
// export const authAddress = `${host}${APIauth}`;
// export const publicAddress = `${host}`;
// export const clearAddress = `${host}`;
// export const blueSnapAddress = `${host}/${bsEndpoint}`;
// export const bsURL = "https://ws.bluesnap.com/web-sdk/4/bluesnap.js";
// export const bsEnv = "production";
