import React, { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import Container from "../Partials/ProgressBar";
import NativeSelect from "../Partials/NativeSelect";
import ChartDataLabels from "chartjs-plugin-datalabels";
import cavaLogo from "../../images/thumbnail.jpeg";
import LoaderIcon from "react-loader-icon";
import CreateApiClient from "../../API";

const api = CreateApiClient();

function LineChart(props) {
  const [showImage, setShowImage] = useState(true);
  const [companyLogo, setCompanyLogo] = useState("");

  const defaultSrc = (ev) => {
    ev.target.src = cavaLogo;
  };

  const data = {
    labels: props.labels,
    datasets: [
      {
        label: "Employees",
        data: props.employeesStats,
        fill: true,
        // backgroundColor: "rgba(75,192,192,0.2)",
        // borderColor: "rgba(75,192,192,1)",

        backgroundColor: [
          "rgba(255, 99, 132, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(255, 206, 86, 0.2)",
          "rgba(75, 192, 192, 0.2)",
          "rgba(153, 102, 255, 0.2)",
          "rgba(255, 159, 64, 0.2)",
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(255, 159, 64, 1)",
        ],
        borderWidth: 2,
        datalabels: {
          color: "#FFCE56",
          align: -120,
        },
      },
      {
        label: "Users",
        data: props.clientsStats,
        fill: true,
        // borderColor: "#742774",
        // backgroundColor: "rgba(75,192,192,0.2)",
        // borderColor: "rgba(75,192,192,1)",

        backgroundColor: ["rgb(54, 162, 235, 0.2)"],
        borderColor: ["rgb(54, 162, 235, 1)"],
        borderWidth: 2,
        datalabels: {
          color: "#FFCE56",
          align: -120,
        },
        // spanGaps: false,
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: true,
        align: "start",
        labels: {
          usePointStyle: true,
          // pointStyle: "circle",
          color: props.labelColor,
        },
      },
      datalabels: {
        align: "bottom",
        display: true,
        color: "#a5a5a5",
        padding: 5,
        clamp: true,
      },
    },
    elements: {
      line: {
        tension: 0.4,
      },
    },
    // scales: {
    //   yAxes: [
    //     {
    //       ticks: {
    //         beginAtZero: true,
    //       },
    //     },
    //   ],
    // },
  };

  // const [year, setYear] = React.useState("");
  // const handleYearChange = (event) => {
  //   setYear(event.target.value);
  // };

  useEffect(() => {
    props.title && getCompanyLogo(props.title);
  }, []);

  // const hideImage = () => {
  //   setShowImage(false);
  // };

  const getCompanyLogo = async (company) => {
    if (company) {
      const companies = await api.fetchSuggestions(company);
      if (companies && companies[0]) {
        const logo = companies[0].logo;
        logo ? setCompanyLogo(logo) : setShowImage(false);
      } else {
        setShowImage(false);
      }
    }
  };

  return (
    <div className="lineChart" style={{ marginTop: companyLogo && "-1.5rem" }}>
      {props.stealersCount > 0 ? (
        !props.loading ? (
          <>
            <div className="header" style={{ display: "flex" }}>
              <div className="left-col chartSelect width-33">
                <NativeSelect
                  handleYearChange={props.handleYearChange}
                  yearsOptions={props.yearsOptions}
                  year={props.year}
                />
              </div>
              <div className="center-col width-33">
                <h2
                  className="title chartTitle"
                  style={{
                    fontSize: "1.5rem",
                    color: props.type === "3rd-party" && "black",
                  }}
                >
                  {props.title && (
                    <>
                      {companyLogo && (
                        <>
                          <img
                            id="logoImg"
                            preserveDrawingBuffer={true}
                            className="roundSmallImg borderBlack"
                            style={{
                              backgroundColor: "#1A1C20",
                              border: "0px",
                              // border: "2px solid black",
                              // borderRadius: "10px",
                              // padding: "5px",
                            }}
                            src={
                              props.currentCompany && props.currentCompany.logo
                                ? props.currentCompany.logo
                                : companyLogo
                            }
                            onError={defaultSrc}
                            alt="logo"
                          />
                          <br />
                        </>
                      )}
                      {props.title}
                      <br />
                    </>
                  )}
                  Compromised Computers Timeline
                </h2>
              </div>
              <div className="right-col width-33"></div>
            </div>
            <hr></hr>
            {props.chartLoader ? (
              <LoaderIcon
                className="tabsLoader mainChartLoader"
                type={"spin"}
              />
            ) : (
              <Line
                plugins={[ChartDataLabels]}
                data={data}
                options={options}
                width={80}
                height={20}
                style={{
                  backgroundColor: props.screen === "main" && "#222831",
                  padding: props.screen === "main" && "30px",
                  borderRadius: props.screen === "main" && "50px",
                  // boxShadow:
                  //   props.screen === "main" &&
                  //   "0 5px 5px 0 rgba(0, 0, 0, 0.2), 0 5px 5px 0 rgba(0, 0, 0, 0.19)",
                }}
              />
            )}
          </>
        ) : (
          <Container />
        )
      ) : (
        <h1>No Timeline To Show</h1>
      )}
    </div>
  );
}

export default LineChart;
