import React from "react";
import logo from "../../images/hrlogo_new.png";
import { ImLinkedin, ImTwitter } from "react-icons/im";

export default function Footer(props) {
  return (
    <div
      className="footer"
      style={{ backgroundColor: props.checkout && "#1A1C20" }}
    >
      <div>
        <img src={logo} width="200px" />
      </div>
      {!props.noSocial && (
        <div className="socialIcons">
          <a
            className="linkedin footer-social"
            href="https://www.linkedin.com/company/hudson-rock/"
            target="_blank"
            rel="noreferrer"
          >
            <ImLinkedin size={25} />
          </a>
          <a
            className="footer-social twitter"
            href="https://twitter.com/RockHudsonRock"
            target="_blank"
            rel="noreferrer"
          >
            <ImTwitter size={25} />
          </a>
        </div>
      )}
      {!props.checkout && (
        <p style={{ fontSize: "12px", color: "white", marginTop: "5px" }}>
          Logo images by Clearbit
        </p>
      )}
    </div>
  );
}
