import React, { Component } from "react";
import Search from "../Search/Search";
import CreateApiClient from "../../API";
import axios from "axios";
import { APIFullAddress } from "../../Config";
import SearchBy from "../Search/SearchBy";

const api = CreateApiClient();

class Form extends Component {
  constructor(props) {
    super(props);
    this.onChangeDomain = this.onChangeDomain.bind(this);
    this.onChangeDomainClients = this.onChangeDomainClients.bind(this);
    this.onChangeSystem = this.onChangeSystem.bind(this);
    this.cancelToken = "";

    this.state = {
      user: props.user,
      loading: false,
      domains: props.domains,
      email: props.email,
      role: props.role,
      type: "Employees",
      domain: props.inputValue ? props.inputValue : "",
      data: [],
      results: "",
      token: props.token,
      // selectDomain: props.domains[0],
      employees: true,
      gmail: true,
      arrows: false,
      selectors: ["User", "Customer"],
      searchers: ["Admin", "MSSP"],
      suggestions: [],
      systemSuggestions: [],
      companies: [],
      machines: "",
      employeesCount: "",
      hasAccess: false,
      activeDomains: [],
      searchType: props.searchType ? props.searchType : "domain",
    };
  }

  config = {
    headers: {
      "auth-token": this.props.token,
      "refresh-token": this.props.refreshToken,
    },
  };

  componentDidMount() {
    this.getPresets();
  }

  getPresets = async () => {
    const role = this.state.role;
    const searchers = this.state.searchers;
    searchers.includes(role)
      ? this.setState({ hasAccess: true })
      : this.setState({ hasAccess: false });
    this.setState({ loading: true });
    if (role === "Admin" || role === "MSSP") {
      const companiesData = await api.fetchData("companies", {}, this.config);
      this.setState({ companies: companiesData });
    }
    this.setState({ loading: false });
  };

  submitFromCard = async (e) => {
    e.preventDefault();
    this.setState({ loading: true });
    const clickedDomain = e.target.value;
    this.setState({ domain: clickedDomain });
    let query = {
      domain: clickedDomain,
    };
    const response = await api.fetchData("employees", query, this.config);
    this.dataValidation(response);
    this.setState({ arrows: true });
  };

  onChangeDomain(e) {
    const response = api.fetchSuggestions(e.target.value.toLowerCase());
    response.then((result) => {
      this.setState({ suggestions: result });
    });
    this.setState({
      domain: e.target.value.toLowerCase(),
    });
  }

  onChangeSystem = async (e) => {
    const d = this.state.domain;
    const q = e.target.value.toLowerCase();

    this.setState({
      domain: q,
    });
    if (this.cancelToken) {
      this.cancelToken.cancel("Canceled");
    }
    this.cancelToken = axios.CancelToken.source();
    await axios
      .get(`${APIFullAddress}/url-parts/fetch-suggestions?query=${q}`, {
        // signal: signal,
        cancelToken: this.cancelToken.token,
      })
      .then((res) => {
        this.setState({ systemSuggestions: res.data });
      })
      .catch((err) => console.log(err));

    // (!q || q.length === 0) &&
    // this.setState({ domain: "", systemSuggestions: [] });

    !q && this.setState({ domain: "", systemSuggestions: [] });
  };

  pickSystem = (system) => {
    this.setState({ domain: system });
    this.setState({ systemSuggestions: [] });
  };

  onChangeDomainClients(e) {
    if (e.target.value !== undefined) {
      if (e.target.value !== 0) {
        this.setState({
          domain: e.target.value.toLowerCase(),
        });
      } else if (e.target.innerText !== undefined) {
        this.setState({
          domain: e.target.innerText.toLowerCase(),
        });
      } else {
        this.setState({
          domain: null,
        });
      }
    } else {
      this.setState({
        domain: null,
      });
    }
  }

  selectMultipleDomains = (e) => {
    let selectedDomains = this.state.activeDomains;
    let checkedDomain;
    if (e.target.value !== undefined) {
      if (e.target.value !== 0) {
        checkedDomain = e.target.value.toLowerCase();
        if (checkedDomain === "all") {
          this.state.activeDomains && this.state.activeDomains.length > 0
            ? this.setState({ activeDomains: [] })
            : this.setState({ activeDomains: this.state.domains });
        } else {
          let index = selectedDomains.indexOf(checkedDomain);
          if (index > -1) {
            selectedDomains.splice(index, 1);
          } else {
            selectedDomains.push(checkedDomain);
          }
          this.setState({ activeDomains: selectedDomains });
        }
      } else if (e.target.innerText !== undefined) {
        if (e.target.innerText.toLowerCase() === "all") {
          this.state.activeDomains && this.state.activeDomains.length > 0
            ? this.setState({ activeDomains: [] })
            : this.setState({ activeDomains: this.state.domains });
        }
      } else {
        // this.setState({
        //   domain: null,
        // });
      }
    } else if (e.target.id !== undefined) {
      if (e.target.id.toLowerCase() === "all") {
        this.state.activeDomains && this.state.activeDomains.length > 0
          ? this.setState({ activeDomains: [] })
          : this.setState({ activeDomains: this.state.domains });
      }
    }
  };

  dataValidation = (dataFound) => {
    dataFound.length > 0
      ? this.setState({ data: dataFound })
      : this.setState({ data: [] });
    this.setState({ results: "Nothing Found" });
    this.setState({ loading: false });
  };

  handleSubjectSelect = (e) => {
    if (e.target.value.toString() === "Employees") {
      this.setState({ employees: true });
      this.setState({ type: "Employees" });
    } else if (e.target.value.toString() === "Clients") {
      this.setState({ employees: false });
      this.setState({ type: "Clients" });
    } else {
      this.setState({ employees: true });
    }
  };

  domainPicked = (e) => {
    e.preventDefault();
    const pickedDomain = e.target.attributes[0].nodeValue;
    this.setState({ domain: pickedDomain });
    this.setState({ suggestions: [] });
  };

  allDomains = () => {
    const options = [];
    this.props.domains.map((domain) => {
      options.push({ label: domain, value: domain });
    });
    return options;
  };

  changeSearchType = (searchType) => {
    this.setState({ searchType: searchType.value });
    this.setState({ domain: "" });
    this.setState({ suggestions: [] });
  };

  options = [
    { value: "domain", label: "Domain" },
    { value: "prospect", label: "Prospect" },
    { value: "system", label: "Keyword" },
    { value: "advanced", label: "Advanced" },
  ];

  render() {
    const role = this.state.role;
    return (
      <div className="mainDiv">
        <div className="form flexForm">
          <SearchBy
            searchType={this.props.searchType}
            changeSearchType={(searchType) => this.changeSearchType(searchType)}
            options={this.options}
            placeholder="Search By"
          />
          <Search
            filterCompanies={(industries, sizes, countries, regions) =>
              this.props.filterCompanies(industries, sizes, countries, regions)
            }
            selectedDomain={this.state.domain}
            machines={this.state.machines}
            employeesCount={this.state.employeesCount}
            value={this.state.domain}
            onChange={this.onChangeDomain}
            onChangeSystem={this.onChangeSystem}
            role={role}
            suggestions={this.state.suggestions}
            systemSuggestions={this.state.systemSuggestions}
            pickDomain={this.domainPicked}
            loader={this.state.loading}
            activeDomain={this.props.activeDomain}
            searchType={this.state.searchType}
            user={this.props.user}
            prospects={this.props.prospects}
            redirect={(e) => this.props.redirect(e)}
            pickSystem={this.pickSystem}
            handleLogOut={this.props.handleLogOut}
            path={this.props.path}
            openUpgradeModal={this.props.openUpgradeModal}
            upgradeLoader={this.props.upgradeLoader}
          />
        </div>
      </div>
    );
  }
}

export default Form;
