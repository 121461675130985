export const CheckUserSession = (user) => {
  const time = Date.now().toString().substring(0, 10);
  const nowNum = Number(time);
  if (user.expiry < nowNum) {
    return null;
  } else {
    return user;
  }
};

export const checkUser = () => {
  const loggedInUser = localStorage.getItem("user");
  if (loggedInUser) {
    const foundUser = JSON.parse(loggedInUser);
    const time = Date.now().toString().substring(0, 10);
    const nowNum = Number(time);

    if (foundUser && foundUser.expiry < nowNum) {
      return null;
    } else {
      return foundUser;
    }
  } else return null;
};
