import React from "react";
import LoaderIcon from "react-loader-icon";
import Button from "@material-ui/core/Button";
import Icon from "@material-ui/core/Icon";

export default function UpgradeTier(props) {
  return (
    <>
      <h1>Please upgrade your subscription level to unlock this feature.</h1>
      <Button
        id="upgradeTierBtn"
        type="button"
        variant="contained"
        className={"searchBtn desktopSearchBtn"}
        endIcon={
          props.upgradeLoader === "upgradeTierBtn" ? (
            <LoaderIcon
              className="removeLoader tableLoader"
              style={{
                position: "relative",
                top: "-10px",
                right: "0px",
              }}
            />
          ) : (
            <Icon>send</Icon>
          )
        }
        onClick={props.openUpgradeModal}
      >
        Upgrade
      </Button>
    </>
  );
}
