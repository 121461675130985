import React, { useEffect, useState } from "react";
import FormControl from "@material-ui/core/FormControl";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import {
  FaUserTie,
  FaUsers,
  FaNetworkWired,
  FaSitemap,
  FaExternalLinkAlt,
} from "react-icons/fa";
import { DiAndroid } from "react-icons/di";
import { AiFillDatabase } from "react-icons/ai";
import EmployeeTabs from "../Data/Tabs";
import Container from "../Partials/ProgressBar";
import CustomPaginationActionsTable from "../Data/Table";
import DbTabs from "../Data/DbTabs";
import CreateApiClient from "../../API";
import ExportToCsv from "../Partials/ExportToCsv";
import AppBarNew from "../Partials/AppBar";
import SearchBy from "../Search/SearchBy";
import SimpleModal from "../Partials/BasicModal";
import Footer from "../Partials/Footer";
import { VscGistSecret } from "react-icons/vsc";
import axios from "axios";
import { APIFullAddress } from "../../Config";
import UnlockedAssets from "./UnlockedAssets";
import SystemDiscovery from "./SystemDiscovery";

const api = CreateApiClient();

export default function UnlockedContent(props) {
  const [type, setType] = useState("");
  const [data, setData] = useState([]);
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(0);
  const [loader, setLoader] = useState(false);
  const [finalRes, setFinalRes] = useState(false);
  const [resLength, setResLength] = useState(0);
  const [loading, setLoading] = useState(false);
  const [sortType, setSortType] = useState("date_unlocked");
  const [open, setOpen] = useState(false);
  const [companyPicked, setCompanyPicked] = useState("");
  const [unlockedCompanies, setUnlockedCompanies] = useState([]);
  const [urlsCsv, setUrlsCsv] = useState([]);
  const [csvLoader, setCsvLoader] = useState(null);
  const [assets, setAssets] = useState([]);
  const [activeDomainAssets, setActiveDomainAssets] = useState([]);
  const [urlsLoading, setUrlsLoading] = useState(null);
  const [assetsNumbers, setAssetsNumbers] = useState([]);
  const [hasNextPage, setHasNextPage] = useState(false);

  const config = {
    headers: {
      "auth-token": props.token,
    },
  };

  useEffect(() => {
    const validTypes = [
      {
        type: "employee",
        label: "employees",
      },
      {
        type: "third-party",
        label: "third-parties",
      },
      {
        type: "user",
        label: "users",
      },
      {
        type: "apk",
        label: "apps",
      },
      {
        type: "external",
        label: "external domains",
      },
      {
        type: "assets-discovery",
        label: "assets-discovery",
      },
    ];
    let defaultType = validTypes.find((s) =>
      props.user.user.unlocked_content.find((t) => t.type === s.type)
    );
    defaultType = defaultType.label;
    setType(defaultType);
    getData([], defaultType, 0, sortType, companyPicked);
  }, []);

  useEffect(() => {
    getCompanies();
  }, [type]);

  const changeCompany = (e) => {
    setCompanyPicked(e.value);
  };

  const getCompanies = async () => {
    let response, _type;
    // if (type === "third-parties") {
    //   _type = "employees";
    // } else {
    //   _type = type;
    // }
    response = await api.getUserData(
      `distinct-unlocked-companies?type=${type}`,
      {},
      config
    );

    if (typeof response != "string") {
      setUnlockedCompanies(response);
    }
  };

  useEffect(() => {
    if (type) {
      getData([], type, 0, sortType, companyPicked);
    }
  }, [companyPicked, sortType]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const changeType = (e) => {
    setData([]);
    setCompanyPicked("");
    setType(e.target.value);
    getData([], e.target.value, 0, sortType);
  };

  const clickOnCsv = (id) => {
    document.getElementById(id).click();
  };

  const exportUrls = async (domain, type) => {
    setCsvLoader(`${domain}_${type}`);
    let body = {
      domains: [domain],
    };
    const response = await axios.post(
      `${APIFullAddress}/assets-discovery/?csv=true&type=${type}`,
      body,
      config
    );
    setCsvLoader(null);
    setUrlsCsv(response.data[0].urls);
    clickOnCsv(`${domain}_${type}_csv`);
  };

  const getData = async (oldData, type, page, sort, company) => {
    if (page > 0) {
      setLoader(true);
    } else {
      setLoading(true);
    }
    let response, domainsAssets;
    if (
      props.user &&
      props.user.user &&
      props.user.user.unlocked_urls &&
      props.user.user.unlocked_urls.length > 0
    ) {
      domainsAssets = await Promise.all(
        props.user.user.unlocked_urls.map((item) => {
          return item.domain;
        })
      );
    } else {
      domainsAssets = [];
    }

    if (type === "systems") {
      await axios
        .post(
          `${APIFullAddress}/url-parts/get-user-systems?company=${company}`,
          {},
          config
        )
        .then((res) => {
          setData(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    } else if (type === "assets-discovery") {
      let body = {
        domains: domainsAssets,
      };

      const assets = await axios.post(
        `${APIFullAddress}/assets-discovery/get-unlocked-content?company=${company}`,
        body,
        config
      );
      response = assets.data;
      if (response) {
        setData(response);
      }
    } else {
      let _type;
      if (type != "apps") {
        if (type === "third-parties") {
          _type = "employees";
        } else if (type === "externals") {
          _type = "external-domains";
        } else {
          _type = type;
        }
        response = await api.fetchData(
          `${_type}/get-unlocked-content?type=${_type}&page=${page}&sortby=${sort}&company=${company}&thirdparty=${
            type === "third-parties"
          }`,
          { company: company },
          config
        );
      } else {
        response = await api.fetchData(
          `users/get-unlocked-content?android=true&type=${_type}&page=${page}&sortby=${sort}&company=${company}`,
          { company: company },
          config
        );
      }

      if (response) {
        if (response.length > 0) {
          setHasNextPage(response[0].hasNextPage);
          if (data && data.length > 0) {
            setData([...oldData, ...response]);
          } else {
            setData([...response]);
          }
          setFinalRes(false);
          setCount(response[0].count);
          setResLength(response.length);
        } else {
          data.length > 0 ? setData(oldData) : setData([]);
          setFinalRes(true);
          setHasNextPage(false);
        }
      }
    }

    if (page > 0) {
      setLoader(false);
    } else {
      setLoading(false);
    }
  };

  useEffect(() => {
    setPage(0);
  }, [sortType, type]);

  const loadMore = (type) => {
    setPage(page + 1);
    getData(data, type, page + 1, sortType);
  };

  const changeSortType = (type) => {
    setSortType(type.value);
  };

  const getUserSystems = async (company) => {
    await axios
      .post(
        `${APIFullAddress}/url-parts/get-user-systems?company=${company}`,
        {},
        config
      )
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getUrlsByDomain = async (company) => {
    setUrlsLoading(company.id);
    let body = {
      domains: [company.domain],
    };
    await axios
      .post(
        `${APIFullAddress}/assets-discovery?system=${company.system}`,
        body,
        config
      )
      .then((res) => {
        if (res.data.data && res.data.data[0]) {
          setActiveDomainAssets(res.data.data[0]);
        }
      })
      .catch((err) => console.log(err));
    setUrlsLoading(null);
  };

  const options = [
    { value: "date_unlocked", label: "Unlock Date" },
    { value: "date_compromised", label: "Date Compromised" },
    { value: "date_added", label: "Date Added" },
    // { value: "asc", label: "Ascending" },
    // { value: "desc", label: "Descending" },
  ];

  return (
    <>
      <>
        <AppBarNew
          handleHomeLogOut={props.handleHomeLogOut}
          className="mobileHeader"
          user={props.user}
          role={props.role}
          token={props.token}
          email={props.email}
          domains={props.domains}
          handleLogOut={props.handleLogOut}
          filterCompanies={(industries, sizes, countries, regions) =>
            props.filterCompanies(industries, sizes, countries, regions)
          }
          resetUser={(user) => props.resetUser(user)}
          credits={props.user.user.credits}
          openTopUps={() =>
            props.user.sub && props.user.sub.status != "pending" && handleOpen()
          }
        />
        <SimpleModal
          title="Top-Up Your Credits Balance for Additional Unlocks:"
          open={open}
          handleOpen={handleOpen}
          handleClose={handleClose}
          topUpLoader={props.topUpLoader}
          openTopUpModal={(item) => props.openTopUpModal(item)}
          user={props.user}
        />
        {props.user.user &&
          ((props.user.user.unlocked_content &&
            props.user.user.unlocked_content.length > 0) ||
            (props.user.user.unlocked_systems &&
              props.user.user.unlocked_systems.length > 0) ||
            (props.user.user.unlocked_urls &&
              props.user.user.unlocked_urls.length > 0)) &&
          type && (
            <div className="unlockedSection">
              <FormControl component="fieldset" className="">
                <RadioGroup
                  onChange={changeType}
                  row
                  aria-label="position"
                  name="position"
                  value={type}
                >
                  {props.user.user.unlocked_content.find(
                    (s) => s.type === "employee"
                  ) && (
                    <FormControlLabel
                      value="employees"
                      control={<Radio color="primary" />}
                      label={
                        <div className="typeIcon">
                          <FaUserTie size={22} className="" />
                          Employees
                        </div>
                      }
                      labelPlacement="top"
                    />
                  )}
                  {props.user.user.unlocked_content.find(
                    (s) => s.type === "third-party"
                  ) && (
                    <FormControlLabel
                      value="third-parties"
                      control={<Radio color="primary" />}
                      label={
                        <div className="typeIcon">
                          <FaSitemap size={22} className="" />
                          Third Parties
                        </div>
                      }
                      labelPlacement="top"
                    />
                  )}
                  {props.user.user.unlocked_content.find(
                    (s) => s.type === "user"
                  ) && (
                    <FormControlLabel
                      value="users"
                      control={<Radio color="primary" />}
                      label={
                        <div className="typeIcon">
                          <FaUsers size={25} className="" />
                          Users
                        </div>
                      }
                      labelPlacement="top"
                    />
                  )}
                  {props.user.user.unlocked_content.find(
                    (s) => s.type === "apk"
                  ) && (
                    <FormControlLabel
                      value="apps"
                      control={<Radio color="primary" />}
                      label={
                        <div className="typeIcon">
                          <DiAndroid size={25} className="" />
                          App Users
                        </div>
                      }
                      labelPlacement="top"
                    />
                  )}
                  {props.user.user.unlocked_content.find(
                    (s) => s.type === "external"
                  ) && (
                    <FormControlLabel
                      value="externals"
                      control={<Radio color="primary" />}
                      label={
                        <div className="typeIcon">
                          <FaExternalLinkAlt size={25} className="" />
                          External Domains
                        </div>
                      }
                      labelPlacement="top"
                    />
                  )}
                  {console.log(props.user.user.unlocked_urls)}
                  {props.user.user.unlocked_urls &&
                    props.user.user.unlocked_urls.length > 0 && (
                      <FormControlLabel
                        value="assets-discovery"
                        control={<Radio color="primary" />}
                        label={
                          <div className="typeIcon">
                            <VscGistSecret size={25} className="" />
                            Assets Discovery
                          </div>
                        }
                        labelPlacement="top"
                      />
                    )}
                  {props.user.user.unlocked_systems &&
                    props.user.user.unlocked_systems.length > 0 && (
                      <FormControlLabel
                        value="systems"
                        control={<Radio color="primary" />}
                        label={
                          <div className="typeIcon">
                            <FaNetworkWired size={25} className="" />
                            Systems
                          </div>
                        }
                        labelPlacement="top"
                      />
                    )}
                </RadioGroup>
              </FormControl>
            </div>
          )}
        {!loading &&
        props.user.user &&
        ((props.user.user.unlocked_content &&
          props.user.user.unlocked_content.length > 0) ||
          (props.user.user.unlocked_systems &&
            props.user.user.unlocked_systems.length > 0) ||
          (props.user.user.unlocked_urls &&
            props.user.user.unlocked_urls.length > 0)) ? (
          <>
            <div
              className="screen-tools"
              style={{
                display: "inline-flex",
                position: "relative",
                alignItems: "center",
                top:
                  type === "employees" ||
                  type === "dbs" ||
                  type === "third-parties"
                    ? "-2rem"
                    : "0rem",
                margin:
                  type === "employees" ||
                  type === "dbs" ||
                  type === "third-parties"
                    ? "30px 0px 25px 5px"
                    : "20px 0px 0px 5px",
              }}
            >
              <div
                style={{ marginRight: "5px" }}
                className="screen-tools width-200"
              >
                <SearchBy
                  searchType={companyPicked}
                  changeSearchType={(companyPicked) =>
                    changeCompany(companyPicked)
                  }
                  options={unlockedCompanies}
                  placeholder="Filter By Company"
                />
              </div>
              {data.length > 0 &&
                type != "assets-discovery" &&
                type != "systems" && (
                  <>
                    <SearchBy
                      searchType={sortType}
                      changeSearchType={(sortType) => changeSortType(sortType)}
                      options={options}
                      placeholder="Sort By"
                    />
                  </>
                )}
              {type != "assets-discovery" && type != "systems" && (
                <ExportToCsv
                  unmask={true}
                  type={type}
                  data={data}
                  user={props.user}
                  sortType={sortType}
                  company={companyPicked}
                  isThirdParty={type === "third-parties" ? true : false}
                  // section={section}
                />
              )}
            </div>

            {type === "employees" && data.length > 0 ? (
              <div
                className="tabScreen unlockedTabScreen"
                style={{ marginBottom: "50px", top: "-2rem" }}
              >
                <EmployeeTabs
                  hasNextPage={hasNextPage}
                  data={data}
                  userRole={props.user.user.role}
                  unmask={true}
                  selectedDomain={props.selectedDomain}
                  loadMore={() => loadMore("employees")}
                  loader={loader}
                  employeesCount={count}
                  finalRes={finalRes}
                  config={config}
                  user={props.user}
                  path={props.path}
                  currentProspect={props.currentProspect}
                />
              </div>
            ) : type === "third-parties" && data.length > 0 ? (
              <div
                className="tabScreen unlockedTabScreen"
                style={{ marginBottom: "50px", top: "-2rem" }}
              >
                <EmployeeTabs
                  hasNextPage={hasNextPage}
                  isThirdParty={true}
                  data={data}
                  userRole={props.user.user.role}
                  unmask={true}
                  selectedDomain={props.selectedDomain}
                  loadMore={() => loadMore("employees")}
                  loader={loader}
                  employeesCount={count}
                  finalRes={finalRes}
                  config={config}
                  user={props.user}
                  path={props.path}
                  currentProspect={props.currentProspect}
                />
              </div>
            ) : type === "users" && data.length > 0 ? (
              <CustomPaginationActionsTable
                hasNextPage={hasNextPage}
                unmask={true}
                data={data}
                userRole={props.user.user.role}
                selectedDomain={props.selectedDomain}
                config={config}
                clientsCount={count}
                machines={count}
                finalRes={finalRes}
                loadMore={() => loadMore("users")}
                loader={loader}
                sortType={sortType}
                type={type}
              />
            ) : type === "apps" && data.length > 0 ? (
              <CustomPaginationActionsTable
                hasNextPage={hasNextPage}
                unmask={true}
                data={data}
                userRole={props.user.user.role}
                selectedDomain={props.selectedDomain}
                config={config}
                clientsCount={count}
                machines={count}
                finalRes={finalRes}
                loadMore={() => loadMore("apps")}
                loader={loader}
                sortType={sortType}
                type={type}
              />
            ) : type === "externals" && data.length > 0 ? (
              <CustomPaginationActionsTable
                hasNextPage={hasNextPage}
                unmask={true}
                data={data}
                userRole={props.user.user.role}
                selectedDomain={props.selectedDomain}
                config={config}
                clientsCount={count}
                machines={count}
                finalRes={finalRes}
                loadMore={() => loadMore("externals")}
                loader={loader}
                sortType={sortType}
                type={type}
              />
            ) : type === "assets-discovery" && data && data.length > 0 ? (
              <div style={{ color: "white", padding: "20px" }}>
                <UnlockedAssets
                  assets={data}
                  user={props.user}
                  token={props.token}
                  resetUser={(user) => props.resetUser(user)}
                  exportUrls={(domain, type) => exportUrls(domain, type)}
                  csvLoader={csvLoader}
                  urlsCsv={urlsCsv}
                />
              </div>
            ) : type === "systems" && data && data.length > 0 ? (
              <div
                style={{ color: "white", padding: "20px", textAlign: "center" }}
              >
                <SystemDiscovery
                  data={data}
                  getUrlsByDomain={(company) => getUrlsByDomain(company)}
                  activeDomainAssets={activeDomainAssets}
                  urlsLoading={urlsLoading}
                  user={props.user}
                  resetUser={(user) => props.resetUser(user)}
                  unmask={true}
                />
              </div>
            ) : (
              data.length === 0 && (
                <h2
                  className="contentPageTitle"
                  style={{
                    textAlign: "center",
                    color: "white",
                    // position: "relative",
                    // top: "-40vh",
                  }}
                >
                  No Data Found
                </h2>
              )
            )}
          </>
        ) : loading ? (
          <Container />
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              height: "70vh",
              color: "white",
            }}
          >
            <h2>NO UNLOCKED DATA</h2>
          </div>
        )}
      </>
      {!loading && !props.loading && data.length > 0 && <Footer />}
    </>
  );
}
