import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import LoaderIcon from "react-loader-icon";
import CreditCard from "./CreditCard";

export default function Billing(props) {
  const [primary, setPrimary] = useState(null);

  useEffect(() => {
    const primaryPaymentSource =
      props.rawSub.paymentSource.creditCardInfo.creditCard.cardLastFourDigits;
    setPrimary(primaryPaymentSource);
  }, [props.shopper]);

  return (
    <div>
      <br />
      <h5>Payment Sources:</h5>
      <div>
        {props.shopper.paymentSources.creditCardInfo.map((item, index) => (
          <div
            key={index}
            style={{
              backgroundColor:
                primary === item.creditCard.cardLastFourDigits
                  ? "#FECC4C"
                  : "#E6E6E6",
              padding: "1rem",
              borderRadius: "20px",
              position: "relative",
              margin: "5px 0px",
            }}
          >
            {primary === item.creditCard.cardLastFourDigits ? (
              <span
                style={{
                  color: "#5131C0",
                  position: "absolute",
                  top: "15px",
                  right: "15px",
                }}
              >
                PRIMARY
              </span>
            ) : (
              <>
                <span
                  className="lightText cursor"
                  style={{
                    position: "absolute",
                    top: "15px",
                    right: "25px",
                  }}
                  onClick={() => props.removeCard(item)}
                >
                  {props.removeCreditCardLoading ===
                  item.creditCard.cardLastFourDigits ? (
                    <LoaderIcon
                      type={"spin"}
                      style={{
                        width: "16px",
                        height: "auto",
                        margin: "0px 2px 2px",
                      }}
                    />
                  ) : (
                    "X"
                  )}
                </span>
                <Button
                  onClick={() => props.setPrimary(item)}
                  type="button"
                  variant="contained"
                  className={"searchBtn desktopSearchBtn"}
                  style={{
                    position: "absolute",
                    bottom: "15px",
                    right: "15px",
                  }}
                >
                  MAKE PRIMARY
                  {props.primaryLoading ===
                    item.creditCard.cardLastFourDigits && (
                    <LoaderIcon
                      type={"spin"}
                      style={{
                        width: "16px",
                        height: "auto",
                        margin: "0px 2px 2px",
                      }}
                    />
                  )}
                </Button>
              </>
            )}
            <p className="spaced lightText">
              {item.creditCard.cardType} - {item.creditCard.cardCategory}
            </p>
            <p className="spaced lightText">
              **** **** **** {item.creditCard.cardLastFourDigits}
            </p>
            <p className="spaced lightText">
              {item.creditCard.expirationMonth}/{item.creditCard.expirationYear}
            </p>
          </div>
        ))}
        <br />
        <div
          style={{
            backgroundColor: "#E6E6E6",
            padding: "1rem",
            borderRadius: "20px",
            position: "relative",
          }}
        >
          <h6 className="lightText">Add Payment Source:</h6>
          <hr style={{ backgroundColor: "black" }} />
          <CreditCard
            loading={props.loading}
            submit={(firstName, lastName, pfToken) => {
              props.submit({
                firstName: firstName,
                lastName: lastName,
                paymentSources: {
                  creditCardInfo: [
                    {
                      pfToken: pfToken,
                    },
                  ],
                },
              });
            }}
          />
          {/* <form
            className="ccForm"
            onSubmit={(e) => {
              e.preventDefault();

              const creditCardInfo = {
                expirationYear: convertShortYearToLong(
                  Number(exp.split("/")[1])
                ),
                securityCode: Number(cvv),
                expirationMonth: exp.split("/")[0],
                cardNumber: Number(cc.replace(/ /g, "")),
              };

              props.submit({
                firstName: firstName,
                lastName: lastName,
                creditCard: creditCardInfo,
              });
            }}
          >
            <div
              style={{
                display: "inline-flex",
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
                marginBottom: "5px",
              }}
            >
              <div style={{ display: "block" }}>
                <label htmlFor="first-name" className="lightText">
                  First Name *
                </label>
                <input
                  type="text"
                  id="first-name"
                  name="name"
                  spellCheck="false"
                  value={firstName}
                  maxLength="20"
                  autoComplete="off"
                  onChange={(e) => setFirstName(e.target.value)}
                  className="form-control-checkout form-control form-control-lg blackTextInput"
                  placeholder="John"
                />
              </div>
              <div style={{ display: "block" }}>
                <label htmlFor="last-name" className="lightText">
                  Last Name *
                </label>
                <input
                  type="text"
                  id="last-name"
                  name="name"
                  spellCheck="false"
                  value={lastName}
                  maxLength="20"
                  autoComplete="off"
                  onChange={(e) => setLastName(e.target.value)}
                  className="form-control-checkout form-control form-control-lg blackTextInput"
                  placeholder="Doe"
                />
              </div>
            </div>
            <label htmlFor="card-number" className="lightText">
              Card Number *
            </label>
            <input
              type="text"
              id="card-number"
              name="card-number"
              spellCheck="false"
              value={cc}
              maxLength="19"
              autoComplete="off"
              onChange={handleCardChange}
              className="form-control-checkout form-control form-control-lg blackTextInput"
              placeholder="1234 5678 9012 3456"
            />

            <div
              style={{
                display: "inline-flex",
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
                marginBottom: "5px",
              }}
            >
              <div style={{ display: "block" }}>
                <label htmlFor="exp-date" className="lightText">
                  Exp. (MM/YY) *
                </label>
                <input
                  type="text"
                  id="exp-date"
                  name="exp-date"
                  spellCheck="false"
                  value={exp}
                  maxLength="20"
                  autoComplete="off"
                  onChange={handleExpiryChange}
                  className="form-control-checkout form-control form-control-lg blackTextInput"
                  placeholder="01/29"
                />
              </div>
              <div style={{ display: "block" }}>
                <label htmlFor="cvv" className="lightText">
                  CVV *
                </label>
                <input
                  type="text"
                  pattern="\d+"
                  id="cvv"
                  name="cvv"
                  spellCheck="false"
                  value={cvv}
                  maxLength="3"
                  autoComplete="off"
                  onChange={handleCvvChange}
                  className="form-control-checkout form-control form-control-lg blackTextInput"
                  placeholder="123"
                />
              </div>
            </div>
            <Button
              type="submit"
              variant="contained"
              className={"searchBtn desktopSearchBtn"}
              fullWidth
            >
              Add
              {props.loading && (
                <LoaderIcon
                  type={"spin"}
                  style={{
                    width: "16px",
                    height: "auto",
                    margin: "0px 2px 2px",
                  }}
                />
              )}
            </Button>
          </form> */}
        </div>
      </div>
    </div>
  );
}
