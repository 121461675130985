import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";

import PricingTable from "./PricingTable";

function rand() {
  return Math.round(Math.random() * 20) - 10;
}

function getModalStyle() {
  const top = 50 + rand();
  const left = 50 + rand();

  return {
    top: `${55}%`,
    left: `${50}%`,
    transform: `translate(-${50}%, -${50}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    // height: 900,
    width: 900,
    maxWidth: 1200,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: "50px",
    zIndex: "999999999",
  },
}));

export default function SimpleModal(props) {
  const classes = useStyles();
  // getModalStyle is not a pure function, we roll the style only on the first render
  const [modalStyle] = React.useState(getModalStyle);

  const body = (
    <div style={modalStyle} className={classes.paper}>
      {props.user &&
      props.user.sub &&
      props.user.sub.subscriptionId === 33501618 ? (
        <div
          style={{
            height: "100px",
            display: "flex",
            alignItems: "center",
          }}
        >
          <h3
            className="align-center"
            style={{ margin: "auto", textTransform: "uppercase" }}
          >
            Please contact sales at:{" "}
            <a href="mailto:sales@hudsonrock.com" className="yellow">
              sales@hudsonrock.com
            </a>
          </h3>
        </div>
      ) : props.type != "failed" ? (
        <>
          {props.user &&
          props.user.sub &&
          props.user.sub.status === "pending" ? (
            <h3
              className="align-center"
              style={{ margin: "auto", textTransform: "uppercase" }}
            >
              Your account is pending approval.
            </h3>
          ) : (
            <PricingTable
              user={props.user}
              title={props.title}
              topUpLoader={props.topUpLoader}
              openTopUpModal={(item) => props.openTopUpModal(item)}
            />
          )}
        </>
      ) : !props.errorMessage ? (
        <div
          style={{
            height: "100px",
            display: "flex",
            alignItems: "center",
          }}
        >
          <h3
            className="align-center"
            style={{ margin: "auto", textTransform: "uppercase" }}
          >
            Your Payment Has Been Failed, Please Contact our Sales Team at:{" "}
            <a href="mailto:sales@hudsonrock.com" className="yellow">
              sales@hudsonrock.com
            </a>
          </h3>
        </div>
      ) : (
        <div
          style={{
            height: "100px",
            display: "flex",
            alignItems: "center",
          }}
        >
          <h3
            className="align-center"
            style={{ margin: "auto", textTransform: "uppercase" }}
          >
            {props.errorMessage}
          </h3>
        </div>
      )}
      <SimpleModal />
    </div>
  );

  return (
    <Modal
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      {body}
    </Modal>
  );
}
