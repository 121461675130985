import React, { useState, useEffect, useRef } from "react";
import Form from "react-bootstrap/Form";
import LoaderIcon from "react-loader-icon";
import Button from "@material-ui/core/Button";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { ImLinkedin, ImFacebook2, ImTwitter } from "react-icons/im";
import Avatar from "@material-ui/core/Avatar";
import Select from "react-select";
import { APIFullAddress, clearbitApi } from "../../Config";
import axios from "axios";
import { useDetectClickOutside } from "react-detect-click-outside";
import Icon from "@material-ui/core/Icon";

export default function AddProspect(props) {
  const mountedRef = useRef(true);
  const [avatar, setAvatar] = useState("A");

  const [industries, setIndustries] = useState([]);
  const [countries, setCountries] = useState([]);
  const [sizes, setSizes] = useState([]);
  const [displayDropdown, setDisplayDropdown] = useState(true);

  useEffect(() => {
    getFilters();
  }, []);

  //   const searchCompany = async () => {
  //     setLoading(true);
  //     const response = api.getCompanies(subject);
  //     response.then((result) => {
  //       setResults(result);
  //       console.log(result);
  //     });
  //     setLoading(false);
  //   };

  const getFilters = async () => {
    // setLoading(true);
    // setLoadOptions(true);
    await axios
      .get(`${APIFullAddress}/companies/get-filters`)
      .then((res) => {
        setIndustries(res.data.industries);
        setCountries(res.data.countries);
        setSizes(res.data.sizes);
        // industriesSelected.length > 0
        //   ? setIndustriesSelected(industriesSelected)
        //   : setIndustriesSelected(res.data.industries);
        // countriesSelected.length > 0
        //   ? setCountriesSelected(countriesSelected)
        //   : setCountriesSelected(res.data.countries);
        // sizesSelected.length > 0
        //   ? setSizesSelected(sizesSelected)
        //   : setSizesSelected(res.data.sizes);
      })
      .catch((err) => console.log(err));

    // setLoading(false);
    // setLoadOptions(false);
  };

  const companyInputs = [
    {
      name: "Industry",
      onChange: props.setIndustry,
      options: industries,
      param: "job_company_industry",
      // disabled:
      //   props.results && props.results.job_company_industry ? true : false,
      value:
        props.results && props.results.job_company_industry
          ? props.results.job_company_industry
          : props.industry,
    },
    {
      name: "Country",
      onChange: props.setCountry,
      options: countries,
      param: "job_company_location_country",
      // disabled:
      //   props.results && props.results.job_company_location_country
      //     ? true
      //     : false,
      value:
        props.results && props.results.job_company_location_country
          ? props.results.job_company_location_country
          : props.country,
    },
    {
      name: "Size",
      onChange: props.setSize,
      options: sizes,
      param: "job_company_size",
      // disabled: props.results && props.results.job_company_size ? true : false,
      value:
        props.results && props.results.job_company_size
          ? props.results.job_company_size
          : props.size,
    },
  ];

  const closeDropdown = (e) => {
    e.target.id != "formBasicEmail"
      ? setDisplayDropdown(false)
      : setDisplayDropdown(true);
  };

  const ref = useDetectClickOutside({ onTriggered: closeDropdown });

  return (
    <div className="addProspectDiv">
      <Form
        onSubmit={(e) => {
          e.preventDefault();
        }}
        autoComplete="off"
        className="addProspectForm"
      >
        <br />
        {props.loading && <LoaderIcon className="companyLoader" />}
        <div
          className="lightText merkerPopUp"
          style={{
            width: "30%",
            // margin: "auto",
            float: "left",
            margin: "0px 20px",
            // margin: "0px 0px 0px 100px",
          }}
        >
          <br />
          <div className="addProspectDiv">
            {props.logo && !avatar ? (
              <img
                // className="mt-5"
                style={{
                  border: "2px solid black",
                  borderRadius: "50px",
                  padding: "5px",
                  marginBottom: "10px",
                }}
                width="60px"
                src={`https://logo.clearbit.com/${props.logo}`}
                onError={() =>
                  setAvatar(props.subject.substring(0, 1).toUpperCase())
                }
                alt="logo"
              />
            ) : (
              avatar && (
                <Avatar
                  style={{
                    backgroundColor: "black",
                    height: "50px",
                    width: "50px",
                  }}
                >
                  {props.subject
                    ? props.subject.substring(0, 1).toUpperCase()
                    : "A"}
                  {/* {avatar} */}
                  {/* {props.subject.substring(0, 1).toUpperCase()} */}
                </Avatar>
              )
            )}

            <br />
            <input
              className="capitalize"
              maxLength="30"
              value={props.subject.toLowerCase()}
              onFocus={props.onChange}
              onChange={(e) => {
                setDisplayDropdown(true);
                props.onChange(e);
              }}
              required={true}
              name="company"
              placeholder="Company Name Or Domain"
            />
            {props.suggestions.length > 0 && (
              <div
                className="prospectSuggestions"
                ref={ref}
                style={{
                  width: "30%",
                  // border: "1px solid black",
                  backgroundColor: "white",
                  position: "absolute",
                  zIndex: "99",
                }}
              >
                {displayDropdown && (
                  <div>
                    {props.suggestions.slice(0, 3).map((company, index) => (
                      <Form.Text
                        value={company.name}
                        onClick={() => {
                          setAvatar(null);
                          props.pickDomain(company);
                        }}
                        key={index}
                        className="text-muted prospectSugRow"
                      >
                        <img
                          alt={company.name}
                          value={company.name}
                          className="prospectLogo"
                          src={company.logo}
                        />
                        {company.name}
                        {/* <p
                          value={company.name}
                          className="prospectCompanyName lightText"
                        >
                          {company.name}
                        </p> */}
                      </Form.Text>
                    ))}
                  </div>
                )}
              </div>
            )}
            {companyInputs &&
              companyInputs.map((item, index) => (
                <Select
                  key={index}
                  value={
                    props.results && props.results[item.param]
                      ? item.options.filter((s) => s.value === item.value)
                      : item.value
                  }
                  onChange={item.onChange}
                  placeholder={item.name}
                  options={item.options}
                  className="capitalize mt-2 blackTextSelect"
                  // disabled={item.disabled}
                />
              ))}
            {/* <input
              placeholder="Primary Domain"
              onChange={(e) => {
                props.setProspectDomain(e.target.value);
              }}
              value={
                props.results && props.results.job_company_website
                  ? props.results.job_company_website
                  : props.prospectDomain
              }
            /> */}
          </div>
        </div>

        <div
          style={{
            width: "65%",
            float: "right",
            margin: "auto",
            textAlign: "center",
            // position: "absolute",
            // top: "20px",
          }}
        >
          <div className="styledBox yellow-bg" style={{ margin: "30px" }}>
            <br />
            <h3 style={{ textAlign: "left" }}>Add Data</h3>
            <TableContainer
              component={Paper}
              style={{
                backgroundColor: "transparent",
                boxShadow: "none",
              }}
            >
              <Table aria-label="collapsible table">
                <TableHead>
                  <TableRow style={{ backgroundColor: "transparent" }}>
                    <TableCell align="left" className="lightText">
                      Domains <br />
                      <span className="smallText">Comma separated</span>
                    </TableCell>
                    <TableCell align="left" className="lightText">
                      APK's
                      <br />
                      <span className="smallText">Comma separated</span>
                    </TableCell>
                    {/* <TableCell align="left" className="lightText">
                      External Domains
                      <br />
                      <span className="smallText">Comma separated</span>
                    </TableCell> */}
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow style={{ backgroundColor: "transparent" }}>
                    <TableCell align="left" className="vertical-bottom">
                      {props.domainsToAdd && (
                        <ul className="domainList lightText">
                          {props.domainsToAdd.map((domain, index) => (
                            <li key={index}>
                              <button
                                className="remove lightText"
                                onClick={(e) => props.removeDomain(e, "domain")}
                                value={domain}
                              >
                                X
                              </button>
                              {domain}
                            </li>
                          ))}
                        </ul>
                      )}
                      <Form.Control
                        value={props.domainInput}
                        maxLength="500"
                        onChange={(e) => props.onChangeDomain(e, "domain")}
                        // required={true}
                        name="domainsToAdd"
                        placeholder="Domains"
                        className="blackTextInput"
                      />
                      <br />
                      {props.domainInput.includes(".") &&
                      !props.domainInput.includes(":") &&
                      !props.domainInput.includes("/") &&
                      /^[a-zA-Z]+$/.test(
                        props.domainInput[props.domainInput.length - 1]
                      ) &&
                      /^[a-zA-Z]+$/.test(props.domainInput[0]) ? (
                        <Button
                          fullWidth
                          variant="contained"
                          color="primary"
                          className="searchBtn"
                          onClick={props.addDomains}
                        >
                          Add
                        </Button>
                      ) : (
                        <Button
                          fullWidth
                          variant="contained"
                          color="primary"
                          className="searchBtn grayBtn"
                        >
                          Add
                        </Button>
                      )}
                    </TableCell>
                    {props.user.user.tier != "basic" ? (
                      <TableCell align="left" className="vertical-bottom">
                        {props.appsToAdd && (
                          <ul className="domainList lightText">
                            {props.appsToAdd.map((app, index) => (
                              <li key={index}>
                                <button
                                  className="remove lightText"
                                  onClick={(e) => props.removeDomain(e, "app")}
                                  value={app}
                                >
                                  X
                                </button>
                                {app}
                              </li>
                            ))}
                          </ul>
                        )}
                        <Form.Control
                          value={props.appInput}
                          maxLength="500"
                          className="blackTextInput"
                          // required={true}
                          name="appsToAdd"
                          onChange={(e) => props.onChangeDomain(e, "app")}
                          placeholder="APK's"
                        />
                        <br />
                        {props.appInput.includes(".") &&
                        !props.appInput.includes(":") &&
                        !props.appInput.includes("/") &&
                        /^[a-zA-Z]+$/.test(
                          props.appInput[props.appInput.length - 1]
                        ) &&
                        /^[a-zA-Z]+$/.test(props.appInput[0]) ? (
                          <Button
                            fullWidth
                            variant="contained"
                            color="primary"
                            className="searchBtn"
                            onClick={props.addApps}
                          >
                            Add
                          </Button>
                        ) : (
                          <Button
                            fullWidth
                            variant="contained"
                            color="primary"
                            className="searchBtn grayBtn"
                            onClick={props.addApps}
                          >
                            Add
                          </Button>
                        )}
                      </TableCell>
                    ) : (
                      <TableCell align="left" className="vertical-bottom">
                        This feature requires a tier upgrade
                        <br />
                        <Button
                          fullWidth
                          variant="contained"
                          color="primary"
                          className="searchBtn"
                          onClick={props.upgradeTier}
                        >
                          Upgrade Now
                        </Button>
                      </TableCell>
                    )}
                    {/* {props.user.user.tier != "basic" ? (
                      <TableCell align="left" className="vertical-bottom">
                        {props.partiesToAdd && (
                          <ul className="domainList lightText">
                            {props.partiesToAdd.map((party, index) => (
                              <li key={index}>
                                <button
                                  className="remove lightText"
                                  onClick={(e) =>
                                    props.removeDomain(e, "party")
                                  }
                                  value={party}
                                >
                                  X
                                </button>
                                {party}
                              </li>
                            ))}
                          </ul>
                        )}
                        <Form.Control
                          value={props.partyInput}
                          maxLength="500"
                          className="blackTextInput"
                          onChange={(e) => props.onChangeDomain(e, "party")}
                          // required={true}
                          name="partiesToAdd"
                          placeholder="3rd Party Domains"
                        />
                        <br />
                        {props.partyInput.includes(".") &&
                        !props.partyInput.includes(":") &&
                        !props.partyInput.includes("/") &&
                        /^[a-zA-Z]+$/.test(
                          props.partyInput[props.partyInput.length - 1]
                        ) &&
                        /^[a-zA-Z]+$/.test(props.partyInput[0]) ? (
                          <Button
                            fullWidth
                            variant="contained"
                            color="primary"
                            className="searchBtn"
                            onClick={props.addParties}
                          >
                            Add
                          </Button>
                        ) : (
                          <Button
                            fullWidth
                            variant="contained"
                            color="primary"
                            className="searchBtn grayBtn"
                            onClick={props.addParties}
                          >
                            Add
                          </Button>
                        )}
                      </TableCell>
                    ) : (
                      <TableCell align="left" className="vertical-bottom">
                        This feature requires a tier upgrade
                        <br />
                        <Button
                          fullWidth
                          variant="contained"
                          color="primary"
                          className="searchBtn"
                          onClick={props.upgradeTier}
                        >
                          Upgrade Now
                        </Button>
                      </TableCell>
                    )} */}
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </div>
          {props.subject ? (
            <Button
              className="btn btn-primary profile-button searchBtn"
              color="inherit"
              onClick={() =>
                props.saveProspect(
                  {
                    job_company_name: props.subject,
                    job_company_website:
                      props.results && props.results.job_company_website
                        ? props.results.job_company_website
                        : null,
                    job_company_industry:
                      props.industry && props.industry.value
                        ? props.industry.value
                        : props.industry,

                    job_company_location_country:
                      props.country && props.country.value
                        ? props.country.value
                        : props.country,

                    job_company_size:
                      props.size && props.size.value
                        ? props.size.value
                        : props.size,
                    job_company_linkedin_url:
                      props.results && props.results.job_company_linkedin_url
                        ? props.results.job_company_linkedin_url
                        : null,
                    job_company_facebook_url:
                      props.results && props.results.job_company_facebook_url
                        ? props.results.job_company_facebook_url
                        : null,
                    job_company_twitter_url:
                      props.results && props.results.job_company_twitter_url
                        ? props.results.job_company_twitter_url
                        : null,
                  },
                  props.domainsToAdd,
                  props.appsToAdd,
                  props.partiesToAdd
                )
              }
            >
              add prospect
              <Icon>send</Icon>
            </Button>
          ) : (
            <Button
              className="btn btn-primary profile-button searchBtn grayBtn"
              color="inherit"
            >
              add prospect
              <Icon>send</Icon>
            </Button>
          )}
        </div>
      </Form>
    </div>
  );
}
