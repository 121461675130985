import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { MdOutlineMail } from "react-icons/md";
import { FaCreditCard, FaRegCalendarAlt } from "react-icons/fa";
import { RiSecurePaymentLine } from "react-icons/ri";
import LoaderIcon from "react-loader-icon";

export default function CreatePlan(props) {
  const [error, setError] = useState("");

  return (
    <div style={{ padding: "20px" }}>
      <form className="newPlanForm" onSubmit={props.newPlan}>
        <select
          placeholder="Charge frequency"
          style={{ width: "100%" }}
          name="freq"
        >
          <option>ANNUALLY</option>
          <option>MONTHLY</option>
          <option>ONDEMAND</option>
        </select>
        <br />
        <input
          type="text"
          placeholder="Name"
          style={{ width: "100%" }}
          name="name"
        />
        <br />
        <input
          type="number"
          placeholder="Trial period (days)"
          style={{ width: "100%" }}
          name="trial"
        />
        <br />
        <input
          name="credits"
          type="number"
          placeholder="Initial credits amount"
          style={{ width: "100%" }}
        />
        <br />
        <input
          name="refill"
          type="number"
          placeholder="Recurring credits amount (refill)"
          style={{ width: "100%" }}
        />
        <br />
        <input
          name="initial"
          type="number"
          placeholder="Initial charge amount"
          style={{ width: "100%" }}
        />
        <br />
        <input
          name="recurring"
          type="number"
          placeholder="Recurring charge amount"
          style={{ width: "100%" }}
        />
        <br />
        <br />
        <button type="submit" className="myBtn">
          {props.loadNewPlan ? (
            <LoaderIcon
              type="spin"
              className="tableLoader"
              style={{
                width: "18px",
                height: "18px",
                position: "relative",
                top: "-5px",
              }}
            />
          ) : (
            "Create"
          )}
        </button>
      </form>
    </div>
  );
}
