import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

// function rand() {
//   return Math.round(Math.random() * 20) - 10;
// }

function getModalStyle() {
  return {
    top: `${50}%`,
    left: `${50}%`,
    transform: `translate(-${50}%, -${50}%)`,
  };
}

export default function SimpleModal(props) {
  const useStyles = makeStyles((theme) => ({
    paper: {
      position: "absolute",
      width: props.halfWidth ? 900 : 1200,
      backgroundColor: props.bg ? props.bg : "#E1E8EB",
      border: "2px solid #000",
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      height: props.halfHeight ? 500 : 600,
      overflowY: "scroll",
      borderRadius: props.borderRadius,
      display: props.flex && "flex",
      alignItems: props.flex && "center",
    },
  }));
  const classes = useStyles();
  const [modalStyle] = React.useState(getModalStyle);

  const body = (
    <div style={modalStyle} className={classes.paper}>
      <IconButton
        edge="start"
        color="inherit"
        onClick={props.handleClose}
        aria-label="close"
        style={{ position: "absolute", top: "10px", right: "10px" }}
      >
        <CloseIcon />
      </IconButton>
      {props.html}
      <SimpleModal />
    </div>
  );

  return (
    <div>
      <Modal
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {body}
      </Modal>
    </div>
  );
}
