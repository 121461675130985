import React from "react";
import CompanyCard from "./CompanyCard";
import LoadMore from "./LoadMore";

function CardsContainer(props) {
  return (
    <div>
      {props.companies && props.companies.length > 0 ? (
        <div id="cardsContainer" className="row">
          {props.companies.map((item, index) => (
            <CompanyCard item={item} key={index} user={props.user} />
          ))}
          <LoadMore
            resultsCount={props.resultsCount}
            setPage={() => props.setPage(props.page + 1)}
            finalRes={props.finalRes}
            loader={props.loader}
            minLength={18}
            data={props.companies}
          />
        </div>
      ) : (
        <div
          style={{
            height: "500px",
            display: "inline-flex",
            alignItems: "center",
          }}
        >
          <h2>No Results</h2>
        </div>
      )}
    </div>
  );
}
export default CardsContainer;
