import cavaLogo from "../../images/thumbnail.jpeg";
import { Link } from "react-router-dom";
import SocialIcons from "./SocialIcons";
import Button from "@material-ui/core/Button";
import "../../styles/Grid.css";
import Icon from "@material-ui/core/Icon";

export default function CompanyCard(props) {
  const defaultSrc = (ev) => {
    ev.target.src = cavaLogo;
  };

  const basicPlans = [2741315, 2741325, 3332681, 3332639];

  const counters = [
    {
      name: "Employees",
      field: props.item.counts ? props.item.counts.employees : "UNKOWN",
    },
    {
      name: "Users",
      field: props.item.counts ? props.item.counts.users : "UNKOWN",
    },
  ];

  const hasData = (item, type, credsType) => {
    if (type === "system") {
      if (item.company_data[0] && item.company_data[0].counts) {
        return item.company_data[0].counts[credsType] > 0;
      } else {
        return false;
      }
    } else {
      if (item && item.counts) {
        return item.counts[credsType] > 0;
      } else {
        return false;
      }
    }
  };

  return (
    <div className="col-md-6 col-sm-12">
      <div className="w-100 hoverable">
        <div className="grid-container">
          <div className="item1 capitalize" style={{ display: "flex" }}>
            <div
              style={{ display: "flex", width: "100%", alignItems: "center" }}
            >
              <span
                style={{ width: "100%", fontSize: "13px" }}
                className="bold"
              >
                {props.type === "system"
                  ? props.item.name
                  : props.item.job_company_name
                  ? props.item.job_company_name
                  : props.item.job_company_website}
              </span>
              <br />
              <div style={{ display: "block" }}>
                {hasData(props.item, props.type, "employees") && (
                  <p
                    style={{
                      fontSize: "12px",
                      padding: "5px 10px",
                      borderRadius: "20px",
                      fontWeight: "900",
                      margin: "2px",
                      width: "fit-content",
                      minWidth: "280px",
                      textAlign: "center",
                    }}
                    className="cyberFont lightText yellow-bg"
                  >
                    Last Compromised Employee:{" "}
                    {props.type === "system"
                      ? props.item.company_data[0] &&
                        props.item.company_data[0].last_seen &&
                        props.item.company_data[0].last_seen
                          .last_employee_compromised &&
                        props.item.company_data[0].last_employee_date
                        ? props.item.company_data[0].last_seen.last_employee_compromised.split(
                            "T"
                          )[0]
                        : "UNKNOWN"
                      : props.item.last_seen &&
                        props.item.last_seen.last_employee_compromised &&
                        props.item.last_seen &&
                        props.item.last_employee_date
                      ? props.item.last_seen.last_employee_compromised.split(
                          "T"
                        )[0]
                      : "UNKNOWN"}
                  </p>
                )}
                {hasData(props.item, props.type, "users") && (
                  <p
                    style={{
                      fontSize: "12px",
                      padding: "5px 10px",
                      borderRadius: "20px",
                      fontWeight: "900",
                      margin: "2px",
                      width: "fit-content",
                      minWidth: "280px",
                      textAlign: "center",
                    }}
                    className="cyberFont lightText yellow-bg"
                  >
                    Last Compromised User:{" "}
                    {props.type === "system"
                      ? props.item.company_data[0] &&
                        props.item.company_data[0].last_seen &&
                        props.item.company_data[0].last_seen
                          .last_user_compromised &&
                        props.item.company_data[0].last_user_date
                        ? props.item.company_data[0].last_seen.last_user_compromised.split(
                            "T"
                          )[0]
                        : "UNKNOWN"
                      : props.item.last_seen &&
                        props.item.last_seen.last_user_compromised &&
                        props.item.last_seen &&
                        props.item.last_user_date
                      ? props.item.last_seen.last_user_compromised.split("T")[0]
                      : "UNKNOWN"}
                  </p>
                )}
              </div>
            </div>
            {/* <div
              style={{ display: "block", width: "50%", textAlign: "center" }}
            >
              {" "}
              <span
                style={{
                  fontSize: "12px",
                  borderRadius: "20px",
                  fontWeight: "900",
                }}
                className="cyberFont"
              >
                {props.type === "system"
                  ? props.item.company_data[0] &&
                    props.item.company_data[0].counts &&
                    props.item.company_data[0].counts.employees
                  : props.item.counts && props.item.counts.employees}{" "}
                Compromised Employees
              </span>
              <br />
              <span
                style={{
                  fontSize: "12px",
                  borderRadius: "20px",
                  fontWeight: "900",
                }}
                className="cyberFont"
              >
                {props.type === "system"
                  ? props.item.company_data[0] &&
                    props.item.company_data[0].counts &&
                    props.item.company_data[0].counts.users
                  : props.item.counts && props.item.counts.users}{" "}
                Compromised Users
              </span>
            </div> */}
          </div>
          <div className="item2" style={{ display: "flex" }}>
            <img
              // className="companyLogo"
              alt="logo"
              src={`https://logo.clearbit.com/${
                props.type === "system"
                  ? props.item.domain
                  : props.item.job_company_website
              }?size=200`}
              onError={defaultSrc}
              style={{
                width: "60px",
                height: "60px",
                borderRadius: "5px",
                // border: "1px solid",
              }}
            />
          </div>
          <div className="item3 bold">
            {props.type === "system" && (
              <>
                Occurrences: <br />
              </>
            )}
            Industry: <br /> Size: <br /> Location:
            {/* <br />
            Employees: <br />
            Users: <br />
            APK's: */}
          </div>
          <div className="item4 capitalize bold">
            {props.type === "system" && (
              <>
                {props.item.count} <br />
              </>
            )}
            {props.type === "system"
              ? props.item.company_data[0] &&
                props.item.company_data[0].job_company_industry
              : props.item.job_company_industry
              ? props.item.job_company_industry
              : "UNKNOWN"}
            <br />
            {props.type === "system"
              ? props.item.company_data[0] &&
                props.item.company_data[0].job_company_size
              : props.item.job_company_size
              ? props.item.job_company_size
              : "UNKNOWN"}
            <br />
            {props.type === "system" ? (
              <>
                {props.item.company_data[0].job_company_location_region &&
                  props.item.company_data[0].job_company_location_region}
                {props.item.company_data[0].job_company_location_region &&
                  props.item.company_data[0].job_company_location_country &&
                  ", "}

                {props.item.company_data[0].job_company_location_country &&
                  props.item.company_data[0].job_company_location_country}
              </>
            ) : !props.item.job_company_location_region &&
              !props.item.job_company_location_country ? (
              <>UNKNOWN</>
            ) : (
              <>
                {props.item.job_company_location_region &&
                  props.item.job_company_location_region}
                {props.item.job_company_location_region &&
                  props.item.job_company_location_country &&
                  ", "}
                {props.item.job_company_location_country &&
                  props.item.job_company_location_country}
              </>
            )}
          </div>
          <div className="item5">
            {props.user && (
              <SocialIcons
                data={
                  props.type === "system"
                    ? props.item.company_data[0]
                    : props.item
                }
                user={props.user}
              />
            )}
          </div>
          {/* <div className="item7">
            {counters.map((item, index) => (
              <div style={{ display: "block" }}>
                <p
                  key={index}
                  style={{
                    color: "black",
                    fontSize: "14px",
                    margin: "0px",
                    textAlign: "left",
                  }}
                >
                  Compromised {item.name}: <span>{item.field}</span>
                </p>
              </div>
            ))}
          </div> */}
          <div className="item6">
            <div
              // className="yellow-bg"
              style={{
                display: "block",
                width: "60%",
                textAlign: "center",
                padding: "5px",
                borderRadius: "20px",
              }}
            >
              {" "}
              {hasData(props.item, props.type, "employees") && (
                <p
                  style={{
                    fontSize: "12px",
                    fontWeight: "900",
                    margin: "0px",
                  }}
                  className="cyberFont lightText"
                >
                  {/* <FaUserTie /> */}
                  {props.type === "system"
                    ? props.item.company_data[0] &&
                      props.item.company_data[0].counts &&
                      props.item.company_data[0].counts.employees
                    : props.item.counts && props.item.counts.employees}{" "}
                  Compromised Employees
                </p>
              )}
              {hasData(props.item, props.type, "users") && (
                <p
                  style={{
                    fontSize: "12px",
                    fontWeight: "900",
                    margin: "0px",
                  }}
                  className="cyberFont lightText"
                >
                  {/* <FaUsers /> */}
                  {props.type === "system"
                    ? props.item.company_data[0] &&
                      props.item.company_data[0].counts &&
                      props.item.company_data[0].counts.users
                    : props.item.counts && props.item.counts.users}{" "}
                  Compromised Users
                </p>
              )}
            </div>
            <Link
              to={`/search-by-domain/${
                props.type === "system"
                  ? props.item.domain
                  : props.item.job_company_website
              }`}
              className="darkText"
              // style={{ width: "50%" }}
            >
              <Button
                className="searchBtn cyberFont bold yellow-bg"
                style={{
                  // backgroundColor: "#1A1C20",
                  fontSize: "12px",
                  textTransform: "none",
                  borderRadius: "50px",
                  marginLeft: "5px",
                }}
                endIcon={<Icon>send</Icon>}
              >
                Display Data
              </Button>
            </Link>{" "}
          </div>
        </div>
      </div>
    </div>
  );
}
