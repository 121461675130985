import axios from "axios";
import {
  APIFullAddress,
  APIFullEmailAddress,
  clearbitApi,
  authAddress,
  bsEndpoint,
  clearAddress,
} from "./Config";

const CreateApiClient = () => {
  return {
    fetchData: async (target, query, config) => {
      return await axios
        .post(`${APIFullAddress}/${target}`, query, config)
        .then((res) => {
          return res.data;
        })
        .catch((error) => {
          return error;
        });
    },
    editUser: async (target, query, config) => {
      return await axios
        .post(`${authAddress}/${target}`, query, config)
        .then((res) => {
          return res.data;
        })
        .catch((error) => {
          return error;
        });
    },
    getUserData: async (target, query, config) => {
      return await axios
        .post(`${authAddress}/${target}`, query, config)
        .then((res) => {
          return res.data;
        })
        .catch((error) => {
          return error;
        });
    },
    getCompanies: async (domain) => {
      return await axios
        .get(`${APIFullAddress}/companies/domain?domain=${domain}`)
        .then((res) => {
          return res.data;
        })
        .catch((error) => {
          return error;
        });
    },
    fetchSuggestions: async (domain) => {
      return await axios
        .get(`${clearbitApi}/${domain}`)
        .then((res) => {
          return res.data;
        })
        .catch((error) => {
          return error;
        });
    },
    companiesAutocomplete: async (name, body) => {
      return await axios
        .get(`${APIFullAddress}/people/fetch-suggestions?query=${name}`, body)
        .then((res) => {
          return res.data;
        })
        .catch((error) => {
          return error;
        });
    },
    getFilters: async (target) => {
      return await axios
        .post(`${APIFullAddress}/${target}`)
        .then((res) => {
          return res.data;
        })
        .catch((error) => {
          return error;
        });
    },
    getCounts: async (target) => {
      return await axios
        .get(`${APIFullAddress}/${target}`)
        .then((res) => {
          return res.data;
        })
        .catch((error) => {
          return error;
        });
    },
    sendEmail: async (target, data) => {
      return await axios
        .post(`${APIFullEmailAddress}/${target}`, data)
        .then((res) => {
          return res.data;
        })
        .catch((error) => {
          return error;
        });
    },
    changePassword: async (target, data, config) => {
      return await axios
        .post(`${APIFullAddress}/${target}`, data, config)
        .then((res) => {
          return res.data;
        })
        .catch((error) => {
          return error;
        });
    },
    registerUser: async (target, data, config) => {
      return await axios
        .post(`${authAddress}/${target}`, data, config)
        .then((res) => {
          return res;
        })
        .catch((error) => {
          return error;
        });
    },
    getPfToken: async (env) => {
      return await axios
        .post(`${clearAddress}/${bsEndpoint}/pftoken?env=${env}`)
        .then((res) => {
          return res.data;
        })
        .catch((error) => {
          return error;
        });
    },
    makePayment: async (env, plan, body, headers, seats, overRideAmount) => {
      return await axios
        .post(
          `${clearAddress}/${bsEndpoint}/payment?plan=${plan}&env=${env}&quantity=${seats}&overRideAmount=${overRideAmount}`,
          body,
          headers
        )
        .then((res) => {
          return res;
        })
        .catch((err) => {
          console.log(err.response.data);
          return err.response;
        });
    },
    fetchContacts: async (headers, domain) => {
      return await axios
        .post(
          `${APIFullAddress}/people/search-by-domain?domain=${domain}`,
          {},
          headers
        )
        .then((res) => {
          return res.data;
        })
        .catch((error) => {
          return error;
        });
    },
    unlockContact: async (headers, id) => {
      return await axios
        .post(`${APIFullAddress}/people/unlock-contact?id=${id}`, {}, headers)
        .then((res) => {
          return res.data;
        })
        .catch((error) => {
          return error;
        });
    },
    getUserContacts: async (headers, domain) => {
      return await axios
        .get(
          `${APIFullAddress}/people/get-user-contacts?domain=${domain}`,
          headers
        )
        .then((res) => {
          return res.data;
        })
        .catch((error) => {
          return error;
        });
    },
    getContactsOptions: async (headers) => {
      return await axios
        .get(`${APIFullAddress}/people/get-contacts-companies`, headers)
        .then((res) => {
          return res.data;
        })
        .catch((error) => {
          return error;
        });
    },
  };
};

export default CreateApiClient;
