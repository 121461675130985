import React, { useEffect, useState } from "react";
import thumbnail from "../../images/thumbnail.jpeg";
import Button from "@material-ui/core/Button";
import Icon from "@material-ui/core/Icon";
import ExportToCsv from "../Partials/ExportToCsv";
import { BsUnlockFill } from "react-icons/bs";
import { FaUserTie, FaUsers, FaFileCsv } from "react-icons/fa";
import BlurryText from "../Partials/Blur";
import { CSVLink, CSVDownload } from "react-csv";
import axios from "axios";
import { APIFullAddress } from "../../Config";
import LoaderIcon from "react-loader-icon";
import SimpleModal from "../Partials/BasicModal";

function AssetsDiscovery(props) {
  //   const [assets, setAssets] = useState(props.assets);
  const [expand, setExapnd] = useState("");
  const [unlockLoader, setUnlockLoader] = useState(null);
  const [externalView, setExternalView] = useState(null);
  const [thirdPartyUrls, setThirdPartyUrls] = useState([]);
  const [externalLoading, setExternalLoading] = useState(false);

  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const defaultSrc = (ev) => {
    ev.target.src = thumbnail;
  };

  const getThirdPartyUrls = async (domain) => {
    setExternalLoading(true);
    const _urls = await axios
      .post(
        `${APIFullAddress}/assets-discovery/third-party?domain=${domain}&page=0`,
        {},
        { headers: { "auth-token": props.user.token } }
      )
      .then((res) => res.data)
      .catch((err) => err);

    setThirdPartyUrls(_urls);
    setExternalLoading(false);
  };

  useEffect(() => {
    if (externalView) {
      setThirdPartyUrls([]);
      getThirdPartyUrls(externalView);
    }
  }, [externalView]);

  const unlockUrls = async (domain, type) => {
    // if (!props.user.sub && props.user.user.role === "mssp") return handleOpen();
    setUnlockLoader(`${domain}_${type}`);
    const body = {
      domain: domain,
      type: type,
    };
    const config = {
      headers: {
        "auth-token": props.user.token,
      },
    };

    await axios
      .post(`${APIFullAddress}/assets-discovery/unlock`, body, config)
      .then((res) => {
        props.resetUser(res.data.user);
        let reveal;
        if (type === "employees") {
          reveal = props.data.employees_urls.find((s) => s.domain == domain);
        } else {
          reveal = props.data.clients_urls.find((s) => s.domain == domain);
        }

        reveal.urls = res.data.unlockedUrls;
        reveal.blur = false;
      })
      .catch((err) => {
        err.response && err.response.data === "credits" && setOpen(true);
      });
    setUnlockLoader(null);
  };

  const censorUrl = (domain, url) => {
    let splittedUrl = url.split(domain);
    let http = splittedUrl[0].split("//")[0];
    return (
      <p>
        <span>{http}</span>//
        <span className="censored">{splittedUrl[0].split("//")[1]}</span>
        <span>{domain}</span>/<span className="censored">{splittedUrl[1]}</span>
      </p>
    );
  };

  const censorDomainInUrl = (url) => {
    let splitted = url.split("•");
    console.log(splitted.slice(1, splitted.length - 1).join("•"));
    return (
      <p>
        <span>{splitted[0]}</span>
        <span className="censored">
          {splitted.slice(1, splitted.length - 1).join("•")}
        </span>
        <span>{splitted[splitted.length - 1]}</span>
      </p>
    );
  };

  return (
    <div
      className="flex_box"
      style={{
        minHeight: "100vh",
        width: "100%",
        justifyContent: "space-evenly",
      }}
    >
      <SimpleModal
        title="Top-Up Your Credits Balance for Additional Unlocks:"
        open={open}
        handleOpen={handleOpen}
        handleClose={handleClose}
        topUpLoader={props.topUpLoader}
        openTopUpModal={(item) => props.openTopUpModal(item)}
        user={props.user}
      />
      <div className="leftCol" style={{ textAlign: "left" }}>
        <div
          className="styledBox yellow-bg lightText"
          style={{ width: "100%" }}
        >
          <h1>
            <FaUserTie
              size={45}
              style={{
                backgroundColor: "black",
                color: "#FBCC4B",
                padding: "5px",
                borderRadius: "50px",
              }}
            />{" "}
            Employee Assets
          </h1>
          <h2>
            {props.system ? "Total Domains:" : "Total URLs:"}
            {props.numbers && props.numbers.totalEmployees}
          </h2>
        </div>
        {props.data &&
          props.data.employees_urls.length > 0 &&
          props.data.employees_urls.map((item, index) => (
            <div
              key={index}
              style={{
                marginTop: "20px",
                padding: "20px",
                borderRadius: "20px",
                backgroundColor: "#222831",
              }}
            >
              <div
                className="cursor"
                style={{
                  display: "inline-flex",
                  justifyContent: "space-around",
                  alignItems: "center",
                  padding: "20px",
                  borderRadius: "20px",
                  backgroundColor: "#222831",
                  width: "100%",
                }}
                onClick={() => {
                  if (expand === `${item.id}_employees`) {
                    setExapnd(null);
                  } else {
                    setExapnd(`${item.id}_employees`);
                    setThirdPartyUrls([]);
                    setExternalView(null);
                  }
                }}
              >
                <img
                  id="logoImg"
                  preserveDrawingBuffer={true}
                  className="roundSmallImg"
                  style={{
                    backgroundColor: "#1A1C20",
                    border: "0px",
                  }}
                  src={
                    props.system && item.blur
                      ? thumbnail
                      : `https://logo.clearbit.com/${item.domain}`
                  }
                  onError={defaultSrc}
                  alt="logo"
                />
                <div>
                  <h5>
                    {props.system && item.blur ? (
                      <span>
                        <BlurryText value={item.domain.split(".")[0]} />.
                        {item.domain.split(".")[1]}
                        {item.domain.split(".")[2] && item.domain.split(".")[2]}
                      </span>
                    ) : (
                      item.domain
                    )}
                  </h5>
                  <h5>{item.urls.length} URLs</h5>
                </div>{" "}
                <Button
                  type="button"
                  variant="contained"
                  className={"searchBtn desktopSearchBtn"}
                  endIcon={<Icon>expand</Icon>}
                  onClick={() => {
                    if (expand === `${item.id}_employees`) {
                      setExapnd(null);
                    } else {
                      setExapnd(`${item.id}_employees`);
                    }
                  }}
                >
                  VIEW ALL
                </Button>
                {item.blur ? (
                  <Button
                    type="button"
                    variant="contained"
                    className={"searchBtn desktopSearchBtn"}
                    endIcon={
                      unlockLoader === `${item.domain}_employees` ||
                      unlockLoader === `${item.id}_employees` ? (
                        <LoaderIcon
                          type="spin"
                          style={{
                            width: "16px",
                            height: "16px",
                            marginLeft: "3px",
                            position: "relative",
                            top: "-10px",
                          }}
                        />
                      ) : (
                        <BsUnlockFill
                          style={{ marginTop: "-5px", marginLeft: "5px" }}
                        />
                      )
                    }
                    onClick={(e) => {
                      e.stopPropagation();
                      unlockUrls(item.domain, "employees");
                    }}
                  >
                    UNLOCK ALL
                  </Button>
                ) : (
                  <CSVLink
                    id="csvDownload"
                    className="csvDownload"
                    data={item.urls}
                    filename={`${item.domain}_employees.csv`}
                    target="_blank"
                    onClick={(e) => e.stopPropagation()}
                  >
                    EXPORT ALL
                  </CSVLink>
                )}
              </div>
              {expand === `${item.id}_employees` && (
                <div className="scrollBox">
                  {(item.hasEmailDomain || item.onlyThirdParties) && (
                    <>
                      {!externalLoading && !item.onlyThirdParties && (
                        <>
                          <button
                            className="cardBtn uppercase"
                            onClick={(e) => {
                              if (item.hasEmailDomain) {
                                externalView
                                  ? setExternalView(null)
                                  : setExternalView(item.domain);
                                // handleCollapse(row._id, row._id, !externalView);
                              }
                            }}
                          >
                            {externalView && thirdPartyUrls.length > 0
                              ? "Show compromised corporate URLs"
                              : "Show third party URLs"}
                          </button>
                          <br />
                          <br />
                        </>
                      )}
                      {((thirdPartyUrls.length > 0 && externalView) ||
                        item.onlyThirdParties) && (
                        <>
                          {item.onlyThirdParties && (
                            <span style={{ color: "#FBCD4E" }}>
                              *Only third party URLs were identified
                            </span>
                          )}
                          <p>
                            Total Third Party URLs:{" "}
                            {item.onlyThirdParties
                              ? item.urls.length
                              : thirdPartyUrls.length}
                          </p>
                        </>
                      )}
                    </>
                  )}
                  {externalLoading && thirdPartyUrls.length <= 0 && (
                    <LoaderIcon
                      type="spin"
                      style={{
                        fill: "white",
                        width: "100px",
                        height: "auto",
                        position: "relative",
                        top: "5rem",
                      }}
                    />
                  )}
                  {externalView ? (
                    <>
                      {thirdPartyUrls.map((asset, index) => (
                        <div
                          key={index}
                          style={{
                            textAlign: "left",
                            backgroundColor: "#1A1C20",
                            borderRadius: "20px",
                            padding: "10px",
                            fontSize: "16px",
                            display: "inline-flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            width: "100%",
                            margin: "5px 0px",
                          }}
                        >
                          <p
                            style={{
                              margin: "0px",
                              width: "70%",
                              wordBreak: "break-word",
                            }}
                          >
                            {item.blur
                              ? censorDomainInUrl(asset.url)
                              : asset.url}
                          </p>
                          <p style={{ margin: "0px" }}>
                            Occurrence: {asset.occurrence}
                          </p>
                        </div>
                      ))}
                    </>
                  ) : (
                    <>
                      {item.urls.map((asset, index) => (
                        <div
                          key={index}
                          style={{
                            textAlign: "left",
                            backgroundColor: "#1A1C20",
                            borderRadius: "20px",
                            padding: "10px",
                            fontSize: "16px",
                            display: "inline-flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            width: "100%",
                            margin: "5px 0px",
                          }}
                        >
                          {item.blur && !props.system ? (
                            <>
                              {asset.url.split(`.${item.domain}`)[0] ? (
                                asset.url.split(`.${item.domain}`)[1] ? (
                                  censorUrl(item.domain, asset.url)
                                ) : asset.url
                                    .split(`.${item.domain}`)[0]
                                    .split("//")[1] ? (
                                  <p>
                                    {
                                      asset.url
                                        .split(`.${item.domain}`)[0]
                                        .split("//")[0]
                                    }
                                    //
                                    <BlurryText
                                      value={`${asset.url
                                        .split(`.${item.domain}`)[0]
                                        .split("//")[1]
                                        .replace(/[a-zA-Z]/g, "•")}`}
                                    />
                                    .{item.domain}
                                  </p>
                                ) : (
                                  <p style={{ margin: "0px" }}>{asset.url}</p>
                                )
                              ) : (
                                <p style={{ margin: "0px" }}>{asset.url}</p>
                              )}
                            </>
                          ) : props.system && item.blur ? (
                            <p
                              style={{
                                margin: "0px",
                                width: "70%",
                                wordBreak: "break-word",
                              }}
                            >
                              {asset.url.split(item.domain)[0]}
                              <BlurryText value={item.domain.split(".")[0]} />.
                              {item.domain.split(".")[1]}
                              {asset.url.split(item.domain)[1]}
                            </p>
                          ) : (
                            <p
                              style={{
                                margin: "0px",
                                width: "70%",
                                wordBreak: "break-word",
                              }}
                            >
                              {asset.url}
                            </p>
                          )}
                          <p style={{ margin: "0px" }}>
                            Occurrence: {asset.occurrence}
                          </p>{" "}
                        </div>
                      ))}
                    </>
                  )}
                </div>
              )}
            </div>
          ))}
      </div>
      <div className="rightCol" style={{ textAlign: "left" }}>
        <div
          className="styledBox yellow-bg lightText"
          style={{ width: "100%" }}
        >
          <h1>
            {" "}
            <FaUsers
              size={45}
              style={{
                backgroundColor: "black",
                color: "#FBCC4B",
                padding: "5px",
                borderRadius: "50px",
              }}
            />{" "}
            User Assets
          </h1>
          <h2>
            {props.system ? "Total Domains:" : "Total URLs:"}
            {props.numbers && props.numbers.totalUsers}
          </h2>
        </div>
        {props.data &&
          props.data.clients_urls.length > 0 &&
          props.data.clients_urls.map((item, index) => (
            <div
              key={index}
              style={{
                marginTop: "20px",
                padding: "20px",
                borderRadius: "20px",
                backgroundColor: "#222831",
              }}
            >
              <div
                style={{
                  display: "inline-flex",
                  justifyContent: "space-around",
                  alignItems: "center",
                  padding: "20px",
                  borderRadius: "20px",
                  backgroundColor: "#222831",
                  width: "100%",
                }}
                className="cursor"
                onClick={() => {
                  if (expand === `${item.id}_users`) {
                    setExapnd(null);
                  } else {
                    setExapnd(`${item.id}_users`);
                  }
                }}
              >
                <img
                  id="logoImg"
                  preserveDrawingBuffer={true}
                  className="roundSmallImg"
                  style={{
                    backgroundColor: "#1A1C20",
                    border: "0px",
                  }}
                  src={`https://logo.clearbit.com/${item.domain}`}
                  onError={defaultSrc}
                  alt="logo"
                />
                <div>
                  <h5>
                    {props.system && item.blur ? (
                      <span>
                        <BlurryText value={item.domain.split(".")[0]} />.
                        {item.domain.split(".")[1]}
                        {item.domain.split(".")[2] && item.domain.split(".")[2]}
                      </span>
                    ) : (
                      item.domain
                    )}
                  </h5>
                  <h5>{item.urls.length} URLs</h5>
                </div>
                <Button
                  type="button"
                  variant="contained"
                  className={"searchBtn desktopSearchBtn"}
                  endIcon={<Icon>expand</Icon>}
                  onClick={() => {
                    if (expand === `${item.id}_users`) {
                      setExapnd(null);
                    } else {
                      setExapnd(`${item.id}_users`);
                    }
                  }}
                >
                  VIEW ALL
                </Button>
                {item.blur ? (
                  <Button
                    type="button"
                    variant="contained"
                    className={"searchBtn desktopSearchBtn"}
                    endIcon={
                      unlockLoader === `${item.domain}_users` ||
                      unlockLoader === `${item.id}_users` ? (
                        <LoaderIcon
                          type="spin"
                          style={{
                            width: "16px",
                            height: "16px",
                            marginLeft: "3px",
                            position: "relative",
                            top: "-10px",
                          }}
                        />
                      ) : (
                        <BsUnlockFill
                          style={{ marginTop: "-5px", marginLeft: "5px" }}
                        />
                      )
                    }
                    onClick={(e) => {
                      e.stopPropagation();
                      unlockUrls(item.domain, "users");
                    }}
                  >
                    UNLOCK ALL
                  </Button>
                ) : (
                  <CSVLink
                    id="csvDownload"
                    className="csvDownload"
                    data={item.urls}
                    filename={`${item.domain}_users.csv`}
                    target="_blank"
                    onClick={(e) => e.stopPropagation()}
                  >
                    EXPORT ALL
                  </CSVLink>
                )}
              </div>
              {expand === `${item.id}_users` && (
                <div className="scrollBox">
                  {item.urls.map((asset, index) => (
                    <div
                      key={index}
                      style={{
                        textAlign: "left",
                        backgroundColor: "#1A1C20",
                        borderRadius: "20px",
                        padding: "10px",
                        fontSize: "16px",
                        display: "inline-flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        width: "100%",
                        margin: "5px 0px",
                      }}
                    >
                      {item.blur && !props.system ? (
                        <>
                          {asset.url.split(`.${item.domain}`)[0] ? (
                            asset.url.split(`.${item.domain}`)[1] ? (
                              censorUrl(item.domain, asset.url)
                            ) : asset.url
                                .split(`.${item.domain}`)[0]
                                .split("//")[1] ? (
                              <p>
                                {
                                  asset.url
                                    .split(`.${item.domain}`)[0]
                                    .split("//")[0]
                                }
                                //
                                <BlurryText
                                  value={`${asset.url
                                    .split(`.${item.domain}`)[0]
                                    .split("//")[1]
                                    .replace(/[a-zA-Z]/g, "•")}`}
                                />
                                .{item.domain}
                              </p>
                            ) : (
                              <p style={{ margin: "0px" }}>{asset.url}</p>
                            )
                          ) : (
                            <p style={{ margin: "0px" }}>{asset.url}</p>
                          )}
                        </>
                      ) : props.system && item.blur ? (
                        <p
                          style={{
                            margin: "0px",
                            width: "70%",
                            wordBreak: "break-word",
                          }}
                        >
                          {asset.url.split(item.domain)[0]}
                          <BlurryText value={item.domain.split(".")[0]} />.
                          {item.domain.split(".")[1]}
                          {asset.url.split(item.domain)[1]}
                        </p>
                      ) : (
                        <p
                          style={{
                            margin: "0px",
                            width: "70%",
                            wordBreak: "break-word",
                          }}
                        >
                          {asset.url}
                        </p>
                      )}
                      <p style={{ margin: "0px" }}>
                        Occurrence: {asset.occurrence}
                      </p>
                    </div>
                  ))}
                </div>
              )}
            </div>
          ))}
      </div>
    </div>
  );
}

export default AssetsDiscovery;

// db.bayonet_users.updateOne({
//     email: "advancedplan@hudsonrock.com"
// }, {
//     $set: {
//         unlocked_urls: []
//     }
// })
