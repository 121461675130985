/* global bluesnap */
import React, { useEffect, useState } from "react";
import "./css/credit-card.css";
import "./css/form-style.css";
import "./css/main.css";
// import "./css/bootstrap.min.css";
import Cards from "react-credit-cards";
import Col from "react-bootstrap/Col";
import useScript from "react-script-hook";
import CreateApiClient from "../../API";
import { Helmet } from "react-helmet";
import cryptoRandomString from "crypto-random-string";
import Overlay from "../Partials/Overlay";
import { bsURL, bsEnv } from "../../Config";
import SimpleModal from "../Partials/BasicModal";
import LoaderIcon from "react-loader-icon";
import { updateProperties, updateProperty } from "../../hooks/localStorage.js";
import $ from "jquery";
import { isValidEmail } from "../../hooks/Validation";

const api = CreateApiClient();
function Creditcard(props) {
  let timeoutID;
  const [loading, setLoading] = useState(false);
  const [cvc, setCvc] = useState("");
  const [expiry, setExpiry] = useState("");
  const [focus, setFocus] = useState("");
  const [name, setName] = useState("");
  const [number, setNumber] = useState("");
  const [expiryyear, setExpiryYear] = useState("");
  const [pfToken, setPfToken] = useState();
  const [paymentLoader, setPaymentLoader] = useState(false);
  const [failed, setFailed] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [cName, setCname] = useState("");
  const [cWeb, setCweb] = useState("");
  const [jobTitle, setJobTitle] = useState("");
  const [linkedIn, setLinkedIn] = useState("");

  useEffect(() => {
    if (pfToken) {
      loadPaymentFields(pfToken);
    }
  }, [pfToken]);

  useScript({
    src: bsURL,
    onload: async () => {
      const token = await api.getPfToken(bsEnv);
      setPfToken(token);
    },
  });

  useScript({
    src: "https://cdnjs.cloudflare.com/ajax/libs/jquery/2.1.3/jquery.min.js",
    onload: () => {},
  });

  useScript({
    src: "https://maxcdn.bootstrapcdn.com/bootstrap/3.3.5/js/bootstrap.min.js",
    onload: () => {},
  });

  useScript({
    src: "https://cdnjs.cloudflare.com/ajax/libs/bootstrap-material-design/0.3.0/js/material.js",
    onload: () => {},
  });

  useScript({
    src: "https://cdnjs.cloudflare.com/ajax/libs/bootstrap-material-design/0.3.0/js/ripples.js",
    onload: () => {},
  });

  function changeImpactedElement(tagId, removeClass, addClass) {
    removeClass = removeClass || "";
    addClass = addClass || "";
    $("[data-bluesnap=" + tagId + "]")
      .removeClass(removeClass)
      .addClass(addClass);
  }
  var cardUrl = {
    AMEX: "https://files.readme.io/97e7acc-Amex.png",
    DINERS: "https://files.readme.io/8c73810-Diners_Club.png",
    DISCOVER: "https://files.readme.io/caea86d-Discover.png",
    JCB: "https://files.readme.io/e076aed-JCB.png",
    MASTERCARD: "https://files.readme.io/5b7b3de-Mastercard.png",
    VISA: "https://files.readme.io/9018c4f-Visa.png",
  };

  const loadPaymentFields = async (token) => {
    $(function () {
      const bsObj = {
        //insert your Hosted Payment Fields token
        token: token,
        onFieldEventHandler: {
          onFocus: function (tagId) {
            // Handle focus
            changeImpactedElement(tagId, "", "hosted-field-focus");
          },
          onBlur: function (tagId) {
            // Handle blur
            changeImpactedElement(tagId, "hosted-field-focus");
          },
          onError: function (tagId, errorCode, errorDescription) {
            // Handle a change in validation by displaying help text
            $("#" + tagId + "-help")
              .removeClass("helper-text-green")
              .text(errorCode + " - " + errorDescription);
          },
          onType: function (tagId, cardType, cardData) {
            // get card type from cardType and display card image
            $("#card-logo > img").attr("src", cardUrl[cardType]);
            if (null != cardData) {
              $("#" + tagId + "-help")
                .addClass("helper-text-green")
                .text(JSON.stringify(cardData));
            }
          },
          onValid: function (tagId) {
            // Handle a change in validation by removing any help text
            $("#" + tagId + "-help").text("");
          },
        },
        style: {
          // Styling all inputs
          input: {
            "font-size": "14px",
            "font-family":
              "RobotoDraft,Roboto,Helvetica Neue,Helvetica,Arial,sans-serif",
            "line-height": "1.42857143",
            color: "#555",
          },
          // Styling input state
          ":focus": {
            color: "#555",
          },
        },
        ccnPlaceHolder: "1234 5678 9012 3456",
        cvvPlaceHolder: "123",
        expPlaceHolder: "MM / YY",
      };
      bluesnap.hostedPaymentFieldsCreate(bsObj);
    });
  };

  const isValidDomain = (domain) => {
    return /^[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9]\.[a-zA-Z]{2,}$/.test(
      domain
    );
  };

  const checkFields = (period) => {
    if (period != "ondemand") {
      if (cName && jobTitle && linkedIn) return true;
    } else {
      return true;
    }
  };

  function submitForm(e) {
    e.preventDefault();
    if (
      //   props.firstName &&
      //   props.lastName &&
      //   isValidEmail(props.email) &&
      props.plan &&
      checkFields(props.period) &&
      name
    ) {
      bluesnap.hostedPaymentFieldsSubmitData(async function (callback) {
        if (null != callback.error) {
          var errorArray = callback.error;
          for (var i in errorArray) {
            $("#" + errorArray[i].tagId + "-help").text(
              errorArray[i].errorCode + " - " + errorArray[i].errorDescription
            );
          }
        } else {
          setPaymentLoader(true);
          var cardData = callback.cardData;
          const url_hash = cryptoRandomString({
            length: 64,
            type: "url-safe",
          });

          const api_key = cryptoRandomString({
            length: 64,
            type: "url-safe",
          });

          const customer = {
            url_hash: url_hash,
            api_key: api_key,
            companyName: cName,
            companyWebsite: cWeb,
            linkedIn: linkedIn,
            job_title: jobTitle,
            card_data: cardData,
            seats: props.qty,
            overRideAmount: props.overRideAmount,
          };

          const paid = await api.makePayment(
            bsEnv,
            props.plan,
            customer,
            {
              headers: { token: pfToken, "auth-token": props.user.token },
            },
            props.qty,
            props.overRideAmount
          );

          if (
            paid &&
            paid.data &&
            paid.data.user &&
            paid.data.user.email === props.user.user.email
          ) {
            updateProperty("user", "sub", paid.data.sub);
            updateProperty("user", "user", paid.data.user);

            props.success();
            setPaymentLoader(false);
          } else {
            if (typeof paid.data === "string") {
              setErrorMessage(paid.data);
            }
            setPaymentLoader(false);
            console.log(paid);
            setFailed(true);
          }
        }
      });
    }
  }

  return (
    <div>
      <div className="credit-card">
        <Cards
          locale={{ valid: "Expires" }}
          placeholders={{ name: "FULL NAME" }}
          cvc={cvc}
          expiry={expiry}
          expiryyear={expiryyear}
          focused={focus}
          name={name}
          number={number}
        />
        {paymentLoader && <Overlay content="payment" />}
      </div>
      <div className="card-credit card" style={{ minHeight: "450px" }}>
        {props.loading ? (
          <LoaderIcon type="spin" style={{ margin: "auto" }} />
        ) : (
          <form className="payment-form" onSubmit={submitForm}>
            {props.period != "ondemand" && (
              <div
                style={{
                  width: "100%",
                  display: "inline-flex",
                  alignItems: "baseline",
                }}
              >
                <div className="form-group" style={{ width: "49%" }}>
                  <label htmlFor="cName">Company Name *</label>
                  <input
                    type="text"
                    id="cName"
                    name="cName"
                    spellCheck="false"
                    value={cName}
                    maxLength="30"
                    minLength="3"
                    autoComplete="off"
                    onChange={(e) => setCname(e.target.value)}
                    className="form-control-checkout form-control form-control-lg"
                  />
                  <span className="helper-text"></span>
                </div>
                <div className="form-group" style={{ width: "49%" }}>
                  <label htmlFor="cWeb">Company Domain</label>
                  <input
                    type="text"
                    id="cWeb"
                    name="cWeb"
                    spellCheck="false"
                    value={cWeb}
                    maxLength="20"
                    autoComplete="off"
                    onChange={(e) => setCweb(e.target.value)}
                    className="form-control-checkout form-control form-control-lg"
                  />
                  <span className="helper-text"></span>
                </div>
              </div>
            )}
            {props.period != "ondemand" && (
              <div
                style={{
                  width: "100%",
                  display: "inline-flex",
                  alignItems: "baseline",
                }}
              >
                <div className="form-group" style={{ width: "49%" }}>
                  <label htmlFor="cName">Job Title *</label>
                  <input
                    type="text"
                    id="jobTitle"
                    name="jobTitle"
                    spellCheck="false"
                    value={jobTitle}
                    maxLength="30"
                    minLength="3"
                    autoComplete="off"
                    onChange={(e) => setJobTitle(e.target.value)}
                    className="form-control-checkout form-control form-control-lg"
                  />
                  <span className="helper-text"></span>
                </div>
                <div className="form-group" style={{ width: "49%" }}>
                  <label htmlFor="cWeb">LinkedIn Profile *</label>
                  <input
                    type="text"
                    id="linkedIn"
                    name="linkedIn"
                    spellCheck="false"
                    value={linkedIn}
                    maxLength="90"
                    autoComplete="off"
                    onChange={(e) => setLinkedIn(e.target.value)}
                    className="form-control-checkout form-control form-control-lg"
                  />
                  <span className="helper-text"></span>
                </div>
              </div>
            )}
            <div className="form-group">
              <label htmlFor="card-number">Card Number *</label>
              <div className="input-group">
                <div
                  data-bluesnap="ccn"
                  type="text"
                  //   onChange={validateInput}
                  //   value={number}
                  //   onKeyDown={removeSpecial}
                  //   onPaste={(e) => e.preventDefault()}
                  //   onKeyPress={addSpace}
                  //   onFocus={handleInputFocus}
                  name="number"
                  maxLength="19"
                  id="card-number"
                  className="form-control-checkout form-control form-control-lg"
                ></div>
                <input type="hidden" id="hiddenCardNumber" />
              </div>
              <span className="helper-text" id="ccn-help"></span>
            </div>
            <div className="date-cvv-box">
              <div className="form-group" style={{ width: "100%" }}>
                <label htmlFor="cardholder-name">Card holder *</label>
                <input
                  type="text"
                  id="cardholder-name"
                  name="name"
                  spellCheck="false"
                  value={name}
                  maxLength="20"
                  autoComplete="off"
                  onChange={(e) => setName(e.target.value)}
                  className="form-control-checkout form-control form-control-lg"
                />
                <span className="helper-text"></span>
              </div>
              <div className="form-group" style={{ minWidth: "25%" }}>
                <label htmlFor="exp-date">Exp. (MM/YY) *</label>
                <div
                  className="form-control-checkout form-control"
                  id="exp-date"
                  data-bluesnap="exp"
                ></div>
                <span className="helper-text" id="exp-help"></span>
              </div>
              <div
                className="form-group"
                style={{ minWidth: "25%", justifyContent: "center" }}
              >
                <label htmlFor="cvv">CVV *</label>
                <div
                  id="cvv"
                  data-bluesnap="cvv"
                  type="number"
                  //   onChange={validateInput}
                  //   onKeyDown={removeSpecial}
                  //   onPaste={(e) => e.preventDefault()}
                  //   onFocus={handleInputFocus}
                  name="cvc"
                  value={cvc}
                  className="form-control-checkout form-control form-control-lg "
                  maxLength="4"
                ></div>
                <span className="helper-text" id="cvv-help"></span>
              </div>
            </div>
            {checkFields(props.period) && props.plan && name ? (
              <button
                className="btn yellow-bg btn-lg btn-block cyberFont"
                style={{ width: "100%", fontWeight: "bold" }}
                type="submit"
              >
                Pay Now
              </button>
            ) : (
              <button
                className="btn btn-lg col-md-4 grayBtn payNowGray"
                type="button"
                // onClick={props.validateFields}
              >
                Pay Now
              </button>
            )}
            <p className="red">
              {failed &&
                "Payment failed! please refresh the page and try again or contact us at sales@hudsonrock.com"}
            </p>
            <p className="red">{errorMessage}</p>
          </form>
        )}
      </div>
    </div>
  );
}

export default Creditcard;

// import React, { useState } from "react";
// import Cards from "react-credit-cards";

// export default function PaymentForm() {
//   const [cvc, setCvc] = useState("");
//   const [expiry, setExpiry] = useState("");
//   const [focus, setFocus] = useState("");
//   const [name, setName] = useState("");
//   const [number, setNumber] = useState("");

//   const handleInputFocus = (e) => {
//     setFocus(e.target.name);
//   };

//   const handleInputChange = (e) => {
//     const { name, value } = e.target;
//     switch (name) {
//       case "number":
//         setName(value);
//         break;
//     }
//   };

//   return (
//     <div id="PaymentForm">
//       <Cards
//         cvc={cvc}
//         expiry={expiry}
//         focused={focus}
//         name={name}
//         number={number}
//       />
//       <form>
//         <input
//           type="tel"
//           name="number"
//           placeholder="Card Number"
//           onChange={handleInputChange}
//           onFocus={handleInputFocus}
//         />
//       </form>
//     </div>
//   );
// }
