import React from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

function CheckoutTable(props) {
  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell className="checkOutTh">
              <p>Tier</p>
            </TableCell>
            <TableCell className="checkOutTh">
              <p>Initial Charge</p>
            </TableCell>
            <TableCell className="checkOutTh">
              <p>Recurring Price</p>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell className="checkOutTd">
              <p>{props.tier}</p>
            </TableCell>
            <TableCell className="checkOutTd">
              <p>
                {props.initialCharge
                  ? `$${props.initialCharge * props.qty}.00`
                  : "$0.00"}
              </p>
            </TableCell>
            <TableCell className="checkOutTd">
              <p>${props.price * props.qty}.00</p>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default CheckoutTable;
