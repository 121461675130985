import React, { useEffect, useState, useRef } from "react";
import thumbnail from "../../images/thumbnail.jpeg";
import Button from "@material-ui/core/Button";
import Icon from "@material-ui/core/Icon";
import ExportToCsv from "../Partials/ExportToCsv";
import { BsUnlockFill } from "react-icons/bs";
import { FaUserTie, FaUsers, FaFileCsv } from "react-icons/fa";
import BlurryText from "../Partials/Blur";
import { CSVLink, CSVDownload } from "react-csv";
import axios from "axios";
import { APIFullAddress } from "../../Config";
import LoaderIcon from "react-loader-icon";

function UnlockedAssets(props) {
  const [assets, setAssets] = useState();
  const [expand, setExapnd] = useState("");
  const [unlockLoader, setUnlockLoader] = useState(null);
  const [activeDomain, setActiveDomain] = useState(null);
  const [loadingUrls, setLoadingUrls] = useState(null);

  const [externalView, setExternalView] = useState(null);
  const [thirdPartyUrls, setThirdPartyUrls] = useState([]);
  const [externalLoading, setExternalLoading] = useState(false);

  const defaultSrc = (ev) => {
    ev.target.src = thumbnail;
  };

  const changeActiveDomain = (domain) => {
    setActiveDomain(domain);
    getData(domain);
  };

  const getThirdPartyUrls = async (domain) => {
    setExternalLoading(true);
    const _urls = await axios
      .post(
        `${APIFullAddress}/assets-discovery/third-party?domain=${domain}&page=0`,
        {},
        { headers: { "auth-token": props.user.token } }
      )
      .then((res) => res.data)
      .catch((err) => err);

    setThirdPartyUrls(_urls);
    setExternalLoading(false);
  };

  useEffect(() => {
    if (externalView) {
      setThirdPartyUrls([]);
      getThirdPartyUrls(externalView);
    }
  }, [externalView]);

  // useEffect(() => {
  //   let mounted = true;
  //   getData(activeDomain, mounted);
  //   return () => {
  //     mounted = false;
  //   };
  // }, [activeDomain]);

  useEffect(() => {
    setActiveDomain(props.assets[0].domain);
    getData(props.assets[0].domain);
  }, []);

  const getData = async (domain) => {
    setLoadingUrls(domain);

    const config = {
      method: "post",
      url: `${APIFullAddress}/assets-discovery`,
      data: {
        domains: [domain],
      },
      headers: {
        "auth-token": props.token,
      },
    };

    let assets;
    try {
      assets = await axios(config);
    } catch (err) {
      console.log("err");
    }

    if (assets && assets.data) {
      setAssets(assets.data);
    }
    setLoadingUrls(null);
  };

  const unlockUrls = async (domain, type) => {
    setUnlockLoader(`${domain}_${type}`);
    const body = {
      domain: domain,
      type: type,
    };
    const config = {
      headers: {
        "auth-token": props.user.token,
      },
    };

    await axios
      .post(`${APIFullAddress}/assets-discovery/unlock`, body, config)
      .then((res) => {
        props.resetUser(res.data.user);
        let reveal;
        if (type === "employees") {
          reveal = assets.data.employees_urls.find((s) => s.domain == domain);
        } else {
          reveal = assets.data.clients_urls.find((s) => s.domain == domain);
        }

        reveal.urls = res.data.unlockedUrls;
        reveal.blur = false;
      })
      .catch((err) => console.log(err));
    setUnlockLoader(null);
  };

  const censorDomainInUrl = (url) => {
    let splitted = url.split("•");
    return (
      <p>
        <span>{splitted[0]}</span>
        <span className="censored">
          {splitted.slice(1, splitted.length - 1).join("•")}
        </span>
        <span>{splitted[splitted.length - 1]}</span>
      </p>
    );
  };

  // const unlockUrlsById = async (id, type) => {
  //   setUnlockLoader(`${id}_${type}`);
  //   const body = {
  //     id: id,
  //     type: type,
  //   };
  //   const config = {
  //     headers: {
  //       "auth-token": props.user.token,
  //     },
  //   };

  //   await axios
  //     .post(`${APIFullAddress}/assets-discovery/unlock?id=${id}`, body, config)
  //     .then((res) => {
  //       props.resetUser(res.data.user);
  //       let reveal;
  //       if (type === "employees") {
  //         reveal = props.data.employees_urls.find((s) => s.id == id);
  //       } else {
  //         reveal = props.data.clients_urls.find((s) => s.id == id);
  //       }

  //       console.log(reveal);
  //       reveal.domain = res.data.domain;
  //       reveal.urls = res.data.unlockedUrls;
  //       reveal.blur = false;
  //     })
  //     .catch((err) => console.log(err));
  //   setUnlockLoader(null);
  // };

  const censorUrl = (domain, url) => {
    let splittedUrl = url.split(domain);
    let http = splittedUrl[0].split("//")[0];
    return (
      <p>
        <span>{http}</span>//
        <span className="censored">{splittedUrl[0].split("//")[1]}</span>
        <span>{domain}</span>/<span className="censored">{splittedUrl[1]}</span>
      </p>
    );
  };

  return (
    <div className="w-100" style={{ minHeight: "100vh" }}>
      <div style={{ width: "80%", margin: "auto" }}>
        {props.assets.map((company, index) => (
          <a
            key={index}
            href={loadingUrls != null ? `#${company.domain}` : undefined}
          >
            <div
              style={{
                width: "0px",
                height: "0px",
                position: "relative",
                top: "-80px",
              }}
              id={company.domain}
            ></div>
            <div
              className="flex_box items_center cursor"
              style={{
                backgroundColor:
                  activeDomain === company.domain ? "#fcce4e" : "lightgray",
                color: "black",
                width: "100%",
                textAlign: "left",
                minHeight: "100px",
                padding: "20px",
                borderRadius: "20px",
                margin: "5px",
                justifyContent: "space-between",
              }}
              onClick={() => {
                if (loadingUrls === null) {
                  changeActiveDomain(company.domain);
                  setThirdPartyUrls([]);
                  setExternalView(null);
                }
              }}
            >
              <img
                src={`https://logo.clearbit.com/${company.domain}`}
                style={{ width: "60px", borderRadius: "50px" }}
                onError={defaultSrc}
              />
              <p className="uppercase left-5 lightText no-margin">
                {company.domain}
              </p>
              <p className="uppercase left-5 lightText no-margin">
                {company.total} URLs →
              </p>
            </div>
            {loadingUrls != company.domain &&
            company.domain === activeDomain ? (
              <div
                className="flex_box"
                style={{
                  width: "100%",
                  margin: "5px",
                  justifyContent: "center",
                }}
              >
                <div
                  style={{
                    width: "49%",
                    marginRight: "5px",
                  }}
                >
                  <div
                    className="items_center align-center normal_pointer"
                    style={{
                      backgroundColor: "#fcce4e",
                      color: "black",
                      width: "100%",
                      textAlign: "left",
                      minHeight: "100px",
                      padding: "20px",
                      borderRadius: "20px",
                      marginBottom: "5px",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <h5 className="uppercase align-center">
                      {assets.data.employees_urls[0].onlyThirdParties
                        ? assets.data.employees_urls[0].urls.length
                        : assets &&
                          assets.data &&
                          assets.visualize.totalEmployees}{" "}
                      employee assets
                    </h5>
                    {assets &&
                      assets.data &&
                      assets.data.employees_urls.length > 0 && (
                        <>
                          <Button
                            variant="contained"
                            onClick={(e) => {
                              e.stopPropagation();
                              props.exportUrls(activeDomain, "employees");
                            }}
                            style={{
                              height: "35px",
                              backgroundColor: "#222831",
                              color: "#fcce4e",
                            }}
                          >
                            Export To CSV
                            {props.csvLoader === `${activeDomain}_employees` ? (
                              <LoaderIcon
                                type="spin"
                                style={{
                                  width: "16px",
                                  height: "auto",
                                  fill: "white",
                                  margin: "0px 2px",
                                }}
                              />
                            ) : (
                              <FaFileCsv size={20} />
                            )}
                          </Button>
                          <CSVLink
                            id={`${company.domain}_employees_csv`}
                            className="csvDownload"
                            data={props.urlsCsv}
                            filename={`${company.domain}_employees.csv`}
                            target="_blank"
                            onClick={(e) => e.stopPropagation()}
                            style={{
                              visibility: "hidden",
                              width: "0px",
                              display: "none",
                            }}
                          ></CSVLink>
                          {assets.data.employees_urls[0].blur && (
                            <Button
                              variant="contained"
                              onClick={(e) => {
                                e.stopPropagation();
                                unlockUrls(activeDomain, "employees");
                              }}
                              style={{
                                height: "35px",
                                backgroundColor: "#222831",
                                color: "#fcce4e",
                                margin: "0px 2px",
                              }}
                            >
                              Unlock URLs
                              {unlockLoader === `${activeDomain}_employees` ? (
                                <LoaderIcon
                                  type="spin"
                                  style={{
                                    width: "16px",
                                    height: "auto",
                                    fill: "white",
                                  }}
                                />
                              ) : (
                                <BsUnlockFill size={20} />
                              )}
                            </Button>
                          )}
                        </>
                      )}
                  </div>
                  <div
                    className="urls_box"
                    style={{
                      backgroundColor: "#fcce4e",
                    }}
                  >
                    {!externalLoading && (
                      <>
                        {!assets.data.employees_urls[0].onlyThirdParties && (
                          <>
                            <button
                              style={{
                                backgroundColor: "#222831",
                                color: "#FCCE4E",
                              }}
                              className="cardBtn uppercase"
                              onClick={(e) => {
                                if (
                                  assets.data.employees_urls[0].hasEmailDomain
                                ) {
                                  externalView
                                    ? setExternalView(null)
                                    : setExternalView(
                                        assets.data.employees_urls[0].domain
                                      );
                                  // handleCollapse(row._id, row._id, !externalView);
                                }
                              }}
                            >
                              {externalView && thirdPartyUrls.length > 0
                                ? "Show compromised corporate URLs"
                                : "Show third party URLs"}
                            </button>
                            <br />
                            <br />
                          </>
                        )}
                        {assets.data.employees_urls[0].onlyThirdParties && (
                          <span style={{ color: "black" }}>
                            *Only third party URLs were identified
                          </span>
                        )}
                        {((thirdPartyUrls.length > 0 && externalView) ||
                          assets.data.employees_urls[0].onlyThirdParties) && (
                          <p className="lightText">
                            Total Third Party URLs:{" "}
                            {assets.data.employees_urls[0].onlyThirdParties
                              ? assets.data.employees_urls[0].urls.length
                              : thirdPartyUrls.length}
                          </p>
                        )}
                      </>
                    )}
                    {externalLoading && thirdPartyUrls.length <= 0 && (
                      <div style={{ height: "500px" }}>
                        <LoaderIcon
                          type="spin"
                          style={{
                            fill: "black",
                            width: "100px",
                            height: "auto",
                            position: "relative",
                            top: "5rem",
                          }}
                        />
                      </div>
                    )}
                    {activeDomain &&
                    assets.data.employees_urls.length > 0 &&
                    !loadingUrls ? (
                      externalView ? (
                        <>
                          {thirdPartyUrls.map((asset, index) => (
                            <div
                              key={index}
                              style={{
                                textAlign: "left",
                                backgroundColor: "#1A1C20",
                                borderRadius: "20px",
                                padding: "10px",
                                fontSize: "16px",
                                display: "inline-flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                width: "100%",
                                margin: "5px 0px",
                              }}
                            >
                              {assets.data.employees_urls[0].blur ? (
                                censorDomainInUrl(asset.url)
                              ) : (
                                <p
                                  style={{
                                    margin: "0px",
                                    width: "70%",
                                    wordBreak: "break-word",
                                  }}
                                >
                                  {asset.url}
                                </p>
                              )}
                              <p style={{ margin: "0px" }}>
                                Occurrence: {asset.occurrence}
                              </p>
                            </div>
                          ))}
                        </>
                      ) : (
                        assets.data.employees_urls[0].urls.map(
                          (asset, index) => (
                            <div
                              key={index}
                              style={{
                                textAlign: "left",
                                backgroundColor: "#222831",
                                borderRadius: "20px",
                                padding: "10px",
                                fontSize: "16px",
                                display: "inline-flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                width: "100%",
                                margin: "5px 0px",
                              }}
                            >
                              {assets.data.employees_urls[0].blur ? (
                                <>
                                  {asset.url.split(`.${activeDomain}`)[0] ? (
                                    asset.url.split(`.${activeDomain}`)[1] ? (
                                      censorUrl(activeDomain, asset.url)
                                    ) : asset.url
                                        .split(`.${activeDomain}`)[0]
                                        .split("//")[1] ? (
                                      <p>
                                        {
                                          asset.url
                                            .split(`.${activeDomain}`)[0]
                                            .split("//")[0]
                                        }
                                        //
                                        <BlurryText
                                          value={`${asset.url
                                            .split(`.${activeDomain}`)[0]
                                            .split("//")[1]
                                            .replace(/[a-zA-Z]/g, "•")}`}
                                        />
                                        .{activeDomain}
                                      </p>
                                    ) : (
                                      <p style={{ margin: "0px" }}>
                                        {asset.url}
                                      </p>
                                    )
                                  ) : (
                                    <p style={{ margin: "0px" }}>{asset.url}</p>
                                  )}
                                </>
                              ) : (
                                <p
                                  style={{
                                    margin: "0px",
                                    width: "70%",
                                    wordBreak: "break-word",
                                  }}
                                >
                                  {asset.url}
                                </p>
                              )}
                              <p style={{ margin: "0px" }}>
                                Occurrence: {asset.occurrence}
                              </p>{" "}
                            </div>
                          )
                        )
                      )
                    ) : loadingUrls === company.domain ? (
                      <LoaderIcon
                        type="spin"
                        style={{ fill: "white", marginTop: "2rem" }}
                      />
                    ) : (
                      <h6 className="align-center lightText">NO ASSETS</h6>
                    )}
                  </div>
                </div>
                <div style={{ width: "49%", marginLeft: "5px" }}>
                  <div
                    className="items_center align-center"
                    style={{
                      backgroundColor: "#fcce4e",
                      color: "black",
                      width: "100%",
                      textAlign: "left",
                      minHeight: "100px",
                      padding: "20px",
                      borderRadius: "20px",
                      marginBottom: "5px",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <h5 className="uppercase align-center normal_pointer">
                      {assets && assets.data && assets.visualize.totalUsers}{" "}
                      user assets
                    </h5>
                    {assets &&
                      assets.data &&
                      assets.data.clients_urls.length > 0 && (
                        <>
                          <Button
                            variant="contained"
                            onClick={(e) => {
                              e.stopPropagation();
                              props.exportUrls(activeDomain, "users");
                            }}
                            style={{
                              height: "35px",
                              backgroundColor: "#222831",
                              color: "#fcce4e",
                              margin: "0px 2px",
                            }}
                          >
                            Export To CSV
                            {props.csvLoader === `${activeDomain}_users` ? (
                              <LoaderIcon
                                type="spin"
                                style={{
                                  width: "16px",
                                  height: "auto",
                                  fill: "white",
                                }}
                              />
                            ) : (
                              <FaFileCsv size={20} />
                            )}
                          </Button>
                          <CSVLink
                            id={`${company.domain}_users_csv`}
                            className="csvDownload"
                            data={props.urlsCsv}
                            filename={`${company.domain}_users.csv`}
                            target="_blank"
                            onClick={(e) => e.stopPropagation()}
                            style={{
                              visibility: "hidden",
                              width: "0px",
                              display: "none",
                            }}
                          ></CSVLink>
                          {assets.data.clients_urls[0].blur && (
                            <Button
                              variant="contained"
                              onClick={(e) => {
                                e.stopPropagation();
                                unlockUrls(activeDomain, "users");
                              }}
                              style={{
                                height: "35px",
                                backgroundColor: "#222831",
                                color: "#fcce4e",
                                margin: "0px 2px",
                              }}
                            >
                              Unlock URLs
                              {unlockLoader === `${activeDomain}_users` ? (
                                <LoaderIcon
                                  type="spin"
                                  style={{
                                    width: "16px",
                                    height: "auto",
                                    fill: "white",
                                  }}
                                />
                              ) : (
                                <BsUnlockFill size={20} />
                              )}
                            </Button>
                          )}
                        </>
                      )}
                  </div>{" "}
                  <div
                    className="urls_box"
                    style={{
                      backgroundColor: "#fcce4e",
                    }}
                  >
                    {activeDomain &&
                    assets.data.clients_urls.length > 0 &&
                    !loadingUrls ? (
                      assets.data.clients_urls[0].urls.map((asset, index) => (
                        <div
                          key={index}
                          style={{
                            textAlign: "left",
                            backgroundColor: "#222831",
                            borderRadius: "20px",
                            padding: "10px",
                            fontSize: "16px",
                            display: "inline-flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            width: "100%",
                            margin: "5px 0px",
                          }}
                        >
                          {assets.data.clients_urls[0].blur ? (
                            <>
                              {asset.url.split(`.${activeDomain}`)[0] ? (
                                asset.url.split(`.${activeDomain}`)[1] ? (
                                  censorUrl(activeDomain, asset.url)
                                ) : asset.url
                                    .split(`.${activeDomain}`)[0]
                                    .split("//")[1] ? (
                                  <p>
                                    {
                                      asset.url
                                        .split(`.${activeDomain}`)[0]
                                        .split("//")[0]
                                    }
                                    //
                                    <BlurryText
                                      value={`${asset.url
                                        .split(`.${activeDomain}`)[0]
                                        .split("//")[1]
                                        .replace(/[a-zA-Z]/g, "•")}`}
                                    />
                                    .{activeDomain}
                                  </p>
                                ) : (
                                  <p style={{ margin: "0px" }}>{asset.url}</p>
                                )
                              ) : (
                                <p style={{ margin: "0px" }}>{asset.url}</p>
                              )}
                            </>
                          ) : (
                            <p
                              style={{
                                margin: "0px",
                                width: "70%",
                                wordBreak: "break-word",
                              }}
                            >
                              {asset.url}
                            </p>
                          )}
                          <p style={{ margin: "0px" }}>
                            Occurrence: {asset.occurrence}
                          </p>{" "}
                        </div>
                      ))
                    ) : LoaderIcon === company.domain ? (
                      <LoaderIcon
                        type="spin"
                        style={{ fill: "white", marginTop: "2rem" }}
                      />
                    ) : (
                      <h6 className="align-center lightText">NO ASSETS</h6>
                    )}
                  </div>
                </div>
              </div>
            ) : loadingUrls === company.domain ? (
              <LoaderIcon
                type="spin"
                style={{ fill: "white", margin: "5rem auto" }}
              />
            ) : (
              <></>
            )}
          </a>
        ))}
      </div>
    </div>
  );
}

export default UnlockedAssets;
