import React from "react";
import { MultiSelect } from "react-multi-select-component";
import LoaderIcon from "react-loader-icon";

export default function TimeFilters(props) {
  const DefaultItemRenderer = ({ checked, option, onClick, disabled }) => (
    <div className={`item-renderer ${disabled && "disabled"}`}>
      {props.loadOptions ? (
        <LoaderIcon className="tabsLoader" type={"spinningBubbles"} />
      ) : (
        <>
          <input
            type="checkbox"
            onChange={onClick}
            checked={checked}
            tabIndex={-1}
            disabled={disabled}
            className="filter-input"
          />
          <span className="filter-label">{option.label}</span>
        </>
      )}
    </div>
  );
  return (
    <MultiSelect
      overrideStrings={{
        selectSomeItems: "All Time",
      }}
      hasSelectAll={false}
      ItemRenderer={DefaultItemRenderer}
      shouldToggleOnHover={true}
      options={props.timeOptions}
      value={props.timeSelected && props.timeSelected}
      onChange={(e) => props.onChange(e)}
      labelledBy="Filter"
    />
  );
}
