import React from "react";
import { BsUnlockFill } from "react-icons/bs";

export default function UnlockSelected(props) {
  return (
    props.user.user.role != "admin" && (
      <div>
        {props.selected.length > 0 ? (
          <button
            onClick={(type, page) => props.unlockStealer(type, page)}
            className="MuiButtonBase-root MuiButton-root MuiButton-contained searchBtn"
          >
            Unlock Selected
            <BsUnlockFill size={15} className="smIcons" />
          </button>
        ) : (
          <button className="MuiButtonBase-root MuiButton-root MuiButton-contained searchBtn grayBtn">
            Unlock Selected
            <BsUnlockFill size={15} className="smIcons" />
          </button>
        )}
      </div>
    )
  );
}
