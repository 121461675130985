import React from "react";

export default function ChangePass(props) {
  return (
    <form>
      <div className="col-md-12">
        <label className="labels">Old Password</label>
        <input
          type="password"
          className="form-control blackTextInput"
          placeholder="Old Password"
          value={props.oldPassword}
          onChange={props.onChangeOldPass}
        />
      </div>
      <div className="col-md-12">
        <label className="labels">New Password</label>
        <input
          type="password"
          className="form-control blackTextInput"
          placeholder="New Password"
          value={props.newPassword}
          minLength={8}
          onChange={props.onChangeNewPass}
        />
      </div>
      <div className="col-md-12" style={{ marginTop: "10px" }}>
        <input
          type="password"
          className="form-control blackTextInput"
          placeholder="Repeat New Password"
          value={props.confirmPassword}
          onChange={props.onChangeConfirmPass}
        />
      </div>
      <p className="error">{props.error}</p>
    </form>
  );
}
