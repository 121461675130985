import React, { useEffect, useState, useRef } from "react";
import AppBarNew from "../Partials/AppBar";
import ScrollableTabsButtonForce from "./HorizontalTabs";
import "../../styles/App.css";
import CreateApiClient from "../../API";
import { useLocation } from "react-router";
import { createBrowserHistory } from "history";
import axios, { CancelToken, isCancel } from "axios";
import { APIFullAddress, clearbitApi, authAddress } from "../../Config";
import FormDialog from "../Partials/FormDialog";
import Overlay from "../Partials/Overlay";
// import { CheckUserSession } from "../hooks/CheckUserSession";
import SimpleModal from "../Partials/BasicModal";
import Footer from "../Partials/Footer";
import GlobalModal from "../Partials/GlobalModal";
import { FaUnlock } from "react-icons/fa";
const history = createBrowserHistory();

const api = CreateApiClient();

function Domain(props) {
  const currentYear = new Date().getFullYear();
  const [employeesCities, setEmployeesCities] = useState([]);
  const [clientsCities, setClientsCities] = useState([]);
  const location = useLocation();
  const domain = location.pathname.split("/")[2];
  const path = location.pathname.split("/")[1];
  const [user, setUser] = useState(props.user);
  const [email, setEmail] = useState(props.email);
  const [domains, setDomains] = useState(props.domains);
  const [role, setRole] = useState(props.user.user.role);
  const [token, setToken] = useState(props.token);
  const [monitoredDomains, setMonitoredDomains] = useState(
    props.monitoredDomains
  );

  const [loading, setLoading] = useState();
  const [loadingCounters, setLoadingCounters] = useState();
  const [clientsCount, setClientssCount] = useState(0);
  const [stealersCount, setStealersCount] = useState(0);
  const [employeesCount, setEmployeesCount] = useState(0);
  const [androidsCount, setAndroidsCount] = useState(0);
  const [dbsCount, setDbsCount] = useState(0);
  const [assetsCount, setAssetsCount] = useState(0);
  const [timeLabels, setTimeLabels] = useState([]);
  const [counts, setCounts] = useState([]);
  const [resData, setResData] = useState([]);
  const [employeesStats, setEmployeesStats] = useState([]);
  const [clientsStats, setClientsStats] = useState([]);
  const [employeesUrls, setEmployeesUrls] = useState([]);
  const [clientsUrls, setClientsUrls] = useState([]);
  const [employeesUrlsCount, setEmployeesUrlsCount] = useState([]);
  const [clientsUrlsCount, setClientsUrlsCount] = useState([]);
  const [cities, setCities] = useState([]);
  const [activeDomains, setActiveDomains] = useState();
  const [config, setConfig] = useState("");
  const [data, setData] = useState([]);
  const [empLength, setEmpLength] = useState(0);
  const [resLength, setResLength] = useState(0);
  const [machines, setMachines] = useState(0);
  const [finalRes, setFinalRes] = useState(false);
  const [loader, setLoader] = useState(false);
  const [section, setSection] = useState("");
  const [monthly, isMonthly] = useState(true);
  const [year, setYear] = React.useState("All Time");
  const [yearsOptions, setYearsOptions] = useState([]);
  const [monitorCount, setMonitorCount] = useState("");
  const [allMonitoredCounts, setAllMonitoredCounts] = useState([]);
  const [mapLoading, setMapLoading] = useState(false);
  const [chartLoader, setChartLoader] = useState(false);
  const [pieLoading, setPieLoading] = useState(false);
  const [dataLoading, setDataLoading] = useState(false);
  const [unlockedStealers, setUnlockedStealers] = useState([]);
  const [unlockedStealersCount, setUnlockedStealersCount] = useState(0);
  const [selected, setSelected] = useState([]);
  const [formOpen, setFormOpen] = React.useState(false);
  const [tier, setTier] = useState();
  const [prospects, setProspects] = useState([]);
  const [credsToUnlock, setCredsToUnlock] = useState([]);
  const [overlay, setOverlay] = useState(false);
  const [page, setPage] = useState();
  const [type, setType] = useState();
  const [currentProspect, setCurrentProspect] = useState("");
  const [currentCompany, setCurrentCompany] = useState("");
  const [addProspectLoader, setProspectLoader] = useState(false);
  const [prospectAdded, setProspectAdded] = useState(false);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [credits, setCredits] = useState(0);
  const [assets, setAssets] = useState([]);
  const [partiesCount, setPartiesCount] = useState(0);
  const [inPageLoading, setInPageLoading] = useState(false);
  const [globalModalOpen, setGlobalModalOpen] = useState(false);
  const [globalModalBody, setGlobalModalBody] = useState("");
  const [externalCount, setExternalCount] = useState(0);
  const [selectedExternals, setSelectedExternals] = useState([]);
  const [externalSuggestions, setExternalSuggestions] = useState([]);
  const [hasNextPage, setHasNextPage] = useState(false);

  const cancelSearch = useRef(null);

  const openForm = () => {
    setFormOpen(true);
  };

  const closeForm = () => {
    setFormOpen(false);
  };

  const handleLogOut = () => {
    localStorage.clear();
    redirectToLoginPage();
  };

  const redirectToLoginPage = () => {
    history.push("/login");
    window.location.reload();
  };

  const handleYearChange = (event) => {
    if (event.target.value === "All Time") {
      switchToAllTimeChart();
    } else {
      setTimelineChart(event.target.value, user);
    }
    setYear(event.target.value);
  };

  const switchToAllTimeChart = () => {
    setAllTimeChart(user);
    isMonthly(false);
  };

  const switchToMonthlyChart = () => {
    isMonthly(true);
    getStats(`stats?frame=monthly&year=${year}`, user);
  };

  const setTimelineChart = (year, loggedInUser) => {
    getStats(`stats?frame=monthly&year=${year}`, loggedInUser);
  };

  const setAllTimeChart = (loggedInUser) => {
    getStats(`stats?frame=all&currentyear=${currentYear}`, loggedInUser);
  };

  const afterUnlockModal = (unique, total) => {
    return (
      <div style={{ textAlign: "center" }}>
        <div className="closeModal" onClick={() => setGlobalModalOpen(false)}>
          X
        </div>
        <FaUnlock size={46} />
        <h2 style={{ width: "70%", margin: "15px auto" }}>
          You unlocked {total}+ results for {unique}{" "}
          {unique > 1 ? "credits" : "credit"}, view them in{" "}
          <span style={{ textDecoration: "underline" }}>
            <a href="/unlocked-data" target="_blank">
              Unlocked Content
            </a>{" "}
          </span>
          or continue unlocking.
        </h2>
      </div>
    );
  };

  const resetStats = () => {
    setCounts([]);
    setTimeLabels([]);
    setEmployeesStats([]);
    setClientsStats([]);
    setEmployeesCities([]);
    setClientsCities([]);
    setEmployeesUrls([]);
    setEmployeesUrlsCount(0);
    setClientsUrls([]);
    setExternalSuggestions([]);
    setClientsUrlsCount(0);
  };

  useEffect(async () => {
    resetStats();
    let loggedInUser;
    typeof user === "string"
      ? (loggedInUser = JSON.parse(user))
      : (loggedInUser = user);
    if (loggedInUser) {
      const time = Date.now().toString().substr(0, 10);
      const nowNum = Number(time);
      if (loggedInUser.expiry < nowNum) {
        // props.handleLogOut();
      } else {
        setUser(loggedInUser);
        setEmail(loggedInUser.user.email);
        setRole(loggedInUser.user.role);
        setToken(loggedInUser.user.token);
        setMonitoredDomains(loggedInUser.user.monitored_domains);
        getPresets(loggedInUser);

        if (path === "search-by-prospect") {
          const newPorspects = await api.editUser(
            "get-prospects",
            {},
            {
              headers: {
                "auth-token": props.user.token,
              },
            }
          );

          if (newPorspects) {
            const foundProspect = newPorspects.prospects.find(
              (s) => s._id === domain
            );
            setCurrentProspect(foundProspect);
          }
        }
      }
    }
  }, [domain]);

  async function getPresets(loggedInUser) {
    setLoading(true);
    setChartLoader(true);
    setMapLoading(true);
    setPieLoading(true);

    await getCounters(loggedInUser);
    if (year != "All Time") {
      await getStats(`stats?frame=monthly&year=${year}`, loggedInUser);
    } else {
      await getStats(
        `stats?frame=all&currentyear=${currentYear}`,
        loggedInUser
      );
    }
    await getUrls("stats/urls", loggedInUser);
  }

  async function getCounters(loggedInUser) {
    // setLoadingCounters(true);
    let response;
    if (path === "search-by-domain") {
      response = await api.fetchData(
        "counts/domain",
        { domain: domain },

        {
          headers: {
            "auth-token": props.token,
          },
        }
      );
    } else if (path === "search-by-prospect") {
      response = await api.fetchData(
        `counts/prospect?prospect=${domain}`,
        {},
        {
          headers: {
            "auth-token": props.token,
          },
        }
      );
    }

    const totalStealers = Number(response.stealers);
    const totalClients = Number(response.clients);
    const totalEmployees = Number(response.employees);
    const totalDbs = Number(response.dbs);
    const monitoredStealersCount = Number(response.monitoredStealersCount);
    const totalUnlockedStealers = Number(response.unlockedStealers);
    const totalAssetsCount = Number(response.assets);
    const totalParties = Number(response.parties);
    const totalExternalCount = Number(response.externalCount);

    setStealersCount(totalStealers);

    setLoading(false);

    if (
      response.keyword_suggestions &&
      response.keyword_suggestions.length > 0
    ) {
      setExternalSuggestions(response.keyword_suggestions);
      let _externals = response.keyword_suggestions.map((item) => {
        let obj = {
          value: item.domain,
          label: item.domain,
        };
        return obj;
      });
      setSelectedExternals(_externals);
    }

    setExternalCount(totalExternalCount);
    // const totalCredits = Number(response.credits);

    // setCredits(totalCredits);
    setAssetsCount(totalAssetsCount);
    setTier(response.tier);
    setUnlockedStealersCount(totalUnlockedStealers);
    setClientssCount(totalClients);
    setEmployeesCount(totalEmployees);
    setMonitorCount(monitoredStealersCount);
    setDbsCount(totalDbs);
    setPartiesCount(totalParties);
    typeof response.androids != "boolean"
      ? setAndroidsCount(Number(response.androids))
      : setAndroidsCount(response.androids);
    let _type = totalEmployees > 0 ? "employees" : "users";
    getMapData(`stats/ips?type=${_type}`, loggedInUser);
  }

  const getStats = async (query, user) => {
    setChartLoader(true);
    if (query == `stats?frame=monthly&year=${year}`) {
      isMonthly(true);
    }
    setSection(query);
    let body;
    let config = {
      headers: {
        "auth-token": user.token,
      },
    };

    if (path === "search-by-domain") {
      body = {
        domain: domain,
      };
    } else if (path === "search-by-prospect") {
      body = {
        prospect: domain,
      };
    }
    // if (domain.includes("&")) {
    //   domainQuery = domain.split("&");
    // } else {
    // }

    const response = await api.fetchData(query, body, config);
    if (response) {
      if (response.datasets && response.datasets.length > 0) {
        setCounts(response.datasets);
      }
      // const latestYears = response.years.filter(
      //   (y) => Number(y) >= 2018 || y === "All Time"
      // );
      setYearsOptions(response.years);
      setTimeLabels(response.labels);
      setEmployeesStats(response.employeesCount);
      setClientsStats(response.clientsCount);
    } else {
      setCounts([]);
      setTimeLabels([]);
      setEmployeesStats([]);
      setClientsStats([]);
    }
    setChartLoader(false);
  };

  const getMapData = async (query, user) => {
    setMapLoading(true);
    let body;
    let config = {
      headers: {
        "auth-token": user.token,
      },
    };

    if (path === "search-by-domain") {
      body = {
        domain: domain,
      };
    } else if (path === "search-by-prospect") {
      body = {
        prospect: domain,
      };
    }

    const response = await api.fetchData(query, body, config);
    if (response.length > 0) {
      setCities(response);
      splitIps(response);
    }
    setMapLoading(false);
  };

  const splitIps = (data) => {
    let employeesCities = [];
    let clientsCities = [];
    data.map((item) => {
      item.type === "employee"
        ? employeesCities.push(item)
        : clientsCities.push(item);
    });
    setEmployeesCities(employeesCities);
    setClientsCities(clientsCities);
  };

  const getUrls = async (query, user) => {
    setPieLoading(true);
    let body;
    let config = {
      headers: {
        "auth-token": user.token,
      },
    };

    if (path === "search-by-domain") {
      body = {
        domain: domain,
      };
    } else if (path === "search-by-prospect") {
      body = {
        prospect: domain,
      };
    }

    const response = await api.fetchData(query, body, config);
    setEmployeesUrls(response.employees_urls);
    setEmployeesUrlsCount(response.employees_count);
    setClientsUrls(response.clients_urls);
    setClientsUrlsCount(response.clients_count);
    setPieLoading(false);
  };

  const getAssetDiscovery = async () => {
    setDataLoading(true);
    const loggedInUser = localStorage.getItem("user");
    if (loggedInUser) {
      const foundUser = JSON.parse(loggedInUser);
      const time = Date.now().toString().substr(0, 10);
      const nowNum = Number(time);
      if (foundUser.expiry < nowNum) {
        handleLogOut();
      } else {
        setUser(foundUser);
        let config = {
          headers: {
            "auth-token": props.user.token,
          },
        };
        let body, url;
        if (path === "search-by-domain") {
          body = {
            domains: [domain],
          };
          url = `${APIFullAddress}/assets-discovery`;
        } else if (path === "search-by-prospect") {
          body = {
            domains: currentProspect.domains,
          };
          url = `${APIFullAddress}/assets-discovery`;
        }

        await axios
          .post(url, body, config)
          .then(async (res) => setAssets(res.data))
          .catch((err) => err);
      }
    }
    setDataLoading(false);
  };

  const getMonitoredDomains = async () => {
    setDataLoading(true);
    const loggedInUser = localStorage.getItem("user");
    if (loggedInUser) {
      const foundUser = JSON.parse(loggedInUser);
      const time = Date.now().toString().substr(0, 10);
      const nowNum = Number(time);
      if (foundUser.expiry < nowNum) {
        handleLogOut();
      } else {
        setUser(foundUser);
        let config = {
          headers: {
            "auth-token": props.user.token,
          },
        };

        await axios
          .post(
            `${authAddress}/getmonitoreddomains?prospect=${
              path === "search-by-prospect" ? domain : false
            }`,
            {},
            config
          )
          .then(async (res) => {
            setMonitoredDomains(res.data);
            const response = await api.fetchData(
              `counts/countallmonitored?prospect=${
                path === "search-by-prospect" ? domain : false
              }`,
              { domains: res.data },
              config
            );
            setAllMonitoredCounts(response);
          })
          .catch((err) => console.log(err));
      }
    }
    setDataLoading(false);
  };

  // const getUnlockedStealers = async () => {
  //   setDataLoading(true);
  //   try {
  //     await axios.post(
  //       `${APIFullAddress}/unlock-stealer/get-unlocked-stealers?sub=${user.sub.subscriptionId}`,
  //       {},
  //       {
  //         headers: {
  //           "auth-token": props.token,
  //         },
  //       }
  //     ).then((res) => {
  //       setUnlockedStealers(res.data);
  //     });
  //   } catch (e) {
  //     console.log(e);
  //   }
  //   setDataLoading(false);
  // };

  const getData = async (oldData, type, page, unlock, inPageLoading) => {
    const loggedInUser = localStorage.getItem("user");
    if (loggedInUser) {
      const foundUser = JSON.parse(loggedInUser);
      const time = Date.now().toString().substr(0, 10);
      const nowNum = Number(time);
      if (foundUser.expiry < nowNum) {
        handleLogOut();
      } else {
        setUser(foundUser);
        setRole(foundUser.user.role);
        setToken(foundUser.token);
        setSection(type);

        let query;
        if (path === "search-by-domain") {
          if (type.includes("external-domains")) {
            query = {
              domains: selectedExternals,
              keyword: domain.split(".")[0],
              page: page,
            };
          } else {
            query = {
              domain: domain,
              page: page,
            };
          }
        } else if (path === "search-by-prospect") {
          if (domain.includes("%20")) {
            query = {
              externals: selectedExternals,
              prospect: domain.split("%20").join(" "),
              page: page,
            };
          } else {
            query = {
              externals: selectedExternals,
              prospect: domain,
              page: page,
            };
          }
        }

        if (page === 0) {
          setData([]);
          if (inPageLoading) {
            setInPageLoading(true);
          } else {
            setDataLoading(true);
          }
          setSelected([]);
        } else {
          setLoader(true);
        }

        cancelRequest();

        let config = {
          headers: {
            "auth-token": foundUser.token,
          },
          cancelToken: new CancelToken(
            (cancel) => (cancelSearch.current = cancel)
          ),
        };

        setConfig(config);

        const response = await api.fetchData(type, query, config);

        if (response && response.message === "New search initiated") {
          console.log("new search");
        } else if (response) {
          if (response.length > 0) {
            response.map(async (item) => {
              item["company"] = await axios
                .get(`${clearbitApi}${item.current_domain}`)
                .then((res) => {
                  if (res.data[0]) {
                    return res.data[0].name;
                  } else {
                    return item.domain;
                  }
                })
                .catch((err) => console.log(err));
            });

            if (data && data.length > 0) {
              setData([...oldData, ...response]);
            } else {
              setData([...response]);
            }
            setFinalRes(false);
            setMachines(response[0].count);
            setEmpLength(response[0].employeesCount);
            setHasNextPage(response[0].hasNextPage);
            setResLength(response.length);
          } else {
            data.length > 0 ? setData(oldData) : setData([]);
            setFinalRes(true);
          }
          setInPageLoading(false);
          setDataLoading(false);
          setLoader(false);
        }
      }
    }
  };

  const cancelRequest = () => {
    if (cancelSearch.current) {
      cancelSearch.current("New search initiated");
    }
  };

  useEffect(async () => {
    const response = await api.editUser(
      "get-prospects",
      {},
      {
        headers: {
          "auth-token": props.user.token,
        },
      }
    );
    setProspects(response.prospects);
  }, []);

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      let tempData = data.filter((s) => s.censored != false);

      // const newSelecteds = tempData.map((n) => n.name);
      // setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  Array.prototype.multiIndexOf = function (el) {
    var idxs = [];
    for (var i = this.length - 1; i >= 0; i--) {
      if (this[i]._id === el) {
        idxs.unshift(i);
      }
    }
    return idxs;
  };

  const selectRow = (row) => {
    if (row !== "all") {
      selected.indexOf(row) !== -1
        ? setSelected(selected.filter((s) => s !== row))
        : setSelected((selected) => [...selected, row]);
    } else {
      setSelected([]);
      let tempData = data.filter((s) => s.censored != false);
      selected.length != tempData.length
        ? tempData.map((item) => {
            const index = data.indexOf(item);
            // const index = data.find((s) => s._id === item._id);
            // const indexes = data.multiIndexOf(item._id);

            setSelected((selected) => [...selected, index]);
          })
        : setSelected([]);
    }
  };

  const unlockStealer = async (type, page, isExternal) => {
    // if (!props.user.sub && props.user.user.role === "mssp")
    //   return setOpen(true);
    setType(type);
    setPage(page);
    let temp = [];
    selected.map((item) => {
      temp.push({ id: data[item]._id, domain: data[item].domain });
    });

    let isAndroid, company, credsDomain;
    if (data[0].android === true) {
      isAndroid = true;
    } else {
      isAndroid = false;
    }
    temp = temp.filter(
      (value, index, self) => index === self.findIndex((t) => t.id === value.id)
    );

    let query = {};
    if (path === "search-by-domain") {
      query = {
        stealers: temp,
        credits: temp.length,
        externals: isExternal ? selectedExternals : null,
        domain: domain,
      };
    } else if (path === "search-by-prospect") {
      if (domain.includes("%20")) {
        query = {
          stealers: temp,
          credits: temp.length,
          externals: isExternal ? selectedExternals : null,
          domain: domain.split("%20").join(" "),
          prospect: true,
        };
      } else {
        query = {
          stealers: temp,
          credits: temp.length,
          externals: isExternal ? selectedExternals : null,
          domain: domain,
          prospect: true,
        };
      }
    }

    // await axios.post(
    //   `${APIFullAddress}/unlock-stealer/unlock-creds?android=${isAndroid}`,
    //   { creds: creds, company: company, domain: credsDomain },
    //   {
    //     headers: {
    //       "auth-token": props.token,
    //     },
    //   }
    // )

    // closeForm();

    setOverlay(true);
    await axios
      .post(
        `${APIFullAddress}/unlock-stealer?android=${isAndroid}&externals=${isExternal}`,
        query,
        {
          headers: {
            "auth-token": props.token,
          },
        }
      )
      .then((res) => {
        const localUser = JSON.parse(localStorage.getItem("user"));
        localUser.user.credits = res.data.credits;
        localStorage.setItem("user", JSON.stringify(localUser));
        setCredits(res.data.credits);

        const response = res.data.stealer;
        let uniqueUnlocked = [];
        let totalUnlocked = [];
        response.map((row) => {
          console.log(row);
          if (!uniqueUnlocked.includes(row._id)) {
            uniqueUnlocked.push(row._id);
          }
          totalUnlocked.push(row._id);

          const reveal = data.find(
            (e) => e._id === row._id && e.censored === true
          );

          if (reveal) {
            reveal.censored = false;
            reveal.source = row.stealer;
            reveal.ip = row.ip;
            reveal.path = row.malware_path;
            reveal.url = row.creds.data.url;
            reveal.login = row.creds.data.login;
            reveal.fb = row.fb;
            reveal.av = row.av;
            reveal.pc = row.pc;
            reveal.os = row.os;
            reveal.password = row.creds.data.password;
            reveal.blurPasswords = row.blurPasswords;
            if (reveal.tree && reveal.tree.name) {
              reveal.tree.name = row.tree.name;
            }
          }
        });
        if (totalUnlocked.length > uniqueUnlocked.length) {
          const modalHTML = afterUnlockModal(
            uniqueUnlocked.length,
            totalUnlocked.length
          );
          setGlobalModalBody(modalHTML);
          setGlobalModalOpen(true);
        }
      })
      .catch((error) => {
        console.log(error);
        setSelected([]);
        setOverlay(false);
        error.response && error.response.data === "credits" && setOpen(true);
        setMessage("Not Enough Credits");
      });

    // getCounters();
    setSelected([]);
    setOverlay(false);

    // openForm();
  };

  // const unlockCreds = async (creds) => {

  // };

  const redirect = (e) => {
    history.push(e);
    window.location.reload();
  };

  useEffect(async () => {
    const response = await api.fetchSuggestions(domain.toLowerCase());
    if (response[0]) {
      setCurrentCompany(response[0]);
    } else {
      let obj = {
        domain: domain.toLowerCase(),
        logo: "",
        name: domain.toLowerCase(),
      };
      setCurrentCompany(obj);
    }
  }, [domain]);

  const addProspect = async (name, domain, isAndroid) => {
    setProspectLoader(true);
    let apps = [];
    let domains = [];
    let type, companyData;

    const config = {
      headers: {
        "auth-token": user.token,
      },
    };

    if (isAndroid > 0) {
      type = "apk";
      apps.push(domain);
    } else {
      type = "domain";
      domains.push(domain);
    }

    const result = api.getCompanies(domain);

    result.then(async (company) => {
      if (company) {
        companyData = {
          primary_domain: company.job_company_website,
          name: company.job_company_name,
          industry: company.job_company_industry,
          continent: company.job_company_location_continent,
          country: company.job_company_location_country,
          city: company.job_company_location_locality,
          full_location: company.job_company_location_name,
          size: company.job_company_size,
          linkedin: company.job_company_linkedin_url,
          facebook: company.job_company_facebook_url,
          twitter: company.job_company_twitter_url,
        };
      } else {
        companyData = {
          primary_domain: domain,
          name: name,
        };
      }
      let obj = {
        name: name,
        company: companyData,
        domains: domains,
        apps: apps,
        parties: [],
      };

      const response = await api.editUser("/add-prospect", obj, config);

      if (response) {
        setProspectLoader(false);
        setProspectAdded(true);
        setTimeout(() => {
          props.resetUser(response);
          setProspects(response.prospects);
        }, 2000);
      }
    });
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    refreshUser();
  }, [overlay, data]);

  useEffect(() => {
    setCredits(props.user.user.credits);
    setUser(props.user);
  }, [props.user]);

  useEffect(() => {
    handleClose();
  }, [credits]);

  const refreshUser = async () => {
    const config = {
      headers: {
        "auth-token": props.user.token,
      },
    };
    await axios
      .post(`${authAddress}/refresh-user`, {}, config)
      .then((res) => {
        props.resetUser(res.data.user);
        setCredits(res.data.user.credits);
      })
      .catch((error) => console.log(error));
  };

  return (
    <div className="App">
      <AppBarNew
        handleHomeLogOut={props.handleHomeLogOut}
        className="mobileHeader"
        currentType={path === "search-by-domain" ? "domain" : "prospect"}
        user={user}
        role={role}
        token={token}
        email={email}
        domains={domains}
        domain={domain}
        handleLogOut={props.handleLogOut}
        prospects={prospects}
        path={path}
        redirect={(e) => redirect(e)}
        filterCompanies={(industries, sizes, countries, regions) =>
          props.filterCompanies(industries, sizes, countries, regions)
        }
        credits={credits}
        resetUser={(user) => props.resetUser(user)}
        data={data}
        openTopUps={() =>
          props.user.sub && props.user.sub.status != "pending" && handleOpen()
        }
      />
      <SimpleModal
        title="Top-Up Your Credits Balance for Additional Unlocks:"
        open={open}
        message={message}
        handleOpen={handleOpen}
        handleClose={handleClose}
        topUpLoader={props.topUpLoader}
        openTopUpModal={(item) => props.openTopUpModal(item)}
        user={props.user}
      />
      <GlobalModal
        open={globalModalOpen}
        handleOpen={() => setGlobalModalOpen(true)}
        handleClose={() => setGlobalModalOpen(false)}
        user={props.user}
        body={globalModalBody}
      />
      <div id="results-page">
        {/* <FormDialog
          unlockCreds={(creds) => unlockCreds(creds)}
          handleClose={closeForm}
          open={formOpen}
          credsToUnlock={credsToUnlock}
          type="unlock"
        /> */}
        <div id="tabsWithResults">
          <ScrollableTabsButtonForce
            credits={credits}
            hasNextPage={hasNextPage}
            handleHomeLogOut={props.handleHomeLogOut}
            resData={resData}
            stealersCount={stealersCount}
            employeesCount={employeesCount}
            androidsCount={androidsCount}
            clientsCount={clientsCount}
            dbsCount={dbsCount}
            user={user}
            role={role}
            token={token}
            domains={domains}
            domain={domain}
            getStats={getStats}
            getData={getData}
            getMonitoredDomains={getMonitoredDomains}
            monitoredDomains={monitoredDomains}
            monitorCount={monitorCount}
            allMonitoredCounts={allMonitoredCounts}
            counts={counts}
            labels={timeLabels}
            loading={loading}
            employeesStats={employeesStats}
            clientsStats={clientsStats}
            cities={cities}
            employeesUrls={employeesUrls}
            clientsUrls={clientsUrls}
            employeesUrlsCount={employeesUrlsCount}
            clientsUrlsCount={clientsUrlsCount}
            data={data}
            userRole={role}
            selectedDomain={activeDomains}
            machines={machines}
            loader={loader}
            finalRes={finalRes}
            config={config}
            loadingCounters={loadingCounters}
            section={section}
            handleYearChange={handleYearChange}
            year={year}
            switchToAllTimeChart={switchToAllTimeChart}
            switchToMonthlyChart={switchToMonthlyChart}
            monthly={monthly}
            yearsOptions={yearsOptions}
            clientsCities={clientsCities}
            employeesCities={employeesCities}
            currentYear={currentYear}
            getMapData={getMapData}
            mapLoading={mapLoading}
            chartLoader={chartLoader}
            dataLoading={dataLoading}
            unlockedStealersCount={unlockedStealersCount}
            unlockedStealers={unlockedStealers}
            getCounters={getCounters}
            tier={tier}
            path={path}
            handleSelectAllClick={handleSelectAllClick}
            selectRow={(row) => selectRow(row)}
            unlockStealer={(type, page, isExternal) =>
              unlockStealer(type, page, isExternal)
            }
            selected={selected}
            currentProspect={currentProspect}
            currentCompany={currentCompany}
            addProspect={(name, domain, isAndroid) =>
              addProspect(name, domain, isAndroid)
            }
            addProspectLoader={addProspectLoader}
            prospects={prospects}
            prospectAdded={prospectAdded}
            handleLogOut={props.handleLogOut}
            setOpen={(action) => setOpen(action)}
            setMessage={(message) => setMessage(message)}
            openUpgradeModal={props.openUpgradeModal}
            upgradeLoader={props.upgradeLoader}
            refreshUser={refreshUser}
            fetchDehashed={() => {
              currentProspect && currentProspect.domains
                ? props.fetchDehashed(currentProspect.domains)
                : props.fetchDehashed([domain]);
            }}
            dehashedMessage={props.dehashedMessage}
            dehashLoader={props.dehashLoader}
            getAssetDiscovery={getAssetDiscovery}
            assets={assets}
            assetsCount={assetsCount}
            resetUser={(user) => props.resetUser(user)}
            getUrls={(endpoint, user) => getUrls(endpoint, user)}
            pieLoading={pieLoading}
            partiesCount={partiesCount}
            inPageLoading={inPageLoading}
            empLength={empLength}
            setSelectedExternals={setSelectedExternals}
            selectedExternals={selectedExternals}
            externalSuggestions={externalSuggestions}
            externalCount={externalCount}
          />
        </div>
        {overlay && (
          <Overlay
            content="unlock"
            charge={Math.ceil(credsToUnlock.length / 10)}
          />
        )}

        {/* )} */}
        {!loading && !dataLoading && <Footer />}
      </div>
    </div>
  );
}

export default Domain;
