import React, { useState, useEffect } from "react";
import logo from "../../images/hrlogo_new.png";
import { useLocation } from "react-router";
import { authAddress, APIFullAddress } from "../../Config";
import axios from "axios";
import LoaderIcon from "react-loader-icon";

export default function Activation(props) {
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [confirmed, setConfirmed] = useState(false);
  const [resent, setResent] = useState(null);
  const location = useLocation();
  const id = location.pathname.split("/")[2];
  const token = location.pathname.split("/")[3];

  useEffect(() => {
    if (token) {
      activateUser();
    }
  }, [token]);

  const activateUser = async () => {
    setLoading(true);
    await axios
      .post(`${authAddress}/activate-account/${id}/${token}`, {}, {})
      .then((res) => {
        if (res.data._id === id) {
          setConfirmed(true);
          // setTimeout(() => {
          //   window.location.href = "/";
          // }, 2000);
        } else {
          setError(
            "Confirmation link is invalid or expired, please contact support."
          );
        }
      })
      .catch((err) => {
        setError(
          "Confirmation link is invalid or expired, please contact support."
        );
      });
    setLoading(false);
  };

  const resendEmail = async () => {
    await axios
      .post(`${authAddress}/resend-activation-email/${id}`, {}, {})
      .then((res) => {
        setResent(res.data);
      })
      .catch((err) => {
        setResent(err.response.data);
      });
  };

  return (
    <div style={{ height: "100vh", textAlign: "center", padding: "3rem" }}>
      <div style={{ margin: "2rem auto" }}>
        <img src={logo} alt="logo" width="400px" className="signupLogo" />
      </div>
      {!token ? (
        <div
          className="styledBox"
          style={{
            backgroundColor: "#fbcd4e",
            color: "black",
            margin: "auto",
            width: "60%",
          }}
        >
          <h3>Thank you for creating an account with us!</h3>
          <span style={{ color: "black" }}>
            In order to complete the registration process, we need you to
            confirm your email address.
            <br /> An email containing a confirmation link has been sent to the
            address provided during registration.
            <br />
            Please click on the link to confirm your email.
            <br />
            <br />
            Please note, your account is still pending approval by our team. You
            will receive an additional email shortly.
            <br /> <br />
            If you did not receive an email from us, <br />
            please check your spam folder or resend by clicking the button
            below:
            <br />
            <br />
            {!resent ? (
              <button
                onClick={resendEmail}
                style={{
                  backgroundColor: "#1A1C20",
                  color: "#fbcd4e",
                  padding: "4px 12px",
                  borderRadius: "4px",
                  border: "0px",
                }}
              >
                Resend
              </button>
            ) : (
              <span>{resent}</span>
            )}
          </span>
        </div>
      ) : (
        <div
          className="styledBox"
          style={{
            backgroundColor: "#fbcd4e",
            color: "black",
            margin: "auto",
            width: "60%",
          }}
        >
          {loading ? (
            <>
              <h3>Validating token...</h3>
              <LoaderIcon type="spin" className="tableLoader" style={{}} />
            </>
          ) : (
            <>
              {confirmed ? (
                <>
                  <h3>Your email has been verified!</h3>
                  <h5>
                    Please note, your account is still pending approval by our
                    team. You will receive an additional email shortly.
                  </h5>
                </>
              ) : (
                error && <h3>{error}</h3>
              )}
            </>
          )}
        </div>
      )}
    </div>
  );
}
