import React, { useState } from "react";
import AppBarNew from "../Partials/AppBar";
import Scribes from "./Scribes";
import SimpleModal from "../Partials/BasicModal";

export default function Tutorials(props) {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <div className="App">
      <div>
        <AppBarNew
          className="mobileHeader"
          user={props.user}
          role={props.role}
          token={props.token}
          email={props.email}
          handleLogOut={props.handleLogOut}
          filterCompanies={(industries, sizes, countries, regions) =>
            props.filterCompanies(industries, sizes, countries, regions)
          }
          handleHomeLogOut={props.handleHomeLogOut}
          resetUser={(user) => props.resetUser(user)}
          credits={props.user.user.credits}
          openTopUps={() =>
            props.user.sub && props.user.sub.status != "pending" && handleOpen()
          }
        />
        <SimpleModal
          user={props.user}
          title="Top-Up Your Credits Balance for Additional Unlocks:"
          open={open}
          handleOpen={handleOpen}
          handleClose={handleClose}
          topUpLoader={props.topUpLoader}
          openTopUpModal={(item) => props.openTopUpModal(item)}
        />
        <Scribes />
      </div>
    </div>
  );
}
